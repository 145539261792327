import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import CreateProfileForm from "components/CreateProfileForm";
import Snackbar from "./Snackbar";
import { ROUTE_FREE_TRIAL_SUCCESS } from "../constants/routes";
import { useForm } from "react-hook-form";
import RBAPI from "api/RoadwayAPI";
import { submitHubSpotForm } from "utils/hubspot";
import { FREETRIAL_FORM_ID } from "constants/hubspot";
import { setSnackbarMessageAndOpen, MessageTypes } from "actions/snackbar";
import { useDispatch } from "react-redux";

export type FreeTrialFormData = {
  email: string;
  organizationName: string;
  firstName: string;
  lastName: string;
  password: string;
  verifyPassword: string;
};

const FreeTrialContainer: React.FC = () => {
  const { register, handleSubmit, watch, errors } = useForm<
    FreeTrialFormData
  >();
  const location = useLocation();
  const history = useHistory();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    handleSubmit(async data => {
      const { email, organizationName, firstName, lastName, password } = data;
      setFormSubmitted(true);
      try {
        if (process.env.NODE_ENV === "production") {
          await submitHubSpotForm(
            firstName,
            lastName,
            email,
            process.env.REACT_APP_ROADWAY_URL + "/" + location.pathname,
            FREETRIAL_FORM_ID
          );
        }
      } catch (e) {
        console.error(e);
      }

      try {
        await RBAPI.createFreeTrial({
          email,
          organizationName,
          firstName,
          lastName,
          password,
        });
        history.push(ROUTE_FREE_TRIAL_SUCCESS);
      } catch (e) {
        console.error(e);
        dispatch(setSnackbarMessageAndOpen(`Failed to create free trial account with organization ${organizationName}. Contact your administrator to see if they have already created an account for your organization.`, MessageTypes.ERROR));
        setFormSubmitted(false);
      }
    })(event);
  };

  return (
    <React.Fragment>
      <CreateProfileForm
        title={"RoadWay Free Trial"}
        register={register}
        watch={watch}
        errors={errors}
        onSubmit={onSubmit}
        location={location}
        formSubmitted={formSubmitted}
      />
      <Snackbar />
    </React.Fragment>
  );
};

export default FreeTrialContainer;
