export const ROUTE_MAP = "/map";
export const ROUTE_LOGIN = "/login";
export const ROUTE_DASHBOARD = "/dashboard";
export const ROUTE_ADMIN = "/admin";
export const ROUTE_NOT_FOUND = "/not-found";
export const ROUTE_WRONG_ROADWAY = "/roadway";
export const ROUTE_SUMMARY = "/summary";
export const ROUTE_FREE_TRIAL = "/free-trial";
export const ROUTE_FREE_TRIAL_SUCCESS = "/free-trial-success";
export const ROUTE_ROAD_SENSE_SIGN_UP = "/road-sense";
// image-logger redirect is necessary for old social media posts that need to direct
// to road-sense
export const ROUTE_IMAGE_LOGGER_SIGN_UP = "/image-logger";
export const ROUTE_ROAD_SENSE_SIGN_UP_SUCCESS = "/road-sense-success";

export const PUBLIC_ROUTES = [
  ROUTE_FREE_TRIAL,
  ROUTE_FREE_TRIAL_SUCCESS,
  ROUTE_IMAGE_LOGGER_SIGN_UP,
  ROUTE_ROAD_SENSE_SIGN_UP,
  ROUTE_ROAD_SENSE_SIGN_UP_SUCCESS,
  ROUTE_FREE_TRIAL,
  ROUTE_WRONG_ROADWAY,
];
