import React from "react";
import LayerSwitch from "components/LayerSwitch";
import get from "lodash/get";
import { toggleLayer } from "actions/layers";
import { connect } from "react-redux";
import track from "react-tracking";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser/dist/index";

import { getLayer, getLayerStatus } from "utils/layers";
import RBAPI from "api/RoadwayAPI";

const RatingsLayerSwitchContainer = ({
  toggleLayer,
  tracking,
  userUId,
  activeScan,
  layerGroups,
  toggle,
}) => {
  const toggleLayerBySource = layerSource => {
    tracking.trackEvent({
      event: "mouse-click",
      action: `ratings-layer-switch-${layerSource}`,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `ratings layer switch ${layerSource}`,
      level: Sentry.Severity.Info,
    });

    const layer = getLayer(layerSource, layerGroups);
    const layerId = get(layer, "id");
    if (layer) {
      toggleLayer(layerId);
    }

    if (layerSource !== "missing") {
      // get variables for firestore
      const layerData = getLayerStatus(layerSource, layerId, layerGroups);

      const scanState = {
        [layer.name]: !layerData.visible,
      };
      RBAPI.updateUserLayerState(userUId, activeScan, scanState).catch(err => {
        console.error(err);
      });
    }
  };

  const isLayerVisible = layerSource => {
    if (layerSource !== "missing") {
      const layer = getLayer(layerSource, layerGroups);
      return layer.visible;
    } else {
      return false;
    }
  };

  const isLayerHidden = layerSource => {
    if (layerSource !== "missing") {
      const layer = getLayer(layerSource, layerGroups);
      return layer.hidden;
    } else {
      return false;
    }
  };

  return (
    <LayerSwitch
      key={toggle.id}
      label={toggle.displayName}
      name={`${toggle.displayName} ${toggle.id}`}
      onChange={() => toggleLayerBySource(toggle.layerId)}
      checked={isLayerVisible(toggle.layerId)}
      disabled={isLayerHidden(toggle.layerId)}
    />
  );
};

RatingsLayerSwitchContainer.propTypes = {
  toggleLayer: PropTypes.func.isRequired,
  tracking: PropTypes.shape({
    trackEvent: PropTypes.func.isRequired,
  }).isRequired,
  activeScan: PropTypes.string.isRequired,
  userUId: PropTypes.string.isRequired,
  layerGroups: PropTypes.array.isRequired,
  toggle: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  layerGroups: state.layers.groups,
  activeScan: state.userData.activeScan,
  userUId: state.user.userUId,
});

const mapDispatchToProps = dispatch => ({
  toggleLayer: layerId => dispatch(toggleLayer(layerId)),
});

export default track()(
  connect(mapStateToProps, mapDispatchToProps)(RatingsLayerSwitchContainer)
);
