import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/styles/withStyles";
import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { RB_POTHOLE_ORANGE } from "constants/colors";
import {
  POTHOLES,
  FATIGUE_CRACKING,
  PAVEMENT_DISTORTIONS,
  PATCH_SEAL,
  TRANS_LONG_CRACKING,
  SURFACE_DETERIORATION,
} from "constants/distressTypes";
import Divider from "@material-ui/core/Divider";

const styles = {
  title: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: "bold",
    fontSize: "18px",
  },
  checkboxGroup: {
    textAlign: "center",
  },
  checkbox: {
    display: "inline-block",
  },
  label: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: "bold",
    fontSize: "10px",
    display: "inline-block",
    textAlign: "left",
  },
  gridCheckbox: {
    textAlign: "left",
    paddingLeft: "10px",
  },
  divContainer: {
    marginTop: "20px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
  },
};

const OrangeCheckbox = withStyles({
  root: {
    "&$checked": {
      color: RB_POTHOLE_ORANGE,
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const IsolateDistressGroup = ({
  classes,
  potholeChecked,
  fatigueCrackingChecked,
  patchSealChecked,
  transLongChecked,
  surfaceDeteriorationChecked,
  pavementDistortionsChecked,
  handleDistressCheckChange,
}) => {
  return (
    <div className={classes.divContainer}>
      <Grid className={classes.header} container spacing={4}>
        <Grid item xs={4}>
          <Divider />
        </Grid>
        <Grid item xs={4}>
          <Typography align="center" className={classes.title}>
            Isolate Distresses
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Divider />
        </Grid>
      </Grid>

      <Grid
        container
        className={classes.checkboxGroup}
        justify="center"
        alignItems="center"
      >
        <Grid container>
          <Grid item xs className={classes.gridCheckbox}>
            <div className={classes.checkbox}>
              <FormControlLabel
                control={
                  <OrangeCheckbox
                    value={potholeChecked}
                    checked={potholeChecked}
                    onChange={handleDistressCheckChange}
                    name="potholeChecked"
                  />
                }
                label={
                  <Typography className={classes.label}>{POTHOLES}</Typography>
                }
              />
            </div>
          </Grid>
          <Grid item xs className={classes.gridCheckbox}>
            <div className={classes.checkbox}>
              <FormControlLabel
                control={
                  <OrangeCheckbox
                    value={fatigueCrackingChecked}
                    checked={fatigueCrackingChecked}
                    onChange={handleDistressCheckChange}
                    name="fatigueCrackingChecked"
                  />
                }
                label={
                  <Typography className={classes.label}>
                    {FATIGUE_CRACKING}
                  </Typography>
                }
              />
            </div>
          </Grid>
          <Grid item xs className={classes.gridCheckbox}>
            <div className={classes.checkbox}>
              <FormControlLabel
                control={
                  <OrangeCheckbox
                    value={pavementDistortionsChecked}
                    checked={pavementDistortionsChecked}
                    onChange={handleDistressCheckChange}
                    name="pavementDistortionsChecked"
                  />
                }
                label={
                  <Typography className={classes.label}>
                    {PAVEMENT_DISTORTIONS}
                  </Typography>
                }
              />
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs className={classes.gridCheckbox}>
            <div className={classes.checkbox}>
              <FormControlLabel
                control={
                  <OrangeCheckbox
                    value={patchSealChecked}
                    checked={patchSealChecked}
                    onChange={handleDistressCheckChange}
                    name="patchSealChecked"
                  />
                }
                label={
                  <Typography className={classes.label}>
                    {PATCH_SEAL}
                  </Typography>
                }
              />
            </div>
          </Grid>
          <Grid item xs className={classes.gridCheckbox}>
            <div className={classes.checkbox}>
              <FormControlLabel
                control={
                  <OrangeCheckbox
                    value={transLongChecked}
                    checked={transLongChecked}
                    onChange={handleDistressCheckChange}
                    name="transLongChecked"
                  />
                }
                label={
                  <Typography className={classes.label}>
                    {TRANS_LONG_CRACKING}
                  </Typography>
                }
              />
            </div>
          </Grid>
          <Grid item xs className={classes.gridCheckbox}>
            <div className={classes.checkbox}>
              <FormControlLabel
                control={
                  <OrangeCheckbox
                    value={surfaceDeteriorationChecked}
                    checked={surfaceDeteriorationChecked}
                    onChange={handleDistressCheckChange}
                    name="surfaceDeteriorationChecked"
                  />
                }
                label={
                  <Typography className={classes.label}>
                    {SURFACE_DETERIORATION}
                  </Typography>
                }
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

IsolateDistressGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  potholeChecked: PropTypes.bool.isRequired,
  fatigueCrackingChecked: PropTypes.bool.isRequired,
  transLongChecked: PropTypes.bool.isRequired,
  surfaceDeteriorationChecked: PropTypes.bool.isRequired,
  pavementDistortionsChecked: PropTypes.bool.isRequired,
  patchSealChecked: PropTypes.bool.isRequired,
  handleDistressCheckChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(IsolateDistressGroup);
