import { OPEN_COG, CLOSE_COG } from "actiontypes/settingsCog";

export function openCog() {
  return {
    type: OPEN_COG,
  };
}

export function closeCog() {
  return {
    type: CLOSE_COG,
  };
}
