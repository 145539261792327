import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import DynamicSwitch from "components/DynamicSwitch";
import PropTypes from "prop-types";

const styles = {
  root: {
    marginLeft: 0,
    marginRight: 0,
  },
  label: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: "bold",
  },
};

const LayerSwitch = ({
  classes,
  label,
  color,
  onChange,
  checked,
  disabled,
  name,
}) => (
  <FormControlLabel
    labelPlacement="start"
    data-testid={checked ? `switch-on-${name}` : `switch-off-${name}`}
    label={label}
    disabled={disabled}
    classes={{
      root: classes.root,
      label: classes.label,
    }}
    control={
      <DynamicSwitch
        onChange={onChange}
        color={color}
        checked={checked}
        disabled={disabled}
      />
    }
  />
);

LayerSwitch.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default withStyles(styles)(LayerSwitch);
