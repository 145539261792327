import { MAPBOX_TOKEN } from "constants/mapbox";
import mbxGeocode from "@mapbox/mapbox-sdk/services/geocoding";

const geocodeClient = mbxGeocode({ accessToken: MAPBOX_TOKEN });

let finalAddress;

const lookupAddress = async ({ lng, lat }) => {
  await geocodeClient
    .reverseGeocode({
      query: [lng, lat],
      types: ["address"],
      limit: 1,
    })
    .send()
    .then(response => {
      const feature = response.body.features[0];
      if (feature) {
        if (feature.address !== undefined) {
          finalAddress = `${feature.address} ${feature.text}`;
        } else {
          finalAddress = feature.text;
        }
      }
      // return response.body.features[0].text;
    })
    .catch(e => {
      console.error(e);
      finalAddress = "Not Found";
    });
};

const returnAddress = async content => {
  await lookupAddress(content);
  return finalAddress;
};

export default returnAddress;
