import React from "react";
import FreeTrialWelcomeDialogContent from "../components/FreeTrialWelcomeDialogContent";
import WelcomeDialogContent from "../components/WelcomeDialogContent";
import Dialog from "@material-ui/core/Dialog";
import { useSelector } from "react-redux";
import State from "../interfaces/state";

type WelcomeDialogContainerProps = {
  isOrganizationsDataLoaded: boolean;
  showWelcome: boolean;
  dismissDialog: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  checked: boolean;
  handleCheckboxChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
};

const WelcomeDialogContainer = ({
  isOrganizationsDataLoaded,
  showWelcome,
  dismissDialog,
  checked,
  handleCheckboxChange,
}: WelcomeDialogContainerProps): React.ReactElement => {
  const isFreeTrialUser = useSelector(
    (state: State) => state?.user?.isFreeTrialUser || false
  );
  return (
    <Dialog
      open={isOrganizationsDataLoaded && showWelcome}
      onClose={dismissDialog}
      maxWidth="md"
    >
      {isFreeTrialUser ? (
        <FreeTrialWelcomeDialogContent
          checked={checked}
          handleCheckboxChange={handleCheckboxChange}
          dismissDialog={dismissDialog}
        />
      ) : (
        <WelcomeDialogContent
          checked={checked}
          handleCheckboxChange={handleCheckboxChange}
          dismissDialog={dismissDialog}
        />
      )}
    </Dialog>
  );
};

export default WelcomeDialogContainer;
