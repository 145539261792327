import React from "react";
import PropTypes from "prop-types";
import track from "react-tracking";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import { connect } from "react-redux";

import { setMapPitch, setMapStyle, setMapZoom } from "actions/map";
import { hideHighlight, clearSegments } from "actions/highlight";
import {
  turnOffDynamicSegmentationDrag,
  turnOffDynamicSegmentationSelect,
  turnOffDynamicSegmentationLasso,
} from "actions/dynamicSegmentation";

import { hideModal } from "actions/modal";
import { onPresentation, offPresentation } from "actions/rotation";
import {
  onMeasure,
  offMeasure,
  updateDistance,
  clearAllMeasure,
  clearSingleMeasure,
} from "actions/measureDistance";
import { clearAllArea, clearSingleArea, updateArea } from "actions/measureArea";
import { openCog, closeCog } from "actions/settingsCog";

import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import SpeedDialMapAction from "components/SpeedDialMapAction";

import GPSFixedIcon from "@material-ui/icons/GpsFixed";
import DeleteIcon from "@material-ui/icons/DeleteRounded";
import RotationContainer from "containers/RotationContainer/RotationContainer";
import MeasureDistanceButtonContainer from "containers/MeasureDistanceButtonContainer";
import MeasureAreaButtonContainer from "containers/MeasureAreaButtonContainer";
import MeasureContainer from "containers/MeasureContainer";
import AreaContainer from "containers/AreaContainer";
import DynamicSegmentationContainer from "containers/DynamicSegmentationContainer";
import DynamicSegmentationButtonContainer from "containers/DynamicSegmentationButtonContainer";

import {
  DEFAULT_ZOOM_LEVEL,
  DEFAULT_MAP_PITCH,
  DEFAULT_MAP_BEARING,
} from "constants/map";
import { ScreenCaptureTypes } from "constants/screenshotTypes";
import ScreenCaptureContainer from "containers/ScreenCaptureContainer";
import { browserCheck } from "utils/deviceCheck";
import ShowDistanceContainer from "../ShowDistanceContainer/ShowDistanceContainer";
import ShowAreaContainer from "../ShowDistanceContainer/ShowAreaContainer";
import ToolboxIconClose from "components/icons/ToolboxIconClose";
import ToolboxIconOpen from "components/icons/ToolboxIconOpen";

import style from "./style";
import * as Sentry from "@sentry/browser/dist/index";
import {
  MAP_STYLE_DARK,
  MAP_STYLE_LIGHT,
  MAP_STYLE_SATELLITE,
  MAP_STYLE_TOPOGRAPHIC_METRIC,
  MAP_STYLE_TOPOGRAPHIC_IMPERIAL,
} from "../../constants/mapStyles";
import SettingsCogSkeleton from "../../skeletons/settingsCog";
import { MeasuringSystem } from "constants/measuringSystem";

class SettingsCog extends React.Component {
  state = {
    open: false,
    showScreenCap: false,
  };

  componentDidMount() {
    // check to see if we are using chrome or firefox or not iPad.
    // No known workaround for screenshots in other browsers
    if (browserCheck()) {
      this.setState({ showScreenCap: true });
    }
  }

  componentWillUnmount() {
    this.clearMap(false);
  }

  startPresenting = () => {
    const {
      map,
      isRotating,
      offPresentation,
      onPresentation,
      measureArea: { isAreaing, draw },
      tracking,
      isDynamicSegmentationLassoActive,
    } = this.props;

    tracking.trackEvent({
      event: "mouse-click",
      action: "toggle-presentation-click",
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `toggle presentation click`,
      level: Sentry.Severity.Info,
    });

    if (isRotating) {
      offPresentation();
      setTimeout(() => map.setBearing(0), 10);
    } else {
      onPresentation();
      if (isAreaing || isDynamicSegmentationLassoActive) {
        map.removeControl(draw);
      }
    }
  };

  centerMap = () => {
    const {
      map,
      center,
      hideModal,
      setMapZoom,
      setMapPitch,
      tracking,
    } = this.props;
    tracking.trackEvent({ event: "mouse-click", action: "center-map-click" });
    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `center map click`,
      level: Sentry.Severity.Info,
    });
    map.setCenter(center);
    setMapZoom(DEFAULT_ZOOM_LEVEL);
    map.setBearing(DEFAULT_MAP_BEARING);
    setMapPitch(DEFAULT_MAP_PITCH);
    hideModal();
  };

  clearArea = () => {
    const {
      measureArea: { draw },
      clearAllArea,
      tracking,
      map,
    } = this.props;
    tracking.trackEvent({ event: "mouse-click", action: "clear-area-click" });
    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `clear area click`,
      level: Sentry.Severity.Info,
    });
    clearAllArea();
    map.removeControl(draw);
  };

  clearDistance = () => {
    const { clearAllMeasure, tracking, map } = this.props;
    tracking.trackEvent({
      event: "mouse-click",
      action: "clear-measure-click",
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `clear measure click`,
      level: Sentry.Severity.Info,
    });

    clearAllMeasure(map);
  };

  clearMap = (shouldRemoveControl = true, isLassoBeingToggled = false) => {
    const {
      hideHighlight,
      hideModal,
      clearAllMeasure,
      map,
      measureArea: { draw, isAreaing },
      clearAllArea,
      tracking,
      turnOffDynamicSegmentationDrag,
      turnOffDynamicSegmentationSelect,
      turnOffDynamicSegmentationLasso,
      clearSegments,
      isDynamicSegmentationLassoActive,
    } = this.props;
    tracking.trackEvent({ event: "mouse-click", action: "clear-map-click" });
    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `clear map click`,
      level: Sentry.Severity.Info,
    });
    hideHighlight();
    hideModal();
    clearAllMeasure(map);
    if (isAreaing || isDynamicSegmentationLassoActive) {
      if (isAreaing) {
        clearAllArea();
      }
      if (shouldRemoveControl) {
        map.removeControl(draw);
      }
    }
    turnOffDynamicSegmentationDrag();
    turnOffDynamicSegmentationSelect();
    if (!isLassoBeingToggled) {
      turnOffDynamicSegmentationLasso();
    }
    clearSegments();
  };

  clearSingleArea = () => {
    const {
      clearSingleArea,
      measureArea: { draw },
      tracking,
    } = this.props;

    tracking.trackEvent({
      event: "mouse-click",
      action: "measure-area-clear-single",
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `measure area clear single`,
      level: Sentry.Severity.Info,
    });

    clearSingleArea();
    draw.deleteAll();
    draw.changeMode("draw_polygon");
  };

  changeUnitsArea = e => {
    const {
      measureArea: { originalArea },
      updateArea,
      tracking,
    } = this.props;

    tracking.trackEvent({
      event: "mouse-click",
      action: "measure-area-unit-change",
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `measure area unit change`,
      level: Sentry.Severity.Info,
    });

    const unit = e.target.value;
    const unitConvertMap = {
      yards: 9,
      acres: 43560,
      miles: 27880000,
      feet: 1,
      kilometers: 1000000,
      meters: 1,
    };

    return updateArea(originalArea / unitConvertMap[unit], unit);
  };

  reloadLayers = () => {
    const { map, geojson, colorPicker } = this.props;
    if (
      geojson &&
      !map.getLayer("measure-points") &&
      !map.getLayer("measure-lines") &&
      !map.getSource("geojson")
    ) {
      map.addSource("geojson", {
        type: "geojson",
        data: geojson,
      });
      // Add styles to the map
      map.addLayer({
        id: "measure-points",
        type: "circle",
        source: "geojson",
        paint: {
          "circle-radius": 5,
          "circle-color": colorPicker.MEASURE_LINE,
        },
        filter: ["in", "$type", "Point"],
      });
      map.addLayer({
        id: "measure-lines",
        type: "line",
        source: "geojson",
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
        paint: {
          "line-color": colorPicker.MEASURE_LINE,
          "line-width": 2.5,
          "line-dasharray": [4, 4],
        },
        filter: ["in", "$type", "LineString"],
      });
    }
  };

  setMapStyle = style => {
    const { setMapStyle, map, tracking } = this.props;

    tracking.trackEvent({
      event: "mouse-click",
      action: `map-style-change-${style}`,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `map style change ${style}`,
      level: Sentry.Severity.Info,
    });

    // this reloads the layers back on top of the base when changing the styles
    map.on("style.load", this.reloadLayers);

    setMapStyle(style);
  };

  handleChange = () => {
    const { tracking, settingsCogOpen, openCog, closeCog } = this.props;
    const { open } = this.state;
    tracking.trackEvent({
      event: "mouse-click",
      action: `settings-cog-click-${open ? "close" : "open"}`,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `settings-cog-click-${open ? "close" : "open"}`,
      level: Sentry.Severity.Info,
    });

    if (settingsCogOpen) {
      closeCog();
    } else {
      openCog();
    }
  };

  generateTopActions = () => {
    return [
      {
        title: "Location",
        action: () => this.centerMap(),
        icon: <GPSFixedIcon data-testid="Location" />,
      },
      {
        title: "Clear",
        action: () => this.clearMap(),
        icon: <DeleteIcon data-testid="Clear" />,
      },
      {
        title: "Present",
        action: () => this.startPresenting(),
        icon: <RotationContainer data-testid="Present" />,
      },
      {
        title: "Distance",
        icon: <MeasureDistanceButtonContainer />,
      },
      {
        title: "Area",
        icon: <MeasureAreaButtonContainer data-testid="Area" />,
      },
      {
        title: "Dynamic Segmentation",
        icon: <DynamicSegmentationButtonContainer />,
      },
      {
        title: "Screenshot",
      },
    ];
  };

  generateRightActions = () => {
    const { measuringSystem } = this.props;
    return [
      {
        title: "Dark Map",
        action: () => this.setMapStyle(MAP_STYLE_DARK),
        backgroundImage: "DarkMap",
      },
      {
        title: "Light Map",
        action: () => this.setMapStyle(MAP_STYLE_LIGHT),
        backgroundImage: "LightMap",
      },
      {
        title: "Satellite Map",
        action: () => this.setMapStyle(MAP_STYLE_SATELLITE),
        backgroundImage: "SatelliteMap",
      },
      {
        title: "Topographic Map",
        action: () =>
          this.setMapStyle(
            measuringSystem === MeasuringSystem.Imperial
              ? MAP_STYLE_TOPOGRAPHIC_IMPERIAL
              : MAP_STYLE_TOPOGRAPHIC_METRIC
          ),
        backgroundImage: "TopographicMap",
      },
    ];
  };

  renderTopActions = () => {
    const { showScreenCap } = this.state;
    const { classes, map, settingsCogOpen } = this.props;
    const topActions = this.generateTopActions();

    return topActions.map(action => {
      if (action.title === "Distance") {
        return (
          <MeasureContainer
            key={`spdial-up-${action.title}`}
            action={action}
            open={settingsCogOpen}
            clearMap={this.clearMap}
            map={map}
          />
        );
      } else if (action.title === "Area") {
        return (
          <AreaContainer
            key={`spdial-up-${action.title}`}
            action={action}
            open={settingsCogOpen}
            clearMap={this.clearMap}
            map={map}
          />
        );
      } else if (action.title === "Dynamic Segmentation") {
        return (
          <DynamicSegmentationContainer
            key={`spdial-up-${action.title}`}
            open={settingsCogOpen}
            action={action}
            map={map}
            clearMap={this.clearMap}
          />
        );
      } else if (!showScreenCap && action.title === "Screenshot") {
        return null;
      } else if (action.title === "Screenshot") {
        return (
          <ScreenCaptureContainer
            action={action}
            open={settingsCogOpen}
            key={`spdial-up-${action.title}`}
          />
        );
      } else {
        return (
          <SpeedDialAction
            key={`spdial-up-${action.title}`}
            tooltipTitle={action.title}
            className={classes.speedDialAction}
            tooltipPlacement="right"
            icon={action.icon}
            onClick={action.action}
          />
        );
      }
    });
  };

  renderRightActions() {
    const { classes } = this.props;
    const rightActions = this.generateRightActions();
    return rightActions.map(action =>
      action.hasOwnProperty("backgroundImage") ? (
        <SpeedDialMapAction
          FabProps={{ "data-testid": action.title }}
          key={`spdial-right-${action.title}`}
          tooltipTitle={action.title}
          tooltipPlacement="top"
          backgroundImage={action.backgroundImage}
          onClick={action.action}
          className={classes.speedDialAction}
        />
      ) : (
        <SpeedDialAction
          key={`spdial-right-${action.title}`}
          tooltipTitle={action.title}
          tooltipPlacement="top"
          icon={action.icon}
          onClick={action.action}
          className={classes.speedDialAction}
        />
      )
    );
  }

  render() {
    const {
      isMapDataLoaded,
      classes,
      clearSingleMeasure,
      map,
      settingsCogOpen,
      screenCapture,
      measureArea,
      measureDistance,
    } = this.props;
    const { open } = this.state;

    return (
      <div>
        {!isMapDataLoaded || !map ? (
          <SettingsCogSkeleton />
        ) : (
          <div
            className={classes.root}
            style={{
              display:
                screenCapture.isActive &&
                screenCapture.type === ScreenCaptureTypes.FULLSCREEN &&
                !measureArea.isAreaing &&
                !measureDistance.isMeasuring
                  ? "none"
                  : "block",
            }}
          >
            <SpeedDial
              FabProps={{
                "data-testid": open ? "speed-dial-open" : "speed-dial-closed",
                onClick: this.handleChange,
              }}
              ariaLabel="SpeedDial Up"
              classes={{
                fab: classes.speedDialFab,
                actions: classes.speedDialActions,
              }}
              icon={
                <SpeedDialIcon
                  icon={<ToolboxIconClose />}
                  openIcon={<ToolboxIconOpen />}
                  classes={{
                    root: classes.speedDialIconRoot,
                    icon: classes.speedDialIconIcon,
                    openIcon: classes.speedDialIconIcon,
                  }}
                />
              }
              open={settingsCogOpen}
              direction="up"
            >
              {this.renderTopActions()}
              <ShowDistanceContainer
                onClearMeasure={() => clearSingleMeasure(map)}
                open={settingsCogOpen}
                clearDistance={() => this.clearDistance()}
              />
              <ShowAreaContainer
                onClearArea={() => this.clearSingleArea()}
                open={settingsCogOpen}
                onChangeUnits={e => this.changeUnitsArea(e)}
                clearArea={() => this.clearArea(map)}
              />
            </SpeedDial>
            <SpeedDial
              ariaLabel="SpeedDial Right"
              className={classes.speedDialPull}
              classes={{
                fab: classNames(
                  classes.speedDialFab,
                  classes.speedDialFabHidden
                ),
                actions: classes.speedDialActions,
              }}
              icon={
                <SpeedDialIcon
                  icon={<ToolboxIconClose />}
                  openIcon={<ToolboxIconOpen />}
                  classes={{
                    root: classes.speedDialIconRoot,
                    icon: classes.speedDialIconIcon,
                    openIcon: classes.speedDialIconIcon,
                  }}
                />
              }
              open={settingsCogOpen}
              direction="right"
            >
              {this.renderRightActions()}
            </SpeedDial>
          </div>
        )}
      </div>
    );
  }
}

SettingsCog.propTypes = {
  isMapDataLoaded: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  map: PropTypes.object,
  geojson: PropTypes.object.isRequired,
  tracking: PropTypes.object.isRequired,
  clearAllMeasure: PropTypes.func.isRequired,
  clearSingleMeasure: PropTypes.func.isRequired,
  setMapPitch: PropTypes.func.isRequired,
  setMapZoom: PropTypes.func.isRequired,
  offPresentation: PropTypes.func.isRequired,
  onPresentation: PropTypes.func.isRequired,
  setMapStyle: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  clearAllArea: PropTypes.func.isRequired,
  clearSingleArea: PropTypes.func.isRequired,
  hideHighlight: PropTypes.func.isRequired,
  updateArea: PropTypes.func.isRequired,
  center: PropTypes.array.isRequired,
  colorPicker: PropTypes.object.isRequired,
  measureArea: PropTypes.object.isRequired,
  isRotating: PropTypes.bool.isRequired,
  settingsCogOpen: PropTypes.bool.isRequired,
  openCog: PropTypes.func.isRequired,
  closeCog: PropTypes.func.isRequired,
  turnOffDynamicSegmentationDrag: PropTypes.func.isRequired,
  turnOffDynamicSegmentationSelect: PropTypes.func.isRequired,
  turnOffDynamicSegmentationLasso: PropTypes.func.isRequired,
  clearSegments: PropTypes.func.isRequired,
  screenCapture: PropTypes.object.isRequired,
  measureDistance: PropTypes.object.isRequired,
  isDynamicSegmentationLassoActive: PropTypes.bool.isRequired,
  measuringSystem: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  center: state.baseMap.center,
  sidebarVisibility: state.visibility.sidebarVisibility,
  isRotating: state.rotation.isRotating,
  measureDistance: state.measureDistance,
  geojson: state.measureDistance.geojson,
  colorPicker: state.colorPicker,
  measureArea: state.measureArea,
  settingsCogOpen: state.settingsCog.settingsCogOpen,
  screenCapture: state.screenCapture,
  isDynamicSegmentationLassoActive: state.dynamicSegmentation.isLassoActive,
  measuringSystem: state.userData.measuringSystem,
});

const mapDispatchToProps = dispatch => ({
  setMapZoom: zoom => dispatch(setMapZoom(zoom)),
  setMapPitch: pitch => dispatch(setMapPitch(pitch)),
  hideHighlight: () => dispatch(hideHighlight()),
  hideModal: () => dispatch(hideModal()),
  setMapStyle: base => dispatch(setMapStyle(base)),
  onPresentation: () => dispatch(onPresentation()),
  offPresentation: () => dispatch(offPresentation()),
  onMeasure: () => dispatch(onMeasure()),
  offMeasure: () => dispatch(offMeasure()),
  updateDistance: (distance, value) =>
    dispatch(updateDistance(distance, value)),
  clearAllMeasure: map => dispatch(clearAllMeasure(map)),
  clearSingleMeasure: map => dispatch(clearSingleMeasure(map)),
  clearAllArea: () => dispatch(clearAllArea()),
  updateArea: (area, value) => dispatch(updateArea(area, value)),
  clearSingleArea: () => dispatch(clearSingleArea()),
  openCog: () => dispatch(openCog()),
  closeCog: () => dispatch(closeCog()),
  turnOffDynamicSegmentationDrag: () =>
    dispatch(turnOffDynamicSegmentationDrag()),
  turnOffDynamicSegmentationSelect: () =>
    dispatch(turnOffDynamicSegmentationSelect()),
  turnOffDynamicSegmentationLasso: () =>
    dispatch(turnOffDynamicSegmentationLasso()),
  clearSegments: () => dispatch(clearSegments()),
});

export default track()(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(SettingsCog))
);
