import React, { ReactNode, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

import Dialog from "components/Dialog/Dialog";
import ActivityIndicatorButton from "components/ActivityIndicatorButton";

import Map from "interfaces/map";

import { RB_WHITESMOKE, RB_LIGHT_GREY } from "constants/colors";

const ROADWAY_LITE_MAP_HANDLER_URL =
  process.env.REACT_APP_ROADWAY_LITE_MAP_HANDLER_URL;

type EmbeddedMapsDetailsDialogProps = {
  title: string;
  isOpen: boolean;
  handleClose: () => void;
  map: Map;
  isNewEmbeddedMap: boolean;
  isExampleOrganization: boolean;
};

enum mapUrlParamNames {
  id = "id",
  bounds = "bounds",
}

type mapUrlParams = [keyof typeof mapUrlParamNames, string];

const useStyles = makeStyles({
  embeddedMap: {
    width: "100%",
    alignItems: "center",
    height: "550px",
    margin: "3px auto",
  },
  codeBlock: {
    margin: "10px auto 20px",
    padding: "5px",
    background: RB_WHITESMOKE,
    border: `1px solid ${RB_LIGHT_GREY}`,
  },
  copyIframeButton: {
    cursor: "pointer",
  },
  copyContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const EmbeddedMapsDetailsDialog = ({
  title,
  isOpen,
  handleClose,
  map,
  isNewEmbeddedMap,
  isExampleOrganization,
}: EmbeddedMapsDetailsDialogProps): ReactNode => {
  const classes = useStyles();

  const [isIFrameCopied, setIsIFrameCopied] = useState(false);

  const generateEmbedURL = (map: Map) => {
    const { id, scanBounds } = map;
    const urlParams: mapUrlParams[] = [];

    if (id) {
      urlParams.push(["id", id]);
    }

    if (scanBounds?.length > 0) {
      urlParams.push(["bounds", scanBounds.join(",")]);
    }

    const convertURLParamsToString = (params: mapUrlParams[]): string =>
      params.map(([name, value]) => `${name}=${value}`).join("&");

    return urlParams.length > 0
      ? `${ROADWAY_LITE_MAP_HANDLER_URL}?${convertURLParamsToString(urlParams)}`
      : null;
  };

  const generateIFrameCode = (
    embedURL: string,
    name: string
  ): string => `<iframe
      src="${embedURL}"
      width="100%"
      height="100%"
      frameBorder="0"
      title="${name}"
    />`;

  const renderEmbeddedMap = (iframeCode: string): ReactNode => (
    <div
      className={classes.embeddedMap}
      dangerouslySetInnerHTML={{ __html: iframeCode }}
    ></div>
  );

  const renderForm = (embedURL: string, name: string): ReactNode => {
    const iFrameCode = generateIFrameCode(embedURL, name);
    return (
      <form onSubmit={handleClose}>
        <div>
          {isNewEmbeddedMap && (
            <Typography variant="body1" paragraph>
              Embedded map successfully created.
            </Typography>
          )}
          {!isExampleOrganization && (
            <div>
              <Typography
                variant="body1"
                paragraph
                className={classes.copyContainer}
              >
                <span>Copy the below code to use it in a webpage:</span>
                <CopyToClipboard
                  text={iFrameCode}
                  onCopy={(): void => setIsIFrameCopied(true)}
                >
                  <Tooltip
                    title={isIFrameCopied ? "Code copied!" : "Copy code"}
                    aria-label="Copy code"
                  >
                    <Link
                      className={classes.copyIframeButton}
                      underline="always"
                    >
                      <span>
                        <Button
                          startIcon={
                            <FileCopyOutlinedIcon fontSize="inherit" />
                          }
                          variant="outlined"
                        >
                          Copy Code
                        </Button>
                      </span>
                    </Link>
                  </Tooltip>
                </CopyToClipboard>
              </Typography>

              <div className={classes.codeBlock}>
                <code>{iFrameCode}</code>
              </div>
            </div>
          )}
          <Typography variant="button">Preview</Typography>
          {renderEmbeddedMap(iFrameCode)}
        </div>
      </form>
    );
  };

  const renderFormActions = (): ReactNode => (
    <ActivityIndicatorButton
      onClick={handleClose}
      isActive={false}
      progressSize={14}
      disableButtonWhileActive={true}
      disabled={false}
    >
      Done
    </ActivityIndicatorButton>
  );

  const embedURL = generateEmbedURL(map);

  return (
    <Dialog
      title={title}
      isOpen={isOpen}
      handleClose={handleClose}
      actions={renderFormActions()}
      maxWidth="md"
    >
      {embedURL ? (
        renderForm(embedURL, map?.name || "")
      ) : (
        <p>Error while generating map embed code</p>
      )}
    </Dialog>
  );
};

EmbeddedMapsDetailsDialog.defaultProps = {
  title: "",
  isOpen: false,
  handleClose: (): void => {
    return;
  },
};

export default EmbeddedMapsDetailsDialog;
