import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import ChevronRightRounded from "@material-ui/icons/ChevronRightRounded";
import ChevronLeftRounded from "@material-ui/icons/ChevronLeftRounded";
import { uiConstants } from "../constants/ui";
import { topBannerHeight } from "../views/Banner";
import classNames from "classnames";

const style = makeStyles({
  closeModalRight: (props: StyleProps) => ({
    right: `calc(${props.drawerWidth} - 4px)`,
    position: "fixed",
    width: "24px",
    top: "58px",
    height: "40px",
    boxShadow: "none",
    cursor: "pointer",
  }),
  closeModalLeft: (props: StyleProps) => ({
    left: `calc(${props.drawerWidth} + ${uiConstants.osDrawerWidth}px - 4px)`,
    position: "fixed",
    width: "24px",
    top: "58px",
    height: "40px",
    boxShadow: "none",
    cursor: "pointer",
  }),
  closeExpandModal: (props: StyleProps) => ({
    right: `calc(${props.largeDrawerWidth} - 4px)`,
    position: "fixed",
    width: "24px",
    top: "58px",
    height: "40px",
    boxShadow: "none",
    cursor: "pointer",
  }),
  chevron: {
    position: "absolute",
    top: "7px",
  },
  openModalRight: {
    right: "-1px",
    position: "fixed",
    width: "24px",
    top: "58px",
    height: "40px",
    boxShadow: "none",
    cursor: "pointer",
  },
  openModalLeft: {
    left: `calc(${uiConstants.osDrawerWidth}px - 1px)`,
    position: "fixed",
    width: "24px",
    top: "58px",
    height: "40px",
    boxShadow: "none",
    cursor: "pointer",
  },
  banner: {
    marginTop: topBannerHeight,
  },
});

interface StyleProps {
  drawerWidth: string;
  largeDrawerWidth: string;
}

export enum OpenSide {
  RIGHT = "right",
  LEFT = "left",
}

interface ModalTabProps {
  isTopBannerOpen: boolean;
  open: boolean;
  closeModalClick: () => void;
  openModalClick: () => void;
  isModalDataPresent: boolean;
  enlargedImg: boolean;
  drawerWidth: string;
  largeDrawerWidth: string;
  openSide: OpenSide;
}

const ModalTab = ({
  open,
  closeModalClick,
  openModalClick,
  isModalDataPresent = true,
  enlargedImg = false,
  drawerWidth,
  largeDrawerWidth = drawerWidth,
  openSide,
  isTopBannerOpen,
}: ModalTabProps) => {
  const props = { drawerWidth, largeDrawerWidth };
  const classes = style(props);
  return (
    <div>
      {open ? (
        // eslint-disable-next-line max-len
        <div onClick={closeModalClick}>
          <Paper
            className={classNames(
              !enlargedImg
                ? openSide === OpenSide.RIGHT
                  ? classes.closeModalRight
                  : classes.closeModalLeft
                : classes.closeExpandModal,
              {
                [classes.banner]: isTopBannerOpen,
              }
            )}
          >
            {openSide === OpenSide.RIGHT ? (
              <ChevronRightRounded className={classes.chevron} />
            ) : (
              <ChevronLeftRounded className={classes.chevron} />
            )}
          </Paper>
        </div>
      ) : null}
      {open || isModalDataPresent ? (
        // eslint-disable-next-line max-len
        <div onClick={openModalClick}>
          <Paper
            className={classNames(
              openSide === OpenSide.RIGHT
                ? classes.openModalRight
                : classes.openModalLeft,

              {
                [classes.banner]: isTopBannerOpen,
              }
            )}
          >
            {openSide === OpenSide.RIGHT ? (
              <ChevronLeftRounded className={classes.chevron} />
            ) : (
              <ChevronRightRounded className={classes.chevron} />
            )}
          </Paper>
        </div>
      ) : null}
    </div>
  );
};

export default ModalTab;
