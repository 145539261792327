import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const styles = {
  formControlLabel: {
    marginBottom: 0,
    alignSelf: "stretch",
  },
  label: {
    fontSize: "11.7px",
    fontWeight: "600",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    textAlign: "center",
    color: "#212121",
    textTransform: "capitalize",
  },
};

const ToolbarToggleAll = ({
  classes,
  checked,
  onChange,
  color,
  label,
  labelPlacement,
}) => (
  <FormControlLabel
    classes={{
      label: classes.label,
    }}
    control={
      <Switch
        inputProps={{
          "data-testid": checked ? "toolbar-toggle-on" : "toolbar-toggle-off",
        }}
        checked={checked}
        onChange={onChange}
        color={color}
      />
    }
    label={label}
    labelPlacement={labelPlacement}
    className={classes.formControlLabel}
  />
);

ToolbarToggleAll.propTypes = {
  classes: PropTypes.object.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
};

export default withStyles(styles)(ToolbarToggleAll);
