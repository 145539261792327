import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PropTypes from "prop-types";
import track from "react-tracking";
import * as Sentry from "@sentry/browser";
import Grid from "@material-ui/core/Grid";

import { setSnackbarMessageAndOpen, MessageTypes } from "actions/snackbar";
import {
  useLoadOrganizationEmbeddedMapsData,
  useLoadOrganizationScansData,
} from "hooks/useLoadOrganizationData";

import OrganizationEmbeddedMaps from "../../components/admin/OrganizationEmbeddedMaps";

import EmbeddedMapsUpdateFormDialog from "./EmbeddedMapsUpdateFormDialog";
import EmbeddedMapsDetailsDialog from "./EmbeddedMapsDetailsDialog";
import { MAP_STYLE_DARK } from "../../constants/mapStyles";
import RBAPI from "api/RoadwayAPI";

const EmbeddedMapsAdminContainer = props => {
  const {
    tracking,
    userUId,
    organizationScans,
    organizationEmbeddedMaps,
    isOrganizationEmbeddedMapsDataLoaded,
    setSnackbarMessageAndOpen,
    currentOrganization,
    openDeletePopup,
    isDeleteDialogOpen,
  } = props;
  const [selectedEmbeddedMap, setSelectedEmbeddedMap] = useState({});
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
  const [isUpdateFormOpen, setIsUpdateFormOpen] = useState(false);
  const [isMapUpdateActive, setIsMapUpdateActive] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [formValues, setFormValues] = useState({});

  const loadCurrentOrganizationEmbeddedMaps = useLoadOrganizationEmbeddedMapsData(
    currentOrganization.id
  );
  const loadCurrentOrganizationScans = useLoadOrganizationScansData(
    currentOrganization.id
  );

  const isExampleOrganization = currentOrganization.isExampleOrganization;
  const newEmbeddedMap = {
    name: "",
    scanId: "",
    layers: [],
    enabled: true,
    showNetworkScore: false,
    showNetworkLength: false,
    showPotholes: false,
    style: MAP_STYLE_DARK,
  };

  const handleEdit = selectedEmbeddedMap => {
    setIsCreating(false);
    setFormValues(selectedEmbeddedMap);
    setIsUpdateFormOpen(true);
  };

  const handlePreview = selectedEmbeddedMap => {
    setSelectedEmbeddedMap(selectedEmbeddedMap);
    setIsDetailsDialogOpen(true);
  };

  const handleError = (e, message) => {
    setSnackbarMessageAndOpen(message, MessageTypes.ERROR);
    throw e;
  };

  useEffect(() => {
    return () => {
      if (isDeleteDialogOpen) {
        loadCurrentOrganizationEmbeddedMaps(true);
      }
    };
  }, [isDeleteDialogOpen, loadCurrentOrganizationEmbeddedMaps]);

  const handleUpdate = async updatedMap => {
    const closeUpdateForm = () => {
      setIsMapUpdateActive(false);
      setIsUpdateFormOpen(false);
      setIsCreating(false);
    };
    const statusMessage = "Edit saved";
    try {
      setIsMapUpdateActive(true);
      if (isCreating) {
        const { data: createdMap } = await RBAPI.createMap(updatedMap);
        closeUpdateForm();
        handlePreview(createdMap);
      } else {
        await RBAPI.updateMap(updatedMap);
        setSnackbarMessageAndOpen(statusMessage);
        closeUpdateForm();
      }
      loadCurrentOrganizationEmbeddedMaps(true);
    } catch (e) {
      const statusMessage = isCreating
        ? "Failed to creat new embedded map"
        : "Failed to save the edit";
      handleError(e, statusMessage);
    } finally {
      tracking.trackEvent({
        event: "mouse-click",
        action: `admin-embedded-map-${isCreating ? "create" : "update"}-${
          updatedMap.id
        }`,
        userUId,
      });

      Sentry.addBreadcrumb({
        category: "mouse-click",
        message: `admin embedded map ${isCreating ? "create" : "update"} ${
          updatedMap.id
        }`,
        level: Sentry.Severity.Info,
      });
    }
  };

  const handleClickCreate = () => {
    setFormValues(newEmbeddedMap);
    setIsCreating(true);
    setIsUpdateFormOpen(true);
  };

  const handleCloseUpdateForm = () => {
    setIsCreating(false);
    setIsUpdateFormOpen(false);
  };

  const handleCloseDetailsDialog = () => setIsDetailsDialogOpen(false);

  const onChangeCurrentOrganization = () => {
    if (currentOrganization.hasOwnProperty("id")) {
      loadCurrentOrganizationEmbeddedMaps();
      loadCurrentOrganizationScans();
    }
  };

  // fetch users (and scans for create embedded map dialog) when an organization is selected
  useEffect(onChangeCurrentOrganization, [currentOrganization]);

  const selectedOrganizationEmbeddedMaps =
    organizationEmbeddedMaps[currentOrganization.id] || [];

  const selectedOrganizationScans =
    organizationScans[currentOrganization.id] || [];

  return (
    <div>
      <Grid container>
        <Grid item xs={10}>
          <OrganizationEmbeddedMaps
            handleDelete={openDeletePopup}
            isEmbeddedMapsLoaded={isOrganizationEmbeddedMapsDataLoaded}
            embeddedMaps={selectedOrganizationEmbeddedMaps}
            organizationName={currentOrganization.name}
            handleClickCreate={handleClickCreate}
            handleEdit={handleEdit}
            handlePreview={handlePreview}
          />
        </Grid>
      </Grid>
      {isDetailsDialogOpen && (
        <EmbeddedMapsDetailsDialog
          map={selectedEmbeddedMap}
          title={isCreating ? "Create Embedded Map" : "Preview Embedded Map"}
          isOpen
          handleClose={handleCloseDetailsDialog}
          isNewEmbeddedMap={isCreating}
          isExampleOrganization={isExampleOrganization}
        />
      )}
      {isUpdateFormOpen && (
        <EmbeddedMapsUpdateFormDialog
          isUpdateActive={isMapUpdateActive}
          isOpen
          handleClose={handleCloseUpdateForm}
          handleUpdate={handleUpdate}
          values={formValues}
          title={isCreating ? "Create Embedded Map" : "Edit Embedded Map"}
          scans={selectedOrganizationScans}
          isExampleOrganizationCreating={isExampleOrganization && isCreating}
        />
      )}
    </div>
  );
};

EmbeddedMapsAdminContainer.propTypes = {
  tracking: PropTypes.object.isRequired,
  userUId: PropTypes.string.isRequired,
  organizationScans: PropTypes.object.isRequired,
  setSnackbarMessageAndOpen: PropTypes.func.isRequired,
  organizationEmbeddedMaps: PropTypes.object.isRequired,
  isOrganizationEmbeddedMapsDataLoaded: PropTypes.bool.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  openDeletePopup: PropTypes.func.isRequired,
  isDeleteDialogOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  userUId: state.user.userUId,
  isOrganizationEmbeddedMapsDataLoaded:
    state.userData.isOrganizationEmbeddedMapsDataLoaded,
  organizationScans: state.userData.organizationScans,
  organizationEmbeddedMaps: state.userData.organizationEmbeddedMaps,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSnackbarMessageAndOpen,
    },
    dispatch
  );

export default track()(
  connect(mapStateToProps, mapDispatchToProps)(EmbeddedMapsAdminContainer)
);
