import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import logo from "images/logos/RoadboticsLogoNoText.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RB_YELLOW } from "constants/colors";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  progress: {
    color: RB_YELLOW,
    opacity: ".7",
  },
  image: {
    width: "150px",
    zIndex: 1,
    position: "absolute",
    marginTop: "10px",
    opacity: ".7",
  },
  loadingText: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "2em",
    fontWeight: 900,
    color: "#000",
    opacity: ".8",
  },
});

const LoadingLogo = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img src={logo} alt="loadinglogo" className={classes.image} />
      <CircularProgress size={170} className={classes.progress} />
      <Typography className={classes.loadingText}>Loading...</Typography>
    </div>
  );
};

export default LoadingLogo;
