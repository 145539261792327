import React from "react";
import PropTypes from "prop-types";
import IndividualDistress from "../components/IndividualDistress";
import IndividualDistresssTypes from "../interfaces/individualDistress";

interface SegmentDistressTypes {
  segmentDistressStatistics: IndividualDistresssTypes;
}

const IndividualDistressContainer = ({
  segmentDistressStatistics,
}: SegmentDistressTypes) => (
  <IndividualDistress
    numPothole={segmentDistressStatistics.numPothole}
    pctFatigueCracking={Math.round(
      segmentDistressStatistics.pctFatigueCracking
    )}
    pctPavementDistortions={Math.round(
      segmentDistressStatistics.pctPavementDistortions
    )}
    numPatchSeal={Math.round(segmentDistressStatistics.numPatchSeal)}
    pctTransLongCracking={Math.round(
      segmentDistressStatistics.pctTransLongCracking
    )}
    pctSurfaceDeterioration={Math.round(
      segmentDistressStatistics.pctSurfaceDeterioration
    )}
  />
);

IndividualDistressContainer.propTypes = {
  segmentDistressStatistics: PropTypes.object.isRequired,
};

export default IndividualDistressContainer;
