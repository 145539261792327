import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import PropTypes from "prop-types";

const ToolboxIconClose = ({
  viewBox = "0 0 20 20",
  fill = "#555",
  ...props
}) => (
  <SvgIcon viewBox={viewBox} {...props}>
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 342.7 261.54"
    >
      <path
        className="cls-1"
        fill={fill}
        d="M276.19,111H263.53a5,5,0,0,0-5,5v32.76a5,5,0,0,0,5,5h12.66a5,5,0,0,0,5-5V116A5,5,0,0,0,276.19,111Z"
      />
      <path
        className="cls-1"
        fill={fill}
        d="M79.16,111H66.5a5,5,0,0,0-5,5v32.76a5,5,0,0,0,5,5H79.16a5,5,0,0,0,5-5V116A5,5,0,0,0,79.16,111Z"
      />
      <path
        className="cls-1"
        fill={fill}
        d="M276.19,161.73H263.53a13,13,0,0,1-13-13V131H92v17.76a13,13,0,0,1-13,13H66.39a13,13,0,0,1-13-13V131H22.64V245.69a9,9,0,0,0,9,9H313.87a9,9,0,0,0,9-9V131H289.19v17.76A13,13,0,0,1,276.19,161.73Z"
      />
      <path
        className="cls-1"
        fill={fill}
        d="M330.77,52.57h-100V5.64H114.8V52.57h-100a9,9,0,0,0-9,9v51.11a9,9,0,0,0,9,9H53.39v-6.33a13,13,0,0,1,13-13H79a13,13,0,0,1,13,13v6.33H250.53v-6.33a13,13,0,0,1,13-13h12.66a13,13,0,0,1,13,13v6.33h41.58a9,9,0,0,0,9-9V61.57A9,9,0,0,0,330.77,52.57ZM137,27.84h71.53V52.57H137Z"
      />
    </svg>
  </SvgIcon>
);

ToolboxIconClose.propTypes = {
  viewBox: PropTypes.string,
  fil: PropTypes.string,
};

export default ToolboxIconClose;
