import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { RB_POTHOLE_ORANGE } from "constants/colors";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IndividualDistresssTypes from "../interfaces/individualDistress";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles({
  box: {
    position: "relative",
    color: "white",
    display: "inline-block",
    margin: "2px",
    padding: "2px",
    textAlign: "center",
    width: "100%",
  },
  potHoleBox: {
    backgroundColor: "#bd0000",
  },
  fatigueBox: {
    backgroundColor: "#6c00d8",
  },
  pavementDistortionBox: {
    backgroundColor: "#c085fb",
  },
  patchesSealantBox: {
    backgroundColor: "#000000",
  },
  transLongCrackingBox: {
    backgroundColor: "#218cce",
  },
  surfaceBox: {
    backgroundColor: "#81c5fe",
  },
  number: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "30px",
    fontWeight: "bold",
  },
  boxTitle: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "13px",
    fontWeight: "bold",
  },
  title: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: "bold",
    fontSize: "18px",
    paddingBottom: "5px",
    textAlign: "center",
  },
  checkmark: {
    display: "inline-block",
  },
  distressText: {
    fontWeight: "bold",
    fontSize: "13px",
    display: "inline-block",
    paddingLeft: "5px",
  },
  distressUnchecked: {
    boxShadow: "none",
    margin: "5px",
    color: "#b4b4b4",
    backgroundColor: "#dbdbdb",
    display: "flex",
  },
  distressChecked: {
    boxShadow: "none",
    margin: "5px",
    backgroundColor: RB_POTHOLE_ORANGE,
    display: "flex",
  },
  divContainer: {
    marginTop: "20px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
    width: "100%",
  },
});

const IndividualDistress = ({
  numPothole,
  pctFatigueCracking,
  pctPavementDistortions,
  numPatchSeal,
  pctTransLongCracking,
  pctSurfaceDeterioration,
}: IndividualDistresssTypes): React.ReactElement => {
  const classes = useStyles();
  return (
    <div className={classes.divContainer}>
      <Grid className={classes.header} container spacing={3}>
        <Grid item xs={3}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <Typography align="center" className={classes.title}>
            Segment Distress Statistics
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Paper className={[classes.potHoleBox, classes.box].join(" ")}>
            <Typography className={classes.number}>{numPothole}</Typography>
            <Typography className={classes.boxTitle}>Potholes</Typography>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={[classes.fatigueBox, classes.box].join(" ")}>
            <Typography className={classes.number}>
              {`${pctFatigueCracking}%`}
            </Typography>
            <Typography className={classes.boxTitle}>
              Fatigue Cracking
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper
            className={[classes.pavementDistortionBox, classes.box].join(" ")}
          >
            <Typography className={classes.number}>
              {`${pctPavementDistortions}%`}
            </Typography>
            <Typography className={classes.boxTitle}>
              Pavement Distortions
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Paper className={[classes.patchesSealantBox, classes.box].join(" ")}>
            <Typography className={classes.number}>{numPatchSeal}</Typography>
            <Typography className={classes.boxTitle}>
              Patches & Sealant
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper
            className={[classes.transLongCrackingBox, classes.box].join(" ")}
          >
            <Typography className={classes.number}>
              {`${pctTransLongCracking}%`}
            </Typography>
            <Typography className={classes.boxTitle}>
              Trans/Long Cracking
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={[classes.surfaceBox, classes.box].join(" ")}>
            <Typography className={classes.number}>
              {`${pctSurfaceDeterioration}%`}
            </Typography>
            <Typography className={classes.boxTitle}>
              Surface Deteriorations
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default IndividualDistress;
