import { sum } from "lodash";
import makeSegment from "./makeSegment";

export function calculateTotalLength(segmentFeatures) {
  let totalLength = 0;
  segmentFeatures.forEach(segmentFeature => {
    const segmentLength = makeSegment(segmentFeature).length;
    totalLength += segmentLength;
  });
  return totalLength;
}

export function calculateAverageRating(segmentFeatures) {
  // if a segment doesn't have an average rating (is unpaved for example), don't include it in
  // the average rating calculation
  let numValidPoints = 0;
  const totalRatings = [];
  segmentFeatures.forEach(segmentFeature => {
    const ratings = JSON.parse(segmentFeature.properties.ratings);
    const filteredRatings = ratings.filter(rating => rating !== null);
    if (filteredRatings) {
      numValidPoints += filteredRatings.length;
      totalRatings.push(...filteredRatings);
    }
  });
  if (numValidPoints > 0) {
    return parseFloat(sum(totalRatings) / numValidPoints).toFixed(2);
  } else {
    return null;
  }
}

export function calculateCombinedIDIData(segmentFeatures, isTotalData = true) {
  let pctPatchSeal = 0;
  let pctTransLongCracking = 0;
  let pctFatigueCracking = 0;
  let pctPavementDistortions = 0;
  let pctPothole = 0;
  let pctSurfaceDeterioration = 0;

  let numPatchSeal = 0;
  let numTransLongCracking = 0;
  let numFatigueCracking = 0;
  let numPavementDistortions = 0;
  let numPothole = 0;
  let numSurfaceDeterioration = 0;

  let totalPoints = 0;

  segmentFeatures.forEach(segmentFeature => {
    if (segmentFeature.properties.segmentDistressStatistics) {
      const segmentDistressStatistics = JSON.parse(
        segmentFeature.properties.segmentDistressStatistics
      );
      numPatchSeal += segmentDistressStatistics.numPatchSeal;
      numTransLongCracking += segmentDistressStatistics.numTransLongCracking;
      numFatigueCracking += segmentDistressStatistics.numFatigueCracking;
      numPavementDistortions +=
        segmentDistressStatistics.numPavementDistortions;
      numPothole += segmentDistressStatistics.numPothole;
      numSurfaceDeterioration +=
        segmentDistressStatistics.numSurfaceDeterioration;
    }

    totalPoints += JSON.parse(segmentFeature.properties.ratings).length;
  });

  pctPatchSeal = ((numPatchSeal / totalPoints) * 100).toFixed(2);
  pctTransLongCracking = ((numTransLongCracking / totalPoints) * 100).toFixed(
    2
  );
  pctFatigueCracking = ((numFatigueCracking / totalPoints) * 100).toFixed(2);
  pctPavementDistortions = (
    (numPavementDistortions / totalPoints) *
    100
  ).toFixed(2);
  pctPothole = ((numPothole / totalPoints) * 100).toFixed(2);
  pctSurfaceDeterioration = (
    (numSurfaceDeterioration / totalPoints) *
    100
  ).toFixed(2);

  if (
    isTotalData &&
    numPatchSeal === 0 &&
    numTransLongCracking === 0 &&
    numFatigueCracking === 0 &&
    numPavementDistortions === 0 &&
    numPothole === 0 &&
    numSurfaceDeterioration === 0
  ) {
    return null;
  } else {
    return {
      pctTransLongCracking,
      pctPatchSeal,
      numPatchSeal,
      numTransLongCracking,
      pctPavementDistortions,
      pctPothole,
      pctFatigueCracking,
      numFatigueCracking,
      numPavementDistortions,
      numPothole,
      pctSurfaceDeterioration,
      numSurfaceDeterioration,
    };
  }
}
