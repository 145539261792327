import withStyles from "@material-ui/core/styles/withStyles";
import MUIRadio from "@material-ui/core/Radio";

import { RB_YELLOW } from "constants/colors";

const Radio = withStyles({
  root: {
    color: RB_YELLOW,
    "&$checked": {
      color: RB_YELLOW,
    },
  },
  checked: {},
})(MUIRadio);

export default Radio;
