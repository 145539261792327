import React from "react";
import ReactMapboxGl, { ZoomControl } from "react-mapbox-gl";
import PropTypes from "prop-types";
import Controls from "components/Controls";
import Highlight from "components/Highlight";
import LayerGroupContainer from "containers/LayerGroupContainer";
import { MAPBOX_TOKEN } from "constants/mapbox";
import { uiConstants } from "../constants/ui";

// preserveDrawingBuffer needed for screenshot

const Mapbox = ReactMapboxGl({
  accessToken: MAPBOX_TOKEN,
  preserveDrawingBuffer: true,
});

const Map = ({
  style,
  center,
  pitch,
  bearing,
  zoom,
  onMove,
  onZoom,
  onPitch,
  onStyleLoad,
  onClick,
  onRotate,
  onMouseMove,
  measuringSystem,
  onMoveEnd,
  zoomStyles,
  map,
  onStyleImageMissing,
  isScreenCaptureActive,
}) => {
  return (
    <Mapbox
      className="clickOnMap"
      containerStyle={{
        height: "100vh",
        width: `calc(100vw - ${uiConstants.osDrawerWidth}px)`,
        position: "absolute",
        marginTop: "0%",
      }}
      movingMethod="jumpTo"
      style={style}
      center={center}
      pitch={[pitch]}
      bearing={bearing}
      zoom={zoom}
      onClick={onClick}
      onMouseMove={onMouseMove}
      onMove={onMove}
      onZoom={onZoom}
      onPitch={onPitch}
      onRotate={onRotate}
      onStyleLoad={onStyleLoad}
      onMoveEnd={onMoveEnd}
      onStyleImageMissing={onStyleImageMissing}
    >
      {!isScreenCaptureActive && (
        <ZoomControl position="top-left" style={zoomStyles} />
      )}
      <Controls measurement={measuringSystem} />
      {map && <LayerGroupContainer />}
      <Highlight />
    </Mapbox>
  );
};

Map.defaultProps = {
  onRotate: undefined,
  onMove: undefined,
  bearing: undefined,
  zoomStyles: {},
  map: null,
};

Map.propTypes = {
  style: PropTypes.string.isRequired,
  center: PropTypes.array.isRequired,
  pitch: PropTypes.number.isRequired,
  zoom: PropTypes.array.isRequired,
  bearing: PropTypes.array,
  onMove: PropTypes.func,
  onZoom: PropTypes.func.isRequired,
  onPitch: PropTypes.func.isRequired,
  onStyleLoad: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onRotate: PropTypes.func,
  onMouseMove: PropTypes.func.isRequired,
  measuringSystem: PropTypes.string.isRequired,
  onMoveEnd: PropTypes.func.isRequired,
  zoomStyles: PropTypes.object,
  map: PropTypes.object,
  isScreenCaptureActive: PropTypes.bool.isRequired,
  onStyleImageMissing: PropTypes.func.isRequired,
};

export default Map;
