import {
  ADD_TOGGLE,
  ADD_TOGGLE_GROUP,
  CLEAR_TOGGLE_GROUPS,
} from "actiontypes/toggles";

let toggleId = 0;

export function addToggle(toggleGroupId, toggle) {
  return {
    type: ADD_TOGGLE,
    id: toggleId++,
    toggleGroupId,
    displayName: toggle.displayName,
    layerId: toggle.layerId,
    toggleable: toggle.toggleable,
    color: toggle.color,
    position: toggle.position,
    layerType: toggle.layerType,
  };
}

let toggleGroupId = 0;

export function addToggleGroup(toggleGroup) {
  return {
    type: ADD_TOGGLE_GROUP,
    id: toggleGroupId++,
    displayName: toggleGroup.displayName,
    name: toggleGroup.name,
    style: toggleGroup.style,
    toggleAll: toggleGroup.toggleAll,
    tooltipInfo: toggleGroup.tooltipInfo,
  };
}

export function clearToggleGroups() {
  return {
    type: CLEAR_TOGGLE_GROUPS,
  };
}
