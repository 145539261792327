import { AssessmentTypes } from "constants/assessmentTypes";

interface SearchKeys {
  assessmentType?: string;
}

export const getAssessmentType = (): AssessmentTypes | null => {
  // remove the first char, which is a ? and split via the & and then =
  // to get an object where the url parameters are converted
  // into key value pairs.
  //     i.e ?key1=value1?key2=value2 === {key1: value1, key2:value2}
  const searchParams = {} as SearchKeys;
  window.location.search
    .substr(1)
    .split("&")
    .map(s => s.split("="))
    .forEach(([key, val]) => Object.assign(searchParams, { [key]: val }));

  // set to normal if on roadway or the url parameter `assessmentType`
  // is set to normal
  const host = window.location.host;
  if (
    host.startsWith("roadway.") ||
    searchParams?.assessmentType?.toUpperCase() ===
      AssessmentTypes.NORMAL.toUpperCase()
  ) {
    return AssessmentTypes.NORMAL;
  }

  // set to roadSense if on roadSense, `assessmentType`
  // is set to roadSense
  if (
    host.startsWith("roadsense.") ||
    searchParams?.assessmentType?.toUpperCase() ===
      AssessmentTypes.ROADSENSE.toUpperCase()
  ) {
    return AssessmentTypes.ROADSENSE;
  }

  //default
  return AssessmentTypes.NORMAL;
};
