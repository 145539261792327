import React, { memo } from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

const useStyles = makeStyles(() => ({
  list: {
    maxHeight: "200px",
    overflowX: "auto",
  },
}));

const ScansList = ({ scans }) => {
  const classes = useStyles();
  return (
    <div>
      <List dense={true} disablePadding={true} className={classes.list}>
        {scans &&
          scans.map(scan => (
            <ListItem key={scan.id} disableGutters>
              {scan.displayName || scan.name}
            </ListItem>
          ))}
      </List>
    </div>
  );
};

ScansList.propTypes = {
  scans: PropTypes.array.isRequired,
};

export default memo(ScansList);
