import React from "react";
import PropTypes from "prop-types";
import { default as MUISnackbar } from "@material-ui/core/Snackbar";
import { connect } from "react-redux";
import { closeSnackbar } from "actions/snackbar";
import CloseIcon from "components/CloseIcon";
import withStyles from "@material-ui/styles/withStyles";
import { MessageTypes, AnchorOrigin } from "actions/snackbar";

const styles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
});

const Snackbar = ({
  closeSnackbar,
  classes,
  open,
  message,
  messageType,
  anchorOrigin,
}) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    closeSnackbar();
  };

  return (
    <MUISnackbar
      anchorOrigin={anchorOrigin}
      className={classes.appBarSpacer}
      open={open}
      autoHideDuration={messageType === MessageTypes.ERROR ? null : 5000}
      onClose={handleClose}
      ContentProps={{
        "aria-describedby": "message-id",
      }}
      message={<span id="message-id">{message}</span>}
      action={<CloseIcon onClick={handleClose} />}
    />
  );
};

Snackbar.defaultProps = {
  message: null,
  messageType: null,
  anchorOrigin: AnchorOrigin.BOTTOM_CENTER,
};

Snackbar.propTypes = {
  closeSnackbar: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  message: PropTypes.string,
  messageType: PropTypes.string,
  anchorOrigin: PropTypes.object,
};

const mapStateToProps = state => ({
  open: state.snackbar.open,
  message: state.snackbar.message,
  messageType: state.snackbar.messageType,
  anchorOrigin: state.snackbar.anchorOrigin,
});

const mapDispatchToProps = dispatch => ({
  closeSnackbar: () => dispatch(closeSnackbar()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Snackbar));
