import React, { memo } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles, Paper } from "@material-ui/core";
import { RB_BLACK, RB_GREY } from "constants/colors";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  radioLabel: {
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(18),
    fontFamily: "'Open Sans', sans-serif",
    alignSelf: "center",
    flexGrow: 1,
    justifyContent: "center",
    display: "flex",
    margin: "0px 30px 0px 20px",
  },
  emptyCircle: {
    width: 20,
    height: 20,
    border: "2px solid black",
    backgroundColor: "white",
    borderRadius: "50%",
  },
  controlContainer: {
    display: "flex",
    margin: "10px 20px 0px 20px",
  },
  radioContainer: {
    padding: 15,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  circle: {
    position: "absolute",
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: "black",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  root: {
    display: "-webkit-box",
    borderRadius: "0%",
    padding: 0,
  },
  uIRowContainer: {
    flexDirection: "row",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
}));

export type RadioValue<T> = {
  value: T;
  selectedStyle: React.CSSProperties;
  unselectedStyle: React.CSSProperties;
  uiElement?: React.ReactNode;
  checked: boolean;
};

interface RadioButtonsGroupProps<T> {
  values: RadioValue<T>[];
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

const RadioButtonsGroup = <T extends {}>(props: RadioButtonsGroupProps<T>) => {
  const styles = useStyles(props);
  const { values, onChange } = props;

  const renderUncheckedRadio = (iterationValue: RadioValue<T>) => {
    return (
      <div
        className={styles.radioContainer}
        style={iterationValue.unselectedStyle}
      >
        <div className={styles.emptyCircle} />
      </div>
    );
  };

  const renderCheckedRadio = (iterationValue: RadioValue<T>) => {
    return (
      <div
        className={styles.radioContainer}
        style={iterationValue.selectedStyle}
      >
        <div className={styles.emptyCircle}>
          <div className={styles.circle} />
        </div>
      </div>
    );
  };

  const renderRadio = (iterationValue: RadioValue<T>, i: number) => {
    const selectedStyle =
      (iterationValue.value as unknown) === (values[i] as unknown)
        ? iterationValue.selectedStyle
        : { backgroundColor: RB_GREY };
    return (
      <Checkbox
        value={iterationValue.value}
        classes={{ root: styles.root }}
        onChange={onChange}
        checked={iterationValue.checked}
        style={selectedStyle}
        checkedIcon={renderCheckedRadio(iterationValue)}
        icon={renderUncheckedRadio(iterationValue)}
      />
    );
  };

  const renderRadioControl = (value: RadioValue<T>, i: number) => {
    if (value) {
      const found = value.checked;
      const selectedLabelStyle = found ? { color: RB_BLACK } : { color: RB_GREY };

      const containerStyle = value.uiElement
        ? { minHeight: 100 }
        : { marginBottom: 7 };
      return (
        <Paper
          style={containerStyle}
          className={styles.controlContainer}
          key={`${value.value}`}
        >
          {renderRadio(value, i)}
          <div
            className={classNames(styles.radioLabel)}
            style={selectedLabelStyle}
          >
            {value.uiElement || value.value}
          </div>
        </Paper>
      );
    }
  };

  return (
    <div className={styles.container}>
      {// eslint-disable-next-line
      values.map((val, i) => {
        if (val.uiElement) {
          if (i % 2 === 0) {
            const nextIndex = ++i;
            return (
              <div className={styles.uIRowContainer} key={`${val.value}${i}`}>
                {renderRadioControl(val, i)}
                {renderRadioControl(values[nextIndex], nextIndex)}
              </div>
            );
          }
        } else {
          return renderRadioControl(val, i);
        }
      })}
    </div>
  );
};

export default memo(RadioButtonsGroup);
