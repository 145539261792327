import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import MUISelect from "@material-ui/core/Select";
import MUIInput from "@material-ui/core/Input";

const StyledInput = withStyles({
  root: {
    marginBottom: "15px",
    width: "100%",
  },
})(MUIInput);

const Select = props => <MUISelect {...props} input={<StyledInput />} />;

export default Select;
