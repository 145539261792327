import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";
import PropTypes from "prop-types";

const DistanceIcon = ({ viewBox = "-1.5 -2 20 20", ...props }) => (
  <SvgIcon viewBox={viewBox} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="17"
      height="17"
      viewBox="0 0 17 17"
    >
      <defs>
        <path id="distance-id-a" d="M0 0h16.389V16.39H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="distance-id-b" fill="#fff">
          <use xlinkHref="#distance-id-a" />
        </mask>
        <path
          fill="#6F6F6F"
          // eslint-disable-next-line max-len
          d="M16.182 14.347l-1.838 1.838a.703.703 0 0 1-.99 0L.2 3.033a.708.708 0 0 1 0-.99L2.041.204a.702.702 0 0 1 .99 0l1.98 1.98-1.062 1.06a.508.508 0 0 0 0 .707.501.501 0 0 0 .707 0l1.061-1.06 1.414 1.415-.354.353a.509.509 0 0 0 0 .708c.198.197.516.19.707 0l.354-.354 1.414 1.413-1.06 1.061a.508.508 0 0 0 0 .707.501.501 0 0 0 .707 0l1.06-1.06 1.414 1.415-.353.353a.508.508 0 0 0 0 .707.501.501 0 0 0 .707 0l.353-.354 1.415 1.414-1.06 1.061a.509.509 0 0 0 0 .707.501.501 0 0 0 .706 0l1.061-1.06 1.98 1.98a.697.697 0 0 1 0 .99"
          mask="url(#b)"
        />
      </g>
    </svg>
  </SvgIcon>
);

DistanceIcon.propTypes = {
  viewBox: PropTypes.string,
};

export default DistanceIcon;
