export const SHOW_HIGHLIGHT = "SHOW_HIGHLIGHT";
export const HIDE_HIGHLIGHT = "HIDE_HIGHLIGHT";
export const SET_POINT_COORDS = "SET_POINT_COORDS";
export const SET_AZIMUTH = "SET_AZIMUTH";
export const SET_ORIGINAL_AZIMUTH = "SET_ORIGINAL_AZIMUTH";
export const SET_SEGMENT_COORDS = "SET_SEGMENT_COORDS";

// For the dynamic segmentation feature (highlighting multiple segments)
export const SET_SEGMENTS = "SET_SEGMENTS";
export const ADD_SEGMENT = "ADD_SEGMENT";
export const REMOVE_SEGMENT = "REMOVE_SEGMENT";
export const CLEAR_SEGMENTS = "CLEAR_SEGMENTS";
