import React from "react";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";
import Divider from "@material-ui/core/Divider";
import { RB_ROAD_SENSE } from "constants/colors";

const styles = {
  label: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: "bold",
    fontSize: "18px",
    display: "inline-block",
  },
  container: {
    textAlign: "center",
    paddingBottom: "5px",
  },
  image: {
    verticalAlign: "middle",
  },
  divider: {
    marginTop: "10px",
    marginBottom: "10px",
    width: "100%",
  },
};

const BlueCheckbox = withStyles({
  root: {
    "&$checked": {
      color: RB_ROAD_SENSE,
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const AllPointCheckbox = ({
  classes,
  showAllPoints,
  handleShowAllPointsChange,
}) => {
  return (
    <div className={classes.container}>
      <FormControlLabel
        control={
          <BlueCheckbox
            value={showAllPoints}
            checked={showAllPoints}
            onChange={handleShowAllPointsChange}
            name="allPointsValue"
          />
        }
        label={
          <Typography className={classes.label}>Show All Points</Typography>
        }
      />
      <Divider className={classes.divider} />
    </div>
  );
};

AllPointCheckbox.propTypes = {
  classes: PropTypes.object.isRequired,
  showAllPoints: PropTypes.bool.isRequired,
  handleShowAllPointsChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(AllPointCheckbox);
