import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { RB_LIGHT_GREY } from "../constants/colors";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "11px",
  },
  topBlock: {
    display: "flex",
    flexDirection: "row",
  },
  bottomBlock: {
    display: "flex",
    flexDirection: "row",
  },
  block: {
    background: RB_LIGHT_GREY,
    flexGrow: 1,
    marginTop: "8px",
    marginBottom: "0px",
    marginLeft: "4px",
    marginRight: "4px",
    paddingTop: "10px",
    paddingBottom: "6px",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
});

interface AnalysisViewProps {
  nBlocksTop: number;
  nBlocksBottom: number;
}

const AnalysisViewSkeleton: React.FC<AnalysisViewProps> = ({
  nBlocksTop,
  nBlocksBottom,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.topBlock}>
        {[...Array(nBlocksTop).keys()].map(i => {
          return (
            <Skeleton
              key={`${i}-skeleton-top-block`}
              className={classes.block}
              variant="rect"
              height={74}
            />
          );
        })}
      </div>
      <div className={classes.bottomBlock}>
        {[...Array(nBlocksBottom).keys()].map(i => {
          return (
            <Skeleton
              key={`${i}-skeleton-bottom-block`}
              className={classes.block}
              variant="rect"
              height={36}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AnalysisViewSkeleton;
