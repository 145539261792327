import React, { useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import length from "@turf/length";

import {
  onMeasure,
  offMeasure,
  updateDistance,
  resetClearMeasureToggles,
} from "actions/measureDistance";
import { setMapStyle } from "actions/map";
import PropTypes from "prop-types";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import { useTracking } from "react-tracking";
import * as Sentry from "@sentry/browser/dist/index";
import { kilometersToMeters, milesToFeet } from "../utils/metrics";

import { usePrevious } from "hooks/usePrevious";
import { MeasuringSystem } from "../constants/measuringSystem";

const useStyles = makeStyles({
  speedDialAction: {
    width: "30px",
    height: "30px",
    minHeight: "30px",
    flex: 1,
  },
});

const MeasureContainer = ({ map, action, clearMap, open }) => {
  const tracking = useTracking();
  const dispatch = useDispatch();
  const classes = useStyles();

  const isClearAll = useSelector(state => state.measureDistance.isClearAll);
  const isClearSingle = useSelector(
    state => state.measureDistance.isClearSingle
  );
  const colorChange = useSelector(state => state.measureDistance.colorChange);
  const isMeasuring = useSelector(state => state.measureDistance.isMeasuring);
  const measuringSystem = useSelector(state => state.userData.measuringSystem);
  const isAreaing = useSelector(state => state.measureArea.isAreaing);
  const geojson = useSelector(state => state.measureDistance.geojson);
  const linestring = useSelector(state => state.measureDistance.linestring);
  const colorPicker = useSelector(state => state.colorPicker);
  const isRotating = useSelector(state => state.rotation.isRotating);
  const isDynamicSegmentationActive = useSelector(
    state =>
      state.dynamicSegmentation.isDragActive ||
      state.dynamicSegmentation.isSelectActive ||
      state.dynamicSegmentation.isLassoActive
  );

  const recolorDistanceLayer = useCallback(() => {
    if (geojson) {
      if (map.loaded()) {
        // handle the map already being loaded
        map.setPaintProperty(
          "measure-points",
          "circle-color",
          colorPicker.MEASURE_LINE
        );
        map.setPaintProperty(
          "measure-lines",
          "line-color",
          colorPicker.MEASURE_LINE
        );
      } else {
        // handle the map style being changed (ex: dark mode to light mode)
        map.on("load", () => {
          map.setPaintProperty(
            "measure-points",
            "circle-color",
            colorPicker.MEASURE_LINE
          );
          map.setPaintProperty(
            "measure-lines",
            "line-color",
            colorPicker.MEASURE_LINE
          );
        });
      }
    }
  }, [geojson, map, colorPicker.MEASURE_LINE]);

  const mouseMove = useCallback(
    e => {
      if (map.getLayer("measure-points")) {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ["measure-points"],
        });

        // UI indicator for clicking/hovering a point on the map
        map.getCanvas().style.cursor = features.length
          ? "pointer"
          : "crosshair";
      }
    },
    [map]
  );

  const measureClick = useCallback(
    e => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ["measure-points"],
      });

      // Remove the linestring from the group
      // So we can redraw it based on the points collection
      if (geojson.features.length > 1) geojson.features.pop();

      // If a feature was clicked, remove it from the map
      if (features.length) {
        const id = features[0].properties.id;
        geojson.features = geojson.features.filter(
          point => point.properties.id !== id
        );
      } else {
        const point = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [e.lngLat.lng, e.lngLat.lat],
          },
          properties: {
            id: String(new Date().getTime()),
          },
        };

        geojson.features.push(point);
      }

      if (geojson.features.length > 1) {
        linestring.geometry.coordinates = geojson.features.map(
          point => point.geometry.coordinates
        );

        geojson.features.push(linestring);

        if (measuringSystem === MeasuringSystem.Imperial) {
          const distance = length(linestring, { units: "miles" });
          if (distance < 1) {
            dispatch(updateDistance(milesToFeet(distance).scalar, "feet"));
          } else {
            dispatch(updateDistance(parseFloat(distance.toFixed(2)), "miles"));
          }
        } else {
          const distance = length(linestring, { units: "kilometers" });
          if (distance < 1) {
            dispatch(
              updateDistance(kilometersToMeters(distance).scalar, "meters")
            );
          } else {
            dispatch(
              updateDistance(parseFloat(distance.toFixed(2)), "kilometers")
            );
          }
        }
      } else if (geojson.features.length === 1) {
        if (measuringSystem === MeasuringSystem.Imperial) {
          dispatch(updateDistance(0, "feet"));
        } else {
          dispatch(updateDistance(0, "meters"));
        }
      }

      map.getSource("geojson").setData(geojson);
    },
    [geojson, linestring, map, measuringSystem, dispatch]
  );

  const addDistanceLayer = useCallback(() => {
    map.addSource("geojson", {
      type: "geojson",
      data: geojson,
    });
    // Add styles to the map
    map.addLayer({
      id: "measure-points",
      type: "circle",
      source: "geojson",
      paint: {
        "circle-radius": 5,
        "circle-color": colorPicker.MEASURE_LINE,
      },
      filter: ["in", "$type", "Point"],
    });
    map.addLayer({
      id: "measure-lines",
      type: "line",
      source: "geojson",
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": colorPicker.MEASURE_LINE,
        "line-width": 2.5,
        "line-dasharray": [4, 4],
      },
      filter: ["in", "$type", "LineString"],
    });
  }, [map, geojson, colorPicker.MEASURE_LINE]);

  const reloadLayers = () => {
    if (
      geojson &&
      !map.getLayer("measure-points") &&
      !map.getLayer("measure-lines") &&
      !map.getSource("geojson")
    ) {
      addDistanceLayer();
    }
  };

  const setMapStyleTopography = () => {
    // this reloads the layers back ontop of the base when changing the styles
    map.once("style.load", reloadLayers);

    dispatch(setMapStyle("mapbox://styles/mdickson/cjepr89eh4q4s2snxcr55ias8"));
  };

  const toggleDistance = () => {
    tracking.trackEvent({
      event: "mouse-click",
      action: "measure-distance-toggle",
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `measure distance toggle`,
      level: Sentry.Severity.Info,
    });

    if (isMeasuring) {
      map.getCanvas().style.cursor = "";
      map.off("mousemove", mouseMove);
      dispatch(offMeasure());
    } else if (!isRotating) {
      if (isDynamicSegmentationActive || isAreaing) {
        clearMap();
      }
      setMapStyleTopography();
      dispatch(onMeasure());
      if (!map.getLayer("measure-points") && !map.getLayer("measure-lines")) {
        addDistanceLayer();
        if (measuringSystem === MeasuringSystem.Imperial) {
          dispatch(updateDistance(0, "feet"));
        } else {
          dispatch(updateDistance(0, "meters"));
        }
      }
      map.on("mousemove", mouseMove);
    }
  };

  const clearSingleMeasure = useCallback(() => {
    tracking.trackEvent({
      event: "mouse-click",
      action: "measure-distance-clear-single",
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `measure distance clear single`,
      level: Sentry.Severity.Info,
    });

    // clear the layers and sources
    if (map.getLayer("measure-points")) {
      map.removeLayer("measure-points");
    }
    if (map.getLayer("measure-lines")) {
      map.removeLayer("measure-lines");
    }
    if (map.getSource("geojson")) {
      map.removeSource("geojson");
    }

    // add the layers and sources back so that the user can make new measures
    addDistanceLayer();
    if (measuringSystem === MeasuringSystem.Imperial) {
      dispatch(updateDistance(0, "feet"));
    } else {
      dispatch(updateDistance(0, "meters"));
    }
  }, [tracking, map, addDistanceLayer, dispatch, measuringSystem]);

  const clearAllMeasure = useCallback(() => {
    tracking.trackEvent({
      event: "mouse-click",
      action: "measure-distance-clear-all",
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `measure distance clear all`,
      level: Sentry.Severity.Info,
    });

    map.off("mousemove", mouseMove);

    // clear the layers and sources
    map.getCanvas().style.cursor = "";
    if (map.getLayer("measure-points")) {
      map.removeLayer("measure-points");
    }
    if (map.getLayer("measure-lines")) {
      map.removeLayer("measure-lines");
    }
    if (map.getSource("geojson")) {
      map.removeSource("geojson");
    }

    // reset isClearAll to prevent a crash when clearing all,
    // going to settings,
    // then back to roadway
    dispatch(resetClearMeasureToggles());
  }, [map, tracking, mouseMove, dispatch]);

  const prevIsMeasuring = usePrevious(isMeasuring);
  useEffect(() => {
    if (prevIsMeasuring && !isMeasuring && isClearAll) {
      clearAllMeasure();
    }
  }, [prevIsMeasuring, isClearAll, isMeasuring, clearAllMeasure]);

  useEffect(() => {
    if (isMeasuring) {
      map.on("click", measureClick);
    }
    return () => {
      map.off("click", measureClick);
    };
  }, [measureClick, map, isMeasuring]);

  const prevIsClearSingle = usePrevious(isClearSingle);
  useEffect(() => {
    if (!prevIsClearSingle && isClearSingle) {
      clearSingleMeasure();
    }
  }, [isClearSingle, clearSingleMeasure, prevIsClearSingle]);

  useEffect(() => {
    recolorDistanceLayer();
  }, [colorChange, recolorDistanceLayer]);

  return (
    <SpeedDialAction
      key={`spdial-up-${action.title}`}
      tooltipTitle={action.title}
      className={classes.speedDialAction}
      tooltipPlacement="right"
      icon={action.icon}
      open={open}
      onClick={toggleDistance}
      FabProps={{
        "data-testid": isMeasuring
          ? `${action.title}-on`
          : `${action.title}-off`,
      }}
    />
  );
};

MeasureContainer.propTypes = {
  map: PropTypes.object.isRequired,
  clearMap: PropTypes.func.isRequired,
  action: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
};

export default MeasureContainer;
