import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { RB_LIGHT_GREY } from "../constants/colors";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  parent: {
    background: RB_LIGHT_GREY,
    marginLeft: "5px",
    marginRight: "40px",
  },
  child: {
    background: RB_LIGHT_GREY,
    marginLeft: "25px",
    marginRight: "30px",
  },
});

interface OrgTreeSkeletonProps {
  parents?: number;
  children?: number;
}

const OrgTreeSkeleton: React.FC<OrgTreeSkeletonProps> = ({
  parents = 2,
  children = 5,
}) => {
  const classes = useStyles();
  return (
    <div>
      {[...Array(parents).keys()].map(i => {
        return (
          <div className={classes.root} key={`${i}-org-tree-skeleton-parent`}>
            <Skeleton
              className={classes.parent}
              variant="text"
              height={35}
              width={180}
            />
            {[...Array(children).keys()].map(i => {
              return (
                <Skeleton
                  key={`${i}-org-tree-skeleton-child`}
                  className={classes.child}
                  variant="text"
                  height={35}
                  width={120}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default OrgTreeSkeleton;
