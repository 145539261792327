import {
  SHOW_HIGHLIGHT,
  HIDE_HIGHLIGHT,
  SET_POINT_COORDS,
  SET_AZIMUTH,
  SET_ORIGINAL_AZIMUTH,
  SET_SEGMENT_COORDS,
  SET_SEGMENTS,
  ADD_SEGMENT,
  REMOVE_SEGMENT,
  CLEAR_SEGMENTS,
} from "actiontypes/highlight";
import makeSegment from "utils/makeSegment";

const initialState = {
  visible: false,
  pointCoords: [],
  segmentCoords: [],
  segments: [], // used when selecting multiple segments
  azimuth: 0,
  originalAzimuth: null,
  highlightType: "",
};

const highlightReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORIGINAL_AZIMUTH:
      return {
        ...state,
        originalAzimuth: action.originalAzimuth,
      };
    case SET_AZIMUTH:
      return {
        ...state,
        azimuth: action.azimuth,
      };
    case SHOW_HIGHLIGHT:
      return {
        ...state,
        visible: true,
        highlightType: action.highlightType,
      };
    case HIDE_HIGHLIGHT:
      return {
        ...state,
        visible: false,
      };
    case SET_POINT_COORDS:
      return {
        ...state,
        pointCoords: action.pointCoords,
        segmentCoords: [],
        segments: [],
      };
    case SET_SEGMENT_COORDS:
      return {
        ...state,
        segmentCoords: action.segmentCoords,
        pointCoords: [],
        segments: [],
      };
    case SET_SEGMENTS:
      return {
        ...state,
        segments: action.segments,
        pointCoords: [],
        segmentCoords: [],
      };
    case ADD_SEGMENT:
      return {
        ...state,
        segments: state.segments.concat(action.segment),
      };
    case REMOVE_SEGMENT:
      return {
        ...state,
        segments: state.segments.filter(
          segment => makeSegment(segment).id !== action.segmentId
        ),
      };
    case CLEAR_SEGMENTS:
      return {
        ...state,
        segments: [],
      };
    default:
      return state;
  }
};

export default highlightReducer;
