import React from "react";

import MUIDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

interface DialogProps {
  isOpen?: boolean;
  handleClose?: () => void;
  children?: React.ReactNode;
  title?: string;
  actions?: React.ReactNode;
  cancelButton?: boolean;
  maxWidth?: false | "sm" | "xs" | "md" | "lg" | "xl" | undefined;
}

const Dialog = ({
  isOpen = false,
  handleClose = (): void => {},
  title = "",
  children = null,
  actions = null,
  cancelButton = true,
  maxWidth = "sm",
}: DialogProps): React.ReactElement => {
  return (
    <MUIDialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth={maxWidth}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {cancelButton && <Button onClick={handleClose}>Cancel</Button>}
        {actions}
      </DialogActions>
    </MUIDialog>
  );
};

export default Dialog;
