import {
  SET_ORGANIZATIONS,
  SET_ACTIVE_ORGANIZATION,
  SET_ACTIVE_SCAN,
  SET_MEMBERSHIPS,
  FETCH_ORGANIZATIONS,
  FETCH_ADMIN_LEVEL_ORGANIZATIONS,
  FETCH_ORGANIZATION_USERS,
  FETCH_ORGANIZATION_SCANS,
  FETCH_ALL_ORGANIZATIONS,
  SET_MEASURING_SYSTEM,
  FETCH_ORGANIZATION_EMBEDDED_MAPS,
  SET_HIDE_EXAMPLE_ORGANIZATIONS,
  SET_ANALYSIS_DATA,
} from "actiontypes/userData";

import { clearAssessment } from "./assessment";

export function setMeasuringSystem(measuringSystem) {
  return {
    type: SET_MEASURING_SYSTEM,
    measuringSystem,
  };
}

export function setOrganizations(organizations) {
  return {
    type: SET_ORGANIZATIONS,
    organizations,
  };
}

export function setActiveOrganization(organization) {
  return {
    type: SET_ACTIVE_ORGANIZATION,
    organization,
  };
}

export function updateActiveScan(activeScan) {
  return {
    type: SET_ACTIVE_SCAN,
    activeScan,
  };
}

export function setActiveScan(activeScan) {
  return (dispatch, getState) => {
    const currentState = getState();
    if (currentState.userData.activeScan !== activeScan) {
      dispatch(clearAssessment());
    }
    dispatch(updateActiveScan(activeScan));
  };
}

export function setMemberships(memberships) {
  return {
    type: SET_MEMBERSHIPS,
    memberships,
  };
}

export function fetchOrganizations(requestPromise) {
  return {
    type: FETCH_ORGANIZATIONS,
    payload: requestPromise,
  };
}

export function fetchAdminLevelOrganizations(requestPromise) {
  return {
    type: FETCH_ADMIN_LEVEL_ORGANIZATIONS,
    payload: requestPromise,
  };
}

export function fetchOrganizationUsers(requestPromise, organizationId) {
  return {
    type: FETCH_ORGANIZATION_USERS,
    payload: requestPromise,
    meta: {
      organizationId,
    },
  };
}

export function fetchOrganizationScans(requestPromise, organizationId) {
  return {
    type: FETCH_ORGANIZATION_SCANS,
    payload: requestPromise,
    meta: {
      organizationId,
    },
  };
}

export function fetchAllOrganizations(requestPromise) {
  return {
    type: FETCH_ALL_ORGANIZATIONS,
    payload: requestPromise,
  };
}

export function fetchOrganizationEmbeddedMaps(requestPromise, organizationId) {
  return {
    type: FETCH_ORGANIZATION_EMBEDDED_MAPS,
    payload: requestPromise,
    meta: {
      organizationId,
    },
  };
}

export function setHideExampleOrganizations(hideExampleOrganizations) {
  return {
    type: SET_HIDE_EXAMPLE_ORGANIZATIONS,
    hideExampleOrganizations,
  };
}

export function setAnalysisData(analysisData) {
  return {
    type: SET_ANALYSIS_DATA,
    analysisData,
  };
}
