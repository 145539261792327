import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

const styles = {
  subMenuItem: {
    display: "flex",
    justifyContent: "space-between",
  },
};

interface SubMenuItemInterface {
  classes: {
    subMenuItem: string
  },
  caption: string,
  parentOpen: boolean,
}

class SubMenuItem extends React.Component<SubMenuItemInterface> {
  state = {
    anchorEl: null,
  };

  handleItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleSubMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { classes, caption, children, parentOpen } = this.props;
    return (
      <React.Fragment>
        <MenuItem
          onClick={this.handleItemClick}
          className={classes.subMenuItem}
        >
          {caption}
          <ArrowRightIcon />
        </MenuItem>
        <Menu
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorEl) && parentOpen}
          anchorEl={anchorEl}
          onClose={this.handleSubMenuClose}
        >
          {children}
        </Menu>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(SubMenuItem);
