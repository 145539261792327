import React from "react";
import PropTypes from "prop-types";
import mapboxgl from "mapbox-gl";
import { connect } from "react-redux";
import { MAPBOX_TOKEN } from "constants/mapbox";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder/lib/index";
import Geocoder from "components/Geocoder";
import track from "react-tracking";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "./mapbox-geocoder.css";
import * as Sentry from "@sentry/browser/dist/index";
import GeocoderSkeleton from "skeletons/geocoder";

const geocoderId = "mapboxGeocoderId";

const GeocoderContainer = ({ map, bounds, tracking, isMapDataLoaded }) => {
  const createGeocoder = () => {
    const geocoder = new MapboxGeocoder({
      accessToken: MAPBOX_TOKEN,
      bbox: bounds,
      placeholder: "Enter Address",
      mapboxgl,
    });
    geocoder.on("result", () => {
      tracking.trackEvent({ event: "mouse-click", action: "geocoder-result" });

      Sentry.addBreadcrumb({
        category: "mouse-click",
        message: `geocoder result`,
        level: Sentry.Severity.Info,
      });
    });

    const geocoderElement = document.getElementById(geocoderId);
    if (geocoderElement !== null) {
      geocoderElement.innerHTML = "";
      geocoderElement.appendChild(geocoder.onAdd(map));
    }
  };

  createGeocoder();

  return (
    <div>
      {!map || !isMapDataLoaded ? (
        <GeocoderSkeleton />
      ) : (
        <Geocoder id={geocoderId} />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  bounds: state.baseMap.bounds,
});

GeocoderContainer.defaultProps = {
  map: undefined,
};

GeocoderContainer.propTypes = {
  bounds: PropTypes.array.isRequired,
  tracking: PropTypes.object.isRequired,
  map: PropTypes.object,
  isMapDataLoaded: PropTypes.bool.isRequired,
};

export default track()(connect(mapStateToProps)(GeocoderContainer));
