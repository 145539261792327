const makeSegment = segmentJson => {
  if (segmentJson.properties.segment) {
    const segment = JSON.parse(segmentJson.properties.segment);
    segment.coordinates = [];
    for (let i = 0; i < segment.longitude.length; i += 1) {
      segment.coordinates.push([segment.longitude[i], segment.latitude[i]]);
    }
    return segment;
  } else {
    const name = segmentJson.layer.id.replace(/_/g, " ");

    return {
      id: segmentJson.properties.id,
      name: name,
      length: segmentJson.properties.length,
      coordinates: segmentJson.geometry.coordinates,
    };
  }
};

export default makeSegment;
