import {
  SHOW_HIGHLIGHT,
  HIDE_HIGHLIGHT,
  SET_AZIMUTH,
  SET_ORIGINAL_AZIMUTH,
  SET_POINT_COORDS,
  SET_SEGMENT_COORDS,
  SET_SEGMENTS,
  ADD_SEGMENT,
  REMOVE_SEGMENT,
  CLEAR_SEGMENTS,
} from "actiontypes/highlight";

export function setOriginalAzimuth(originalAzimuth) {
  return {
    type: SET_ORIGINAL_AZIMUTH,
    originalAzimuth,
  };
}

export function setAzimuth(azimuth) {
  return {
    type: SET_AZIMUTH,
    azimuth,
  };
}

export function showHighlight(highlightType) {
  return {
    type: SHOW_HIGHLIGHT,
    highlightType,
  };
}

export function hideHighlight() {
  return {
    type: HIDE_HIGHLIGHT,
  };
}

export function setPointCoords(pointCoords) {
  return {
    type: SET_POINT_COORDS,
    pointCoords,
  };
}

export function setSegmentCoords(segmentCoords) {
  return {
    type: SET_SEGMENT_COORDS,
    segmentCoords,
  };
}

export function setSegments(segments) {
  return {
    type: SET_SEGMENTS,
    segments,
  };
}

export function addSegment(segment) {
  return {
    type: ADD_SEGMENT,
    segment,
  };
}

export function removeSegment(segmentId) {
  return {
    type: REMOVE_SEGMENT,
    segmentId,
  };
}

export function clearSegments() {
  return {
    type: CLEAR_SEGMENTS,
  };
}
