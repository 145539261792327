import React, { useEffect, useState } from "react";
import Layer from "../interfaces/state/layers/layer";
import moment from "moment-timezone";
import DynamicCheckbox from "./DynamicCheckbox";
import { FormControlLabel, FormControl, Grid, Typography } from "@material-ui/core";
import { RB_ROAD_SENSE } from "constants/colors";
import { EditRounded } from "@material-ui/icons";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { findIndex } from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editIcon: {
      top: "6px",
      position: "relative",
      "&:hover": {
        cursor: "pointer",
      },
    },
    roadSenseCollections: {
      textAlign: "left"
    },
    roadSenseCollection: {
      width: "100%",
    },
    textButton: {
      "&:hover": {
          cursor: "pointer",
      },
    },
    disabledText: {
      color: theme.palette.text.disabled
    }
  })
);

interface RoadSenseCheckboxesProps {
  layers: Layer[];
  toggleLayer: (layerId: number) => void;
  isEditing: boolean;
  editLayer: (layer: Layer) => void;
  flyToLayer: (layerId: number, allLayers: boolean, onlyId?: boolean) => void;
}

const RoadSenseCheckboxes = ({
  layers,
  toggleLayer,
  isEditing,
  editLayer,
  flyToLayer,
}: RoadSenseCheckboxesProps): React.ReactElement => {
  const classes = useStyles();
  const [isToggleAllChecked, setIsToggleAllChecked] = useState(false)

  const onChangeToggleAll = () => {
    if (isToggleAllChecked) {
      layers.forEach(layer => {
        if (layer.visible) toggleLayer(layer.id)
      })
    } else {
      layers.forEach(layer => {
        if (!layer.visible) toggleLayer(layer.id)
      })
    }
    setIsToggleAllChecked(!isToggleAllChecked)
    flyToLayer(0, true)
  }

  const checkboxOnChange = (layerId: number) => {
    if (!isEditing) {
      toggleLayer(layerId)
      flyToLayer(layerId, false)
    }
  }

  useEffect(() => {
    const allToggledOffLayers = findIndex(layers, { visible: false })
    if (allToggledOffLayers < 0) {
      setIsToggleAllChecked(true)
    } else {
      setIsToggleAllChecked(false)
    }
  }, [layers])

  return (
    <div className={classes.roadSenseCollections}>
      <FormControlLabel
        labelPlacement="end"
        label={"Toggle All"}
        className={classes.roadSenseCollection}
        control={
          <div>
            <DynamicCheckbox
              color={RB_ROAD_SENSE}
              onChange={onChangeToggleAll}
              checked={isToggleAllChecked}
              isCircle={false}
              disabled={isEditing}
            />
          </div>
        }
      />
      {layers.map(layer => {
        const name = layer.name && layer.name !== "" ? layer.name : moment(layer.dateDriven).format("MMM Do YYYY h:mm a");
        const color = layer.color && layer.color !== "" ? layer.color : RB_ROAD_SENSE;
        
        return (
          <FormControl
            key={layer.id}
            className={classes.roadSenseCollection}
            style={{marginLeft: "-11px"}}
            >
              <Grid container alignItems="center" wrap="nowrap">
                <Grid item>
                  <div>
                  <DynamicCheckbox
                    color={color}
                    onChange={() => checkboxOnChange(layer.id)}
                    checked={layer.visible}
                    isCircle={false}
                    disabled={isEditing}
                  />
                  {isEditing && (
                    <EditRounded
                      className={classes.editIcon}
                      onClick={() => editLayer(layer)}
                    />
                  )}
                  </div>
                </Grid>
                <Grid item>
                  <Typography 
                    className={(layer.visible && !isEditing) ? classes.textButton : classes.disabledText}
                    onClick={(layer.visible && !isEditing) ? (() => flyToLayer(layer.id, false, true)) : undefined}
                  >
                    {name}
                  </Typography>
                </Grid>
              </Grid>
           </FormControl>
        );
      })}
    </div>
  );
};

export default RoadSenseCheckboxes;
