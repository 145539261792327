import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import track from "react-tracking";

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import AppBarContainer from "containers/AppBarContainer";

import { closeSidebar } from "../../actions/visibility";

import useMount from "hooks/useMount";
import { goToTop } from "react-scrollable-anchor";
import * as Sentry from "@sentry/browser/dist/index";
import HandleAdminContainer from "./HandleAdminContainer";

const styles = {
  card: {
    marginTop: "5%",
  },
};

const filterNonAdminOrganizations = (organizations, memberships) => {
  const filteredOrgs = [];
  // need to first check if the organizations array is empty
  // this prevents us from returning an array of undefined
  if (organizations && organizations.length > 0) {
    memberships.forEach(membership => {
      if (membership.role === "Admin") {
        filteredOrgs.push(
          organizations.find(org => org.id === membership.organizationId)
        );
      }
    });
  }
  return filteredOrgs;
};

const AdminContainer = ({
  userUId,
  organizations,
  adminOrganizations,
  memberships,
  isOrganizationsDataLoaded,
  isRoadBoticsAdmin,
  closeSidebar,
  location,
  tracking,
}) => {
  const [tabValue, setTabValue] = useState(0);
  const emptyOrganization = {};
  const [currentOrganization, setCurrentOrganization] = useState(
    emptyOrganization
  );

  const handleOrganizationClick = selectedOrganization => {
    goToTop();
    setCurrentOrganization(selectedOrganization);
  };

  const usersAdminOrganizations = useMemo(
    () => filterNonAdminOrganizations(adminOrganizations, memberships),
    [adminOrganizations, memberships]
  );

  const onMount = () => {
    closeSidebar();
  };

  const onLoadOrganizationsData = () => {
    if (isOrganizationsDataLoaded && organizations.length > 0) {
      setCurrentOrganization(organizations[0]);
    }
  };

  // fetch the organizations on mount
  useMount(onMount);

  // set the default organization after organizations are loaded
  useEffect(onLoadOrganizationsData, [
    isOrganizationsDataLoaded,
    organizations,
  ]);

  const handleTabChange = (_event, tabValue) => {
    setTabValue(tabValue);
    tracking.trackEvent({
      event: "mouse-click",
      action: "admin-tab-change",
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `admin tab change`,
      level: Sentry.Severity.Info,
    });
  };

  const hasOrganizationsData =
    isOrganizationsDataLoaded && organizations && organizations.length >= 1;

  return (
    <div>
      <AppBarContainer urlLocation={location.pathname} />
      <Card style={styles.card}>
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          centered={true}
          onChange={handleTabChange}
        >
          <Tab label="Users" value={0} />
          {isRoadBoticsAdmin && <Tab label="Assessments" value={1} />}
          {isRoadBoticsAdmin && <Tab label="Organizations" value={2} />}
          <Tab label="Embedded Maps" value={3} />
        </Tabs>

        <CardContent>
          <HandleAdminContainer
            tabValue={tabValue}
            currentOrganization={currentOrganization}
            adminOrganizations={usersAdminOrganizations}
            handleOrganizationClick={handleOrganizationClick}
            hasOrganizationsData={hasOrganizationsData}
            userUId={userUId}
          />
        </CardContent>
      </Card>
    </div>
  );
};

AdminContainer.defaultProps = {
  userUId: null,
};

AdminContainer.propTypes = {
  userUId: PropTypes.string,
  organizations: PropTypes.array.isRequired,
  closeSidebar: PropTypes.func.isRequired,
  isRoadBoticsAdmin: PropTypes.bool.isRequired,
  isOrganizationsDataLoaded: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  tracking: PropTypes.object.isRequired,
};

const mapStateToProps = ({ user, userData }) => ({
  userUId: user.userUId,
  organizations: userData.organizations,
  memberships: userData.memberships,
  isOrganizationsDataLoaded: userData.isOrganizationsDataLoaded,
  isRoadBoticsAdmin: userData.isRoadBoticsAdmin,
  adminOrganizations: userData.adminOrganizations,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeSidebar,
    },
    dispatch
  );

export default track()(
  connect(mapStateToProps, mapDispatchToProps)(AdminContainer)
);
