import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import PropTypes from "prop-types";

const ToolboxIconOpen = ({
  viewBox = "0 0 20 20",
  fill = "#555",
  ...props
}) => (
  <SvgIcon viewBox={viewBox} {...props}>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 319.3 288.6"
    >
      <path
        fill={fill}
        d="M172,157.1c-1.4-0.1-2.7,0.9-2.8,2.3l-9.5,103l-11-1l7.1-77.2c0-1.3-0.9-2.4-2.2-2.6c-1.4-0.2-2.7,0.8-2.9,2.2
		l0,0l-7.2,78c-0.1,0.9,0.1,1.8,0.4,2.6l0.1,0.2c0.5,1.1,1.5,1.8,2.7,1.9l14,1.3c1.2,0.1,2.4-0.5,3.1-1.5c0.5-0.7,0.8-1.6,0.9-2.5
		l9.6-103.8C174.5,158.4,173.4,157.2,172,157.1z"
      />
      <path
        fill={fill}
        d="M133.8,275.7l-0.9,0.4l-1.2,0.5l40.8,3.1l-0.9-0.7c-1.2-0.8-1.8-2.1-1.7-3.5l0.1-1.3l-34.3-0.1
		C135.3,274.8,134.6,275.4,133.8,275.7z"
      />
      <path
        fill={fill}
        d="M216.2,119.8c-5.9-7.7-13.5-13.9-22.1-18.4c-0.5-0.3-1.1-0.5-1.7-0.6c-2.1-0.2-3.9,1.3-4.2,3.4l0,0l0,0
	c0,0,0,0,0,0l-1.1,12.1l-1,11.2l-10.2,4.7l-10.2,4.7l-9.2-6.5l-9-6.4l-0.1-0.1l1-11.1l1.1-12.1c0.1-0.6,0-1.2-0.3-1.8
	c-0.8-2-3-2.9-5-2.2l0-0.1l-0.2,0.1c-4.5,1.4-8.8,3.2-12.9,5.5c-4.3,2.4-8.3,5.2-12,8.5c-0.7,0.7-1.2,1.6-1.3,2.6l0,0l0,0.1
	c-0.3,2.6-0.5,4.9-0.8,7c-1,9-1.5,13.4,0.6,17.6c2,3.8,5.6,6.3,12.6,11c2.5,1.7,5.4,3.7,8.7,6l0.3,0.2l-5.7,62.2l-2.3,6.7l1.7,0.2
	l-4.1,45l-0.3,0.1c-3.7,1.7-6.9,3.1-9.7,4.3l16.9,0.1c0.3-0.5,0.5-1,0.5-1.6l4.2-46.8l33.5,4.9l-4,43.7l8,0l-0.3-0.2l10-109.1
	l0.8-5.3c3.3-1.5,6.8-3,9.7-4.3c7.7-3.4,11.7-5.1,14.4-8.5c2.8-3.7,3.2-8.1,3.8-17.2c0.2-2,0.3-4.4,0.5-7
	C217.1,121.7,216.9,120.6,216.2,119.8z"
      />
      <path
        fill={fill}
        d="M317.4,47.1c0-5-4-9-9-9h-89.5V2.5H103v35.6H12.4c-5,0-9,4-9,9v36.4c0,2.1,0.7,4.1,2,5.7l21.1,54.8L8.2,194
		v81.7c0,5,4,9,9,9h287.6c5,0,9-4,9-9V194l-19.6-40.2l22.4-64.7c1.2-1.6,1.9-3.5,1.9-5.5v0L317.4,47.1z M125.2,24.7h71.5v13.4h-71.5
		V24.7z M281.6,138h-28.2l-7.2,8l5.7,9h23.7l-0.1,0.2l3.4,6.5l17.2,32.3h-26.8v15.3c0,6.7-5.8,12.2-13,12.2h-12.7
		c-7.2,0-13-5.5-13-12.2V194h-28.7h-6.5h-5.2h-4.3H177h-4.3h-5.2h-6.5h-20.6h-23.2H92.2v15.3c0,6.7-5.8,12.2-13,12.2H66.5
		c-7.2,0-13-5.5-13-12.2V194H25.8l14.9-39H70l4.8-7l-4.3-10H42.5L25,92.5h21.1h38.6h151.5h38.7h22.5L281.6,138z"
      />
      <path
        fill={fill}
        d="M259.3,178.7c0.5,0.3,1,0.7,1.4,1.1C260.3,179.4,259.8,179,259.3,178.7z"
      />
      <path
        fill={fill}
        d="M65.9,177.4H79c2.8,0,5,2.2,5,5V208c0,2.8-2.2,5-5,5H65.9c-2.8,0-5-2.2-5-5v-25.5
	C60.9,179.7,63.2,177.4,65.9,177.4z"
      />
      <path
        fill={fill}
        d="M243.8,177.4H257c2.8,0,5,2.2,5,5V208c0,2.8-2.2,5-5,5h-13.1c-2.8,0-5-2.2-5-5v-25.5
	C238.8,179.7,241.1,177.4,243.8,177.4z"
      />
    </svg>
  </SvgIcon>
);

ToolboxIconOpen.propTypes = {
  viewBox: PropTypes.string,
  fil: PropTypes.string,
};

export default ToolboxIconOpen;
