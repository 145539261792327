import {
  SET_USER,
  LOGOUT_USER,
  SET_FIRST_NAME,
  SET_LAST_NAME,
  SET_ORGANIZATION,
  SET_USER_UID,
  SET_LAST_LOGIN,
  SET_ROLE,
  SHOW_ROADWAY_WELCOME,
  SET_IS_FREE_TRIAL_USER,
  SET_USER_STATUS,
  SET_IS_BETA_USER,
} from "actiontypes/login";

export function setUser(user) {
  return {
    type: SET_USER,
    user,
  };
}

export function setFirstName(firstName) {
  return {
    type: SET_FIRST_NAME,
    firstName,
  };
}

export function setLastName(lastName) {
  return {
    type: SET_LAST_NAME,
    lastName,
  };
}

export function setOrganization(organization) {
  return {
    type: SET_ORGANIZATION,
    organization,
  };
}

export function setRole(role) {
  return {
    type: SET_ROLE,
    role,
  };
}

export function setLastLogin(lastLogin) {
  return {
    type: SET_LAST_LOGIN,
    lastLogin,
  };
}

export function setUserUId(userUId) {
  return {
    type: SET_USER_UID,
    userUId,
  };
}

export function showRoadwayWelcome(roadwayWelcome) {
  return {
    type: SHOW_ROADWAY_WELCOME,
    roadwayWelcome,
  };
}

export function logoutUser() {
  return {
    type: LOGOUT_USER,
  };
}

export function setIsFreeTrialUser(isFreeTrialUser) {
  return {
    type: SET_IS_FREE_TRIAL_USER,
    isFreeTrialUser,
  };
}

export function setUserStatus(status) {
  return {
    type: SET_USER_STATUS,
    status,
  };
}

export function setIsBetaUser(isBetaUser) {
  return {
    type: SET_IS_BETA_USER,
    isBetaUser,
  };
}
