import {
  SHOW_MODAL,
  HIDE_MODAL,
  ENLARGE_IMG,
  SHRINK_IMG,
  UPDATE_MODAL,
  SET_PLAY,
  RESET_PLAY,
  NO_POINTS_MODAL,
  RESET_MODAL,
  SET_MODAL_TAB,
} from "actiontypes/modal";

export function showModal(modalData) {
  return {
    type: SHOW_MODAL,
    modalData,
  };
}

export function updateModal(modalData) {
  return {
    type: UPDATE_MODAL,
    modalData,
  };
}

export function noPointsModal() {
  return {
    type: NO_POINTS_MODAL,
  };
}

export function openModal(modalData) {
  return dispatch => {
    dispatch(showModal(modalData));
  };
}

export function hideModal() {
  return {
    type: HIDE_MODAL,
  };
}

export function enlargeImg() {
  return {
    type: ENLARGE_IMG,
  };
}

export function shrinkImg() {
  return {
    type: SHRINK_IMG,
  };
}

export function resetModal() {
  return {
    type: RESET_MODAL,
  };
}

export function playModal(modalPlayingID) {
  return {
    type: SET_PLAY,
    modalPlayingID,
  };
}

export function stopModal() {
  return {
    type: RESET_PLAY,
  };
}

export function setTabLayer(modalTab) {
  return {
    type: SET_MODAL_TAB,
    modalTab,
  };
}
