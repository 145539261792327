import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import GetAppIcon from "@material-ui/icons/GetApp";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Scan from "interfaces/scan";
import RBAPI, { mappyAPI, veritasAPI } from "api/RoadwayAPI";
import fileDownload from "js-file-download";
import { AssessmentTypes } from "constants/assessmentTypes";
import { setSnackbarMessageAndOpen, MessageTypes } from "actions/snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import SubMenuItem from "./SubMenuItem";

const useStyles = makeStyles({
  downloadButton: {
    width: "50px",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  popover: {
    marginLeft: "8px",
  },
  optionsBtn: {
    textTransform: "none",
    justifyContent: "flex-end",
    backgroundColor: "rgba(255, 255, 255, .55)",
  },
});

interface DownloadButtonProps {
  scanId: string;
  isRoadBoticsAdmin: boolean
  isIDIAssessment: boolean
}

const DownloadButton = ({
  scanId,
  isRoadBoticsAdmin,
  isIDIAssessment,
}: DownloadButtonProps): React.ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [isRoadSenseScan, setIsRoadSenseScan] = useState(false)
  const [scan, setScan] = useState<Scan>();
  const urlLocation = location.pathname;
  const mapRegex = /\/map\/.*/g;

  const handleError = (message: string): void => {
    dispatch(setSnackbarMessageAndOpen(message, MessageTypes.ERROR));
  };

  const fetchActiveScanData = useCallback(async () => {
    if (scanId) {
      try {
        const scan = await RBAPI.fetchScan(scanId).catch((err: any) => {
          if (err?.response?.status === 404) {
            setSnackbarMessageAndOpen(
              "Could not find assessment",
              MessageTypes.ERROR
            );
            return;
          }
          throw err;
        });
        if (scan.assessmentType === AssessmentTypes.ROADSENSE) {
          setIsRoadSenseScan(true)
        } else {
          setIsRoadSenseScan(false)
        }
        setScan(scan);
      } catch (error) {
        console.error(error);
      }
    }
  }, [scanId]);

  useEffect(() => {
    fetchActiveScanData();
  }, [fetchActiveScanData, scanId]);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const downloadBlobGeojsonOrCSV = (fileName: string, content: object): void => {
    try {
      const blob = new Blob([content as BlobPart], {
        type: "text/plain;charset=utf-8",
      });
      fileDownload(blob, fileName);
    } catch (error) {
      handleError("Error encountered when downloading geoJson");
      console.error(error);
    }
  };

  const downloadBlobZip = (fileName: string, content: object): void => {
    try {
      const blob = new Blob([content as BlobPart], {
        type: "application/zip",
      });
      fileDownload(blob, fileName);
    } catch (error) {
      handleError("Error encountered when downloading geoJson");
      console.error(error);
    }
  };

  const generateFileOutName = (
    scan: Scan,
    outType: "points" | "segments",
    fileType: "shapefile" | "geojson" | "csv"
  ): string => {
    return `${scan?.displayName || scan.name}_${outType}.${fileType === "shapefile" ? "tar.gz" : fileType}`.replace(
      /\s/g,
      "_"
    );
  };

  const downloadPointsGeoJson = async (): Promise<void> => {
    setLoading(true);
    if (scan) {
      const fileName = generateFileOutName(scan, "points", "geojson");
      if (scan?.assessmentType === AssessmentTypes.ROADSENSE) {
        const geoJson = await veritasAPI.getRoadSensePointsGeoJson(scan.id);
        downloadBlobGeojsonOrCSV(fileName, geoJson);
      } else {
        let geoJson = {};
        let downloaded = false;
        try {
          geoJson = await veritasAPI.getRatedPointsGeoJson(scan.id);
          downloadBlobGeojsonOrCSV(fileName, geoJson);
          downloaded = true;
        } catch (e) {
          // Show error if not a 404 since 404 means assessment not in V2
          if (e.response.status !== 404) {
            console.error("Veritas points geojson error", e)
          }
        }

        try {
          if (!downloaded) {
            geoJson = await mappyAPI.getPointsGeoJson(scan.name);
            downloadBlobGeojsonOrCSV(fileName, geoJson);
          }
        } catch (e) {
          // If we tried veritas and mappy already, then there really is no data. 
          handleError("Error encountered when downloading points geoJson");
          console.error("Mappy points geojson error", e);
        }
      }
    } else {
      handleError("A valid assessment was not found");
      console.error("A valid assessment was not found");
    }
    setLoading(false);
    handleClose();
  };

  const downloadSegmentsGeoJson = async (): Promise<void> => {
    setLoading(true);
    if (scan) {
      const fileName = generateFileOutName(scan, "segments", "geojson");
      let geoJson = {};
      let downloaded = false;
      try {
        geoJson = await veritasAPI.getRatedSegmentsGeoJson(scan.id);
        downloadBlobGeojsonOrCSV(fileName, geoJson);
        downloaded = true;
      } catch (e) {
        // Show error if not a 404 since 404 means assessment not in V2
        if (e.response.status !== 404) {
          console.error("Veritas segments geojson error", e)
        }
      }

      try {
        if (!downloaded) {
          geoJson = await mappyAPI.getSegmentsGeoJson(scan.name);
          downloadBlobGeojsonOrCSV(fileName, geoJson);
        }
      } catch (e) {
        // If we tried veritas and mappy already, then there really is no data. 
        handleError("Error encountered when downloading segments geoJson");
        console.error("Mappy segments geojson error", e);
      }
    } else {
      handleError("A valid assessment was not found");
      console.error("A valid assessment was not found");
    }
    setLoading(false);
    handleClose();
  };

  const downloadPointsCSV = async (): Promise<void> => {
    setLoading(true);
    if (scan) {
      const fileName = generateFileOutName(scan, "points", "csv");
      let csv = {};
      try {
        csv = await veritasAPI.getRatedPointsCSV(scan.id);

        downloadBlobGeojsonOrCSV(fileName, csv);
      } catch (e) {
        handleError("Error encountered when downloading points csv");
        console.error(e);
      }
    } else {
      handleError("A valid assessment was not found");
      console.error("A valid assessment was not found");
    }
    setLoading(false);
    handleClose();
  }

  const downloadSegmentsCSV = async (): Promise<void> => {
    setLoading(true);
    if (scan) {
      const fileName = generateFileOutName(scan, "segments", "csv");

      let csv = {};
      let downloaded = false;
      try {
        csv = await veritasAPI.getRatedSegmentsCSV(scan.id);
        downloadBlobGeojsonOrCSV(fileName, csv);
        downloaded = true;
      } catch (e) {
        // Show error if not a 404 since 404 means assessment not in V2
        if (e.response.status !== 404) {
          console.error("Veritas segments CSV error", e)
        }
      }

      try {
        if (!downloaded) {
          csv = await mappyAPI.getSegmentsCSV(scan.name);
          downloadBlobGeojsonOrCSV(fileName, csv);
        }
      } catch (e) {
        // If we tried veritas and mappy already, then there really is no data. 
        handleError("Error encountered when downloading segments csv");
        console.error("Mappy segments csv error", e);
      }
    } else {
      handleError("A valid assessment was not found");
      console.error("A valid assessment was not found");
    }
    setLoading(false);
    handleClose();
  }

  const downloadPointsShapefile = async (): Promise<void> => {
    setLoading(true);
    if (scan) {
      const fileName = generateFileOutName(scan, "points", "shapefile");
      let shapefile = {};
      let downloaded = false;
      try {
        shapefile = await veritasAPI.getRatedPointsShapefile(scan.id);

        downloadBlobZip(fileName, shapefile);
        downloaded = true;
      } catch (e) {
        // Show error if not a 404 since 404 means assessment not in V2
        if (e.response.status !== 404) {
          console.error("Veritas segments Shapefile error", e)
        }
      }

      try {
        if (!downloaded) {
          shapefile = await mappyAPI.getShapefile(scan.name);
          downloadBlobZip(fileName, shapefile);
        }
      } catch (e) {
        // If we tried veritas and mappy already, then there really is no data. 
        handleError("Error encountered when downloading points shapefile");
        console.error("Mappy points shapefile error", e);
      }
    } else {
      handleError("A valid assessment was not found");
      console.error("A valid assessment was not found");
    }
    setLoading(false);
    handleClose();
  }

  const downloadSegmentsShapefile = async (): Promise<void> => {
    setLoading(true);
    if (scan) {
      const fileName = generateFileOutName(scan, "segments", "shapefile");
      let shapefile = {};
      let downloaded = false;
      try {
        shapefile = await veritasAPI.getRatedSegmentsShapefile(scan.id);

        downloadBlobZip(fileName, shapefile);
        downloaded = true;
      } catch (e) {
        // Show error if not a 404 since 404 means assessment not in V2
        if (e.response.status !== 404) {
          console.error("Veritas segments Shapefile error", e)
        }
      }

      try {
        if (!downloaded) {
          shapefile = await mappyAPI.getShapefile(scan.name);
          downloadBlobZip(fileName, shapefile);
        }
      } catch (e) {
        // If we tried veritas and mappy already, then there really is no data. 
        handleError("Error encountered when downloading segments shapefile");
        console.error("Mappy segments shapefile error", e);
      }
    } else {
      handleError("A valid assessment was not found");
      console.error("A valid assessment was not found");
    }
    setLoading(false);
    handleClose();
  }

  const downloadIDIcsv = async (): Promise<void> => {
    setLoading(true);

    if (scanId) {
      try {
        const data = await RBAPI.fetchIDIcsv(scanId)
        window.open(data.signedUrl)
      } catch (e) {
        handleError("Error encountered when downloading IDI csv");
        console.error(e);
      }
    } else {
      handleError("A valid assessment was not found");
      console.error("A valid assessment was not found");
    }
    setLoading(false);
    handleClose();
  }

  const clickMenuItem = (type: string) => {
    const onClick = (type: string, isPoint: boolean) => {
      if (type === "geojson") {
        return isPoint ? downloadPointsGeoJson : downloadSegmentsGeoJson
      } else if (type === "csv") {
        return isPoint ? downloadPointsCSV : downloadSegmentsCSV
      } else if (type === "shapefile") {
        return isPoint ? downloadPointsShapefile : downloadSegmentsShapefile
      }
    }

    return (
      <div>
        <Button
          className={classes.optionsBtn}
          fullWidth={true}
          onClick={onClick(type, true)}
        >
          Points
          </Button>
        <br />
        {scan?.assessmentType === AssessmentTypes.NORMAL &&
          <Button
            className={classes.optionsBtn}
            fullWidth={true}
            onClick={onClick(type, false)}
          >
            Segments
          </Button>
        }
      </div>
    )
  };

  return (
    <>
      {/* will have to remove the check for roadsensescan 
      once issue https://gitlab.com/roadbotics/veritas/-/issues/432 is resolved */}
      {urlLocation.match(mapRegex) && !isRoadSenseScan && (
        <>
          <Button className={classes.downloadButton} onClick={handleClick}>
            <Tooltip title="Download Files" placement="bottom">
              <GetAppIcon />
            </Tooltip>
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {isRoadBoticsAdmin && <SubMenuItem parentOpen={Boolean(anchorEl)} caption="Geojson">
              {clickMenuItem("geojson")}
            </SubMenuItem>}
            {isRoadBoticsAdmin && <SubMenuItem parentOpen={Boolean(anchorEl)} caption="CSV">
              {clickMenuItem("csv")}
            </SubMenuItem>}
            {isIDIAssessment && (
              <MenuItem onClick={downloadIDIcsv}>
                IDI CSV
              </MenuItem>
            )}
            {isRoadBoticsAdmin && <SubMenuItem parentOpen={Boolean(anchorEl)} caption="Shapefile">
              {clickMenuItem("shapefile")}
            </SubMenuItem>}
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Menu>
        </>
      )}
    </>
  );
};

export default DownloadButton;
