import {
  ADD_LAYERS,
  TOGGLE_LAYER,
  SHOW_LAYER,
  HIDE_LAYER,
  ADD_LAYER_GROUP,
  CLEAR_LAYER_GROUPS,
  CHANGE_LAYER_NAME,
  CHANGE_LAYER_COLOR,
} from "actiontypes/layers";
import find from "lodash/find";
import get from "lodash/get";

export function addLayers(layerGroupId, layers) {
  return {
    type: ADD_LAYERS,
    layers,
    layerGroupId,
  };
}

export function changeLayerName(layerId, name) {
  return {
    type: CHANGE_LAYER_NAME,
    id: layerId,
    name: name,
  };
}

export function changeLayerColor(layerId, color) {
  return {
    type: CHANGE_LAYER_COLOR,
    id: layerId,
    color: color,
  };
}

export function toggleLayer(layerId) {
  return {
    type: TOGGLE_LAYER,
    id: layerId,
  };
}

export function showLayer(layerId) {
  return {
    type: SHOW_LAYER,
    id: layerId,
  };
}

export function hideLayer(layerId) {
  return {
    type: HIDE_LAYER,
    id: layerId,
  };
}

export function toggleLayerBySource(layerSource, toggleValue) {
  return (dispatch, getState) => {
    const currentState = getState();
    const { layers } = currentState;

    const results = find(layers.groups, {
      layers: [{ sourceLayer: layerSource }],
    });
    const layer = find(get(results, "layers"), { sourceLayer: layerSource });

    if (layer) {
      if (toggleValue) {
        dispatch(showLayer(layer.id));
      } else {
        dispatch(hideLayer(layer.id));
      }
    }
  };
}

let layerGroupId = 0;

export function addLayerGroup(layerGroup) {
  return {
    projectName: layerGroup.projectName,
    name: layerGroup.name,
    type: ADD_LAYER_GROUP,
    id: layerGroupId++,
    style: layerGroup.style,
    clickBehavior: layerGroup.clickBehavior,
    position: layerGroup.position,
  };
}

export function clearLayerGroups() {
  return {
    type: CLEAR_LAYER_GROUPS,
  };
}
