import isPromise from "is-promise";
import { Dispatch, AnyAction, Middleware } from "redux";

const errorMiddleware: Middleware = () => (next: Dispatch) => (
  action: AnyAction
) => {
  // If not a promise, continue on
  if (!isPromise(action.payload)) {
    return next(action);
  }

  // Dispatch initial pending promise, but catch any errors
  return next(action).catch((error: Error) => {
    // only log errors for now and prevent unhandled promise rejections
    console.error(error);
    return error;
  });
};

export default errorMiddleware;
