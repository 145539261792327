import { TOGGLE_ISOLATE_DISTRESS } from "actiontypes/isolateDistresses";

const initialState = {
  potholeChecked: false,
  fatigueCrackingChecked: false,
  patchSealChecked: false,
  transLongChecked: false,
  surfaceDeteriorationChecked: false,
  pavementDistortionsChecked: false,
};

const isolateDistressesReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_ISOLATE_DISTRESS:
      if (action.toggleType === "potholeChecked") {
        return {
          ...state,
          potholeChecked: !state[action.toggleType],
        };
      } else if (action.toggleType === "fatigueCrackingChecked") {
        return {
          ...state,
          fatigueCrackingChecked: !state[action.toggleType],
        };
      } else if (action.toggleType === "patchSealChecked") {
        return {
          ...state,
          patchSealChecked: !state[action.toggleType],
        };
      } else if (action.toggleType === "transLongChecked") {
        return {
          ...state,
          transLongChecked: !state[action.toggleType],
        };
      } else if (action.toggleType === "surfaceDeteriorationChecked") {
        return {
          ...state,
          surfaceDeteriorationChecked: !state[action.toggleType],
        };
      } else if (action.toggleType === "pavementDistortionsChecked") {
        return {
          ...state,
          pavementDistortionsChecked: !state[action.toggleType],
        };
      }
      break;
    default:
      return state;
  }
};

export default isolateDistressesReducer;
