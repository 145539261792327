import React from "react";
import { connect } from "react-redux";
import StopDrawing from "components/icons/DistanceIconClose";
import StartDrawing from "components/icons/DistanceIcon";
import PropTypes from "prop-types";

const MeasureDistanceContainer = ({ measureDistance: { isMeasuring } }) => (
  <React.Fragment>
    {isMeasuring ? <StopDrawing /> : <StartDrawing />}
  </React.Fragment>
);

MeasureDistanceContainer.propTypes = {
  measureDistance: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  measureDistance: state.measureDistance,
});

export default connect(mapStateToProps)(MeasureDistanceContainer);
