import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { RB_LIGHT_GREY } from "../constants/colors";
import { topBannerHeight } from "../views/Banner";
import classNames from "classnames";

const useStyles = makeStyles({
  compass: {
    background: RB_LIGHT_GREY,
    margin: "5px 20px ",
  },
  compassBanner: {
    marginTop: topBannerHeight,
  },
});

interface CompassSkeletonProps {
  isTopBannerOpen: boolean;
}

const CompassSkeleton: React.FC<CompassSkeletonProps> = ({
  isTopBannerOpen,
}) => {
  const classes = useStyles();
  return (
    <Skeleton
      className={classNames(classes.compass, {
        [classes.compassBanner]: isTopBannerOpen,
      })}
      variant="circle"
      width={70}
      height={70}
    />
  );
};

export default CompassSkeleton;
