import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { RB_LIGHT_GREY } from "../constants/colors";

const useStyles = makeStyles({
  title: {
    background: RB_LIGHT_GREY,
    marginTop: "15px",
    marginBottom: "18px",
    marginLeft: "35px",
    marginRight: "35px",
  },
});

const TitleSkeleton: React.FC = () => {
  const classes = useStyles();
  return <Skeleton className={classes.title} variant="rect" height={30} />;
};

export default TitleSkeleton;
