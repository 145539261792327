const directions = [
  "North",
  "North East",
  "East",
  "South East",
  "South",
  "South West",
  "West",
  "North West",
];

export function getDirection(angle: number): string {
  // if the angle is less that 0 or greater that 360, find the adjusted angle
  // so that it is between 0 and 360
  const adjustedAngle = (angle %= 360) < 0 ? angle + 360 : angle;
  const nDirections = directions.length;
  // determine the quadrant you are in
  const quadrantAngle = 360 / nDirections;
  return directions[Math.round(adjustedAngle / quadrantAngle) % nDirections];
}
