import React from "react";
import { connect } from "react-redux";
import sortBy from "lodash/sortBy";
import CrackSealLayer from "components/CrackSealLayer";
import PropTypes from "prop-types";

const CrackSealContainer = ({ toggleGroups }) => {
  const getToggleGroups = () => {
    return toggleGroups.filter(
      toggleGroup => toggleGroup.name === "crackseals"
    );
  };

  return (
    <CrackSealLayer toggleGroups={sortBy(getToggleGroups(), "position")} />
  );
};

CrackSealContainer.propTypes = {
  toggleGroups: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  toggleGroups: state.toggles.groups,
});

export default connect(mapStateToProps)(CrackSealContainer);
