import sortBy from "lodash/sortBy";
import { addLayers, showLayer } from "actions/layers";
import { addLayerGroup, clearLayerGroups } from "./layers";
import { addToggle, addToggleGroup, clearToggleGroups } from "./toggles";
import { clearAllArea } from "./measureArea";
import { clearAllMeasure } from "./measureDistance";
import {
  turnOffDynamicSegmentationDrag,
  turnOffDynamicSegmentationSelect,
  turnOffDynamicSegmentationLasso,
} from "./dynamicSegmentation";
import moment from "moment-timezone";
import { clickBehaviorConstants } from "constants/clickBehavior";

function processLayers(layerGroupId, layers) {
  return dispatch => {
    dispatch(addLayers(layerGroupId, layers));
  };
}

function processLayerGroups(layerGroups) {
  return dispatch => {
    layerGroups.forEach((layerGroup, layerGroupIndex) => {
      const newLayerGroup = addLayerGroup(layerGroup);
      dispatch(newLayerGroup);
      let layers;
      if (
        layerGroup.clickBehavior ===
        clickBehaviorConstants.roadwayRoadSensePoint
      ) {
        layers = layerGroup.layers
          .sort(
            (a, b) =>
              moment(a.dateDriven).valueOf() - moment(b.dateDriven).valueOf()
          )
          .map(layer => (layer.visible ? layer : { ...layer, visible: true }));
      } else {
        layers = layerGroup.layers.map(layer =>
          layerGroupIndex === 0
            ? { ...layer, visible: true }
            : { ...layer, visible: false }
        );
      }

      if (layers) {
        dispatch(processLayers(newLayerGroup.id, layers));
      }
    });
  };
}

function processDataProject(project) {
  return (dispatch, getState) => {
    dispatch(processLayerGroups(project.dataSet.layerGroups));

    const currentState = getState();
    const layerGroups = currentState.layers.groups;

    const layerGroup = sortBy(layerGroups, "position")[0];
    layerGroup.layers.forEach(layer => {
      // only show the layer if we set the layer to visible processLayerGroups
      if (layer.visible) {
        dispatch(showLayer(layer.id));
      }
    });
  };
}

function processToggleGroups(toggleGroups) {
  return dispatch =>
    toggleGroups.forEach(toggleGroup => {
      const newToggleGroup = addToggleGroup(toggleGroup);
      dispatch(newToggleGroup);

      const toggles = toggleGroup.toggles;
      toggles.forEach(toggle => {
        dispatch(addToggle(newToggleGroup.id, toggle));
      });
    });
}

function processLayoutProject(layoutProject) {
  return dispatch => {
    const toggleGroups = layoutProject.dataSet.sidebar.toggleGroups;
    if (toggleGroups) {
      dispatch(processToggleGroups(toggleGroups));
    }
  };
}

export function processAssessment(assessment) {
  return dispatch => {
    const data = assessment.data;
    const layout = assessment.layout;

    dispatch(processDataProject(data.project));
    dispatch(processLayoutProject(layout.project));
  };
}

export function clearAssessment() {
  return dispatch => {
    dispatch(clearToggleGroups());
    dispatch(clearLayerGroups());
    dispatch(clearAllArea());
    dispatch(clearAllMeasure());
    dispatch(turnOffDynamicSegmentationDrag());
    dispatch(turnOffDynamicSegmentationSelect());
    dispatch(turnOffDynamicSegmentationLasso());
  };
}
