import React from "react";
import { ScaleControl } from "react-mapbox-gl";
import PropTypes from "prop-types";
import { measurementScaleMap } from "constants/measuringSystem";

const defaultScaleStyle = {
  marginRight: "45%",
};

const Controls = ({ scaleStyle, measurement }) => {
  return (
    <div>
      <ScaleControl
        measurement={measurementScaleMap[measurement]}
        style={scaleStyle}
      />
    </div>
  );
};

Controls.defaultProps = {
  scaleStyle: defaultScaleStyle,
};

Controls.propTypes = {
  scaleStyle: PropTypes.object,
  measurement: PropTypes.string.isRequired,
};

export default Controls;
