import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { RB_LIGHT_GREY } from "../constants/colors";
import { times } from "lodash";

const useStyles = makeStyles({
  button: {
    background: RB_LIGHT_GREY,
    flexGrow: 1,
    margin: "10px",
    float: "right",
  },
  label: {
    background: RB_LIGHT_GREY,
    textAlign: "left",
  },
  dropdown: {
    background: RB_LIGHT_GREY,
    textAlign: "left",
  },
});

interface AnnotationsSkeletonProps {
  numberOfItems: number;
}

const AnnotationsSkeleton = ({
  numberOfItems,
}: AnnotationsSkeletonProps): React.ReactElement => {
  const classes = useStyles();
  return (
    <div>
      <Grid key="skeleton-grid" container>
        {times(numberOfItems).map(idx => {
          return (
            <Grid key={`annotation-skeleton-item-${idx}`} item md={6}>
              <Skeleton
                className={classes.label}
                variant="text"
                height={40}
                width={130}
              />
              <Skeleton
                className={classes.dropdown}
                variant="rect"
                height={30}
                width={220}
              />
            </Grid>
          );
        })}
      </Grid>
      <Skeleton
        className={classes.button}
        variant="rect"
        height={30}
        width={60}
      />
    </div>
  );
};

export default AnnotationsSkeleton;
