import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import HighlightOff from "@material-ui/icons/HighlightOff";
import PropTypes from "prop-types";

const styles = () => ({
  close: {
    top: 1,
    position: "absolute",
    right: 1,
    zIndex: 100,
    cursor: "pointer",
    backgroundColor: "rgba(255,255,255,0)",
  },
});

const CloseModalIcon = ({ classes, onClick }) => (
  <HighlightOff className={classes.close} onClick={onClick} />
);

CloseModalIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(CloseModalIcon);
