import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route, Switch } from "react-router-dom";

import PrivateRoute from "Routes/PrivateRoute";
import MapView from "containers/MapView/MapView";
import DashboardContainer from "containers/DashboardContainer";
import AdminSettingsContainer from "containers/AdminContainer/AdminContainer";
import SummaryPageContainer from "containers/SummaryPageContainer";
import PageNotFound from "components/PageNotFound";
import FreeTrialContainer from "containers/FreeTrialContainer";
import SuccessPageContainer from "containers/SuccessPageContainer";

import {
  ROUTE_MAP,
  ROUTE_DASHBOARD,
  ROUTE_ADMIN,
  ROUTE_NOT_FOUND,
  ROUTE_SUMMARY,
  ROUTE_FREE_TRIAL,
  ROUTE_FREE_TRIAL_SUCCESS,
  ROUTE_IMAGE_LOGGER_SIGN_UP,
  ROUTE_ROAD_SENSE_SIGN_UP,
  ROUTE_ROAD_SENSE_SIGN_UP_SUCCESS,
  ROUTE_LOGIN,
} from "../constants/routes";
import UserStatus from "../constants/userStatus";
import RoadSenseSignUpContainer from "../containers/RoadSenseSignUpContainer";
import { getAssessmentType } from "../utils/getAssessmentType";
import { AssessmentTypes } from "../constants/assessmentTypes";
import { useSelector } from "react-redux";

const Router = ({ userUId, user, userStatus }) => {
  const isUserNull = user === null;
  const isUserDisabled = userStatus === UserStatus.DISABLED;
  const isAdmin = useSelector(state => state?.userData?.isAdmin);
  // image-logger redirect is necessary for old social media posts that need to direct
  // to road-sense
  return (
    <Switch>
      <Route path={ROUTE_FREE_TRIAL} component={FreeTrialContainer} />
      <Route path={ROUTE_FREE_TRIAL_SUCCESS} component={SuccessPageContainer} />
      <Redirect
        from={ROUTE_IMAGE_LOGGER_SIGN_UP}
        to={ROUTE_ROAD_SENSE_SIGN_UP}
      />
      <Route
        path={ROUTE_ROAD_SENSE_SIGN_UP}
        component={RoadSenseSignUpContainer}
      />
      <Route
        path={ROUTE_ROAD_SENSE_SIGN_UP_SUCCESS}
        component={SuccessPageContainer}
      />
      <PrivateRoute
        userUId={userUId}
        path={ROUTE_ADMIN}
        component={AdminSettingsContainer}
        accessAllowed={!isUserNull && !isUserDisabled && isAdmin}
        allowFrom={!isUserNull && !isUserDisabled}
      />
      <PrivateRoute
        userUId={userUId}
        path={`${ROUTE_MAP}/:mapId`}
        component={MapView}
        accessAllowed={!isUserNull && !isUserDisabled}
        allowFrom={!isUserNull && !isUserDisabled}
      />
      <PrivateRoute
        userUId={userUId}
        path={ROUTE_DASHBOARD}
        component={DashboardContainer}
        accessAllowed={!isUserNull}
      />
      <Route exact path="/" render={() => <Redirect to={ROUTE_DASHBOARD} />} />
      {getAssessmentType() !== AssessmentTypes.ROADSENSE && (
        <PrivateRoute
          userUId={userUId}
          path={`${ROUTE_SUMMARY}/:scanId`}
          component={SummaryPageContainer}
          accessAllowed={!isUserNull}
        />
      )}
      <Route path={ROUTE_NOT_FOUND} component={PageNotFound} />
      <Route
        path={ROUTE_LOGIN}
        component={() => {
          window.location = `${process.env.REACT_APP_ACCOUNTS_DOMAIN}/login`;
          return null;
        }}
      />
      <Redirect to={ROUTE_NOT_FOUND} />
    </Switch>
  );
};

Router.defaultProps = {
  user: null,
  userStatus: UserStatus.DISABLED,
};

Router.propTypes = {
  user: PropTypes.string,
  userStatus: PropTypes.string,
};

export default Router;
