import React, { useState } from "react";
import Dashboard from "components/Dashboard";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { showRoadwayWelcome } from "actions/login";
import firebase from "config/firebase";
import get from "lodash/get";
import { closeSidebar } from "../actions/visibility";
import useMount from "hooks/useMount";
import RBAPI from "api/RoadwayAPI";

const DashboardContainer = ({
  showWelcome,
  closeSidebar,
  userUId,
  isOrganizationsDataLoaded,
  showRoadwayWelcome,
}) => {
  const [checked, setChecked] = useState(false);

  const getFirestoreWelcome = () => {
    const uid = get(firebase.auth(), "currentUser.uid");

    if (!uid) return;

    RBAPI.getUser(uid)
      .then(user => {
        showRoadwayWelcome(
          user.roadwayWelcome !== undefined ? user.roadwayWelcome : true
        );
      })
      .catch(err => {
        console.error(err);
      });
  };

  // onMount with a callback to act as a unmount
  useMount(() => {
    getFirestoreWelcome();
    closeSidebar();
    return function cleanup() {
      showRoadwayWelcome(false);
    };
  });

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const dismissDialog = () => {
    showRoadwayWelcome(false);

    if (checked) {
      const editedUser = {
        id: userUId,
        roadwayWelcome: false,
      };
      RBAPI.updateUser(editedUser);
    }
  };

  return (
    <Dashboard
      showWelcome={showWelcome}
      dismissDialog={dismissDialog}
      checked={checked}
      handleCheckboxChange={handleCheckboxChange}
      isOrganizationsDataLoaded={isOrganizationsDataLoaded}
    />
  );
};

DashboardContainer.propTypes = {
  showWelcome: PropTypes.bool.isRequired,
  closeSidebar: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  userUId: PropTypes.string,
  isOrganizationsDataLoaded: PropTypes.bool.isRequired,
  showRoadwayWelcome: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  showWelcome: state.user.roadwayWelcome,
  userUId: state.user.userUId,
  isOrganizationsDataLoaded: state.userData.isOrganizationsDataLoaded,
});

const mapDispatchToProps = dispatch => ({
  closeSidebar: () => dispatch(closeSidebar()),
  showRoadwayWelcome: roadwayWelcome =>
    dispatch(showRoadwayWelcome(roadwayWelcome)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
