import {
  SET_MAP_STYLE,
  SET_MAP_CENTER,
  SET_MAP_PITCH,
  SET_MAP_ZOOM,
  SET_MAP_BOUNDS,
  SET_MAP_BEARING,
  SET_MAP_DEFAULT_ZOOM,
  SET_IS_MAP_DATA_LOADED,
} from "actiontypes/map";

export function setIsMapDataLoaded(isMapDataLoaded) {
  return {
    type: SET_IS_MAP_DATA_LOADED,
    isMapDataLoaded,
  };
}

export function setMapStyle(style) {
  return {
    type: SET_MAP_STYLE,
    style,
  };
}

export function setMapCenter(center) {
  return {
    type: SET_MAP_CENTER,
    center,
  };
}

export function setMapPitch(pitch) {
  return {
    type: SET_MAP_PITCH,
    pitch,
  };
}

export function setMapZoom(zoom) {
  return {
    type: SET_MAP_ZOOM,
    zoom,
  };
}

export function setMapBearing(bearing) {
  const trueBearing = typeof bearing === "object" ? bearing : [bearing];
  return {
    type: SET_MAP_BEARING,
    bearing: trueBearing,
  };
}

export function setMapBounds(bounds) {
  return {
    type: SET_MAP_BOUNDS,
    bounds,
  };
}

export function setMapDefaultZoom(min, max) {
  return {
    type: SET_MAP_DEFAULT_ZOOM,
    min,
    max,
  };
}
