import React from "react";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { RB_YELLOW, TABLE_ROW_SHADING } from "../../constants/colors";
import DownloadRoadSenseButton from "../DownloadRoadSenseButton";
import RoadSenseVideo from "../RoadSenseVideo";
import Paper from "@material-ui/core/Paper";
import Button from "../form/Button";

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px",
  },
  child: {
    width: "50%",
  },
  dialogButton: {
    backgroundColor: RB_YELLOW,
    borderRadius: "2px",
    borderBottom: "none",
    paddingLeft: "10px",
    fontFamily: "'Open Sans', sans-serif",
    "&:hover": {
      backgroundColor: RB_YELLOW,
    },
    width: "170px",
    whiteSpace: "nowrap",
  },
  dialogTitle: {
    paddingTop: "30px",
    paddingBottom: "10px",
    textAlign: "center",
  },
  text: {
    fontFamily: "'Open Sans', sans-serif",
  },
  strong: {
    fontWeight: 600,
  },
  textContent: {
    backgroundColor: TABLE_ROW_SHADING,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  grow: {
    flexGrow: 1,
    alignSelf: "flex-start",
  },
  padding: {
    padding: "10px",
  },
  fullHeight: {
    height: "100%",
  },
  fullWidth: {
    width: "100%",
  },
  buttonText: {
    fontSize: "14px",
  },
  button: {
    width: "170px",
    backgroundColor: RB_YELLOW,
    textTransform: "none",
    fontFamily: "'Open Sans', sans-serif",
    "&:hover": {
      backgroundColor: RB_YELLOW,
    },
    borderRadius: "2px",
    margin: "5px",
    whiteSpace: "nowrap",
  },
});

const RoadSenseGridItem = (): React.ReactElement => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.child}>
        <Grid container className={classes.root}>
          <Grid item>
            <Typography
              variant="h4"
              className={classNames(classes.text, classes.strong)}
            >
              RoadSense App Tutorial
            </Typography>
          </Grid>
        </Grid>
        <Grid container component={Paper} className={classes.padding}>
          <Grid item xs={6} className={classes.padding}>
            <RoadSenseVideo />
          </Grid>
          <Grid
            item
            xs={6}
            className={classNames(classes.padding, classes.textContent)}
          >
            <Typography
              variant={"body1"}
              className={classNames(classes.text, classes.grow)}
            >
              Download <strong>RoadSense</strong> to your smartphone to begin
              collecting your road network data. Watch the video for a
              step-by-step walkthrough on how to use the app.
            </Typography>
            <DownloadRoadSenseButton className={classes.dialogButton} />
            <Button
              className={classes.button}
              onClick={() => {
                window.location.replace(
                  "https://support.roadbotics.com/hc/en-us/articles/360042566251"
                );
              }}
            >
              <Typography align="center" className={classes.buttonText}>
                RoadSense FAQ
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RoadSenseGridItem;
