import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import omit from "lodash/omit";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

import Dialog from "components/Dialog/Dialog";
import TextField from "components/form/TextField";
import Select from "components/form/Select";
import ActivityIndicatorButton from "components/ActivityIndicatorButton";

import { useForm, Controller } from "react-hook-form";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  formControl: {
    width: "100%",
  },
});

const OrganizationsAdminUpdateFormDialog = ({
  title,
  isOpen,
  organizations,
  handleClose,
  handleUpdate,
  values: initialFormState,
  isUpdateActive,
}) => {
  const { register, errors, control, handleSubmit } = useForm({
    defaultValues: initialFormState,
  });
  const classes = useStyles();

  const handleFormSubmit = e => {
    e.preventDefault();
    handleSubmit(data => {
      handleUpdate(omit(data, ["tableData"]));
    })(e);
  };

  const renderForm = () => (
    <form onSubmit={handleFormSubmit}>
      <div>
        <input type="hidden" name="id" ref={register} />
        <TextField
          label="Organization Name"
          name="name"
          inputRef={register({
            required: "This field is required.",
          })}
          error={errors?.name !== undefined}
          helperText={errors?.name?.message}
        />
        <br />
        <FormControl
          error={errors?.parentOrganizationId !== undefined}
          className={classes.formControl}
        >
          <Controller
            name={"parentOrganizationId"}
            control={control}
            rules={{
              required: "This field is required",
            }}
            defaultValue={initialFormState?.parentOrganizationId}
            as={
              <Select displayEmpty name="parentOrganizationId">
                <MenuItem value="" disabled>
                  Select Parent Organization
                </MenuItem>
                {organizations &&
                  organizations
                    .sort((a, b) => {
                      const aName = get(a, "name", "");
                      const bName = get(b, "name", "");
                      return aName.toLowerCase() > bName.toLowerCase();
                    })
                    .map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
              </Select>
            }
          />
          <FormHelperText>
            {errors?.parentOrganizationId?.message}
          </FormHelperText>
        </FormControl>
      </div>
    </form>
  );

  const renderFormActions = () => (
    <ActivityIndicatorButton
      onClick={handleFormSubmit}
      isActive={isUpdateActive}
    >
      Submit
    </ActivityIndicatorButton>
  );

  return (
    <Dialog
      title={title}
      isOpen={isOpen}
      handleClose={handleClose}
      actions={renderFormActions()}
    >
      {renderForm()}
    </Dialog>
  );
};

OrganizationsAdminUpdateFormDialog.defaultProps = {
  title: "",
  isOpen: false,
  handleClose: () => {},
};

OrganizationsAdminUpdateFormDialog.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  organizations: PropTypes.array.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  isUpdateActive: PropTypes.bool.isRequired,
};

export default OrganizationsAdminUpdateFormDialog;
