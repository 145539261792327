import React, { FC, useEffect, useState } from "react";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import { RB_GREY } from "constants/colors";
import { Grid, Input, Typography } from "@material-ui/core";
import { DistressesRanges } from "./DistressesSliders";
import { PCIRanges } from "./PciSlider";

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.up("lg")]: {
      minWidth: 350,
    },
  },
  slider: {
    color: RB_GREY,
  },
  input: {
    width: 42,
  },
  title: {
    fontSize: theme.typography.pxToRem(18),
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: "bold",
  },
}));

export interface AnalysisSliderProps {
  value: DistressesRanges | PCIRanges;
  showInputs?: boolean;
  handleSliderChange: (value: any) => void;
}

const AnalysisSlider: FC<AnalysisSliderProps> = ({
  value,
  showInputs,
  handleSliderChange,
}) => {
  const [newValue, setNewValue] = useState<number[]>(value.initialRange);
  const classes = useStyles();

  // useEffect for when switching between segments and streets
  // have to set the correct slider values
  useEffect(() => {
    setNewValue(value.value)
  }, [value])

  const handleMinInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const targetVal = event.target.value as unknown;
    if (typeof targetVal === "string" && +targetVal >= value.initialRange[0]) {
      setNewValue(oldValue => [+targetVal, oldValue[1]]);
      handleSliderChange({ ...value, value: [+targetVal, value.value[1]] });
    }
  };

  const handleMaxInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const targetVal = event.target.value as unknown;
    if (typeof targetVal === "string" && +targetVal <= value.initialRange[1]) {
      setNewValue(oldValue => [oldValue[0], +targetVal]);
      handleSliderChange({ ...value, value: [value.value[0], +targetVal] });
    }
  };

  const handleSliderOnChange = (
    _: React.ChangeEvent<{}>,
    changedValue: number | number[]
  ) => {
    if (Array.isArray(changedValue)) {
      setNewValue(changedValue);
      handleSliderChange({ ...value, value: changedValue });
    }
  };

  const renderSlider = () => {
    const start = value.initialRange[0];
    const end = value.initialRange[1];

    const marks = [
      {
        value: start,
        label: start,
      },
      {
        value: end,
        label: end,
      },
    ];

    return (
      <div className={classes.container}>
        <Slider
          className={classes.slider}
          value={newValue}
          onChange={handleSliderOnChange}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          marks={marks}
          max={end}
          min={start}
        />
      </div>
    );
  };

  const renderInputSlider = () => {
    return (
      <div className={classes.container}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Input
              className={classes.input}
              value={newValue[0]}
              margin="dense"
              onChange={handleMinInputChange}
              inputProps={{
                step: 1,
                min: value.initialRange[0],
                max: value.initialRange[1],
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
          </Grid>
          <Grid item xs>
            <Slider
              className={classes.slider}
              value={newValue}
              onChange={handleSliderOnChange}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              min={value.initialRange[0]}
              max={value.initialRange[1]}
            />
          </Grid>
          <Grid item>
            <Input
              className={classes.input}
              value={newValue[1]}
              margin="dense"
              onChange={handleMaxInputChange}
              inputProps={{
                step: 1,
                min: value.initialRange[0],
                max: value.initialRange[1],
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.title} gutterBottom>
        {value.title}
      </Typography>
      {showInputs ? renderInputSlider() : renderSlider()}
    </div>
  );
};

AnalysisSlider.defaultProps = {
  showInputs: true,
};

export default AnalysisSlider;
