export const MAP_STYLE_DARK =
  "mapbox://styles/mdickson/cjdklaqf3k9v92rpmgu9zjakl";
export const MAP_STYLE_LIGHT =
  "mapbox://styles/mdickson/cjdkke6wkk7mp2rmtixetu2xf";
export const MAP_STYLE_SATELLITE =
  "mapbox://styles/mdickson/cjepr89eh4q4s2snxcr55ias8";
export const MAP_STYLE_TOPOGRAPHIC_METRIC =
  "mapbox://styles/mdickson/cjvmnuynw47zn1cq4und8h7kp";
export const MAP_STYLE_TOPOGRAPHIC_IMPERIAL =
  "mapbox://styles/mdickson/cki7s4dhi0h9819mnv83bryss";
