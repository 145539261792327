import React from "react";
import { components } from "react-select";
import makeStyles from "@material-ui/core/styles/makeStyles";

interface GroupedOptionProps {
  data: {
    level: number;
    isOption: boolean;
  };
  innerProps: {
    onMouseMove?: Function;
    onMouseOver?: Function;
  };
}

const GroupHeader = (props: GroupedOptionProps): JSX.Element => {
  const useStyles = makeStyles({
    group: {
      marginLeft: `${5 * props.data.level}px`,
      fontSize: "75%",
      textTransform: "uppercase",
    },
    groupHeading: {
      paddingTop: "4px",
      paddingBottom: "4px",
    },
  });
  const { group, groupHeading } = useStyles();
  return (
    <div className={group}>
      {/*
      // @ts-ignore */}
      <components.Option {...props} className={groupHeading} />
    </div>
  );
};

const GroupOption = (props: GroupedOptionProps): JSX.Element => {
  const useStyles = makeStyles({
    option: {
      paddingLeft: `${5 * props.data.level + 3}px`,
    },
  });
  const { option } = useStyles();
  return (
    <div className={option}>
      {/*
      // @ts-ignore */}
      <components.Option {...props} />
    </div>
  );
};

const GroupedOption = (props: GroupedOptionProps): JSX.Element => {
  // I found this issue with react-select lagging down when there are a large number of options
  // It looks like removing the default MouseOver callbacks removes this issue
  // https://github.com/JedWatson/react-select/issues/3128#issuecomment-451936743
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };
  return props.data.isOption ? (
    <GroupOption {...newProps} />
  ) : (
    <GroupHeader {...newProps} />
  );
};

export default GroupedOption;
