import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import ScreenCapture from "components/ScreenCapture";
import Button from "@material-ui/core/Button";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Popover from "@material-ui/core/Popover";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import Close from "@material-ui/icons/Close";
import Crop from "@material-ui/icons/Crop";
import "../css/screenCapture.css";
import moment from "moment-timezone";
import fileDownload from "js-file-download";
import track, { useTracking } from "react-tracking";
import { connect } from "react-redux";
import {
  turnOnScreenCapture,
  turnOffScreenCapture,
} from "actions/screenCapture";

import { RB_YELLOW, RB_ORANGE } from "../constants/colors";
import { ScreenCaptureTypes } from "../constants/screenshotTypes";
import * as Sentry from "@sentry/browser/dist/index";

import { enableCanvasToBlob } from "utils/toBlob";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  speedDialAction: {
    width: "30px",
    height: "30px",
    minHeight: "30px",
    flex: 1,
  },
});

const ScreenCaptureContainer = ({
  userUId,
  sidebarVisibility,
  turnOnScreenCapture,
  screenCapture,
  turnOffScreenCapture,
  open,
  action,
}) => {
  const tracking = useTracking();
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [isHighlighting, setIsHighlighting] = useState(false);
  const [file, setFile] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const styles = {
    Btn: {
      backgroundColor: RB_YELLOW,
      width: "50%",
    },
    image: {
      border: "5px solid #000",
    },
    popover: {
      marginLeft: "8px",
      zIndex: 20000,
    },
    optionsBtn: {
      textTransform: "none",
      justifyContent: "flex-end",
      backgroundColor: "rgba(255, 255, 255, .55)",
    },
    svgIcon: {
      marginLeft: "21%",
    },
    closeButton: {
      float: "right",
      cursor: "pointer",
    },
  };

  const handleClose = () => {
    tracking.trackEvent({
      event: "mouse-click",
      action: "screen-capture-dialog-close",
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `screen capture dialog close`,
      level: Sentry.Severity.Info,
    });

    setIsDialogOpen(false);
  };

  const handleScreenCapture = file => {
    setIsHighlighting(false);
    setFile(file);
    setIsDialogOpen(true);
    setAnchorEl(null);
  };

  const openOptions = e => {
    tracking.trackEvent({
      event: "mouse-click",
      action: "screen-capture-open-options",
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `screen capture open options`,
      level: Sentry.Severity.Info,
    });

    setIsOptionsOpen(true);
    setAnchorEl(e.currentTarget);
  };

  const closeOptions = () => {
    tracking.trackEvent({
      event: "mouse-click",
      action: "screen-capture-close-options",
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `screen capture close options`,
      level: Sentry.Severity.Info,
    });

    setIsOptionsOpen(false);
    setAnchorEl(null);
  };

  const download = () => {
    tracking.trackEvent({
      event: "mouse-click",
      action: "screen-capture-download",
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `screen capture download`,
      level: Sentry.Severity.Info,
    });

    enableCanvasToBlob();
    const signature = moment().format("MMDDYYYY_hhmmss");
    try {
      file.toBlob(blob =>
        fileDownload(blob, `roadbotics_map_${signature}.png`)
      );
    } catch (error) {
      console.error(
        `Downloading to roadbotics_map_${signature}.png has failed`
      );
      console.error(error);
    }
  };

  const print = () => {
    tracking.trackEvent({
      event: "mouse-click",
      action: "screen-capture-print",
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `screen capture print`,
      level: Sentry.Severity.Info,
    });

    const content = document.getElementById("screenCapDiv").innerHTML;

    const myWindow = window.open("", `Print`, "height=800, width=800");

    myWindow.document.write("<hello><head><title>Print</title>");
    myWindow.document.write("</head><body >");
    myWindow.document.write(content);
    myWindow.document.write("</body></hello>");
    myWindow.document.close();
    myWindow.focus();
    myWindow.print();
    myWindow.close();
  };

  const handleHighlightingOn = () => {
    setIsHighlighting(true);
  };

  const buttonStyle = () => {
    if (isHighlighting) {
      return {
        marginBottom: "-6px",
      };
    } else {
      return null;
    }
  };

  return (
    <ScreenCapture
      onEndCapture={handleScreenCapture}
      sidebarVisibility={sidebarVisibility}
      turnOnScreenCapture={turnOnScreenCapture}
      turnOffScreenCapture={turnOffScreenCapture}
      screenCapture={screenCapture}
    >
      {({ onStartCapture }) => (
        <Fragment>
          <SpeedDialAction
            className={classes.speedDialAction}
            icon={
              <CameraAltIcon
                style={isOptionsOpen ? { color: RB_ORANGE } : buttonStyle()}
              />
            }
            tooltipPlacement="right"
            tooltipTitle={action.title}
            open={open}
            onClick={openOptions}
          />
          <Popover
            style={styles.popover}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            anchorEl={anchorEl}
            onClose={closeOptions}
            open={isOptionsOpen}
            PaperProps={{
              style: { backgroundColor: "rgba(255,255,255,.6)" },
            }}
          >
            <Button
              style={styles.optionsBtn}
              fullWidth={true}
              onClick={() => {
                onStartCapture("select");
                handleHighlightingOn();
                closeOptions();
              }}
            >
              Selected Area &nbsp;&nbsp;
              <Crop />
            </Button>
            <br />
            <Button
              style={styles.optionsBtn}
              fullWidth={true}
              onClick={() => {
                closeOptions();
                setTimeout(
                  () => onStartCapture(ScreenCaptureTypes.FULLSCREEN),
                  10
                );
              }}
            >
              Fullscreen &nbsp;
              <AspectRatioIcon style={styles.svgIcon} />
            </Button>
          </Popover>

          <Dialog open={isDialogOpen} onClose={handleClose}>
            <DialogTitle>
              Screenshot
              <Close onClick={handleClose} style={styles.closeButton} />
            </DialogTitle>
            <DialogContent>
              <div id="screenCapDiv">
                {file && (
                  <img
                    id="screenCapId"
                    src={file.toDataURL()}
                    alt="screenshot"
                  />
                )}
              </div>
            </DialogContent>
            <DialogActions style={{ width: "95%", margin: "auto" }}>
              <Button style={styles.Btn} variant="contained" onClick={print}>
                Print
              </Button>
              <Button style={styles.Btn} variant="contained" onClick={download}>
                Download
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      )}
    </ScreenCapture>
  );
};

ScreenCaptureContainer.propTypes = {
  tracking: PropTypes.object.isRequired,
  action: PropTypes.object.isRequired,
  sidebarVisibility: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  screenCapture: PropTypes.object.isRequired,
  turnOnScreenCapture: PropTypes.func.isRequired,
  turnOffScreenCapture: PropTypes.func.isRequired,
  userUId: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  sidebarVisibility: state.visibility.sidebarVisibility,
  userUId: state.user.userUId,
  screenCapture: state.screenCapture,
});

const mapDispatchToProps = dispatch => ({
  turnOnScreenCapture: screenshotType =>
    dispatch(turnOnScreenCapture(screenshotType)),
  turnOffScreenCapture: () => dispatch(turnOffScreenCapture()),
});

export default track({
  tool: "ScreenCapture",
})(connect(mapStateToProps, mapDispatchToProps)(ScreenCaptureContainer));
