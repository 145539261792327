import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { RB_LIGHT_GREY } from "../constants/colors";

const useStyles = makeStyles({
  cogContainer: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "row",
  },
  cogColContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  cogRowContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: "14px",
  },
  cog: {
    background: RB_LIGHT_GREY,
    width: "30px",
    margin: "8px",
  },
});

const SettingsCogSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.cogContainer}>
      <div className={classes.cogColContainer}>
        {[...Array(7).keys()].map(i => {
          return (
            <Skeleton
              key={`${i}-cog-button`}
              className={classes.cog}
              variant="circle"
              width={30}
              height={30}
            />
          );
        })}
        <Skeleton
          className={classes.cog}
          variant="circle"
          width={56}
          height={56}
        />
      </div>
      <div className={classes.cogRowContainer}>
        {[...Array(4).keys()].map(i => {
          return (
            <Skeleton
              key={`${i}-cog-button`}
              className={classes.cog}
              variant="circle"
              width={30}
              height={30}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SettingsCogSkeleton;
