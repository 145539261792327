import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { RB_LIGHT_GREY } from "../constants/colors";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flexGrow: 1,
  },
  radialTitle: {
    background: RB_LIGHT_GREY,
    marginTop: "15px",
  },
  number: {
    background: RB_LIGHT_GREY,
  },
  piechartTitle: {
    background: RB_LIGHT_GREY,
    marginTop: "15px",
  },
  piechart: {
    background: RB_LIGHT_GREY,
    marginTop: "15px",
  },
});

const PieChartSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Skeleton
        className={classes.radialTitle}
        variant="text"
        height={40}
        width={180}
      />
      <Skeleton
        className={classes.number}
        variant="rect"
        height={72}
        width={284}
      />
      <Skeleton
        className={classes.piechartTitle}
        variant="text"
        height={40}
        width={200}
      />
      <Skeleton
        className={classes.piechart}
        variant="circle"
        height={325}
        width={325}
      />
    </div>
  );
};

export default PieChartSkeleton;
