import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import track from "react-tracking";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";

import { fetchOrganizations } from "actions/userData";
import { setSnackbarMessageAndOpen, MessageTypes } from "actions/snackbar";
import { RB_YELLOW } from "constants/colors";

import OrganizationScans from "components/admin/OrganizationScans";
import { DEFAULT_MAX_ZOOM, DEFAULT_MIN_ZOOM } from "constants/map";
import ScansAdminUpdateFormDialog from "./ScansAdminUpdateFormDialog";
import * as Sentry from "@sentry/browser/dist/index";
import ScansAdminCopyFormDialog from "./ScansAdminCopyFormDialog";
import { excludeExampleOrganizations } from "../../utils/excludeExampleOrganizations";
import ScansAdminCreateFormDialog from "./ScansAdminCreateFormDialog";
import RBAPI from "api/RoadwayAPI";
import { useLoadOrganizationScansData } from "hooks/useLoadOrganizationData";

const useStyles = makeStyles({
  button: { backgroundColor: RB_YELLOW, textTransform: "none" },
});

const ScansAdminContainer = props => {
  const {
    currentOrganization,
    organizationScans,
    setSnackbarMessageAndOpen,
    tracking,
    allOrganizations,
    userUId,
    isAllOrganizationsDataLoaded,
    isOrganizationScansDataLoaded,
    openDeletePopup,
  } = props;
  const newScanValues = {
    name: "",
    displayName: "",
    scanType: "normal",
  };

  const [updateFormValues, setUpdateFormValues] = useState({});
  const [copyFormValues, setCopyFormValues] = useState({});
  const [createFormValues] = useState(newScanValues);
  const [isUpdateFormOpen, setIsUpdateFormOpen] = useState(false);
  const [isCopyFormOpen, setIsCopyFormOpen] = useState(false);
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);
  const [isScanCopyActive, setIsScanCopyActive] = useState(false);
  const [isScanCreateActive, setIsScanCreateActive] = useState(false);
  const [isScanUpdateActive, setIsScanUpdateActive] = useState(false);

  const filteredOrganizations = excludeExampleOrganizations(allOrganizations);

  const loadCurrentOrganizationScans = useLoadOrganizationScansData(
    currentOrganization.id
  );

  const handleError = (e, message) => {
    setSnackbarMessageAndOpen(message, MessageTypes.ERROR);
    throw e;
  };

  const handleCloseUpdateForm = () => setIsUpdateFormOpen(false);
  const handleCloseCopyForm = () => setIsCopyFormOpen(false);
  const handleCloseCreateForm = () => setIsCreateFormOpen(false);

  const handleDuplicate = async scanToCopy => {
    const statusMessage = "Scan copied";
    try {
      setIsScanCopyActive(true);
      const layersToCopy = await RBAPI.fetchLayersByScan(scanToCopy.id);
      scanToCopy.deleted = false;
      delete scanToCopy.dateCreated;
      delete scanToCopy.analysis;
      delete scanToCopy.networkAnalysis;
      delete scanToCopy.id;
      const createdScan = await RBAPI.createScan(scanToCopy);

      for (const layerCopy of layersToCopy) {
        const { id, dateCreated, scanId, ...restLayerProps } = layerCopy;
        const updatedLayerCopy = { ...restLayerProps, scanId: createdScan.id };
        await RBAPI.createLayer(updatedLayerCopy);
      }
      setSnackbarMessageAndOpen(statusMessage);
    } catch (e) {
      const statusMessage = "Failed to copy scan";
      handleError(e, statusMessage);
    } finally {
      tracking.trackEvent({
        event: "mouse-click",
        action: `admin-scan-copy-${scanToCopy.id}`,
        userUId,
      });

      Sentry.addBreadcrumb({
        category: "mouse-click",
        message: `admin scan copy ${scanToCopy.id}`,
        level: Sentry.Severity.Info,
      });
      setIsScanCopyActive(false);
      setIsCopyFormOpen(false);
    }
  };

  const handleUpdate = async updatedScan => {
    const { defaultMinZoom, defaultMaxZoom, ...restScanProps } = updatedScan;
    const properScanObject = {
      ...restScanProps,
      defaultZoom: { max: defaultMaxZoom, min: defaultMinZoom },
    };
    const statusMessage = "Edit saved";
    try {
      setIsScanUpdateActive(true);
      await RBAPI.updateScan(properScanObject).catch(err => {
        if (err?.response?.status === 404) {
          handleError(err, "Failed to delete the Assessment");
          return;
        }
        throw err;
      });
      setSnackbarMessageAndOpen(statusMessage);
    } catch (e) {
      const statusMessage = "Failed to save the edit";
      handleError(e, statusMessage);
    } finally {
      tracking.trackEvent({
        event: "mouse-click",
        action: `admin-scan-update-${properScanObject.id}`,
        userUId,
      });

      Sentry.addBreadcrumb({
        category: "mouse-click",
        message: `admin scan update ${properScanObject.id}`,
        level: Sentry.Severity.Info,
      });
      setIsScanUpdateActive(false);
      setIsUpdateFormOpen(false);
    }
  };

  const handleClickCreate = () => setIsCreateFormOpen(true);

  const handleEdit = selectedScan => {
    const {
      defaultZoom: { min, max } = {
        min: DEFAULT_MIN_ZOOM,
        max: DEFAULT_MAX_ZOOM,
      },
      ...restScanProps
    } = selectedScan;
    setUpdateFormValues({
      ...restScanProps,
      defaultMinZoom: min,
      defaultMaxZoom: max,
    });
    setIsUpdateFormOpen(true);
  };

  const handleCopy = selectedScan => {
    setCopyFormValues(selectedScan);
    setIsCopyFormOpen(true);
  };

  const handleCreate = async ({ name, displayName, assessmentType }) => {
    setIsScanCreateActive(true);

    try {
      await RBAPI.createScan({
        name,
        displayName,
        organizationId: currentOrganization.id,
        deleted: false,
        assessmentType: assessmentType,
      });
      setSnackbarMessageAndOpen(`Created new scan ${displayName}`);
    } catch (e) {
      const statusMessage = `Failed to create scan ${displayName}.`;
      handleError(e, statusMessage);
    } finally {
      tracking.trackEvent({
        event: "mouse-click",
        action: `admin-create-scan`,
        userUId: userUId,
      });

      Sentry.addBreadcrumb({
        category: "mouse-click",
        message: `admin create scan ${displayName}`,
        level: Sentry.Severity.Info,
      });
      setIsScanCreateActive(false);
      setIsCreateFormOpen(false);
    }
  };

  const onChangeCurrentOrganization = () => {
    if (currentOrganization.hasOwnProperty("id")) {
      loadCurrentOrganizationScans();
    }
  };

  // fetch scans when an organization is selected
  useEffect(onChangeCurrentOrganization, [currentOrganization]);

  const selectedOrganizationScans =
    organizationScans[currentOrganization.id] || [];

  const classes = useStyles();

  return (
    <div>
      <Grid container>
        <Grid item xs={10}>
          <OrganizationScans
            isScansLoaded={
              isOrganizationScansDataLoaded && isAllOrganizationsDataLoaded
            }
            scans={selectedOrganizationScans}
            organizationName={currentOrganization.name}
            handleEdit={handleEdit}
            handleDelete={openDeletePopup}
            handleCopy={handleCopy}
            defaultZoom={{ min: DEFAULT_MIN_ZOOM, max: DEFAULT_MAX_ZOOM }}
            handleClickCreate={handleClickCreate}
          />
        </Grid>
      </Grid>
      {isCreateFormOpen && (
        <ScansAdminCreateFormDialog
          title={"Create Scan"}
          isOpen={isCreateFormOpen}
          handleClose={handleCloseCreateForm}
          handleCreate={handleCreate}
          values={createFormValues}
          isCreateActive={isScanCreateActive}
        />
      )}
      {isCopyFormOpen && (
        <ScansAdminCopyFormDialog
          classes={classes}
          handleCopy={handleDuplicate}
          isOpen={isCopyFormOpen}
          handleClose={handleCloseCopyForm}
          isCopyActive={isScanCopyActive}
          values={copyFormValues}
          title="Copy Scan"
          organizations={allOrganizations}
        />
      )}
      {isUpdateFormOpen && (
        <ScansAdminUpdateFormDialog
          classes={classes}
          handleUpdate={handleUpdate}
          isOpen
          handleClose={handleCloseUpdateForm}
          isUpdateActive={isScanUpdateActive}
          values={updateFormValues}
          title="Edit Scan"
          organizations={filteredOrganizations}
        />
      )}
    </div>
  );
};

ScansAdminContainer.defaultProps = {
  currentOrganization: {},
  isDeleteDialogOpen: false,
};

ScansAdminContainer.propTypes = {
  organizationScans: PropTypes.object.isRequired,
  setSnackbarMessageAndOpen: PropTypes.func.isRequired,
  allOrganizations: PropTypes.array.isRequired,
  fetchOrganizations: PropTypes.func.isRequired,
  isAllOrganizationsDataLoaded: PropTypes.bool.isRequired,
  currentOrganization: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  isOrganizationScansDataLoaded: PropTypes.bool.isRequired,
  tracking: PropTypes.object.isRequired,
  userUId: PropTypes.string.isRequired,
  isDeleteDialogOpen: PropTypes.bool,
  openDeletePopup: PropTypes.func.isRequired,
};

const mapStateToProps = ({ userData, user }) => ({
  organizationScans: userData.organizationScans,
  allOrganizations: userData.allOrganizations,
  isAllOrganizationsDataLoaded: userData.isAllOrganizationsDataLoaded,
  isOrganizationScansDataLoaded: userData.isOrganizationScansDataLoaded,
  userUId: user.userUId,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSnackbarMessageAndOpen,
      fetchOrganizations,
    },
    dispatch
  );

export default track()(
  connect(mapStateToProps, mapDispatchToProps)(ScansAdminContainer)
);
