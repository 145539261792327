import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  viewDashboardGrid,
  viewDashboardList,
} from "../../actions/visibility";
import AppBar from "components/AppBar";
import { track } from "react-tracking";
import State from "../../interfaces/state";

interface AppBarProps {
  urlLocation: string;
  isTOSView?: boolean;
}

const AppBarContainer: React.FC<AppBarProps> = ({
  urlLocation,
  isTOSView = false,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state: State) => state.user);

  const isTopBannerOpen = useSelector(
    (state: State) => state.visibility.isTopBannerOpen
  );
  const activeScan = useSelector((state: State) => state.userData.activeScan);

  const isDashboardViewList = useSelector(
    (state: State) => state.visibility.isDashboardList
  );

  const changeToList = () => {
    dispatch(viewDashboardList());
  };

  const changeToGrid = () => {
    dispatch(viewDashboardGrid());
  };
  const isAdmin = useSelector((state: State) => state.userData.isAdmin);
  const isBetaUser = useSelector((state: State) => state.user.isBetaUser);

  return (
    <AppBar
      isTopBannerOpen={isTopBannerOpen}
      user={user}
      urlLocation={urlLocation}
      activeScan={activeScan}
      isTOSView={isTOSView}
      isDashboardList={isDashboardViewList}
      changeToGrid={changeToGrid}
      changeToList={changeToList}
      isAdmin={isAdmin}
      isBeta={isBetaUser}
    />
  );
};

export default track({ component: "app-bar" })(AppBarContainer);
