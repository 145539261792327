import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MapIcon from "@material-ui/icons/Map";
import ThreeDRotationIcon from "@material-ui/icons/ThreeDRotation";
import PropTypes from "prop-types";
import { setMapBearing } from "actions/map";

class RotationContainer extends React.Component {
  constructor() {
    super();
    this.animationFrameId = 0;
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.animationFrameId);
  }

  rotateCamera = timestamp => {
    const { isRotating } = this.props;
    const bearing = (timestamp / 100) % 360;
    if (isRotating) {
      this.props.setMapBearing(bearing);
      this.animationFrameId = requestAnimationFrame(this.rotateCamera);
    }
  };

  stagePicker = () => {
    const { isRotating } = this.props;
    if (isRotating) {
      this.rotateCamera(0);
    }
  };

  render() {
    this.stagePicker();
    const { isRotating } = this.props;
    return (
      <React.Fragment>
        {isRotating ? <MapIcon /> : <ThreeDRotationIcon />}
      </React.Fragment>
    );
  }
}

RotationContainer.propTypes = {
  isRotating: PropTypes.bool.isRequired,
  setMapBearing: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isRotating: state.rotation.isRotating,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setMapBearing }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RotationContainer);
