import React from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import withStyles from "@material-ui/core/styles/withStyles";
import CrackSealSwitchContainer from "containers/CrackSealSwitchContainer";
import PropTypes from "prop-types";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  title: {
    fontFamily: "'Open Sans', sans-serif",
  },
  divider: {
    marginTop: "10px",
    marginBottom: "10px",
    width: "70%",
  },
  dividerFullWidth: {
    marginTop: "10px",
    marginBottom: "10px",
    width: "100%",
  },
};

const CrackSealLayer = ({ classes, toggleGroups }) => (
  <div className={classes.root} data-testid="crack-seal-layer">
    <Typography className={classes.title} variant="h5">
      Crack Seal
    </Typography>
    <Divider className={classes.divider} />
    {toggleGroups.map(toggleGroup => {
      return toggleGroup.toggles.map(toggle => {
        return (
          <CrackSealSwitchContainer
            key={`Ratings Layer Toggle ${toggle.id}`}
            toggle={toggle}
          />
        );
      });
    })}
    <Divider className={classes.dividerFullWidth} />
  </div>
);

CrackSealLayer.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleGroups: PropTypes.array.isRequired,
};

export default withStyles(styles)(CrackSealLayer);
