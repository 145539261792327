import { useEffect, useRef } from "react";

/* eslint-disable */
/*
Taken from: https://stackoverflow.com/questions/53446020/how-to-compare-oldvalues-and-newvalues-on-react-hooks-useeffect
This hook is very similar to prevProps in componentDidUpdate: https://reactjs.org/docs/react-component.html#componentdidupdate
It allows us to compare the current value of a variable with the value it had the previous render, giving us more fine-grained
control over our conditional logic inside `useEffect` hooks. Please take a look at `MeasureContainer.js` to see how this
hook works in practice.
*/
/* eslint-enable */
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
