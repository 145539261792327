import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import track from "react-tracking";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownRounded from "@material-ui/icons/KeyboardArrowDownRounded";
import BarChart from "@material-ui/icons/BarChart";
import Map from "@material-ui/icons/Map";
import Grid from "@material-ui/core/Grid";
import OrgTree from "containers/OrgTree";
import { TABLE_HEADER_BACKGROUND, TABLE_HEADER_TEXT } from "constants/colors";
import styleTableRow from "utils/styleTableRow";
import { goToTop } from "react-scrollable-anchor";
import get from "lodash/get";
import { ROUTE_SUMMARY } from "constants/routes";
import { getLengthInPreferredUnit } from "../../utils/metrics";
import * as Sentry from "@sentry/browser/dist/index";
import { RAIToRoadwayRating, sidewalkMap } from "utils/sidewalkHelperFunctions";
import { MeasuringSystem } from "constants/measuringSystem";

const styles = {
  button: {
    textTransform: "none",
    width: "150px",
    left: "-25px",
  },
  iconButton: {
    textTransform: "none",
    width: "auto",
  },
  scanName: {
    width: "270px",
  },
  buttonText: {
    rightMargin: "4px",
  },
  container: {
    paddingLeft: "15px",
  },
};

const ChildOrgList = props => {
  const {
    organizations,
    setActiveScan,
    organizationScans,
    isOrganizationsDataLoaded,
    measuringSystem,
    userUId,
    tracking,
  } = props;
  const emptyOrganization = {};
  const [currentOrganization, setCurrentOrganization] = useState(
    emptyOrganization
  );
  const [pageSize, setPageSize] = useState(5);

  const handleOrganizationClick = selectedOrganization => {
    tracking.trackEvent({
      event: "mouse-click",
      action: `organization-selector-click-${selectedOrganization.id}`,
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `organization-selector-click-${selectedOrganization.id}`,
      level: Sentry.Severity.Info,
    });
    goToTop();
    setCurrentOrganization(selectedOrganization);
  };

  const buildScans = () => {
    const scanList = [];
    if (Object.keys(organizationScans).length > 0 && currentOrganization.id) {
      organizationScans[currentOrganization.id].forEach(scan => {
        scan.displayName = scan.displayName || scan.name;
        scanList.push(scan);
      });
    }
    return scanList;
  };

  const scans = buildScans();

  const onLoadOrganizationsData = () => {
    if (isOrganizationsDataLoaded && organizations.length > 0) {
      setCurrentOrganization(organizations[0]);
    }
  };

  // set the default organization after organizations are loaded
  useEffect(onLoadOrganizationsData, [isOrganizationsDataLoaded]);

  const history = useHistory();

  const goToSummaryPage = rowData => {
    if (rowData.analysis) {
      tracking.trackEvent({
        event: "mouse-click",
        action: `organization-selector-go-to-summary-page-${rowData.id}`,
        userUId,
      });

      Sentry.addBreadcrumb({
        category: "mouse-click",
        message: `organization selector go to summary page ${rowData.id}`,
        level: Sentry.Severity.Info,
      });

      history.push(`${ROUTE_SUMMARY}/${rowData.id}`);
    }
  };

  const handleMapClick = rowData => {
    tracking.trackEvent({
      event: "mouse-click",
      action: `organization-selector-set-active-scan-${rowData.id}`,
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `organization-selector set active scan ${rowData.id}`,
      level: Sentry.Severity.Info,
    });
    setActiveScan([rowData.organizationId, rowData.id]);
  };

  const shouldShowOrgTree = () => {
    if (organizations.length > 1) {
      // part of multiple orgs so need to show org tree
      return true;
    } else if (
      // part of an org that has children orgs
      organizations.length > 0 &&
      organizations[0].childrenOrganizations
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Grid container style={styles.container}>
      {shouldShowOrgTree() && (
        <Grid item xs={3}>
          <Typography variant="overline" gutterBottom>
            Organizations
          </Typography>
          <OrgTree
            rootOrganizations={organizations}
            handleOrganizationClick={handleOrganizationClick}
            isLoading={!isOrganizationsDataLoaded}
          />
        </Grid>
      )}
      <Grid item xs>
        <MaterialTable
          isLoading={!isOrganizationsDataLoaded}
          style={{ boxShadow: "none", paddingLeft: "15px" }}
          onChangeRowsPerPage={setPageSize}
          options={{
            pageSizeOptions: [5, 10, 20, 50],
            pageSize,
            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: TABLE_HEADER_BACKGROUND,
              color: TABLE_HEADER_TEXT,
              fontWeight: "bold",
              fontSize: "14px",
              paddingRight: "16px",
            },
            rowStyle: styleTableRow,
          }}
          localization={{
            header: { actions: "" },
            body: {
              emptyDataSourceMessage: isOrganizationsDataLoaded ? (
                <Typography>
                  No records to display. Contact RoadBotics if this is incorrect
                </Typography>
              ) : (
                <Typography>Loading...</Typography>
              ),
            },
          }}
          title={`${currentOrganization.name || ""} Assessments`}
          columns={[
            {
              title: "Name",
              field: "displayName",
              customSort: (a, b) =>
                a.displayName
                  .toLowerCase()
                  .localeCompare(b.displayName.toLowerCase()),
              cellStyle: {
                paddingRight: "16px",
              },
            },
            {
              title: "Network Score",
              hidden: !scans.some(o => o.analysis !== undefined),
              customSort: (a, b) =>
                get(a, "analysis.networkScore", 0) -
                get(b, "analysis.networkScore", 0),
              render: function renderNetworkScore(rowData) {
                return (
                  <div>
                    {rowData.analysis
                      ? parseFloat(rowData.analysis.networkScore).toFixed(2)
                      : ""}
                  </div>
                );
              },
              cellStyle: {
                paddingRight: "16px",
              },
            },
            {
              title:
                measuringSystem === MeasuringSystem.Imperial
                  ? "Length (mi)"
                  : "Length (km)",
              hidden: !scans.some(o => o.analysis !== undefined),
              cellStyle: {
                paddingRight: "16px",
              },
              customSort: (a, b) =>
                get(a, "analysis.networkLength", 0) -
                get(b, "analysis.networkLength", 0),
              render: function renderNetworkLength(rowData) {
                return (
                  <div>
                    {rowData.analysis
                      ? getLengthInPreferredUnit(
                          rowData.analysis.networkLength,
                          measuringSystem,
                          "large"
                        ).scalar
                      : ""}
                  </div>
                );
              },
            },
            {
              title: "Network Accessibility",
              hidden: !scans.some(o => o.sidewalkAnalysis !== undefined),
              customSort: (a, b) =>
                get(a, "sidewalkAnalysis.sidewalkScore", 0) -
                get(b, "sidewalkAnalysis.sidewalkScore", 0),
              render: function renderNetworkScore(rowData) {
                return (
                  <div>
                    {rowData.sidewalkAnalysis
                      ? sidewalkMap[
                          RAIToRoadwayRating(
                            parseFloat(
                              rowData.sidewalkAnalysis.sidewalkScore
                            ).toFixed(2)
                          )
                        ]
                      : ""}
                  </div>
                );
              },
              cellStyle: {
                paddingRight: "16px",
              },
            },
            {
              title:
                measuringSystem === MeasuringSystem.Imperial
                  ? "Length (mi)"
                  : "Length (km)",
              hidden: !scans.some(o => o.sidewalkAnalysis !== undefined),
              cellStyle: {
                paddingRight: "16px",
              },
              customSort: (a, b) =>
                get(a, "sidewalkAnalysis.sidewalkLength", 0) -
                get(b, "sidewalkAnalysis.sidewalkLength", 0),
              render: function renderNetworkLength(rowData) {
                return (
                  <div>
                    {rowData.sidewalkAnalysis
                      ? getLengthInPreferredUnit(
                          rowData.sidewalkAnalysis.sidewalkLength,
                          measuringSystem,
                          "large"
                        ).scalar
                      : ""}
                  </div>
                );
              },
            },
            {
              title: "Map",
              sorting: false,
              headerStyle: {
                textAlign: "center",
              },
              cellStyle: {
                textAlign: "center",
                paddingRight: "16px",
              },
              render: function renderMap(rowData) {
                return (
                  <Button
                    onClick={() => {
                      handleMapClick(rowData);
                    }}
                    style={styles.iconButton}
                    title="View Map"
                    disabled={!rowData?.defaults?.bounds}
                  >
                    <Map />
                  </Button>
                );
              },
            },
            {
              title: "Network Analysis",
              hidden: !scans.some(o => o.analysis !== undefined),
              sorting: false,
              render: function renderAnalysis(rowData) {
                return (
                  <Button
                    style={styles.iconButton}
                    onClick={() => {
                      goToSummaryPage(rowData);
                    }}
                    disabled={!rowData.analysis}
                    title="View Analysis"
                  >
                    <BarChart />
                  </Button>
                );
              },
            },
          ]}
          data={scans}
          icons={{ SortArrow: KeyboardArrowDownRounded }}
        />
      </Grid>
    </Grid>
  );
};

ChildOrgList.propTypes = {
  organizations: PropTypes.array.isRequired,
  setActiveScan: PropTypes.func.isRequired,
  isOrganizationsDataLoaded: PropTypes.bool.isRequired,
  organizationScans: PropTypes.object.isRequired,
  measuringSystem: PropTypes.string.isRequired,
  userUId: PropTypes.string.isRequired,
  tracking: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userUId: state.user.userUId,
});

export default track()(connect(mapStateToProps)(ChildOrgList));
