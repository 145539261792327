import React from "react";
import get from "lodash/get";
import { toggleLayer } from "actions/layers";
import { connect } from "react-redux";
import CrackSealSwitch from "components/CrackSealSwitch";
import track from "react-tracking";
import * as Sentry from "@sentry/browser/dist/index";
import PropTypes from "prop-types";

import { getLayer } from "utils/layers";

const CrackSealSwitchContainer = ({
  toggleLayer,
  tracking,
  layerGroups,
  toggle,
}) => {
  const toggleLayerBySource = layerSource => {
    tracking.trackEvent({
      event: "mouse-click",
      action: `crack-seal-layer-toggle-${layerSource}`,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `crack seal layer toggle ${layerSource}`,
      level: Sentry.Severity.Info,
    });

    const layer = getLayer(layerSource, layerGroups);
    if (layer) {
      toggleLayer(get(layer, "id"));
    }
  };

  const isLayerVisible = layerSource => {
    if (layerSource !== "missing") {
      const layer = getLayer(layerSource, layerGroups);
      return layer.visible;
    } else {
      return false;
    }
  };

  const isLayerHidden = layerSource => {
    if (layerSource !== "missing") {
      const layer = getLayer(layerSource, layerGroups);
      return layer.hidden;
    } else {
      return false;
    }
  };

  return (
    <CrackSealSwitch
      label={toggle.displayName}
      color={toggle.color}
      onChange={() => toggleLayerBySource(toggle.layerId)}
      checked={isLayerVisible(toggle.layerId)}
      disabled={isLayerHidden(toggle.layerId)}
    />
  );
};

CrackSealSwitchContainer.propTypes = {
  toggleLayer: PropTypes.func.isRequired,
  tracking: PropTypes.object.isRequired,
  layerGroups: PropTypes.array.isRequired,
  toggle: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  layerGroups: state.layers.groups,
});

const mapDispatchToProps = dispatch => ({
  toggleLayer: layerId => dispatch(toggleLayer(layerId)),
});

export default track()(
  connect(mapStateToProps, mapDispatchToProps)(CrackSealSwitchContainer)
);
