import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import packageJson from "../../package.json";
import * as Sentry from "@sentry/browser";
import Typography from "@material-ui/core/Typography";
import logo from "images/logos/RoadboticsLogoWithText.png";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventId: null,
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  handleError(event) {
    const { error } = event;
    Sentry.captureException(error);
  }

  componentDidMount() {
    window.addEventListener("error", this.handleError);
  }

  componentWillUnmount() {
    window.removeEventListener("error", this.handleError);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { user, userUId } = this.props;
    Sentry.configureScope(scope => {
      scope.setUser({
        email: user ? user : "No Email Available",
        id: userUId ? userUId : "No UID Available",
      });
      scope.setTag("version", packageJson.version);
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.configureScope(scope => scope.setExtras(errorInfo));
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ marginTop: "10%", width: "100%", textAlign: "center" }}>
          <img style={{ width: "30%" }} src={logo} alt="errorlogo" />
          <br />
          <br />
          <Typography align="center" variant="h4">
            An error happened! Please try refreshing the page
          </Typography>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  user: PropTypes.string,
  userUId: PropTypes.string,
  children: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user: state.user.email,
  userUId: state.user.userUId,
});

export default connect(mapStateToProps)(ErrorBoundary);
