import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import LayerGroup from "components/LayerGroup";
import orderBy from "lodash/orderBy";

class LayerGroupContainer extends Component {
  getLayerGroups = () => {
    const { layerGroups } = this.props;
    const layerGroupIds = layerGroups.map(layerGroup => layerGroup.id);
    const layerGroupsArray = orderBy(
      layerGroups,
      ["position"],
      ["desc", "asc"]
    ).filter(layerGroup => layerGroupIds.indexOf(layerGroup.id) !== -1);

    return layerGroupsArray;
  };

  render() {
    const layerGroups = this.getLayerGroups();
    return (
      <div>
        {layerGroups.map((layerGroup, index) => {
          return (
            <LayerGroup
              position={layerGroup.position}
              key={layerGroup.id}
              clickBehavior={layerGroup.clickBehavior}
              layers={layerGroup.layers}
              style={layerGroup.style}
              beforeLayer={
                layerGroups[index - 1] &&
                layerGroups[index - 1].layers.length > 0
                  ? layerGroups[index - 1].layers[0].sourceLayer
                  : "road-label-medium"
              }
            />
          );
        })}
      </div>
    );
  }
}

LayerGroupContainer.propTypes = {
  layerGroups: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  layerGroups: state.layers.groups,
});

export default connect(mapStateToProps)(LayerGroupContainer);
