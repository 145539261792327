import { openModal, stopModal, shrinkImg } from "actions/modal";
import {
  setSegmentCoords,
  showHighlight,
  hideHighlight,
} from "actions/highlight";
import { SEGMENT } from "constants/highlightTypes";

import { NOT_PAVED } from "constants/damageRatings";
import { closeSidebar } from "actions/visibility";
import { isIPad } from "utils/deviceCheck";
import chartData from "../chartData";
import { centerMap } from "../pointsUtils";
import returnAddress from "../returnAddress";

const sidewalkSegment = async ({
  feature,
  dispatch,
  colorPicker,
  modalVisibility,
  sidebarVisibility,
  enlargedImg,
  map,
  event,
}) => {
  const lng = event.lngLat.lng;
  const lat = event.lngLat.lat;
  const address = await returnAddress({ lng: lng, lat: lat });

  centerMap({
    map,
    enlargedImg,
    sidebarVisibility,
    modalVisibility,
    lng,
    lat,
  });

  const layer = feature.layer;
  const properties = feature.properties;

  const ratings = JSON.parse(properties.ratings);
  //   const segment = JSON.parse(properties.segment);

  const coords = feature.geometry.coordinates.map(coord => [
    coord[0],
    coord[1],
  ]);

  dispatch(hideHighlight());
  dispatch(setSegmentCoords(coords));
  dispatch(showHighlight(SEGMENT));
  dispatch(stopModal());

  // close the sidebar if on iPad
  if (isIPad()) {
    dispatch(closeSidebar());
  }

  const segmentName = address || "Damage Report";
  let averageRating = properties.avg_rating;
  let data;
  if (layer.color !== "808080") {
    data = chartData(ratings, segmentName, colorPicker);
  } else {
    averageRating = undefined;
    data = {
      labels: [NOT_PAVED],
      datasets: [
        {
          label: segmentName,
          backgroundColor: ["gray"],
          hoverBackgroundColor: ["gray"],
          borderColor: "black",
          hoverBorderWidth: 4,
          data: [100],
        },
      ],
    };
  }

  const style = {
    width: "40%",
    position: "fixed",
    zIndex: "5",
    backgroundColor: "white",
    marginTop: "-5%",
    marginLeft: "2%",
    borderRadius: "10px",
  };

  dispatch(
    openModal({
      type: "sidewalkSegment",
      pointData: {},
      segmentData: {
        title: layer.id,
        content: {
          chartData: data,
          segmentId: feature.id,
          averageRating,
          length: feature.properties.length,
        },
      },
      style,
      expanded: {},
    })
  );
  dispatch(shrinkImg());
};

export default sidewalkSegment;
