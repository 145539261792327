import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import AppBarContainer from "../containers/AppBarContainer/AppBarContainer";
import Button from "./form/Button";
import Typography from "@material-ui/core/Typography";
import welcomeImage from "../images/pghroadway.jpg";
import classNames from "classnames";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  container: {
    marginTop: "80px",
  },
  root: {
    display: "flex",
    justifyContent: "center",
  },
  wrapper: {
    justifyContent: "center",
    width: "70%",
    padding: "10px",
  },
  spacer: {
    marginBottom: "20px",
  },
  text: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 600,
  },
});

type FreeTrialSuccessProps = {
  location: {
    pathname: string;
  };
  goToLogin: () => void;
};

const FreeTrialSuccess = ({
  location,
  goToLogin,
}: FreeTrialSuccessProps): React.ReactElement => {
  const classes = useStyles();
  return (
    <div>
      <AppBarContainer urlLocation={location.pathname} isTOSView={true} />
      <Grid container className={classes.container}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <div className={classes.root}>
            <Grid container component={Paper} className={classes.wrapper}>
              <Grid item>
                <Typography
                  variant={"h3"}
                  className={classNames(classes.spacer, classes.text)}
                >
                  Welcome To RoadWay!
                </Typography>
              </Grid>
              <Grid item className={classes.spacer}>
                <img src={welcomeImage} alt="welcome" />
              </Grid>
              <Grid item>
                <Typography
                  variant={"body1"}
                  className={classNames(classes.spacer, classes.text)}
                >
                  {`Thanks for signing up to assess Five Miles Free! We're
                    excited for you to try out our online road assessment
                    platform, RoadWay.`}
                </Typography>
                <Typography
                  variant={"body1"}
                  className={classNames(classes.spacer, classes.text)}
                >
                  {`Soon, you will be able to visually communicate the condition
                    of your road, efficiently prioritize maintenance, and
                    virtually inspect your roads any time from your desktop
                    computer.`}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={goToLogin}
                  className={classes.text}
                >
                  Go To Login
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </div>
  );
};

export default FreeTrialSuccess;
