import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/form/Button";
import ActivityIndicatorButton from "components/ActivityIndicatorButton";

interface ConvertOrganizationPopupProps {
  convertName: string;
  isOpen: boolean;
  handleConvert: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  handleClose: () => void;
  isProcessingConvert: boolean;
}
const ConvertOrganizationPopup = ({
  convertName,
  isOpen,
  handleConvert,
  handleClose,
  isProcessingConvert,
}: ConvertOrganizationPopupProps): React.ReactElement => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        !isProcessingConvert && handleClose();
      }}
    >
      <DialogTitle>{`Are you sure you want to convert ${convertName} and all of its users to a full time organization?`}</DialogTitle>
      <DialogContent>
        <DialogContentText>This action cannot be undone.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            !isProcessingConvert && handleClose();
          }}
          color="default"
          variant="text"
        >
          Cancel
        </Button>

        <ActivityIndicatorButton
          onClick={handleConvert}
          isActive={isProcessingConvert}
          progressSize={14}
        >
          Yes, Convert
        </ActivityIndicatorButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConvertOrganizationPopup;
