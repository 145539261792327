export const TURN_OFF_DYNAMIC_SEGMENTATION_DRAG =
  "TURN_OFF_DYNAMIC_SEGMENTATION_DRAG";
export const TOGGLE_DYNAMIC_SEGMENTATION_DRAG =
  "TOGGLE_DYNAMIC_SEGMENTATION_DRAG";
export const TURN_OFF_DYNAMIC_SEGMENTATION_SELECT =
  "TURN_OFF_DYNAMIC_SEGMENTATION_SELECT";
export const TOGGLE_DYNAMIC_SEGMENTATION_SELECT =
  "TOGGLE_DYNAMIC_SEGMENTATION_SELECT";
export const TURN_OFF_DYNAMIC_SEGMENTATION_LASSO =
  "TURN_OFF_DYNAMIC_SEGMENTATION_LASSO";
export const TOGGLE_DYNAMIC_SEGMENTATION_LASSO =
  "TOGGLE_DYNAMIC_SEGMENTATION_LASSO";
