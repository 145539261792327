import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

const styles = theme => ({
  close: {
    padding: theme.spacing(0.5),
  },
});

const CloseIcon = ({ classes, onClick }) => (
  <IconButton
    key="close"
    aria-label="Close"
    color="inherit"
    className={classes.close}
    onClick={onClick}
  >
    <Close />
  </IconButton>
);

CloseIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(CloseIcon);
