import React from "react";
import PropTypes from "prop-types";
import LineModalContent from "components/LineModalContent";
import MaterialTable from "material-table";
import { TABLE_HEADER_BACKGROUND, TABLE_HEADER_TEXT } from "constants/colors";
import styleTableRow from "utils/styleTableRow";
import KeyboardArrowDownRounded from "@material-ui/icons/KeyboardArrowDownRounded";
import {
  calculateAverageRating,
  calculateCombinedIDIData,
  calculateTotalLength,
} from "utils/segments";
import { getLengthInPreferredUnit } from "utils/metrics";
import { displayAverageRating } from "utils/ratings";
import makeSegment from "utils/makeSegment";
import { MeasuringSystem } from "constants/measuringSystem";
import IndividualDistressContainer from "containers/IndividualDistressContainer";
import Button from "@material-ui/core/Button";

const ModalDynamicSegmentation = ({
  segmentsData,
  measuringSystem,
  segments,
  colorPicker,
  closeSidebar,
  expandImg,
  shrinkImg,
  enlargedImg,
}) => {
  const dynamicSegmentationContent = segmentsData.content;
  const streets = {};
  segments.forEach(segmentFeature => {
    const segmentStreetName = makeSegment(segmentFeature).name;
    if (!(segmentStreetName in streets)) {
      streets[segmentStreetName] = {
        segmentFeatures: [segmentFeature],
      };
    } else {
      streets[segmentStreetName].segmentFeatures.push(segmentFeature);
    }
  });
  const streetsList = Object.entries(streets).map(street => {
    const [name, streetObj] = street;
    const { segmentFeatures } = streetObj;
    const totalLength = calculateTotalLength(segmentFeatures);
    const averageRating = calculateAverageRating(segmentFeatures);
    const segmentDistressStatistics = calculateCombinedIDIData(
      segmentFeatures,
      false
    );
    return {
      name,
      totalLength: getLengthInPreferredUnit(
        totalLength, //assumed to be metric meters at this point
        measuringSystem,
        "small"
      ).scalar,
      averageRating: displayAverageRating(averageRating),
      ...segmentDistressStatistics,
    };
  });

  return (
    <React.Fragment>
      <LineModalContent
        chartData={dynamicSegmentationContent.chartData}
        averageRating={dynamicSegmentationContent.averageRating}
        length={dynamicSegmentationContent.length}
        colorPicker={colorPicker}
      />
      {dynamicSegmentationContent.segmentDistressStatistics && (
        <IndividualDistressContainer
          segmentDistressStatistics={
            dynamicSegmentationContent.segmentDistressStatistics
          }
        />
      )}
      <div
        style={{
          paddingTop: "20px",
          width: "100%",
        }}
      >
        <Button
          onClick={() => {
            closeSidebar();
            enlargedImg ? shrinkImg() : expandImg();
          }}
          variant="contained"
          color="primary"
        >
          {enlargedImg ? "Reduce" : "Expand"} the table
        </Button>
      </div>
      <MaterialTable
        title="Dynamic_segmentation_data"
        style={{ boxShadow: "none", marginTop: "10px" }}
        options={{
          exportButton: true,
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: TABLE_HEADER_BACKGROUND,
            color: TABLE_HEADER_TEXT,
            fontWeight: "bold",
            fontSize: "14px",
          },
          rowStyle: styleTableRow,
          search: false,
          pageSize: streetsList.length,
          paging: false,
          toolbar: true,
          showTitle: false,
        }}
        columns={[
          {
            title: "Street Name",
            field: "name",
            export: true,
            customSort: (a, b) =>
              a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
            // eslint-disable-next-line react/display-name
            render: rowData => (
              <div>
                {rowData.name !== "null" ? rowData.name : <i>Unknown Name</i>}
              </div>
            ),
          },
          {
            title: `Length (${
              measuringSystem === MeasuringSystem.Imperial ? "ft" : "m"
            })`,
            field: "totalLength",
            // eslint-disable-next-line react/display-name
            render: rowData => <div>{rowData.totalLength.toFixed(3)}</div>,
          },
          {
            title: "Avg Score",
            field: "averageRating",
          },
          {
            title: "Number Potholes",
            field: "numPothole",
            hidden:
              dynamicSegmentationContent.segmentDistressStatistics === null,
          },
          {
            title: "Number Patch/Seal",
            field: "numPatchSeal",
            hidden:
              dynamicSegmentationContent.segmentDistressStatistics === null,
          },
          {
            title: "Percent Fatigue Cracking",
            field: "pctFatigueCracking",
            hidden:
              dynamicSegmentationContent.segmentDistressStatistics === null,
          },
          {
            title: "Percent Pavement Distortions",
            field: "pctPavementDistortions",
            hidden:
              dynamicSegmentationContent.segmentDistressStatistics === null,
          },
          {
            title: "Percent Trans/Long Cracking",
            field: "pctTransLongCracking",
            hidden:
              dynamicSegmentationContent.segmentDistressStatistics === null,
          },
          {
            title: "Percent Surface Deterioration",
            field: "pctSurfaceDeterioration",
            hidden:
              dynamicSegmentationContent.segmentDistressStatistics === null,
          },
        ]}
        data={streetsList}
        icons={{ SortArrow: KeyboardArrowDownRounded }}
      />
    </React.Fragment>
  );
};

export default ModalDynamicSegmentation;

ModalDynamicSegmentation.propTypes = {
  segmentsData: PropTypes.object.isRequired,
  measuringSystem: PropTypes.string.isRequired,
  segments: PropTypes.array.isRequired,
  colorPicker: PropTypes.object.isRequired,
};
