import React from "react";
import { connect } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { CompassBG, CompassNeedle } from "images/compass";
import PropTypes from "prop-types";
import { drawerWidth, largeDrawerWidth } from "components/ModalSidebar";
import CompassSkeleton from "../../skeletons/compass";
import { topBannerHeight } from "../../views/Banner";
import classNames from "classnames";

const useStyles = makeStyles({
  compass: {
    margin: "auto",
    width: 70,
    position: "absolute",
  },
  needle: props => ({
    userSelect: "none",
    height: 33,
    marginTop: 18,
    marginLeft: 31,
    transform: `rotate(${props.bearing * -1}deg)`,
  }),
  compassBanner: {
    marginTop: topBannerHeight,
  },
  wholeCompass: props => ({
    width: 70,
    height: 70,
    marginRight: props.isModalOpen
      ? props.enlargedImg
        ? largeDrawerWidth
        : drawerWidth
      : 10,
  }),
});

const Compass = ({
  bearing,
  isModalOpen,
  enlargedImg,
  isMapDataLoaded,
  isTopBannerOpen,
}) => {
  const classes = useStyles({ bearing, isModalOpen, enlargedImg });
  if (Array.isArray(bearing)) bearing = bearing[0];
  return (
    <div>
      {!isMapDataLoaded ? (
        <CompassSkeleton isTopBannerOpen={isTopBannerOpen} />
      ) : (
        <div
          className={classNames(classes.wholeCompass, {
            [classes.compassBanner]: isTopBannerOpen,
          })}
        >
          <img className={classes.compass} src={CompassBG} alt="compass" />
          <img className={classes.needle} src={CompassNeedle} alt="Needle" />
        </div>
      )}
    </div>
  );
};

Compass.propTypes = {
  bearing: PropTypes.oneOfType([PropTypes.number, PropTypes.array]).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  enlargedImg: PropTypes.bool.isRequired,
  isMapDataLoaded: PropTypes.bool,
  isTopBannerOpen: PropTypes.bool,
};

const mapStateToProps = state => ({
  bearing: state.baseMap.bearing,
  isModalOpen: state.modal.isModalOpen,
  enlargedImg: state.modal.enlargedImg,
});

export default connect(mapStateToProps, null)(Compass);
