import {
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR,
  SET_SNACKBAR_MESSAGE,
} from "actiontypes/snackbar";

export const MessageTypes = {
  INFO: "INFO",
  ERROR: "ERROR",
};

export const AnchorOrigin = {
  TOP_CENTER: { vertical: "top", horizontal: "center" },
  BOTTOM_CENTER: { vertical: "bottom", horizontal: "center" },
  TOP_LEFT: { vertical: "top", horizontal: "left" },
  BOTTOM_LEFT: { vertical: "bottom", horizontal: "left" },
  TOP_RIGHT: { vertical: "top", horizontal: "right" },
  BOTTOM_RIGHT: { vertical: "bottom", horizontal: "right" },
};

export function openSnackbar() {
  return {
    type: OPEN_SNACKBAR,
  };
}

export function closeSnackbar() {
  return {
    type: CLOSE_SNACKBAR,
  };
}

export function setSnackbarMessage(
  message,
  messageType = MessageTypes.INFO,
  anchorOrigin = AnchorOrigin.BOTTOM_CENTER
) {
  return {
    type: SET_SNACKBAR_MESSAGE,
    message,
    messageType,
    anchorOrigin,
  };
}

export function setSnackbarMessageAndOpen(message, messageType, anchorOrigin) {
  return dispatch => {
    dispatch(setSnackbarMessage(message, messageType, anchorOrigin));
    dispatch(openSnackbar());
  };
}
