import {
  CLOSE_SIDEBAR,
  OPEN_SIDEBAR,
  OPEN_TOP_BANNER,
  CLOSE_TOP_BANNER,
  VIEW_DASHBOARD_GRID,
  VIEW_DASHBOARD_LIST,
} from "../actiontypes/visibility";

interface ActionReturn {
  type: string;
}

export function closeSidebar(): ActionReturn {
  return {
    type: CLOSE_SIDEBAR,
  };
}

export function openSidebar(): ActionReturn {
  return {
    type: OPEN_SIDEBAR,
  };
}

export function openTopBanner(): ActionReturn {
  return {
    type: OPEN_TOP_BANNER,
  };
}

export function closeTopBanner(): ActionReturn {
  return {
    type: CLOSE_TOP_BANNER,
  };
}

export function viewDashboardGrid(): ActionReturn {
  return {
    type: VIEW_DASHBOARD_GRID,
  };
}

export function viewDashboardList(): ActionReturn {
  return {
    type: VIEW_DASHBOARD_LIST,
  };
}
