export const NOT_PAVED = "Not Paved";
export const NOT_AVAILABLE = "Not Available";

export const LEVEL_1 = "Level 1";
export const LEVEL_2 = "Level 2";
export const LEVEL_3 = "Level 3";
export const LEVEL_4 = "Level 4";
export const LEVEL_5 = "Level 5";
export const LEVEL_6 = NOT_PAVED;
export const LEVEL_7 = "Candidates";
export const LEVEL_8 = "Sealed";
