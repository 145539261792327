import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/form/Button";
import ActivityIndicatorButton from "components/ActivityIndicatorButton";

interface ExtendFreeTrialPopupProps {
  extendOrg: string;
  isOpen: boolean;
  handleExtend: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  handleClose: () => void;
  isProcessingExtend: boolean;
}
const ExtendFreeTrialPopup = ({
  extendOrg,
  isOpen,
  handleExtend,
  handleClose,
  isProcessingExtend,
}: ExtendFreeTrialPopupProps): React.ReactElement => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        !isProcessingExtend && handleClose();
      }}
    >
      <DialogTitle>{`Are you sure you want to extend all free trials for everyone in ${extendOrg} by 30 days?`}</DialogTitle>
      <DialogContent>
        <DialogContentText>This action cannot be undone.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            !isProcessingExtend && handleClose();
          }}
          color="default"
          variant="text"
        >
          Cancel
        </Button>

        <ActivityIndicatorButton
          onClick={handleExtend}
          isActive={isProcessingExtend}
          progressSize={14}
        >
          Yes, Extend
        </ActivityIndicatorButton>
      </DialogActions>
    </Dialog>
  );
};

export default ExtendFreeTrialPopup;
