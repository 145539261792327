import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import AnnotationsSkeleton from "../../skeletons/annotations";
import PointAnnotationsAutoComplete from "./PointAnnotationsAutoComplete";

const useStyles = makeStyles({
  divContainer: {
    marginBottom: "65px",
  },
  saveBanner: {
    backgroundColor: "#fad201",
  },
});

interface headerInt {
  [key: string]: { value: string };
}

interface PointAnnotationProps {
  textFieldError: string;
  isAnnotationsLoaded: boolean;
  submitAnnotation: () => void;
  isAnnotationBeingSaved: boolean;
  dropdownOptions: any;
  userAnnotations: headerInt;
  setDropdownOptions: (options: any) => void;
  setUserAnnotations: (newState: headerInt) => void;
  addField: (field: string) => void;
  deleteField: (field: string) => void;
  deleteOption: (option: string, optionKey: string) => void;
  addOption: (option: string, optionKey: string) => void;
  fieldName: string;
  setFieldName: (fieldName: string) => void;
  isDialogOpen: boolean;
  closeDialog: () => void;
  openDialog: () => void;
  setIsFormClean: (answer: boolean) => void;
  isFormClean: boolean;
}

const PointAnnotations = ({
  textFieldError,
  isAnnotationsLoaded,
  addField,
  deleteField,
  addOption,
  deleteOption,
  submitAnnotation,
  dropdownOptions,
  setDropdownOptions,
  userAnnotations,
  setUserAnnotations,
  isAnnotationBeingSaved,
  setIsFormClean,
  fieldName,
  setFieldName,
  isDialogOpen,
  closeDialog,
  openDialog,
  isFormClean,
}: PointAnnotationProps): React.ReactElement => {
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={classes.divContainer}>
        <Divider />
        <Typography variant="h6">
          <strong>Notes</strong>
        </Typography>
        {!isFormClean && (
          <Grid container>
            <Grid item xs={12}>
              <Typography align="center" className={classes.saveBanner}>
                You have unsaved work. Please save before moving on.
              </Typography>
            </Grid>
          </Grid>
        )}
        <br />
        {isAnnotationsLoaded ? (
          <PointAnnotationsAutoComplete
            setIsFormClean={setIsFormClean}
            addField={addField}
            deleteField={deleteField}
            addOption={addOption}
            deleteOption={deleteOption}
            submitAnnotation={submitAnnotation}
            textFieldError={textFieldError}
            setDropdownOptions={setDropdownOptions}
            userAnnotations={userAnnotations}
            setUserAnnotations={setUserAnnotations}
            isAnnotationBeingSaved={isAnnotationBeingSaved}
            fieldName={fieldName}
            setFieldName={setFieldName}
            isDialogOpen={isDialogOpen}
            openDialog={openDialog}
            closeDialog={closeDialog}
            dropdownOptions={dropdownOptions}
          />
        ) : (
          <AnnotationsSkeleton numberOfItems={4} />
        )}
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default PointAnnotations;
