import React from "react";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";
import potholesIcon from "images/icons/potholesIcon.png";
import Divider from "@material-ui/core/Divider";
import { RB_POTHOLE_ORANGE } from "constants/colors";

const styles = {
  label: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: "bold",
    fontSize: "18px",
    display: "inline-block",
  },
  container: {
    textAlign: "center",
    paddingBottom: "5px",
  },
  image: {
    verticalAlign: "middle",
  },
  divider: {
    marginTop: "10px",
    marginBottom: "10px",
    width: "100%",
  },
};

const OrangeCheckbox = withStyles({
  root: {
    "&$checked": {
      color: RB_POTHOLE_ORANGE,
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const PotholeCheckbox = ({
  classes,
  showPotholes,
  handleShowPotholeChange,
}) => {
  return (
    <div className={classes.container}>
      <FormControlLabel
        control={
          <OrangeCheckbox
            value={showPotholes}
            checked={showPotholes}
            onChange={handleShowPotholeChange}
            name="potholeValue"
          />
        }
        label={<Typography className={classes.label}>Show Potholes</Typography>}
      />
      <img className={classes.image} src={potholesIcon} alt="" />
      <Divider className={classes.divider} />
    </div>
  );
};

PotholeCheckbox.propTypes = {
  classes: PropTypes.object.isRequired,
  showPotholes: PropTypes.bool.isRequired,
  handleShowPotholeChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(PotholeCheckbox);
