import React from "react";
import { connect } from "react-redux";

import Checkbox from "@material-ui/core/Checkbox";
import get from "lodash/get";
import { toggleLayer } from "actions/layers";
import { getLayer } from "../../utils/layers";

import signs from "images/signs";
import track from "react-tracking";
import * as Sentry from "@sentry/browser/dist/index";
import PropTypes from "prop-types";

const ToolbarToggleCheckbox = ({
  layerGroups,
  toggleLayer,
  tracking,
  toggle,
}) => {
  const toggleLayerBySource = layerSource => {
    const layer = getLayer(layerSource, layerGroups);
    tracking.trackEvent({
      event: "mouse-click",
      action: "toolbar-toggle-click",
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `toolbar toggle click`,
      level: Sentry.Severity.Info,
    });
    if (layer) {
      toggleLayer(get(layer, "id"));
    }
  };

  const isLayerToggled = layerSource => {
    const layer = getLayer(layerSource, layerGroups);
    return layer.visible;
  };

  const getIcons = layerId => {
    const cleanedName = layerId
      .replace("geojson", "")
      .split("_")
      .slice(-2)
      .join("_");
    switch (cleanedName) {
      case "ONWY_1":
        return {
          icon: signs.OneWaySignGreenInactive,
          checkedIcon: signs.OneWaySignGreenActive,
        };
      case "ONWY_3":
        return {
          icon: signs.OneWaySignYellowInactive,
          checkedIcon: signs.OneWaySignYellowActive,
        };
      case "ONWY_5":
        return {
          icon: signs.OneWaySignRedInactive,
          checkedIcon: signs.OneWaySignRedActive,
        };
      case "YLD_1":
        return {
          icon: signs.YieldSignGreenInactive,
          checkedIcon: signs.YieldSignGreenActive,
        };
      case "YLD_3":
        return {
          icon: signs.YieldSignYellowInactive,
          checkedIcon: signs.YieldSignYellowActive,
        };
      case "YLD_5":
        return {
          icon: signs.YieldSignRedInactive,
          checkedIcon: signs.YieldSignRedActive,
        };
      case "STOP_1":
        return {
          icon: signs.StopSignGreenInactive,
          checkedIcon: signs.StopSignGreenActive,
        };
      case "STOP_3":
        return {
          icon: signs.StopSignYellowInactive,
          checkedIcon: signs.StopSignYellowActive,
        };
      case "STOP_5":
        return {
          icon: signs.StopSignRedInactive,
          checkedIcon: signs.StopSignRedActive,
        };
      case "NLT_1":
        return {
          icon: signs.NoLeftTurnSignGreenInactive,
          checkedIcon: signs.NoLeftTurnSignGreenActive,
        };
      case "NLT_3":
        return {
          icon: signs.NoLeftTurnSignYellowInactive,
          checkedIcon: signs.NoLeftTurnSignYellowActive,
        };
      case "NLT_5":
        return {
          icon: signs.NoLeftTurnSignRedInactive,
          checkedIcon: signs.NoLeftTurnSignRedActive,
        };
      case "NRT_1":
        return {
          icon: signs.NoRightTurnSignGreenInactive,
          checkedIcon: signs.NoRightTurnSignGreenActive,
        };
      case "NRT_3":
        return {
          icon: signs.NoRightTurnSignYellowInactive,
          checkedIcon: signs.NoRightTurnSignYellowActive,
        };
      case "NRT_5":
        return {
          icon: signs.NoRightTurnSignRedInactive,
          checkedIcon: signs.NoRightTurnSignRedActive,
        };
      case "WRWY_1":
        return {
          icon: signs.WrongWaySignGreenInactive,
          checkedIcon: signs.WrongWaySignGreenActive,
        };
      case "WRWY_3":
        return {
          icon: signs.WrongWaySignYellowInactive,
          checkedIcon: signs.WrongWaySignYellowActive,
        };
      case "WRWY_5":
        return {
          icon: signs.WrongWaySignRedInactive,
          checkedIcon: signs.WrongWaySignRedActive,
        };
      case "SPDL_1":
        return {
          icon: signs.SpeedLimitSignGreenInactive,
          checkedIcon: signs.SpeedLimitSignGreenActive,
        };
      case "SPDL_3":
        return {
          icon: signs.SpeedLimitSignYellowInactive,
          checkedIcon: signs.SpeedLimitSignYellowActive,
        };
      case "SPDL_5":
        return {
          icon: signs.SpeedLimitSignRedInactive,
          checkedIcon: signs.SpeedLimitSignRedActive,
        };
      case "DNE_1":
        return {
          icon: signs.DoNotEnterSignGreenInactive,
          checkedIcon: signs.DoNotEnterSignGreenActive,
        };
      case "DNE_3":
        return {
          icon: signs.DoNotEnterSignYellowInactive,
          checkedIcon: signs.DoNotEnterSignYellowActive,
        };
      case "DNE_5":
        return {
          icon: signs.DoNotEnterSignRedInactive,
          checkedIcon: signs.DoNotEnterSignRedActive,
        };
      case "FIRE_0":
        return {
          icon: signs.FireHydrantInactive,
          checkedIcon: signs.FireHydrantActive,
        };
      case "TRFL_0":
        return {
          icon: signs.TrafficLightInactive,
          checkedIcon: signs.TrafficLightActive,
        };
      default:
    }
  };

  const { icon, checkedIcon } = getIcons(toggle.layerId);
  return (
    <Checkbox
      onChange={() => toggleLayerBySource(toggle.layerId)}
      checked={isLayerToggled(toggle.layerId)}
      icon={<img src={icon} alt={`${toggle.layerId}-inactive`} />}
      checkedIcon={<img src={checkedIcon} alt={`${toggle.layerId}-inactive`} />}
      value="oneWaySignGreen"
      inputProps={{
        "data-testid": isLayerToggled(toggle.layerId)
          ? `sign-checkbox-on-${toggle.id}`
          : `sign-checkbox-off-${toggle.id}`,
      }}
    />
  );
};

ToolbarToggleCheckbox.propTypes = {
  layerGroups: PropTypes.array.isRequired,
  toggleLayer: PropTypes.func.isRequired,
  tracking: PropTypes.object.isRequired,
  toggle: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  toolbarToggleGroups: state.toolbarToggleGroups,
  layerGroups: state.layers.groups,
});

const mapDispatchToProps = dispatch => ({
  toggleLayer: layerId => dispatch(toggleLayer(layerId)),
});

export default track()(
  connect(mapStateToProps, mapDispatchToProps)(ToolbarToggleCheckbox)
);
