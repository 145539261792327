import React, { ChangeEvent, ReactNode } from "react";

import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { createStyles } from "@material-ui/core/styles";

import Checkbox from "components/form/Checkbox";

import { MUI_INPUT_BOTTOM_BORDER } from "constants/colors";
import { LayerTypes } from "../../constants/layerTypes";

export interface CheckBoxListDataItem {
  name: string;
  value: string;
}

export interface CheckBoxListGroupedData {
  [dataGroupKey: string]: CheckBoxListDataItem[];
}

interface CheckBoxListProps {
  data: CheckBoxListDataItem[] | CheckBoxListGroupedData;
  selected: string[];
  handleChange: (layers: string[]) => void;
  isDataLoading?: boolean;
  titleMessage?: string;
  noDataMessage?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      marginTop: "5px",
    },
    progress: {
      display: "block",
      margin: "100px auto",
    },
    dataGroup: {
      marginTop: "10px",
      borderBottom: `1px solid ${MUI_INPUT_BOTTOM_BORDER}`,
    },
  })
);

const CheckBoxList = ({
  data,
  selected,
  handleChange,
  isDataLoading = false,
  titleMessage = "Select from the list",
  noDataMessage = "No data available",
}: CheckBoxListProps): React.ReactElement => {
  const classes = useStyles();

  const isChecked = (name: string): boolean => selected.includes(name);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name: changedName } = event.target;
    const newSelected = selected.includes(changedName)
      ? selected.filter(name => name !== changedName)
      : [changedName, ...selected];
    handleChange(newSelected);
  };

  const isGroupedData = !Array.isArray(data);

  const renderCheckbox = (
    name: string,
    value: string,
    key: string
  ): ReactNode => (
    <FormControlLabel
      key={key}
      control={
        <Checkbox
          checked={isChecked(name)}
          onChange={handleCheckboxChange}
          name={name}
        />
      }
      label={value}
      className="test-checkbox-list-item"
    />
  );

  const renderData = (data: CheckBoxListDataItem[]): ReactNode =>
    data?.length > 0 ? (
      <FormControl>
        <FormGroup>
          {data.map(({ name, value }, idx_item) =>
            renderCheckbox(name, value, `item-${idx_item}`)
          )}
        </FormGroup>
      </FormControl>
    ) : (
      <Typography variant="body2" color="textSecondary">
        {noDataMessage}
      </Typography>
    );

  const renderGroupedData = (data: CheckBoxListGroupedData): ReactNode => {
    const hasData =
      data &&
      Object.keys(data).length > 0 &&
      Object.values(data).some(item => item?.length > 0);
    return hasData
      ? Object.keys(data).map((dataGroupKey: string, idx_group: number) => (
          <div
            key={`group-${idx_group}`}
            className={`${classes.dataGroup} test-checkbox-list-group`}
          >
            <Typography variant="button">
              {dataGroupKey === LayerTypes.ROADSENSE_POINT
                ? "RoadSense"
                : dataGroupKey}
            </Typography>
            <FormGroup row>
              {Array.isArray(data[dataGroupKey])
                ? data[dataGroupKey].map(({ name, value }, idx_item) =>
                    renderCheckbox(name, value, `item-${idx_item}`)
                  )
                : null}
            </FormGroup>
          </div>
        ))
      : null;
  };

  return (
    <div className={classes.root}>
      <FormLabel component="legend">{titleMessage}</FormLabel>
      {isDataLoading ? (
        <CircularProgress className={classes.progress} size={35} />
      ) : isGroupedData ? (
        renderGroupedData(data as CheckBoxListGroupedData)
      ) : (
        renderData(data as CheckBoxListDataItem[])
      )}
    </div>
  );
};

export default CheckBoxList;
