import React from "react";
import { connect } from "react-redux";
import { hideLayer, showLayer, toggleLayer } from "actions/layers";
import PopoverButton from "containers/PopoverButton";

import signs from "images/signs";
import flatten from "lodash/flatten";
import includes from "lodash/includes";
import every from "lodash/every";
import get from "lodash/get";
import ToolbarToggleAll from "components/ToolbarToggleAll";
import track from "react-tracking";
import * as Sentry from "@sentry/browser/dist/index";
import PropTypes from "prop-types";
import {
  PLURAL_DO_NOT_ENTER,
  PLURAL_FIRE_HYRDRANT,
  PLURAL_NO_LEFT_TURN,
  PLURAL_NO_RIGHT_TURN,
  PLURAL_ONE_WAY,
  PLURAL_SPEED_LIMIT,
  PLURAL_STOP_SIGN,
  PLURAL_TRAFFIC_LIGHT,
  PLURAL_WRONG_WAY,
  PLURAL_YIELD,
  pluralizedSignTypes,
} from "../../constants/signs";

const ToolbarToggleGroupContainer = ({
  toggleGroups,
  layerGroups,
  showLayer,
  hideLayer,
  tracking,
}) => {
  const getIcon = name => {
    switch (name) {
      case PLURAL_ONE_WAY:
        return signs.OneWaySign;
      case PLURAL_YIELD:
        return signs.YieldSign;
      case PLURAL_STOP_SIGN:
        return signs.StopSign;
      case PLURAL_NO_LEFT_TURN:
        return signs.NoLeftTurnSign;
      case PLURAL_NO_RIGHT_TURN:
        return signs.NoRightTurnSign;
      case PLURAL_WRONG_WAY:
        return signs.WrongWaySign;
      case PLURAL_SPEED_LIMIT:
        return signs.SpeedLimitSign;
      case PLURAL_DO_NOT_ENTER:
        return signs.DoNotEnterSign;
      case PLURAL_FIRE_HYRDRANT:
        return signs.FireHydrant;
      case PLURAL_TRAFFIC_LIGHT:
        return signs.TrafficLight;
      default:
    }
  };

  const getActiveLayers = () => {
    const activeSigns = toggleGroups.filter(toggleGroup =>
      pluralizedSignTypes.includes(toggleGroup.name)
    );

    const layerIds = flatten(
      activeSigns.map(toolbarToggleGroup =>
        toolbarToggleGroup.toggles.map(toggle => toggle.layerId)
      )
    );

    return flatten(
      layerGroups.map(layerGroup =>
        layerGroup.layers
          .filter(layer => !includes(layer.color, "FULL"))
          .filter(layer => includes(layerIds, layer.sourceLayer))
      )
    );
  };

  const getAllLayersVisible = layers =>
    every(layers, layer => get(layer, "visible", false));

  const toggleAllToolbarToggles = () => {
    const layers = getActiveLayers();
    const allLayersVisible = getAllLayersVisible(layers);

    tracking.trackEvent({ event: "mouse-click", action: "toolbar-toggle-all" });
    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `toolbar toggle all`,
      level: Sentry.Severity.Info,
    });

    layers.forEach(layer => {
      allLayersVisible ? hideLayer(layer.id) : showLayer(layer.id);
    });
  };

  const layers = getActiveLayers();
  const allLayersVisible = getAllLayersVisible(layers);

  return (
    <React.Fragment>
      {toggleGroups
        .filter(toggleGroup => pluralizedSignTypes.includes(toggleGroup.name))
        .map(toggleGroup => {
          const { id, name, toggles, displayName } = toggleGroup;
          const icon = getIcon(name);
          const toggleableToggles = toggles.filter(toggle => toggle.toggleable);
          return (
            <PopoverButton
              key={`popoverButton-${id}`}
              buttonIcon={icon}
              buttonIconText={displayName}
              toggles={toggleableToggles}
            />
          );
        })}
      {toggleGroups.filter(toggleGroup =>
        pluralizedSignTypes.includes(toggleGroup.name)
      ).length > 0 && (
        <ToolbarToggleAll
          checked={allLayersVisible}
          onChange={toggleAllToolbarToggles}
          color="primary"
          label={`Toggle All Toolbar`}
          labelPlacement="start"
        />
      )}
    </React.Fragment>
  );
};

ToolbarToggleGroupContainer.propTypes = {
  activeToolbarCategoryId: PropTypes.string,
  layerGroups: PropTypes.array.isRequired,
  showLayer: PropTypes.func.isRequired,
  hideLayer: PropTypes.func.isRequired,
  tracking: PropTypes.object.isRequired,
  toggleGroups: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  layerGroups: state.layers.groups,
  activeToolbarCategoryId: state.activeToolbarCategoryId,
  toggleGroups: state.toggles.groups,
});

const mapDispatchToProps = dispatch => ({
  toggleLayer: layerId => dispatch(toggleLayer(layerId)),
  showLayer: layerId => dispatch(showLayer(layerId)),
  hideLayer: layerId => dispatch(hideLayer(layerId)),
});

export default track({ component: "toolbar-toggle-groups" })(
  connect(mapStateToProps, mapDispatchToProps)(ToolbarToggleGroupContainer)
);
