import React from "react";
import HighlightSourcesAndLayers from "components/HighlightSourcesAndLayers";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { SEGMENT, SEGMENTS, POINT } from "constants/highlightTypes";
import { RAIToRoadwayRating } from "utils/sidewalkHelperFunctions";

const Highlight = ({
  highlightVisible,
  pointCoords,
  segmentCoords,
  azimuth,
  segments,
  highlightType,
  colorPicker,
  modalData,
}) => {
  if (!highlightVisible) {
    return null;
  } else if (highlightType === SEGMENTS) {
    return (
      <HighlightSourcesAndLayers
        segments={segments}
        highlightType={SEGMENTS}
        azimuth={azimuth}
      />
    );
  } else if (highlightType === SEGMENT) {
    return (
      <HighlightSourcesAndLayers
        segmentCoords={segmentCoords}
        highlightType={SEGMENT}
        azimuth={azimuth}
      />
    );
  } else if (highlightType === POINT) {
    return (
      <HighlightSourcesAndLayers
        pointCoords={pointCoords}
        highlightType={POINT}
        azimuth={azimuth}
        color={
          modalData.pointData.content &&
          colorPicker[
            `RATING_${RAIToRoadwayRating(
              modalData.pointData.content.segmentRAI
            )}`
          ]
        }
      />
    );
  }
};

Highlight.defaultProps = {
  azimuth: 0,
};

Highlight.propTypes = {
  highlightVisible: PropTypes.bool.isRequired,
  pointCoords: PropTypes.array.isRequired,
  azimuth: PropTypes.number,
  segmentCoords: PropTypes.arrayOf(PropTypes.array).isRequired,
  segments: PropTypes.arrayOf(PropTypes.object).isRequired,
  highlightType: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  azimuth: state.highlight.azimuth,
  highlightVisible: state.highlight.visible,
  pointCoords: state.highlight.pointCoords,
  segments: state.highlight.segments,
  segmentCoords: state.highlight.segmentCoords,
  highlightType: state.highlight.highlightType,
  colorPicker: state.colorPicker,
  modalData: state.modal.modalData,
});

export default connect(mapStateToProps)(Highlight);
