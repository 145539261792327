import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import RatingsLayerToggleAll from "containers/RatingsLayerToggleAll";
import sortBy from "lodash/sortBy";
import RatingsLayerToggle from "containers/RatingsLayerToggle";
import { toggleLayer } from "actions/layers";
import FormGroup from "@material-ui/core/FormGroup";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import RatingsLayerSwitchContainer from "containers/RatingsLayerSwitchContainer";
import { NOT_PAVED } from "../constants/damageRatings";

const styles = {
  title: {
    textTransform: "capitalize",
    fontFamily: "'Open Sans', sans-serif",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  divider: {
    marginTop: "10px",
    marginBottom: "10px",
    width: "70%",
  },
};

class RatingsLayerToggleGroup extends React.Component {
  extractPavementToggle = toggles => {
    const pavementRoadDisplayName = NOT_PAVED;
    return {
      pavementToggle: toggles.find(
        toggle => toggle.displayName === pavementRoadDisplayName
      ),
      nonPavementToggles: toggles.filter(
        toggle => toggle.displayName !== pavementRoadDisplayName
      ),
    };
  };

  render() {
    const {
      classes,
      toggleGroup,
      toggleGroup: { id, toggles, toggleAll },
    } = this.props;

    const { pavementToggle, nonPavementToggles } = this.extractPavementToggle(
      toggles
    );

    return (
      <div className={classes.root}>
        <Typography className={classes.title} variant="h6">
          {toggleGroup.displayName}
        </Typography>
        <Divider className={classes.divider} />
        <FormGroup row>
          {nonPavementToggles &&
            sortBy(nonPavementToggles, "position").map(toggle => {
              return (
                <RatingsLayerToggle
                  key={`Ratings Layer Toggle ${toggle.id}`}
                  toggle={toggle}
                />
              );
            })}
          {toggleAll && <RatingsLayerToggleAll toggleGroupId={id} />}
        </FormGroup>
        {pavementToggle && (
          <RatingsLayerSwitchContainer toggle={pavementToggle} />
        )}
      </div>
    );
  }
}

RatingsLayerToggleGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleGroup: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  layerGroups: state.layers.groups,
});

const mapDispatchToProps = dispatch => ({
  toggleLayer: layerId => dispatch(toggleLayer(layerId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RatingsLayerToggleGroup));
