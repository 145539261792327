import {
  TURN_ON_SCREEN_CAP,
  TURN_OFF_SCREEN_CAP,
} from "actiontypes/screenCapture";

const initialState = {
  type: "",
  isActive: false,
};

const screenCaptureReducer = (state = initialState, action) => {
  switch (action.type) {
    case TURN_ON_SCREEN_CAP:
      return {
        ...state,
        isActive: true,
        type: action.screenshotType,
      };
    case TURN_OFF_SCREEN_CAP:
      return {
        ...state,
        isActive: false,
      };
    default:
      return state;
  }
};

export default screenCaptureReducer;
