import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import ReplyIcon from "@material-ui/icons/Reply";
import PropTypes from "prop-types";
import { PRIMARY_HOVER_COLOR, RB_YELLOW } from "../constants/colors";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginLeft: "30px",
  },
  RBButton: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
    borderRadius: "25px",
    fontWeight: "600",
    color: "#000",
    boxShadow: "0 1px 4px 0 #00000094",
    backgroundColor: RB_YELLOW,
    "&:hover": {
      backgroundColor: "#000",
      color: PRIMARY_HOVER_COLOR,
    },
  },
  RBButtonIcon: {
    marginLeft: theme.spacing(0.5),
  },
});

const RBWebsiteButton = ({ classes }) => (
  <div className={classes.root}>
    <Button
      className={classes.RBButton}
      onClick={() =>
        window.open(
          "https://roadbotics.com/?utm_medium=roadbotics&utm_source=demo&utm_campaign=website",
          "_blank"
        )
      }
    >
      Roadbotics.com
      <ReplyIcon className={classes.RBButtonIcon} />
    </Button>
  </div>
);

RBWebsiteButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RBWebsiteButton);
