import React from "react";
import BottomBar from "components/BottomBar";
import { connect } from "react-redux";
import { setTabLayer } from "actions/modal";
import * as Sentry from "@sentry/browser/dist/index";
import track, { useTracking } from "react-tracking";
import PointAnnotationsContainer from "containers/PointAnnotationsContainer";
import ModalSidewalkPoint from "../ModalSidebarContainer/ModalVariations/ModalSidewalkPoint";
import { modalTabDefinitions } from "constants/modal";
import useMount from "hooks/useMount";

const BottomBarContainer = ({
  modalTab,
  modalData,
  colorPicker,
  setTabLayer,
  userUId,
}) => {
  const tracking = useTracking();

  const tabsToShow = () => {
    const tabsToShow = [];

    if (modalData.type === "sidewalkPoint") {
      tabsToShow.push(modalTabDefinitions.SIDEWALK);
    }
    if (modalData.pointData?.content?.feature?.properties?.primaryDistresses) {
      tabsToShow.push(modalTabDefinitions.IDI);
    }
    if (process.env.REACT_APP_PROJECT_ID === "roadbotics-roadway-api-dev") {
      tabsToShow.push(modalTabDefinitions.ANNOTATIONS);
    }

    return tabsToShow;
  };

  useMount(() => {
    if (modalData.pointData?.content?.feature?.properties?.primaryDistresses) {
      setTabLayer(modalTabDefinitions.IDI);
    }
  });

  const handleTabChange = (event, newValue) => {
    tracking.trackEvent({
      event: "mouse-click",
      action: "tab-switch",
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `tab switch`,
      level: Sentry.Severity.Info,
    });

    setTabLayer(newValue);
  };

  const renderModule = () => {
    if (modalTab === modalTabDefinitions.SIDEWALK) {
      return (
        <ModalSidewalkPoint modalData={modalData} colorPicker={colorPicker} />
      );
    }
  };

  return (
    <div>
      {renderModule()}
      <div
        style={{
          display: modalTab === modalTabDefinitions.ANNOTATIONS ? null : "none",
        }}
      >
        {process.env.REACT_APP_PROJECT_ID === "roadbotics-roadway-api-dev" && (
          <PointAnnotationsContainer />
        )}
      </div>
      <BottomBar
        tabsToShow={tabsToShow()}
        modalTab={modalTab}
        handleTabChange={handleTabChange}
      />
    </div>
  );
};

const mapStateToProps = state => {
  const {
    modal: { modalTab, modalData },
    user: { userUId },
    colorPicker,
  } = state;
  return {
    userUId,
    modalTab,
    modalData,
    colorPicker,
  };
};

const mapDispatchToProps = dispatch => ({
  setTabLayer: modalTab => dispatch(setTabLayer(modalTab)),
});

export default track()(
  connect(mapStateToProps, mapDispatchToProps)(BottomBarContainer)
);
