import React from "react";
import OrganizationSelectorContainer from "containers/OrganizationSelectorContainer";
import AppBarContainer from "containers/AppBarContainer";
import WelcomeDialogContainer from "../containers/WelcomDialogContainer";
import OrganizationGridSkeleton from "../skeletons/organizationGrid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  div: {
    paddingTop: "5%",
    overflowX: "hidden",
  },
  dialogTitle: {
    paddingTop: "30px",
    paddingBottom: "10px",
  },
});

interface DashboardInterface {
  showWelcome: boolean;
  dismissDialog: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  checked: boolean;
  handleCheckboxChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  isOrganizationsDataLoaded: boolean;
}

const Dashboard: React.FC<DashboardInterface> = ({
  showWelcome,
  dismissDialog,
  checked,
  handleCheckboxChange,
  isOrganizationsDataLoaded,
}) => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <div className={classes.div}>
      <AppBarContainer urlLocation={location.pathname} />
      {!isOrganizationsDataLoaded ? (
        <OrganizationGridSkeleton />
      ) : (
        <OrganizationSelectorContainer />
      )}
      <WelcomeDialogContainer
        isOrganizationsDataLoaded={isOrganizationsDataLoaded}
        showWelcome={showWelcome}
        dismissDialog={dismissDialog}
        checked={checked}
        handleCheckboxChange={handleCheckboxChange}
      />
    </div>
  );
};

export default Dashboard;
