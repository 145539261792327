import React, { useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Dialog from "../components/Dialog/Dialog";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import Button from "../components/form/Button";
import { RB_RED, RB_YELLOW, RB_GREEN, RB_ORANGE, RB_LIGHT_GREEN, RB_ROAD_SENSE, RB_BLACK, RB_GREY } from "constants/colors";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "80%",
      textAlign: "center",
      margin: "auto",
    },
    button: {
      margin: "5px",
    },
    layerItem: {
      display: "block"
    }
  })
);

interface LayerUpdateDialogProps {
  currentName: string | undefined;
  currentColor: string | undefined;
  isOpen: boolean;
  closeDialog: () => void;
  updateLayer: (newName: string, newColor: string) => void;
}

const LayerUpdateDialog = ({
  currentName,
  currentColor,
  isOpen,
  closeDialog,
  updateLayer,
}: LayerUpdateDialogProps): React.ReactElement => {
  const classes = useStyles();
  const [name, setName] = useState(currentName);
  const [color, setColor] = useState(currentColor);

  useEffect(() => {
    setName(currentName);
    setColor(currentColor)
  }, [currentName, currentColor]);

  const handleClose = (): void => {
    setName("");
    closeDialog();
  };

  const onLayerUpdateSubmit = (): void => {
    updateLayer(name || "", color || "");
    setName("");
    setColor("");
  };

  const clearLayerUpdateChange = (): void => {
    updateLayer("", "");
    setName("");
    setColor("");
  };

  const createActions = (): JSX.Element => {
    return (
      <div>
        <Button className={classes.button} onClick={clearLayerUpdateChange}>
          Clear
        </Button>
        <Button className={classes.button} onClick={onLayerUpdateSubmit}>
          Submit
        </Button>
      </div>
    );
  };

  const handleNameEdit = (newName: string): void => {
    setName(newName);
  };

  const handleColorEdit = (event: React.ChangeEvent<{ value: unknown }>) => {
    setColor(event.target.value as string);
  };

  return (
    <div className={classes.root}>
      <Dialog
        isOpen={isOpen}
        actions={createActions()}
        cancelButton={false}
        handleClose={handleClose}
        maxWidth="xs"
        title="Change Collection Visuals"
      >
        <div className={classes.layerItem}>
          <TextField
            label="New Collection Name"
            value={name}
            onChange={(e): void => handleNameEdit(e.target.value)}
          />
        </div>
        <div className={classes.layerItem}>
          <FormControl style={{width: "120px"}}>
            <InputLabel>Layer Color</InputLabel>
            <Select
              value={color}
              onChange={handleColorEdit}
            >
              <MenuItem value={RB_RED}>Red</MenuItem>
              <MenuItem value={RB_ORANGE}>Orange</MenuItem>
              <MenuItem value={RB_YELLOW}>Yellow</MenuItem>
              <MenuItem value={RB_LIGHT_GREEN}>Light Green</MenuItem>
              <MenuItem value={RB_GREEN}>Green</MenuItem>
              <MenuItem value={RB_ROAD_SENSE}>Blue</MenuItem>
              <MenuItem value={RB_BLACK}>Black</MenuItem>
              <MenuItem value={RB_GREY}>Grey</MenuItem>
            </Select>
          </FormControl>
        </div>
      </Dialog>
    </div>
  );
};

export default LayerUpdateDialog;
