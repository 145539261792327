import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "./form/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import RoadSenseVideo from "./RoadSenseVideo";
import { RB_YELLOW } from "../constants/colors";
import DownloadRoadSenseButton from "./DownloadRoadSenseButton";
import { getAssessmentType } from "utils/getAssessmentType";
import { AssessmentTypes } from "constants/assessmentTypes";

const useStyles = makeStyles({
  dialogTitle: {
    paddingTop: "30px",
    paddingBottom: "10px",
  },
  dialogText: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 600,
  },
  roadSenseButton: {
    backgroundColor: RB_YELLOW,
    borderRadius: "2px",
    borderBottom: "none",
    paddingLeft: "10px",
    fontFamily: "'Open Sans', sans-serif",
    "&:hover": {
      backgroundColor: RB_YELLOW,
    },
    marginRight: "auto",
  },
});

type FreeTrialWelcomeDialogContentProps = {
  checked: boolean;
  handleCheckboxChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  dismissDialog: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
};

const FreeTrialWelcomeDialogContent = ({
  checked,
  handleCheckboxChange,
  dismissDialog,
}: FreeTrialWelcomeDialogContentProps): React.ReactElement => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <DialogTitle className={classes.dialogTitle} disableTypography={true}>
        <Typography className={classes.dialogText} variant="h3">
          Welcome to {getAssessmentType() === AssessmentTypes.NORMAL ? "RoadWay" : "RoadSense"}!
        </Typography>
      </DialogTitle>
      <DialogContent>
        <RoadSenseVideo size="large" />
      </DialogContent>
      <DialogActions>
        <DownloadRoadSenseButton className={classes.roadSenseButton} />
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={handleCheckboxChange}
              color="primary"
            />
          }
          label="Do Not Show Again"
        />
        <Button onClick={dismissDialog} variant="contained" color="primary">
          Close
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

export default FreeTrialWelcomeDialogContent;
