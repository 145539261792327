import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { RB_YELLOW } from "../constants/colors";
import Button from "./form/Button";
import classNames from "classnames";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import RBAPI from "api/RoadwayAPI";

const useStyles = makeStyles({
  buttonText: {
    fontSize: "14px",
  },
  button: {
    width: "37%",
    backgroundColor: RB_YELLOW,
    textTransform: "none",
    fontFamily: "'Open Sans', sans-serif",
    "&:hover": {
      backgroundColor: RB_YELLOW,
    },
    borderRadius: "2px",
  },
});

type DownloadRoadSenseButtonProps = {
  className: string;
};

const DownloadRoadSenseButton = ({
  className,
}: DownloadRoadSenseButtonProps): React.ReactElement => {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    setIsDialogOpen(true);
    RBAPI.downloadRoadSense().catch((e: any) => {
      throw e;
    });
  };

  const dismissDialog = (): void => {
    setIsDialogOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        className={classNames(classes.button, className)}
        onClick={handleClick}
      >
        <Typography align="center" className={classes.buttonText}>
          Download RoadSense
        </Typography>
      </Button>
      <Dialog open={isDialogOpen} onClose={dismissDialog} maxWidth="md">
        <DialogContent>
          <DialogContentText>
            {`RoadSense download instructions have been emailed.
            Please check your email used for your RoadWay account.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={dismissDialog} variant="contained" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default DownloadRoadSenseButton;
