export function parseTimestamp(timestamp) {
  if (timestamp.slice(timestamp.length - 3) === "UTC") {
    return timestamp.slice(0, timestamp.length - 4);
  } else {
    return timestamp;
  }
}

export function getDaysBetween(timestamp1, timestamp2) {
  // One day Time in ms (milliseconds)
  const one_day = 1000 * 60 * 60 * 24;

  // calculate the result in milliseconds and then convert into days
  const result =
    Math.round(timestamp1.getTime() - timestamp2.getTime()) / one_day;

  // remove the decimals from the (result) resulting days value
  return result.toFixed(0);
}
