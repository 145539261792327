import * as do_not_enter_signs from "./do_not_enter_signs";
import * as no_left_turn_signs from "./no_left_turn_signs";
import * as no_right_turn_signs from "./no_right_turn_signs";
import * as one_way_signs from "./one_way_signs";
import * as speed_limit_signs from "./speed_limit_signs";
import * as stop_signs from "./stop_signs";
import * as wrong_way_signs from "./wrong_way_signs";
import * as yield_signs from "./yield_signs";
import * as fire_hydrants from "./fire_hydrants";
import * as traffic_lights from "./traffic_lights";
import * as pothole_signs from "./pothole_signs";

export default {
  ...do_not_enter_signs,
  ...no_left_turn_signs,
  ...no_right_turn_signs,
  ...one_way_signs,
  ...speed_limit_signs,
  ...stop_signs,
  ...wrong_way_signs,
  ...yield_signs,
  ...fire_hydrants,
  ...traffic_lights,
  ...pothole_signs,
};
