import { CHROME, FIREFOX, IPAD } from "constants/devices";

export const isChrome = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf(CHROME) !== -1;
};

export const isFirefox = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf(FIREFOX) !== -1;
};

export const isIPad = () => {
  return navigator.platform.toLowerCase() === IPAD;
};

export const browserCheck = () => {
  // check if we are using chrome or firefox or not an ipad
  return (isChrome() || isFirefox()) && !isIPad();
};
