import React from "react";
import PropTypes from "prop-types";

import SvgIcon from "@material-ui/core/SvgIcon";

const SquareFootIcon = ({ viewBox = "-2.5 -2 20 20", ...props }) => (
  <SvgIcon viewBox={viewBox} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-4-4h24v24H-4z" />
        <path d="M-4-4h24v24H-4z" />
        <g fill="#f26622" fillRule="nonzero">
          <path
            d="M13.66 13.66l-.71.71c-.2.2-.51.2-.71 0-.2-.2-.2-.51
                  0-.71l.71-.71-1.94-1.94-.71.71c-.2.2-.51.2-.71 0-.2-.2-.2-.51
                  0-.71l.71-.71-1.94-1.94-.71.71c-.2.2-.51.2-.71 0-.2-.2-.2-.51
                  0-.71l.71-.71L5.7 5.7l-.71.71c-.2.2-.51.2-.71 0-.2-.2-.2-.51
                  0-.71l.71-.71-1.94-1.94-.71.71c-.2.2-.51.2-.71 0-.2-.2-.2-.51
                  0-.71l.71-.71L.85.85a.5.5 0 0 0-.85.36V14c0 1.1.9 2 2 2h12.79c.45
                  0 .67-.54.35-.85l-1.48-1.49zM3 12V7.24L8.76 13H4c-.55 0-1-.45-1-1z"
          />
        </g>
      </g>
    </svg>
  </SvgIcon>
);

SquareFootIcon.defaultProps = {
  viewBox: "-2.5 -2 20 20",
};

SquareFootIcon.propTypes = {
  viewBox: PropTypes.string,
};

export default SquareFootIcon;
