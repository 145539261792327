import React, { useEffect, useState } from "react";
import classNames from "classnames";
import track, { useTracking } from "react-tracking";

import { default as MUIAppBar } from "@material-ui/core/AppBar";
import { default as MUIToolbar } from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import ToolbarToggleGroupContainer from "containers/ToolbarToggleGroupContainer";
import RBWebsiteButton from "components/RBWebsiteButton";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import * as Sentry from "@sentry/browser";
import ViewList from "@material-ui/icons/ViewList";
import ViewModule from "@material-ui/icons/ViewModule";

import { drawerWidth } from "./Sidebar";
import { getAppBarHeight } from "../utils/getAppBarHeight";
import {
  PRIMARY_HOVER_COLOR,
  RB_YELLOW,
  RB_LIGHT_GREEN,
} from "../constants/colors";
import { ROUTE_MAP, ROUTE_ADMIN } from "../constants/routes";
import { topBannerHeight } from "../views/Banner";
import { uiConstants } from "../constants/ui";
import UserStatus from "constants/userStatus";
import AdminIcon from "images/icons/adminIcon.svg";
import Tooltip from "@material-ui/core/Tooltip";
import ScanSwitcherSelectContainer from "../containers/ScanSwitcherSelectContainer";
import { useDispatch } from "react-redux";
import { setActiveScan as setActiveScanAction } from "../actions/userData";
import { resetModal as resetModalAction } from "../actions/modal";
import { setIsMapDataLoaded as setIsMapDataLoadedAction } from "../actions/map";
import DownloadAssessmentFilesButton from "./DownloadAssessmentFilesButton";
import { getAssessmentType } from "utils/getAssessmentType";
import RBAPI from "api/RoadwayAPI";
import { Chip, makeStyles } from "@material-ui/core";

const styles = makeStyles(theme => ({
  topBanner: {
    top: `${topBannerHeight}px`,
  },
  appBar: props => ({
    backgroundColor: props.isBeta ? RB_LIGHT_GREEN : "#ffffff",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: "1201",
  }),
  appBarOSDrawerShift: {
    left: uiConstants.osDrawerWidth,
    width: `calc(100% - ${uiConstants.osDrawerWidth}px)`,
  },
  appBarShift: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  toolbarDense: {
    minHeight: "50px",
  },
  gutters: {
    paddingLeft: "0px",
  },
  menuButton: {
    marginRight: "auto",
    color: "#000000",
    height: `${getAppBarHeight()}px`,
    "&:hover": {
      color: PRIMARY_HOVER_COLOR,
    },
  },
  backButton: {
    marginRight: "auto",
    color: "#000000",
    height: `${getAppBarHeight()}px`,
    "&:hover": {
      color: PRIMARY_HOVER_COLOR,
    },
    width: "130px",
  },
  roadboticsButton: {
    marginRight: "auto",
    color: "#000000",
    height: `${getAppBarHeight()}px`,
    "&:hover": {
      color: PRIMARY_HOVER_COLOR,
    },
  },
  logo: {
    width: "180px",
  },
  roadboticsButtonNoMenu: {
    marginRight: "auto",
    color: "#000000",
    height: `${getAppBarHeight()}px`,
    "&:hover": {
      color: PRIMARY_HOVER_COLOR,
    },
    left: "64px",
  },
  notificationButton: {
    marginLeft: "auto",
    height: `${getAppBarHeight()}px`,
    "&:hover": {
      color: PRIMARY_HOVER_COLOR,
    },
    color: "black",
  },
  rightButtons: {
    float: "right",
  },
  leftButtons: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "10px",
  },
  optionsButton: {
    marginLeft: "auto",
    height: `${getAppBarHeight()}px`,
    paddingRight: "15px",
    paddingLeft: "15px",
    "&:hover": {
      color: PRIMARY_HOVER_COLOR,
    },
    color: "black",
  },
  menuLabel: {
    fontSize: "16px",
    alignText: "center",
    fontFamily: "'Open Sans', sans-serif",
    padding: "0px 0px 2px 5px",
  },
  toolbarItems: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
  },
  avatar: {
    height: "30px",
    width: "30px",
  },
  expandOpen: {
    color: PRIMARY_HOVER_COLOR,
  },
  expandClosed: {
    color: "black",
  },
  settingsLabel: {
    fontSize: "16px",
    alignText: "center",
    fontFamily: "'Open Sans', sans-serif",
    padding: "0px 7px 2px 0px",
    textTransform: "none",
    textOverflow: "ellipsis",
    maxWidth: "200px",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  notificationActive: {
    fontSize: "30px",
    color: RB_YELLOW,
  },
  notificationInactive: {
    fontSize: "30px",
  },
  scanTypography: {
    fontFamily: "'Open Sans', sans-serif",
  },
  adminIcon: {
    width: "25px",
  },
  adminButton: {
    width: "50px",
  },
  scanSwitcherSelect: {
    margin: "5px 0px 5px 25px",
    width: "250px",
  },
  largerIcon: {
    fontSize: "2.5em",
  },
  betaChip: {
    backgroundColor: "#3f51b5",
    color: "white",
  },
}));

const AppBar = ({
  user,
  urlLocation,
  activeScan,
  isTOSView,
  isTopBannerOpen,
  isDashboardList,
  changeToGrid,
  changeToList,
  isAdmin,
  isBeta,
}) => {
  const classes = styles({ isBeta });
  const tracking = useTracking();
  const handleBackToMap = () => {
    tracking.trackEvent({
      event: "mouse-click",
      action: `app-bar-back-to-map-click`,
      userUId: user.userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: "Clicked on back to map",
      level: Sentry.Severity.Info,
    });
  };

  const mapRegex = /\/map\/.*/g;
  const dashboardRegex = /\/dashboard/g;

  const renderDashboardViewButton = () => {
    if (isDashboardList) {
      return (
        <Button onClick={changeToGrid}>
          <Tooltip title="Change to Grid View" placement="bottom">
            <ViewModule className={classes.largerIcon} />
          </Tooltip>
        </Button>
      );
    } else {
      return (
        <Button onClick={changeToList}>
          <Tooltip title="Change to List View" placement="bottom">
            <ViewList className={classes.largerIcon} />
          </Tooltip>
        </Button>
      );
    }
  };
  const renderAdminButton = () => {
    if (user && isAdmin && user.status !== UserStatus.DISABLED) {
      return (
        <Link to={ROUTE_ADMIN}>
          <Tooltip title="Go to Admin Panel" placement="bottom">
            <Button className={classes.adminButton}>
              <img className={classes.adminIcon} src={AdminIcon} alt="admin" />
            </Button>
          </Tooltip>
        </Link>
      );
    }
    return null;
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const setActiveScan = scanValue => dispatch(setActiveScanAction(scanValue));
  const resetModal = () => dispatch(resetModalAction());
  const setIsMapDataLoaded = value => dispatch(setIsMapDataLoadedAction(value));
  const getScanValue = scanOption => scanOption && scanOption.value;
  const isRoadBoticsAdmin = useSelector(
    state => state?.userData?.isRoadBoticsAdmin
  );
  const [isIDIAssessment, setIsIDIAssessment] = useState(false);

  useEffect(() => {
    async function fetchIDIData() {
      if (activeScan) {
        const isIDIAssessment_ = (await RBAPI.fetchScan(activeScan))?.analysis
          ?.potholeCount
          ? true
          : false;
        setIsIDIAssessment(isIDIAssessment_);
      }
    }
    fetchIDIData();
  }, [activeScan]);

  const handleSelectChange = selectedOption => {
    const formattedScanValue = getScanValue(selectedOption);
    if (activeScan !== formattedScanValue) {
      setActiveScan(formattedScanValue);
      resetModal();
      tracking.trackEvent({
        event: "mouse-click",
        action: `scan-switcher-select-change-${formattedScanValue}`,
        userUId: user.userUId,
      });

      Sentry.addBreadcrumb({
        category: "mouse-click",
        message: `scan switcher select change ${formattedScanValue}`,
        level: Sentry.Severity.Info,
      });

      setIsMapDataLoaded(false);
      history.push(
        `${ROUTE_MAP}/${formattedScanValue}/?assessmentType=${getAssessmentType()}`
      );
    }
  };

  return (
    <Grid container>
      <MUIAppBar
        position="fixed"
        id="appBarId"
        className={classNames(classes.appBar, {
          [classes.topBanner]: isTopBannerOpen,
          [classes.appBarOSDrawerShift]: !isTOSView,
        })}
      >
        <MUIToolbar
          variant="dense"
          classes={{
            dense: classes.toolbarDense,
            gutters: classes.gutters,
          }}
        >
          <Grid item sm={5} md={4}>
            <div className={classes.leftButtons}>
              {!isTOSView && (
                <div className={classes.scanSwitcherSelect}>
                  <ScanSwitcherSelectContainer
                    className={classes.scanSwitcherDropdown}
                    handleChange={handleSelectChange}
                  />
                </div>
              )}
              {!urlLocation.match(mapRegex) && (
                <>
                  {activeScan !== "" ? (
                    <Link
                      to={`${ROUTE_MAP}/${activeScan}/?assessmentType=${getAssessmentType()}`}
                    >
                      <Button
                        color="inherit"
                        onClick={handleBackToMap}
                        className={classes.backButton}
                      >
                        <Typography className={classes.scanTypography}>
                          Back to map
                        </Typography>
                      </Button>
                    </Link>
                  ) : null}
                </>
              )}
            </div>
          </Grid>

          <Grid item sm={6} md={7}>
            {urlLocation.includes(ROUTE_MAP) && (
              <div className={classes.toolbarItems}>
                <ToolbarToggleGroupContainer />
              </div>
            )}
          </Grid>

          {!isTOSView && (
            <Grid item sm={5} md={4}>
              <div className={classes.rightButtons}>
                {(isRoadBoticsAdmin || isIDIAssessment) && (
                  <DownloadAssessmentFilesButton
                    scanId={activeScan}
                    isRoadBoticsAdmin={isRoadBoticsAdmin}
                    isIDIAssessment={isIDIAssessment}
                  ></DownloadAssessmentFilesButton>
                )}
                {urlLocation.match(dashboardRegex) ? (
                  renderDashboardViewButton()
                ) : (
                  <Link to="/">
                    <Button>
                      <Tooltip title="Go to Dashboard" placement="bottom">
                        <ViewModule className={classes.largerIcon} />
                      </Tooltip>
                    </Button>
                  </Link>
                )}
                {renderAdminButton()}
              </div>
            </Grid>
          )}
          {isBeta && <Chip label="BETA" className={classes.betaChip} />}
          {process.env.REACT_APP_ISDEMO === "1" && <RBWebsiteButton />}
        </MUIToolbar>
      </MUIAppBar>
    </Grid>
  );
};

AppBar.defaultProps = {
  user: {
    organization: null,
    firstName: null,
    lastName: null,
    userUId: null,
  },
  activeScan: "",
};

AppBar.propTypes = {
  user: PropTypes.shape({
    organization: PropTypes.string,
    lastLogin: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    userUId: PropTypes.string,
  }),
  urlLocation: PropTypes.string.isRequired,
  activeScan: PropTypes.string,
  isTOSView: PropTypes.bool.isRequired,
  isTopBannerOpen: PropTypes.bool.isRequired,
  isDashboardList: PropTypes.bool.isRequired,
  changeToList: PropTypes.func.isRequired,
  changeToGrid: PropTypes.func.isRequired,
};

export default track()(AppBar);
