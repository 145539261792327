import React, { useState } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Map from "@material-ui/icons/Map";
import Tooltip from "@material-ui/core/Tooltip";
import CrackSealIcon from "components/icons/crackSealIcon";
import RatingsLayerContainer from "containers/RatingsLayerContainer";
import CrackSealContainer from "containers/CrackSealContainer";
import { connect } from "react-redux";
import { hideLayer } from "actions/layers";
import track, { useTracking } from "react-tracking";
import { RB_YELLOW } from "../constants/colors";
import * as Sentry from "@sentry/browser/dist/index";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { clickBehaviorConstants } from "constants/clickBehavior";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "20px",
  },
  tabs: {
    color: RB_YELLOW,
    marginBottom: "15px",
  },
  tab: {
    minWidth: "48px",
    width: "48px",
    marginRight: theme.spacing(),
    marginLeft: theme.spacing(),
    borderRadius: "25px",
    color: "white",
    backgroundColor: "#d2d1d0",
  },
  tabSelected: {
    backgroundColor: RB_YELLOW,
  },
  tabIndicator: {
    display: "none",
  },
  tabContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  tabHeader: {
    margin: "20px",
  },
  tabDivider: {
    width: "70%",
  },
  divider: {
    marginTop: "10px",
    marginBottom: "10px",
  },
}));

const TabsContainer = ({ userUId, layerGroups, hideLayer }) => {
  const tracking = useTracking();
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState(0);

  const tabs = [
    {
      id: 0,
      name: "ratings_layer",
      tooltip: "Ratings Map",
      icon: <Map style={{ color: "#000000" }} />,
      component: <RatingsLayerContainer />,
    },
    {
      id: 1,
      name: "crack_seal",
      tooltip: "Crack Seal Map",
      icon: <CrackSealIcon style={{ color: "#000000" }} />,
      component: <CrackSealContainer />,
    },
  ];

  const hideAllLayers = () => {
    layerGroups.forEach(layerGroup => {
      layerGroup.layers.forEach(layer => hideLayer(layer.id));
    });
  };

  const handleChangeIndex = (event, index) => {
    tracking.trackEvent({
      event: "mouse-click",
      action: "tab-switch",
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `tab switch`,
      level: Sentry.Severity.Info,
    });

    hideAllLayers();
    setActiveIndex(index);
  };

  const renderTabs = () => {
    return (
      <Tabs
        value={activeIndex}
        onChange={handleChangeIndex}
        textColor="inherit"
        centered={tabs.length < 3}
        variant={tabs.length > 3 ? "scrollable" : "standard"}
        className={classes.tabs}
        classes={{
          indicator: classes.tabIndicator,
        }}
      >
        {tabs.map(item => (
          <Tooltip key={item.id} title={item.tooltip} placement="top">
            <Tab
              classes={{
                root: classes.tab,
                selected: classes.tabSelected,
              }}
              icon={item.icon}
              data-testid={`sidebar-tab-${item.id}`}
            />
          </Tooltip>
        ))}
      </Tabs>
    );
  };

  const shouldRenderTabs = () => {
    const isCracksealLayerPresent = Boolean(
      layerGroups.find(
        layerGroup =>
          layerGroup.clickBehavior === clickBehaviorConstants.roadwayCrackseal
      )
    );
    return isCracksealLayerPresent;
  };

  const renderTabContent = tabs => {
    const activeContent = tabs.find(
      tabContent => tabContent.id === activeIndex
    );
    if (activeContent) return activeContent.component;
  };

  return (
    <Paper className={classes.root} elevation={0}>
      {shouldRenderTabs() && renderTabs()}
      <div className={classes.tabContainer}>{renderTabContent(tabs)}</div>
    </Paper>
  );
};

TabsContainer.defaultProps = {
  layerGroups: [],
};

TabsContainer.propTypes = {
  tracking: PropTypes.object.isRequired,
  layerGroups: PropTypes.array,
  hideLayer: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  userUId: PropTypes.string,
};

const mapStateToProps = state => {
  const {
    layers: { groups },
    user: { userUId },
  } = state;
  return {
    layerGroups: groups,
    userUId,
  };
};

const mapDispatchToProps = dispatch => ({
  hideLayer: layerId => dispatch(hideLayer(layerId)),
});

export default track()(
  connect(mapStateToProps, mapDispatchToProps)(TabsContainer)
);
