import { Divider, makeStyles } from "@material-ui/core";
import { IDI, Segment } from "interfaces/IDIAnalysis";
import React, { FC, useEffect, useState } from "react";
import DistressesSliders, {
  Distresses,
  DistressesRanges,
} from "./DistressesSliders";
import IDIAnalysisTable from "./IDIAnalysisTable";
import TooltipInfo from "./TooltipInfo";
import RatingRadios, { Ratings, RatingsType } from "./RatingRadios";
import DistressSlidersSkeleton from "skeletons/distressSliders";
import TitleSkeleton from "skeletons/title";
import {
  RB_GREEN,
  RB_LIGHT_GREEN,
  RB_LIGHT_ORANGE,
  RB_LIGHT_RED,
  RB_LIGHT_YELLOW,
  RB_LIGHTER_GREEN,
  RB_ORANGE,
  RB_RED,
  RB_YELLOW,
} from "../constants/colors";
import { RadioValue } from "./form/RadioGroup";
import { flatMap } from "lodash";
import { StreetData } from "./SummaryPage";
import classNames from "classnames";
import PciSlider, { PCI, PCIRanges } from "./PciSlider";

const useStyles = makeStyles(theme => ({
  container: {
    padding: 20,
  },
  radiosContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
    marginBottom: 30,
  },
  pciRadiosContainer: {
    display: "grid",
    alignContent: "center",
    justifyContent: "space-around",
    marginBottom: 30,
  },
  selectStreetsLabel: {
    display: "inline-block",
    verticalAlign: "middle",
    borderRadius: "10px 0px 0px 10px",
    padding: "2px 10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  selectSegmentsLabel: {
    display: "inline-block",
    verticalAlign: "middle",
    borderRadius: "0px 10px 10px 0px",
    padding: "2px 10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  selectedLabel: {
    border: "2px blue solid",
    backgroundColor: "#8f8fffa6",
    color: "blue",
  },
  unselectedLabel: {
    border: "2px #777 solid",
    backgroundColor: "#7777776e",
    color: "#777",
  },
  select: {
    display: "inline-block"
  },
  segmentsToggle: {
    display: "inline-block",
    width: "100%",
    textAlign: "center"
  },
  segmentsChild: {
    display: "inline-block",
    verticalAlign: "middle",
  }
}));

export type Filters = Ratings | DistressesRanges | PCIRanges;

interface IDIAnalysisProps {
  IDIData?: IDI;
  dataLoaded: boolean;
  showErrorMessage: boolean;
  individualRoadsAnalysisList?: {}
}

const IDIAnalysis: FC<IDIAnalysisProps> = props => {
  const { IDIData, dataLoaded, showErrorMessage, individualRoadsAnalysisList } = props;
  const styles = useStyles();
  // used to determine whether to show segment data or street data
  const [showSegmentData, setShowSegmentData] = useState(showErrorMessage ? false : true)
  const [ratings, setRatings] = useState<RadioValue<RatingsType>[]>([
    {
      value: "Rating 1",
      selectedStyle: { backgroundColor: RB_GREEN },
      unselectedStyle: { backgroundColor: RB_LIGHT_GREEN },
      checked: true,
    },
    {
      value: "Rating 2",
      selectedStyle: { backgroundColor: RB_LIGHT_GREEN },
      unselectedStyle: { backgroundColor: RB_LIGHTER_GREEN },
      checked: true,
    },
    {
      value: "Rating 3",
      selectedStyle: { backgroundColor: RB_YELLOW },
      unselectedStyle: { backgroundColor: RB_LIGHT_YELLOW },
      checked: true,
    },
    {
      value: "Rating 4",
      selectedStyle: { backgroundColor: RB_ORANGE },
      unselectedStyle: { backgroundColor: RB_LIGHT_ORANGE },
      checked: true,
    },
    {
      value: "Rating 5",
      selectedStyle: { backgroundColor: RB_RED },
      unselectedStyle: { backgroundColor: RB_LIGHT_RED },
      checked: true,
    },
  ]);
  const [distresses, setDistresses] = useState<DistressesRanges[]>([]);
  const [pci, setPCI] = useState<PCIRanges[]>([{
    title: PCI.pci,
    initialRange: [0, 100],
    value: [0, 100],
    checked: true,
  }]);
  const [filters, setFilters] = useState<Filters[]>([]);
  const [segments, setSegments] = useState<Segment[]>([]);
  const [streets, setStreets] = useState<StreetData[]>([])

  const calculateMaxIDIData = (streetFeatures: StreetData[]) => {
    let maxPatchSeal = 0;
    let maxTransLongCracking = 0;
    let maxFatigueCracking = 0;
    let maxPavementDistortions = 0;
    let maxPothole = 0;
    let maxSurfaceDeterioration = 0;


    streetFeatures.forEach(streetFeature => {
      if (streetFeature.segmentDistressStatistics) {
        const { numPatchSeal, numTransLongCracking, numPothole, numFatigueCracking, numPavementDistortions, numSurfaceDeterioration } = streetFeature.segmentDistressStatistics
        maxPatchSeal = Math.max(numPatchSeal, maxPatchSeal);
        maxTransLongCracking = Math.max(numTransLongCracking, maxTransLongCracking);
        maxFatigueCracking = Math.max(numFatigueCracking, maxFatigueCracking);
        maxPavementDistortions = Math.max(numPavementDistortions, maxPavementDistortions);
        maxPothole = Math.max(numPothole, maxPothole);
        maxSurfaceDeterioration = Math.max(numSurfaceDeterioration, maxSurfaceDeterioration);
      }
    });

    return {
      maxPatchSeal,
      maxTransLongCracking,
      maxFatigueCracking,
      maxPavementDistortions,
      maxPothole,
      maxSurfaceDeterioration,
    };
  }

  const setPCISliderDefaults = () => {
    const PCT_MAX_VAL = 100;
    const sliders: PCIRanges[] = [
      {
        title: PCI.pci,
        initialRange: [0, PCT_MAX_VAL],
        value: [0, PCT_MAX_VAL],
        checked: true,
      },
    ];
    return sliders
  }

  const setSliderDefaults = (maxPothole: number, maxPatchSeal: number) => {
    const PCT_MAX_VAL = 100;
    const sliders: DistressesRanges[] = [
      {
        title: Distresses.potholes,
        initialRange: [0, maxPothole],
        value: [0, maxPothole],
        checked: true,
      },
      {
        title: Distresses.sealant,
        initialRange: [0, maxPatchSeal],
        value: [0, maxPatchSeal],
        checked: true,
      },
      {
        title: Distresses.fatigueCracking,
        initialRange: [0, PCT_MAX_VAL],
        value: [0, PCT_MAX_VAL],
        checked: true,
      },
      {
        title: Distresses.deterioration,
        initialRange: [0, PCT_MAX_VAL],
        value: [0, PCT_MAX_VAL],
        checked: true,
      },
      {
        title: Distresses.distortion,
        initialRange: [0, PCT_MAX_VAL],
        value: [0, PCT_MAX_VAL],
        checked: true,
      },
      {
        title: Distresses.longCracking,
        initialRange: [0, PCT_MAX_VAL],
        value: [0, PCT_MAX_VAL],
        checked: true,
      },
    ];
    return sliders
  }

  useEffect(() => {
    if (IDIData && showSegmentData) {
      setSegments(IDIData.segments);
      const maxValues = IDIData.maxValues;
      const { maxPothole, maxPatchSeal } = maxValues;
      setDistresses(setSliderDefaults(maxPothole, maxPatchSeal));
      setPCI(setPCISliderDefaults())
    } else if (individualRoadsAnalysisList && !showSegmentData) {
      const flatStreetData: StreetData[] = flatMap(individualRoadsAnalysisList)
      setStreets(flatStreetData)
      const { maxPothole, maxPatchSeal } = calculateMaxIDIData(flatStreetData)
      setDistresses(setSliderDefaults(maxPothole, maxPatchSeal));
      setPCI(setPCISliderDefaults())
    }
  }, [IDIData, showSegmentData, individualRoadsAnalysisList]);

  useEffect(() => {
    const activeRatings = ratings.filter(r => r.checked);
    const activeDistresses = distresses.filter(d => d.checked);
    const activePCI = pci.filter(p => p.checked)
    // Make sure when applying filters ratings are first!
    setFilters([
      ...activeRatings.map(r => Ratings[r.value]),
      ...activeDistresses,
      ...activePCI,
    ]);
  }, [ratings, distresses, pci]);

  return (
    <div className={styles.container}>
      <>
        <div className={styles.segmentsToggle}>
          <div className={styles.segmentsChild}>
            <TooltipInfo
              title={"Data Toggle"}
              body={`Toggle to set the data used in the filters below. Segments are intersection to intersection chunks of road,
                while Streets are made up of every Segment with the same name.`}
            />
          </div>
          <div className={styles.segmentsChild}>
            <div
              style={showSegmentData ? { borderRight: "0px" } : {}}
              className={classNames(styles.selectStreetsLabel, !showSegmentData ? styles.selectedLabel : styles.unselectedLabel)}
              onClick={() => { setShowSegmentData(false) }}
            >
              Street View
            </div>
            <div
              style={!showSegmentData ? { borderLeft: "0px" } : {}}
              className={classNames(styles.selectSegmentsLabel, showSegmentData ? styles.selectedLabel : styles.unselectedLabel)}
              onClick={() => { setShowSegmentData(true) }}
            >
              Segment View
            </div>
          </div>
          <Divider />
        </div>
        <div className={styles.radiosContainer}>
          <div>
            <TooltipInfo
              title={"Condition Ratings"}
              body={`Filter and sort by conditional rating by toggling on and off
            whichever ratings you want to see across your road network.`}
            />
            {dataLoaded ? (
              <RatingRadios setRatings={setRatings} ratings={ratings} />
            ) : (
                <div>
                  {[...Array(5).keys()].map(i => (
                    <TitleSkeleton key={`${i} skeleton`} />
                  ))}
                </div>
              )}
          </div>
          <div>
            <TooltipInfo
              title={"Pavement Distresses"}
              body={`Filter and sort by pavement distress by toggling on and off which pavement
            distresses you want to see across your road network. Use the slider to further filter
            and sort by the specific number or percentage of each distress you wish to see.`}
            />
            {dataLoaded ? (
              <DistressesSliders
                setDistresses={setDistresses}
                distresses={distresses}
              />
            ) : (
                <DistressSlidersSkeleton rows={3} />
              )}
          </div>
        </div>
        <div className={styles.pciRadiosContainer}>
          <TooltipInfo
            title={"HD-PCI Score"}
            body={`Filter and sort by HD-PCI score. Use the slider to filter, show,
            and sort by the specific numbers you wish to see.`}
          />
          {dataLoaded ? (
            <PciSlider
              setPCI={setPCI}
              pci={pci}
            />
          ) : (
              <DistressSlidersSkeleton rows={1} />
            )}
        </div>
        <IDIAnalysisTable
          isLoaded={dataLoaded}
          filters={filters}
          segments={segments}
          streets={streets}
          showSegmentData={showSegmentData}
          showErrorMessage={showErrorMessage}
        />
      </>
    </div>
  );
};

export default IDIAnalysis;
