import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { ROUTE_LOGIN } from "constants/routes";
import SideBar from "roadbotics-os-sidebar";
import firebase from "config/firebase";

const PrivateRoute = ({
  component: Component,
  userUId,
  user,
  accessAllowed,
  allowFrom = true,
  ...rest
}) => {
  const renderPublicRoute = () => {
    return (
      <Route
        {...rest}
        render={props =>
          accessAllowed ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: ROUTE_LOGIN,
                // eslint-disable-next-line react/prop-types
                state: { from: allowFrom ? props.location : null },
              }}
            />
          )
        }
      />
    );
  };

  return (
    <div>
      <SideBar
        userUId={userUId}
        apiBase={process.env.REACT_APP_ROADWAY_API_BASE}
        accountsDomain={`${process.env.REACT_APP_ACCOUNTS_DOMAIN}/login`}
        firebase={firebase}
      >
        {renderPublicRoute()}
      </SideBar>
    </div>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  accessAllowed: PropTypes.bool.isRequired,
  user: PropTypes.string,
  allowFrom: PropTypes.bool,
};

export default PrivateRoute;
