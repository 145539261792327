import { PORTAL_ID } from "constants/hubspot";
import axios, { AxiosPromise } from "axios";
import { getCookie } from "cookieWatcher";

function createHubSpotData(
  firstName: string,
  lastName: string,
  email: string,
  url: string
): object {
  return {
    fields: [
      {
        name: "email",
        value: email,
      },
      {
        name: "firstname",
        value: firstName,
      },
      {
        name: "lastname",
        value: lastName,
      },
    ],
    context: {
      // include this param and set it to the hubspotutk cookie to enable cookie tracking
      hutk: getCookie("hubspotutk"),
      pageName: url,
    },
  };
}

export function submitHubSpotForm(
  firstName: string,
  lastName: string,
  email: string,
  url: string,
  formId: string
): AxiosPromise {
  const data = createHubSpotData(firstName, lastName, email, url);
  return axios.post(
    `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${formId}`,
    data
  );
}
