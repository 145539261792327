import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { RB_LIGHT_GREY } from "../constants/colors";

const useStyles = makeStyles({
  addressBar: {
    background: RB_LIGHT_GREY,
    marginTop: "15px",
    marginBottom: "15px",
    marginLeft: "35px",
    marginRight: "35px",
  },
});

const GeocoderSkeleton: React.FC = () => {
  const classes = useStyles();
  return <Skeleton className={classes.addressBar} variant="rect" height={36} />;
};

export default GeocoderSkeleton;
