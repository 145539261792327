import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  RB_GREEN,
  RB_LIGHT_GREEN,
  RB_YELLOW,
  RB_ORANGE,
  RB_RED,
} from "constants/colors";
import { Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import TooltipInfo from "./TooltipInfo";

const useStyles = makeStyles({
  divContainer: {
    marginTop: "20px",
  },
  pci: {
    alignItems: "center",
    width: "50%",
    display: "inline",
  },
  header: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
    width: "100%",
  },
  title: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: "bold",
    fontSize: "18px",
    paddingBottom: "5px",
    textAlign: "center",
  },
  progressBar: {
    borderRadius: "5px",
    width: "100%",
    height: "50px",
    boxShadow: "3px 3px 5px #bfbfbf",
    background: `linear-gradient(to right, ${RB_RED} 0%, ${RB_ORANGE} 20%, ${RB_YELLOW} 40%, ${RB_LIGHT_GREEN} 80%, ${RB_GREEN} 100%)`,
  },
  currentProgress: {
    height: "inherit",
    width: "0px",
    borderLeft: "5px solid transparent",
    borderRight: "5px solid transparent",
    borderBottom: "10px solid black",
    borderTop: "10px solid black"
  },
  pciScore: {
    height: "50px",
    alignItems: "center",
    display: "flex",
  },
  pciText: {
    textAlign: "center",
    fontSize: "22px",
    fontWeight: "bold",
    margin: "auto",
    borderRadius: "5px",
    border: "2px solid black",
    padding: "3px",
  }
});

interface PieChartProps {
  pciScore: number;
}

const PCIScoreBar = ({
  pciScore,
}: PieChartProps): React.ReactElement => {
  const classes = useStyles();


  return (
    <div className={classes.divContainer}>
      <Grid className={classes.header} container spacing={3}>
        <Grid item xs={3}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <TooltipInfo
            title={"HD-PCI"}
            body={`HD-PCI is a composite index that factors in the conditional ratings of each point, along with the density and severity of each individual distress across a segment.`}
          />
        </Grid>
        <Grid item xs={3}>
          <Divider />
        </Grid>
      </Grid>

      <div className={classes.pci}>
        <Grid container >
          <Grid item xs={9}>
            <div className={classes.progressBar}>
              <div
                className={classes.currentProgress}
                style={{ marginLeft: `calc(${pciScore}% - 1.5%)` }}
              />
              {[0, 25, 50].map(item => {
                return <div style={{ float: "left", width: "25%" }}>{item}</div>
              })}
              <div style={{ float: "left", width: "15%" }}>75</div>
              <div style={{ float: "right" }}>100</div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.pciScore}>
              <Typography className={classes.pciText}>
                {pciScore.toFixed(2)}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default PCIScoreBar;
