import React from "react";
import Button from "components/form/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import Dialog from "components/Dialog/Dialog";

interface PointAnnotationsDialogProps {
  isDialogOpen: boolean;
  openDialog: () => void;
  closeDialog: () => void;
  fieldName: string;
  setFieldName: (e: string) => void;
  addField: (fieldName: string) => void;
  textFieldError: string;
}

const useStyles = makeStyles({
  button: {
    marginTop: "10px",
    float: "left",
  },
  textField: {
    width: "100%",
  },
});

const PointAnnotationsDialog = ({
  textFieldError,
  isDialogOpen,
  openDialog,
  closeDialog,
  fieldName,
  setFieldName,
  addField,
}: PointAnnotationsDialogProps): JSX.Element => {
  const classes = useStyles();

  const renderFormActions = (): React.ReactNode => (
    <Button
      variant="contained"
      color="primary"
      onClick={() => {
        addField(fieldName);
      }}
    >
      Add Field
    </Button>
  );

  return (
    <div>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={openDialog}
      >
        Add Field
      </Button>
      <Dialog
        title="Field Name"
        isOpen={isDialogOpen}
        handleClose={closeDialog}
        actions={renderFormActions()}
        maxWidth="xs"
      >
        <TextField
          className={classes.textField}
          error={textFieldError !== ""}
          helperText={textFieldError}
          value={fieldName}
          onChange={e => setFieldName(e.target.value)}
        />
      </Dialog>
    </div>
  );
};

export default PointAnnotationsDialog;
