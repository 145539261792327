import React, { Fragment } from "react";
import Popover from "@material-ui/core/Popover";
import SelectAllIcon from "@material-ui/icons/SelectAll";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import BorderClearIcon from "@material-ui/icons/BorderClear";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import PropTypes from "prop-types";
import mapboxgl from "mapbox-gl";
import { connect } from "react-redux";
import track from "react-tracking";
import calcbbox from "@turf/bbox";
import { lineString } from "@turf/helpers";
import lineIntersect from "@turf/line-intersect";
import booleanWithin from "@turf/boolean-within";
import { zip } from "lodash";

import { SEGMENTS } from "constants/highlightTypes";

import {
  setSegments,
  addSegment,
  removeSegment,
  showHighlight,
} from "actions/highlight";
import { openModal, updateModal } from "actions/modal";
import { hideLayer, showLayer } from "actions/layers";

import {
  toggleDynamicSegmentationDrag,
  toggleDynamicSegmentationSelect,
  toggleDynamicSegmentationLasso,
} from "actions/dynamicSegmentation";

import chartData from "utils/chartData";
import {
  calculateAverageRating,
  calculateTotalLength,
  calculateCombinedIDIData,
} from "utils/segments";
import {
  anyTogglesToggled,
  getCurrentToggleGroups,
  toggleToggleGroup,
} from "utils/toggleGroups";
import * as Sentry from "@sentry/browser/dist/index";
import makeSegment from "utils/makeSegment";
import { clickBehaviorConstants } from "constants/clickBehavior";

const styles = {
  speedDialAction: {
    width: "30px",
    height: "30px",
    minHeight: "30px",
    flex: 1,
  },
  optionsBtn: {
    textTransform: "none",
    justifyContent: "flex-end",
    backgroundColor: "rgba(255, 255, 255, .55)",
  },
  popover: {
    marginLeft: "8px",
  },
};

class DynamicSegmentationContainer extends React.Component {
  state = {
    start: null,
    current: null,
    box: null,
    optionsOpen: false,
    anchorEl: null,
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.isDynamicSegmentationDragActive !==
      this.props.isDynamicSegmentationDragActive
    ) {
      this.toggleDynamicSegmentationDragEventListener();
    }

    if (
      prevProps.isDynamicSegmentationSelectActive !==
      this.props.isDynamicSegmentationSelectActive
    ) {
      this.toggleDynamicSegmentationSelectEventListener();
    }

    if (
      prevProps.isDynamicSegmentationLassoActive !==
      this.props.isDynamicSegmentationLassoActive
    ) {
      this.toggleDynamicSegmentationLassoEventListener();
    }

    if (prevProps.segments.length !== this.props.segments.length) {
      const { modalData, segments, updateModal } = this.props;
      updateModal({
        ...modalData,
        segmentsData: {
          content: {
            averageRating: calculateAverageRating(segments),
            length: calculateTotalLength(segments),
            chartData: this.calculateChartData(segments),
            segmentDistressStatistics: calculateCombinedIDIData(segments),
          },
        },
      });
    }

    if (
      (!prevProps.isDynamicSegmentationDragActive &&
        this.props.isDynamicSegmentationDragActive) ||
      (!prevProps.isDynamicSegmentationLassoActive &&
        this.props.isDynamicSegmentationLassoActive)
    ) {
      this.props.map.getCanvas().style.cursor = "crosshair";
    } else if (
      (prevProps.isDynamicSegmentationDragActive &&
        !this.props.isDynamicSegmentationDragActive) ||
      (prevProps.isDynamicSegmentationLassoActive &&
        !this.props.isDynamicSegmentationLassoActive)
    ) {
      this.props.map.getCanvas().style.cursor = "";
    }
  }

  toggleDynamicSegmentationDragEventListener = () => {
    const {
      isDynamicSegmentationDragActive,
      map,
      tracking,
      userUId,
    } = this.props;

    tracking.trackEvent({
      event: "mouse-click",
      action: "click-drag-dynamic-segmentation",
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `click drag dynamic segmentation`,
      level: Sentry.Severity.Info,
    });

    const canvas = map.getCanvas();
    if (isDynamicSegmentationDragActive) {
      this.decideWhetherToToggleAllSegments();
      map.dragPan.disable();
      map.dragRotate.disable();
      canvas.addEventListener("mousedown", this.mouseDown, true);
    } else {
      map.dragPan.enable();
      map.dragRotate.enable();
      canvas.removeEventListener("mousedown", this.mouseDown, true);
    }
  };

  toggleDynamicSegmentationLassoEventListener = () => {
    const {
      isDynamicSegmentationLassoActive,
      map,
      tracking,
      userUId,
      draw,
    } = this.props;

    tracking.trackEvent({
      event: "mouse-click",
      action: "click-lasso-dynamic-segmentation",
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `click lasso dynamic segmentation`,
      level: Sentry.Severity.Info,
    });

    if (isDynamicSegmentationLassoActive) {
      map.addControl(draw);
      draw.changeMode("draw_polygon");

      map.on("draw.create", this.grabPolygonSegments);
      map.on("draw.delete", this.grabPolygonSegments);
      map.on("draw.update", this.grabPolygonSegments);
      map.on("zoomend", this.grabPolygonSegments);
      this.decideWhetherToToggleAllSegments();
    } else {
      map.off("draw.create", this.grabPolygonSegments);
      map.off("draw.delete", this.grabPolygonSegments);
      map.off("draw.update", this.grabPolygonSegments);
      map.off("zoomend", this.grabPolygonSegments);
    }
  };

  toggleDynamicSegmentationSelectEventListener = () => {
    const {
      isDynamicSegmentationSelectActive,
      map,
      tracking,
      userUId,
    } = this.props;

    tracking.trackEvent({
      event: "mouse-click",
      action: "click-select-dynamic-segmentation",
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `click select dynamic segmentation`,
      level: Sentry.Severity.Info,
    });

    const canvas = map.getCanvas();
    if (isDynamicSegmentationSelectActive) {
      map.getCanvas().style.cursor = "";
      this.decideWhetherToToggleAllSegments();
      canvas.addEventListener("mousedown", this.mouseDownSelect, true);
    } else {
      canvas.removeEventListener("mousedown", this.mouseDownSelect, true);
    }
  };

  filterDuplicateSegments = segments => {
    const filteredSegments = [];
    const getSegmentId = segment => makeSegment(segment).id;
    segments.forEach(segment => {
      if (
        !filteredSegments.find(
          filteredSegment =>
            getSegmentId(segment) === getSegmentId(filteredSegment)
        )
      ) {
        filteredSegments.push(segment);
      }
    });
    return filteredSegments;
  };

  mousePos = e => {
    const canvas = this.props.map.getCanvas();
    const rect = canvas.getBoundingClientRect();
    return new mapboxgl.Point(
      e.clientX - rect.left - canvas.clientLeft,
      e.clientY - rect.top - canvas.clientTop
    );
  };

  mouseDownSelect = e => {
    // check if they clicked on a feature
    const position = this.mousePos(e);
    const bbox = [
      [position.x - 5, position.y - 5],
      [position.x + 5, position.y + 5],
    ];

    const map = this.props.map;
    const features = map.queryRenderedFeatures(bbox);
    // user shift clicked on a segmentFeature if this is true
    const segmentFeature = features.find(
      feature =>
        feature.layer.metadata &&
        (feature.layer.metadata.clickBehavior ===
          clickBehaviorConstants.roadwaySegment ||
          feature.layer.metadata.clickBehavior ===
            clickBehaviorConstants.roadwaySidewalkSegment)
    );
    if (segmentFeature) {
      // check if already selected this segmentFeature
      const segmentId = makeSegment(segmentFeature).id;

      const {
        addSegment,
        showHighlight,
        segments,
        removeSegment,
        isModalOpen,
        highlightType,
        openModal,
      } = this.props;
      if (!segments.find(segment => makeSegment(segment).id === segmentId)) {
        addSegment(segmentFeature);
        showHighlight(SEGMENTS);
        if (!isModalOpen || highlightType !== SEGMENTS) {
          openModal({
            type: "dynamicSegmentation",
            segmentsData: {
              content: {
                averageRating: calculateAverageRating([segmentFeature]),
                length: calculateTotalLength([segmentFeature]),
                chartData: this.calculateChartData([segmentFeature]),
                segmentDistressStatistics: calculateCombinedIDIData([
                  segmentFeature,
                ]),
              },
            },
            segmentData: {},
            pointData: {},
          });
        }
      } else {
        removeSegment(segmentId);
      }
    }
  };

  mouseDown = e => {
    // Capture the first xy coordinates
    this.setState({
      start: this.mousePos(e),
    });

    // Call functions for the following events
    // probably shouldn't call this on every mouseDown?
    document.addEventListener("mousemove", this.onMouseMove);
    document.addEventListener("mouseup", this.onMouseUp);
    document.addEventListener("keydown", this.onKeyDown);
  };

  onMouseMove = e => {
    // Capture the ongoing xy coordinates
    this.setState({
      current: this.mousePos(e),
    });
    let { box } = this.state;
    // need to use .getCanvasContainer() over getCanvas() for the bounding box to show up properly
    const canvasContainer = this.props.map.getCanvasContainer();

    // Append the box element if it doesnt exist
    if (!box) {
      box = document.createElement("div");
      box.classList.add("boxdraw");
      canvasContainer.appendChild(box);
    }

    const { start, current } = this.state;
    const minX = Math.min(start.x, current.x);
    const maxX = Math.max(start.x, current.x);
    const minY = Math.min(start.y, current.y);
    const maxY = Math.max(start.y, current.y);

    // Adjust width and xy position of the box element ongoing
    const pos = `translate(${minX}px,${minY}px)`;
    box.style.transform = pos;
    box.style.WebkitTransform = pos;
    box.style.width = `${maxX - minX}px`;
    box.style.height = `${maxY - minY}px`;
    this.setState({ box });
  };

  onMouseUp = e => {
    this.finish([this.state.start, this.mousePos(e)]);
  };

  onKeyDown = e => {
    // If the ESC key is pressed
    if (e.keyCode === 27) this.finish();
  };

  finish = bbox => {
    // means the user only clicked on something
    // Remove these events now that finish has been called.
    document.removeEventListener("mousemove", this.onMouseMove);
    document.removeEventListener("keydown", this.onKeyDown);
    document.removeEventListener("mouseup", this.onMouseUp);

    const { box } = this.state;

    if (box) {
      box.parentNode.removeChild(box);
      this.setState({
        box: null,
      });
    }

    // If bbox exists. use this value as the argument for `queryRenderedFeatures`
    if (bbox) {
      // prevents stray clicks from deleting all segments
      if (bbox[0].x === bbox[1].x && bbox[0].y === bbox[1].y) return;

      const map = this.props.map;
      const features = map.queryRenderedFeatures(bbox);
      // probably a cleaner way to see whether a feature is a segment feature
      const segmentFeatures = this.filterDuplicateSegments(
        features.filter(
          feature =>
            feature.layer.metadata &&
            (feature.layer.metadata.clickBehavior ===
              clickBehaviorConstants.roadwaySegment ||
              feature.layer.metadata.clickBehavior ===
                clickBehaviorConstants.roadwaySidewalkSegment)
        )
      );

      const { setSegments, showHighlight, openModal } = this.props;
      setSegments(segmentFeatures);
      showHighlight(SEGMENTS);

      if (segmentFeatures.length > 0) {
        openModal({
          type: "dynamicSegmentation",
          segmentsData: {
            content: {
              averageRating: calculateAverageRating(segmentFeatures),
              length: calculateTotalLength(segmentFeatures),
              chartData: this.calculateChartData(segmentFeatures),
              segmentDistressStatistics: calculateCombinedIDIData(
                segmentFeatures
              ),
            },
          },
          segmentData: {},
          pointData: {},
        });
      }
    }
    this.props.toggleDynamicSegmentationDrag();
  };

  grabPolygonSegments = () => {
    const { map, setSegments, showHighlight, openModal, draw } = this.props;
    const data = draw.getAll();
    if (data.features.length > 0) {
      // Get the polygon's feature
      const polygon = data.features[0];

      // Since we cannot query on the polygon, grab the bbox so we can get close
      // to the right answer.
      const polygonBoundingBox = calcbbox(polygon);

      const southWest = [polygonBoundingBox[0], polygonBoundingBox[1]];
      const northEast = [polygonBoundingBox[2], polygonBoundingBox[3]];
      const northEastPointPixel = map.project(northEast);
      const southWestPointPixel = map.project(southWest);
      const features = this.props.map.queryRenderedFeatures([
        southWestPointPixel,
        northEastPointPixel,
      ]);

      // Filter by just the types we want to look at.
      const segmentFeatures = this.filterDuplicateSegments(
        features.filter(
          feature =>
            feature.layer.metadata &&
            (feature.layer.metadata.clickBehavior ===
              clickBehaviorConstants.roadwaySegment ||
              feature.layer.metadata.clickBehavior ===
                clickBehaviorConstants.roadwaySidewalkSegment)
        )
      );

      const checkIfPointsInside = (line, polygon) => {
        // Check if any of the line segments in the polygon
        const segVals = JSON.parse(line.properties.segment);
        const coords = zip(segVals["longitude"], segVals["latitude"]);

        // Return true if any of the segments evaluate as true; otherwise, false.
        return coords.some((coord, idx) => {
          if (idx + 1 !== coords.length) {
            const lineSeg = lineString([coord, coords[idx + 1]]);
            const intersectRetVal = lineIntersect(lineSeg, polygon);
            const withinRetVal = booleanWithin(lineSeg, polygon);
            if (intersectRetVal.features.length !== 0 || withinRetVal) {
              return true;
            }
          }
          return false;
        });
      };

      // Filter out the extraneous features that aren't inside the polygon by at least one point.
      const intersectFeatures = segmentFeatures.filter(feature =>
        checkIfPointsInside(feature, polygon)
      );

      setSegments(intersectFeatures);
      showHighlight(SEGMENTS);

      if (intersectFeatures.length > 0) {
        openModal({
          type: "dynamicSegmentation",
          segmentsData: {
            content: {
              averageRating: calculateAverageRating(intersectFeatures),
              length: calculateTotalLength(intersectFeatures),
              chartData: this.calculateChartData(intersectFeatures),
              segmentDistressStatistics: calculateCombinedIDIData(
                intersectFeatures
              ),
            },
          },
          segmentData: {},
          pointData: {},
        });
      }
    }
  };

  calculateChartData = segmentFeatures => {
    const allRatings = [];
    segmentFeatures.forEach(segmentFeature => {
      const ratings = JSON.parse(segmentFeature.properties.ratings);
      allRatings.push(...ratings);
    });
    const segmentName = "Dynamic Segmentation";
    return chartData(allRatings, segmentName, this.props.colorPicker);
  };

  openOptions = e => {
    const { tracking, userUId } = this.props;

    tracking.trackEvent({
      event: "mouse-click",
      action: "turn-on-dynamic-segmentation",
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `turn on dynamic segmentation`,
      level: Sentry.Severity.Info,
    });

    this.setState({
      optionsOpen: true,
      anchorEl: e.currentTarget,
    });
  };

  closeOptions = () => {
    const { tracking, userUId } = this.props;

    tracking.trackEvent({
      event: "mouse-click",
      action: "turn-off-dynamic-segmentation",
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `turn off dynamic segmentation`,
      level: Sentry.Severity.Info,
    });

    this.setState({
      optionsOpen: false,
      anchorEl: null,
    });
  };

  decideWhetherToToggleAllSegments() {
    const {
      toggleGroups,
      layerGroups,
      userUId,
      tracking,
      hideLayer,
      showLayer,
      activeScan,
    } = this.props;

    const currentToggleGroups = getCurrentToggleGroups(toggleGroups);
    if (currentToggleGroups.length > 0) {
      const toggleGroupIdSegments = currentToggleGroups.find(
        toggleGroup =>
          toggleGroup.name === "segments" ||
          toggleGroup.name === "sidewalkSegments"
      ).id;

      if (toggleGroupIdSegments.length > 0) {
        const anySegmentsToggled = anyTogglesToggled({
          toggleGroupId: toggleGroupIdSegments,
          toggleGroups,
          layerGroups,
        });

        if (!anySegmentsToggled) {
          toggleToggleGroup({
            toggleGroupId: toggleGroupIdSegments,
            tracking,
            userUId,
            hideLayer,
            showLayer,
            toggleGroups,
            layerGroups,
            activeScan,
          });
        }
      }
    }
  }

  showDynamicSegmentationTool() {
    const { layerGroups } = this.props;
    return !layerGroups.find(
      layerGroup =>
        layerGroup.clickBehavior ===
        clickBehaviorConstants.roadwayRoadSensePoint
    );
  }

  render() {
    const {
      classes,
      open,
      action,
      toggleDynamicSegmentationDrag,
      toggleDynamicSegmentationSelect,
      toggleDynamicSegmentationLasso,
      isDynamicSegmentationDragActive,
      isDynamicSegmentationSelectActive,
      isDynamicSegmentationLassoActive,
      toggleGroups,
    } = this.props;

    let toggleGroupSegments = [];
    if (toggleGroups.length > 0) {
      toggleGroupSegments =
        toggleGroups.find(
          toggleGroup =>
            toggleGroup.name === "segments" ||
            toggleGroup.name === "sidewalkSegments"
        ) || [];
    }

    return (
      <Fragment>
        {this.showDynamicSegmentationTool() ? (
          <div>
            <SpeedDialAction
              className={classes.speedDialAction}
              icon={action.icon}
              tooltipPlacement="right"
              tooltipTitle={action.title}
              open={open}
              onClick={this.openOptions}
            />
            <Popover
              className={classes.popover}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              anchorEl={this.state.anchorEl}
              onClose={this.closeOptions}
              open={this.state.optionsOpen}
              PaperProps={{
                style: { backgroundColor: "rgba(255,255,255,.6)" },
              }}
            >
              <Button
                disabled={toggleGroupSegments.length <= 0}
                className={classes.optionsBtn}
                fullWidth={true}
                onClick={() => {
                  this.closeOptions();
                  if (
                    this.props.isMeasuring ||
                    this.props.isAreaing ||
                    this.props.isDynamicSegmentationLassoActive
                  ) {
                    this.props.clearMap();
                  }
                  toggleDynamicSegmentationDrag();
                }}
              >
                Click and Drag &nbsp;
                <SelectAllIcon
                  style={{
                    color: isDynamicSegmentationDragActive ? "#f77400" : "",
                  }}
                />
              </Button>
              <br />
              <Button
                disabled={toggleGroupSegments.length <= 0}
                className={classes.optionsBtn}
                fullWidth={true}
                onClick={() => {
                  this.closeOptions();
                  if (this.props.isMeasuring || this.props.isAreaing) {
                    this.props.clearMap();
                  }
                  if (this.props.isDynamicSegmentationLassoActive) {
                    this.props.clearMap(true, true);
                  }
                  toggleDynamicSegmentationLasso();
                }}
              >
                Polygon Lasso &nbsp;
                <BorderClearIcon
                  style={{
                    color: isDynamicSegmentationLassoActive ? "#f77400" : "",
                  }}
                />
              </Button>
              <br />
              <Button
                disabled={toggleGroupSegments.length <= 0}
                className={classes.optionsBtn}
                fullWidth={true}
                onClick={() => {
                  this.closeOptions();
                  if (
                    this.props.isMeasuring ||
                    this.props.isAreaing ||
                    this.props.isDynamicSegmentationLassoActive
                  ) {
                    this.props.clearMap();
                  }
                  toggleDynamicSegmentationSelect();
                }}
              >
                Select &nbsp;
                <TouchAppIcon
                  style={{
                    color: isDynamicSegmentationSelectActive ? "#f77400" : "",
                  }}
                />
              </Button>
            </Popover>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

DynamicSegmentationContainer.defaultProps = {
  toggleGroups: [],
};

DynamicSegmentationContainer.propTypes = {
  setSegments: PropTypes.func.isRequired,
  addSegment: PropTypes.func.isRequired,
  removeSegment: PropTypes.func.isRequired,
  showHighlight: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  updateModal: PropTypes.func.isRequired,
  toggleDynamicSegmentationDrag: PropTypes.func.isRequired,
  toggleDynamicSegmentationSelect: PropTypes.func.isRequired,
  toggleDynamicSegmentationLasso: PropTypes.func.isRequired,
  segments: PropTypes.arrayOf(PropTypes.object).isRequired,
  isDynamicSegmentationDragActive: PropTypes.bool.isRequired,
  isDynamicSegmentationSelectActive: PropTypes.bool.isRequired,
  isDynamicSegmentationLassoActive: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  map: PropTypes.object.isRequired,
  action: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  colorPicker: PropTypes.object.isRequired,
  modalData: PropTypes.object.isRequired,
  highlightType: PropTypes.string.isRequired,
  toggleGroups: PropTypes.array,
  layerGroups: PropTypes.array.isRequired,
  userUId: PropTypes.string.isRequired,
  activeScan: PropTypes.string.isRequired,
  tracking: PropTypes.shape({
    trackEvent: PropTypes.func.isRequired,
  }).isRequired,
  showLayer: PropTypes.func.isRequired,
  hideLayer: PropTypes.func.isRequired,
  clearMap: PropTypes.func.isRequired,
  isMeasuring: PropTypes.bool.isRequired,
  isAreaing: PropTypes.bool.isRequired,
  draw: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  segments: state.highlight.segments,
  isDynamicSegmentationDragActive: state.dynamicSegmentation.isDragActive,
  isDynamicSegmentationSelectActive: state.dynamicSegmentation.isSelectActive,
  isDynamicSegmentationLassoActive: state.dynamicSegmentation.isLassoActive,
  isAreaing: state.measureArea.isAreaing,
  isMeasuring: state.measureDistance.isMeasuring,
  colorPicker: state.colorPicker,
  modalData: state.modal.modalData,
  isModalOpen: state.modal.isModalOpen,
  highlightType: state.highlight.highlightType,
  layerGroups: state.layers.groups,
  toggleGroups: state.toggles.groups,
  userUId: state.user.userUId,
  activeScan: state.userData.activeScan,
  draw: state.measureArea.draw,
});

const mapDispatchToProps = dispatch => ({
  setSegments: segments => dispatch(setSegments(segments)),
  addSegment: segment => dispatch(addSegment(segment)),
  removeSegment: segmentId => dispatch(removeSegment(segmentId)),
  showHighlight: highlightType => dispatch(showHighlight(highlightType)),
  openModal: modalData => dispatch(openModal(modalData)),
  updateModal: modalData => dispatch(updateModal(modalData)),
  toggleDynamicSegmentationDrag: () =>
    dispatch(toggleDynamicSegmentationDrag()),
  toggleDynamicSegmentationSelect: () =>
    dispatch(toggleDynamicSegmentationSelect()),
  toggleDynamicSegmentationLasso: () =>
    dispatch(toggleDynamicSegmentationLasso()),
  showLayer: layerId => dispatch(showLayer(layerId)),
  hideLayer: layerId => dispatch(hideLayer(layerId)),
});

export default track()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(DynamicSegmentationContainer))
);
