import React from "react";
import PropTypes from "prop-types";

import MenuItem from "@material-ui/core/MenuItem";

import Dialog from "components/Dialog/Dialog";
import TextField from "components/form/TextField";
import Select from "components/form/Select";
import ActivityIndicatorButton from "components/ActivityIndicatorButton";

import { useForm, Controller } from "react-hook-form";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  formControl: {
    width: "100%",
  },
});

const ScansAdminUpdateFormDialog = ({
  title,
  isOpen,
  organizations,
  handleClose,
  handleUpdate,
  values: initialFormState,
  isUpdateActive,
}) => {
  const { register, errors, control, handleSubmit } = useForm({
    defaultValues: initialFormState,
  });
  const classes = useStyles();

  const handleFormSubmit = e => {
    e.preventDefault();
    handleSubmit(data => {
      // have to expand the initialFormState here so the other initial values can be set correctly
      handleUpdate({ ...initialFormState, ...data });
    })(e);
  };

  const renderForm = () => (
    <form onSubmit={handleFormSubmit}>
      <div>
        <TextField
          label="Scan Display Name"
          name="displayName"
          inputRef={register({
            required: "This field is required.",
          })}
          error={errors?.displayName !== undefined}
          helperText={errors?.displayName?.message}
        />
        <TextField
          label="Default Min Zoom Level"
          name="defaultMinZoom"
          type="number"
          inputRef={register({
            required: "This field is required.",
          })}
          error={errors?.defaultMinZoom !== undefined}
          helperText={errors?.defaultMinZoom?.message}
        />
        <TextField
          label="Default Max Zoom Level"
          name="defaultMaxZoom"
          type="number"
          inputRef={register({
            required: "This field is required.",
          })}
          error={errors?.defaultMaxZoom !== undefined}
          helperText={errors?.defaultMaxZoom?.message}
        />
        <FormControl
          error={errors?.organizationId !== undefined}
          className={classes.formControl}
        >
          <Controller
            name={"organizationId"}
            control={control}
            rules={{
              required: "This field is required",
            }}
            defaultValue={initialFormState?.organizationId}
            as={
              <Select displayEmpty name="organizationId">
                <MenuItem value="" disabled>
                  Select Organization
                </MenuItem>
                {organizations &&
                  organizations
                    .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase())
                    .map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
              </Select>
            }
          />
          <FormHelperText>{errors?.organizationId?.message}</FormHelperText>
        </FormControl>
      </div>
    </form>
  );

  const renderFormActions = () => (
    <ActivityIndicatorButton
      onClick={handleFormSubmit}
      isActive={isUpdateActive}
    >
      Submit
    </ActivityIndicatorButton>
  );

  return (
    <Dialog
      title={title}
      isOpen={isOpen}
      handleClose={handleClose}
      actions={renderFormActions()}
    >
      {renderForm()}
    </Dialog>
  );
};

ScansAdminUpdateFormDialog.defaultProps = {
  title: "",
  isOpen: false,
  handleClose: () => {},
};

ScansAdminUpdateFormDialog.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  organizations: PropTypes.array.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  isUpdateActive: PropTypes.bool.isRequired,
};

export default ScansAdminUpdateFormDialog;
