import React from "react";
import IconButton from "@material-ui/core/IconButton/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import PointAnnotationsDialog from "components/PointAnnotations/PointAnnotationsDialog";
import ActivityIndicatorButton from "../ActivityIndicatorButton";
import Grid from "@material-ui/core/Grid";
import { startCase, camelCase } from "lodash";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { AnnotationOptionType } from "constants/pointAnnotation";
import { RB_WHITESMOKE } from "constants/colors";
import PointAnnotationOptions from "components/PointAnnotations/PointAnnotationOptions";

const useStyles = makeStyles({
  cardRoot: {
    backgroundColor: RB_WHITESMOKE,
  },
  deleteIcon: {
    padding: "0px",
  },
  select: {
    width: "95%",
    margin: "5px 0",
    lineHeight: 1,
    padding: 0,
  },
  popper: {
    backgroundColor: "#F0F0F0",
    "& .MuiAutocomplete-option": {
      borderBottom: "1px solid #E0E0E0",
    },
  },
  signSelect: {
    width: "95%",
    marginTop: "14px",
    lineHeight: 1,
    padding: 0,
  },
  signImg: {
    width: "10%",
    marginRight: "10px",
  },
  button: {
    marginTop: "10px",
    float: "right",
  },
  datePicker: {
    margin: 0,
    width: "95%",
  },
  input: {
    padding: "10px 15px",
  },
  divContainer: {
    marginBottom: "65px",
  },
});
interface headerInt {
  [key: string]: { value: string };
}

interface PointAnnotationsAutoCompleteProps {
  textFieldError: string;
  submitAnnotation: () => void;
  isAnnotationBeingSaved: boolean;
  dropdownOptions: any;
  userAnnotations: headerInt;
  setDropdownOptions: (options: any) => void;
  setUserAnnotations: (newState: headerInt) => void;
  addField: (field: string) => void;
  deleteField: (field: string) => void;
  deleteOption: (option: string, optionKey: string) => void;
  addOption: (option: string, optionKey: string) => void;
  fieldName: string;
  setFieldName: (fieldName: string) => void;
  isDialogOpen: boolean;
  openDialog: () => void;
  closeDialog: () => void;
  setIsFormClean: (answer: boolean) => void;
}

const filter = createFilterOptions<AnnotationOptionType>();

const prefix = "Add";
const stringIndex = 4;
let isDeletingOptions = false;

const PointAnnotationsAutoComplete = ({
  addField,
  deleteField,
  addOption,
  deleteOption,
  submitAnnotation,
  textFieldError,
  setDropdownOptions,
  userAnnotations,
  setUserAnnotations,
  isAnnotationBeingSaved,
  fieldName,
  setFieldName,
  isDialogOpen,
  openDialog,
  closeDialog,
  dropdownOptions,
  setIsFormClean,
}: PointAnnotationsAutoCompleteProps): React.ReactElement => {
  const classes = useStyles();
  const isDeletingOptionsToTrue = () => {
    isDeletingOptions = true;
  };

  return (
    <form>
      <Grid container spacing={2}>
        {Object.keys(dropdownOptions)
          .sort(function(a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          })
          .map(optionKey => {
            return (
              dropdownOptions[optionKey].display && (
                <Grid key={optionKey} item md={6}>
                  <Card className={classes.cardRoot}>
                    <CardContent>
                      <Grid
                        container
                        alignItems="center"
                        justify="space-between"
                      >
                        <Typography>
                          {startCase(camelCase(optionKey))}
                        </Typography>
                        {dropdownOptions[optionKey].enableDelete && (
                          <IconButton
                            id={optionKey}
                            classes={{ root: classes.deleteIcon }}
                            onClick={(): void => {
                              setDropdownOptions({
                                ...dropdownOptions,
                                [optionKey]: { display: false },
                              });
                              deleteField(optionKey);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Grid>
                      <Autocomplete
                        selectOnFocus
                        autoSelect={true}
                        ListboxProps={{
                          className: classes.popper,
                        }}
                        value={{
                          name: userAnnotations[optionKey]?.value || "",
                          id: optionKey,
                          enableDelete: true,
                        }}
                        id={`autocomplete-option-${optionKey}`}
                        onChange={(_e: any, _reason: any): void => {
                          setIsFormClean(false);
                          const newAnnotations = { ...userAnnotations };
                          if (_reason && !isDeletingOptions) {
                            newAnnotations[optionKey] = {
                              value: _reason.id,
                            };
                          } else {
                            delete newAnnotations[optionKey];
                          }
                          setUserAnnotations(newAnnotations);
                        }}
                        onInputChange={(_e, option, _reason) => {
                          if (option) {
                            if (option.slice(0, 3) === prefix) {
                              addOption(option.slice(stringIndex), optionKey);
                            }
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(
                            options,
                            params
                          ) as AnnotationOptionType[];

                          isDeletingOptions = false;

                          if (filtered.length < 1 && params.inputValue !== "") {
                            filtered.push({
                              id: params.inputValue,
                              name: `${prefix} ${params.inputValue}`,
                              enableDelete: true,
                            });
                          }
                          return filtered;
                        }}
                        options={dropdownOptions[optionKey].options || []}
                        className={classes.select}
                        getOptionLabel={option =>
                          startCase(camelCase(option.name))
                        }
                        renderOption={option => (
                          <PointAnnotationOptions
                            option={option}
                            optionKey={optionKey}
                            prefix={prefix}
                            stringIndex={stringIndex}
                            isDeletingOptionsToTrue={isDeletingOptionsToTrue}
                            deleteOption={deleteOption}
                          />
                        )}
                        renderInput={params => (
                          <TextField
                            margin="dense"
                            {...params}
                            label="Add Field"
                            variant="outlined"
                          />
                        )}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              )
            );
          })}
      </Grid>

      <Grid container>
        <Grid item sm={3}>
          <PointAnnotationsDialog
            textFieldError={textFieldError}
            addField={addField}
            fieldName={fieldName}
            setFieldName={setFieldName}
            isDialogOpen={isDialogOpen}
            openDialog={openDialog}
            closeDialog={closeDialog}
          />
        </Grid>
        <Grid item sm={7} />
        <Grid item sm={2}>
          <ActivityIndicatorButton
            className={classes.button}
            onClick={e => {
              e.preventDefault();
              setIsFormClean(true);
              submitAnnotation();
            }}
            isActive={isAnnotationBeingSaved}
          >
            Save
          </ActivityIndicatorButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default PointAnnotationsAutoComplete;
