export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const ENLARGE_IMG = "ENLARGE_IMG";
export const SHRINK_IMG = "SHRINK_IMG";
export const UPDATE_MODAL = "UPDATE_MODAL";
export const RESET_MODAL = "RESET_MODAL";
export const SET_PLAY = "SET_PLAY";
export const RESET_PLAY = "RESET_PLAY";
export const NO_POINTS_MODAL = "NO_POINTS_MODAL";
export const SET_MODAL_TAB = "SET_MODAL_TAB";
