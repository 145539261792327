import React from "react";
import Switch from "@material-ui/core/Switch";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const defaultTheme = createMuiTheme({
  typography: { useNextVariants: true },
});

const DynamicSwitch = ({ color, onChange, checked }) => (
  <MuiThemeProvider
    theme={
      color
        ? {
            ...defaultTheme,
            overrides: {
              ...defaultTheme.overrides,
              MuiSwitch: {
                colorPrimary: {
                  color: "#FFF",
                  "&$checked": {
                    color,
                    "& + $bar": {
                      backgroundColor: color,
                    },
                  },
                },
              },
            },
          }
        : defaultTheme
    }
  >
    <Switch color="primary" onChange={onChange} checked={checked} />
  </MuiThemeProvider>
);

DynamicSwitch.propTypes = {
  color: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};

export default DynamicSwitch;
