import React from "react";
import PropTypes from "prop-types";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import makeStyles from "@material-ui/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import ChildOrgGrid from "../ChildOrg/ChildOrgGrid";

const useStyles = makeStyles({
  header: {
    borderBottom: "1px #000 solid",
  },
  button: {
    width: "50%",
  },
  card: {
    backgroundColor: "#f5f5f5",
    boxShadow: "none",
  },
});

const OrganizationSelectorGridView = props => {
  const classes = useStyles(props);
  const { organization, setActiveScan } = props;
  return (
    <Card className={classes.card}>
      <CardContent>
        <CardHeader
          className={classes.header}
          title={
            <Typography variant="h4" align="left">
              <strong>{organization.name}</strong>
            </Typography>
          }
        />
        <br />

        {organization.childrenOrganizations && (
          <ChildOrgGrid
            childOrgs={
              organization.scans.length > 0
                ? [...organization.childrenOrganizations, organization]
                : organization.childrenOrganizations
            }
            parentName={organization.name}
            setActiveScan={setActiveScan}
          />
        )}
        {!organization.childrenOrganizations &&
          organization.scans.length > 0 && (
            <ChildOrgGrid
              childOrgs={[organization]}
              setActiveScan={setActiveScan}
            />
          )}
        {!organization.childrenOrganizations &&
          organization.scans.length === 0 && (
            <Typography align="center" variant="h4">
              There are currently no projects to display.
            </Typography>
          )}
      </CardContent>
    </Card>
  );
};

OrganizationSelectorGridView.defaultProps = {
  organization: null,
};

OrganizationSelectorGridView.propTypes = {
  organization: PropTypes.object,
  setActiveScan: PropTypes.func.isRequired,
};

export default OrganizationSelectorGridView;
