import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Drawer from "@material-ui/core/Drawer";
import { RB_YELLOW } from "../constants/colors";
import { closeTopBanner } from "../actions/visibility";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { uiConstants } from "../constants/ui";

export const topBannerHeight = 80;
const useStyles = makeStyles({
  paper: {
    left: uiConstants.osDrawerWidth,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    height: `${topBannerHeight}px`,
    background: RB_YELLOW,
  },
  notification: {
    fontFamily: "'Open Sans',sans-serif",
    fontSize: "20px",
    fontWeight: 800,
    padding: "25px",
    paddingLeft: "75px",
    flexGrow: 1,
    textAlign: "center",
  },
  cancel: {
    justifyContent: "flex-end",
    margin: "10px",
    flexGrow: 0,
  },
});

interface PersistentBannerInterface {
  text: string;
}
type State = {
  visibility: {
    isTopBannerOpen: boolean;
  };
};
const PersistentBanner: React.FC<PersistentBannerInterface> = ({ text }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isTopBannerOpen = useSelector(
    (state: State) => state.visibility.isTopBannerOpen
  );

  const handleDrawerClose = (): void => {
    dispatch(closeTopBanner());
  };

  return (
    <Drawer
      anchor={"top"}
      open={isTopBannerOpen}
      variant="persistent"
      PaperProps={{ className: classes.paper }}
      transitionDuration={{
        exit: 0,
      }}
    >
      <p className={classes.notification}>{text}</p>
      <IconButton className={classes.cancel} onClick={handleDrawerClose}>
        <CloseIcon />
      </IconButton>
    </Drawer>
  );
};

export default PersistentBanner;
