import firebase from "config/firebase";
import { toggleColorBlindMode } from "../actions/colorPicker";
import {
  setHideExampleOrganizations,
  setMeasuringSystem,
} from "../actions/userData";
import store from "../store";
import { getAssessmentType } from "utils/getAssessmentType";
import { AssessmentTypes } from "constants/assessmentTypes";
import { setMapStyle } from "../actions/map";
import { MeasuringSystem } from "constants/measuringSystem";
import {
  MAP_STYLE_TOPOGRAPHIC_IMPERIAL,
  MAP_STYLE_TOPOGRAPHIC_METRIC,
} from "constants/mapStyles";

export const preferencesListener = (userId, mapStyle) => {
  const db = firebase.firestore();

  const unsubscribe = db
    .collection("users")
    .doc(userId)
    .collection("settings")
    .doc("preferences")
    .onSnapshot(doc => {
      const data = doc.data();
      if (data) {
        // have to use store.dispatch() since this is not a react component
        store.dispatch(toggleColorBlindMode(data.colorMode));
        store.dispatch(setMeasuringSystem(data.measuringSystem));
        store.dispatch(
          setHideExampleOrganizations(data.hideExampleOrganizations)
        );
        if (
          mapStyle === MAP_STYLE_TOPOGRAPHIC_IMPERIAL ||
          mapStyle === MAP_STYLE_TOPOGRAPHIC_METRIC
        ) {
          store.dispatch(
            setMapStyle(
              data.measuringSystem === MeasuringSystem.Imperial
                ? MAP_STYLE_TOPOGRAPHIC_IMPERIAL
                : MAP_STYLE_TOPOGRAPHIC_METRIC
            )
          );
        }

        // redirect to roadway beta if: not on beta site, on production, and beta user
        // can't just use REACT_APP_PROJECT_ID because both beta and prod use it
        // checking for not beta prevents a cyclical window.location.replace
        if (
          !process.env.REACT_APP_ACCOUNTS_DOMAIN.includes("beta") &&
          process.env.REACT_APP_PROJECT_ID === "roadbotics-roadway-api" &&
          data.isBetaUser
        ) {
          switch (getAssessmentType()) {
            case AssessmentTypes.NORMAL:
            case AssessmentTypes.PARKINGLOT:
              window.location.replace("https://roadway.beta.roadbotics.com");
              return;
            case AssessmentTypes.ROADSENSE:
              window.location.replace("https://roadsense.beta.roadbotics.com");
              return;
            default:
              return;
          }
        } else if (
          process.env.REACT_APP_ACCOUNTS_DOMAIN.includes("beta") &&
          process.env.REACT_APP_PROJECT_ID === "roadbotics-roadway-api" &&
          !data.isBetaUser
        ) {
          switch (getAssessmentType()) {
            case AssessmentTypes.NORMAL:
              window.location.replace("https://roadway.roadbotics.com");
              return;
            case AssessmentTypes.ROADSENSE:
              window.location.replace("https://roadsense.roadbotics.com");
              return;
            default:
              return;
          }
        }
      }
    });

  const unsubscribeWrapper = () => {
    unsubscribe();
  };

  return unsubscribeWrapper;
};
