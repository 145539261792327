import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// eslint-disable-next-line max-len
import { ReactComponent as DynamicSegmentationIconClose } from "images/icons/dynamic_segmentation_close.svg";
// eslint-disable-next-line max-len
import { ReactComponent as DynamicSegmentationIconOpen } from "images/icons/dynamic_segmentation_open.svg";

const style = { height: 20, width: 20 };

const DynamicSegmentationButtonContainer = ({
  isDynamicSegmentationActive,
}) => {
  if (isDynamicSegmentationActive) {
    return <DynamicSegmentationIconOpen style={style} />;
  } else {
    return <DynamicSegmentationIconClose style={style} />;
  }
};

DynamicSegmentationButtonContainer.propTypes = {
  isDynamicSegmentationActive: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isDynamicSegmentationActive:
    state.dynamicSegmentation.isDragActive ||
    state.dynamicSegmentation.isSelectActive ||
    state.dynamicSegmentation.isLassoActive,
});

export default connect(mapStateToProps)(DynamicSegmentationButtonContainer);
