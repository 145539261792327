import produce from "immer";
import {
  ADD_TOGGLE,
  ADD_TOGGLE_GROUP,
  CLEAR_TOGGLE_GROUPS,
} from "actiontypes/toggles";

const defaultState = {
  groups: [],
};

const toggles = (state = defaultState, action) => {
  switch (action.type) {
    case ADD_TOGGLE_GROUP:
      return produce(state, draft => {
        draft.groups.push({
          id: action.id,
          displayName: action.displayName,
          name: action.name,
          style: action.style,
          toggleAll: action.toggleAll,
          tooltipInfo: action.tooltipInfo,
          toggles: [],
        });
      });
    case ADD_TOGGLE:
      return produce(state, draft => {
        const toggleGroup = draft.groups.find(
          toggleGroup => toggleGroup.id === action.toggleGroupId
        );
        if (
          toggleGroup.name === "sidewalkPoints" ||
          toggleGroup.name === "sidewalkSegments"
        ) {
          action.layerType = "sidewalk";
        }
        toggleGroup.toggles.push({
          id: action.id,
          displayName: action.displayName,
          layerId: action.layerId,
          toggleable: action.toggleable,
          color: action.color,
          position: action.position,
          layerType: action.layerType,
        });
      });
    case CLEAR_TOGGLE_GROUPS:
      return defaultState;
    default:
      return state;
  }
};

export default toggles;
