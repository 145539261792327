import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IndeterminateCheckBoxOutlinedIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import flattenDeep from "lodash/flattenDeep";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { cloneDeep } from "lodash";
import Organization from "interfaces/organization";
import IconButton from "@material-ui/core/IconButton/IconButton";
import OrgTreeSkeleton from "../skeletons/organizationTree";

const useStyles = makeStyles({
  minimizeButton: {
    display: "inline-block",
    padding: 7,
    paddingLeft: 0,
    minHeight: 0,
    minWidth: 0,
  },
  treeView: {
    maxHeight: "80vh",
    overflow: "scroll",
    paddingLeft: 4,
    overflowX: "hidden",
  },
  treeParentText: {
    fontWeight: "bold",
  },
  treeChildText: {
    fontWeight: "normal",
    display: "list-item",
    listStyleType: "disc",
    listStylePosition: "inside",
  },
});

interface OrganizationTree {
  rootOrganizations: Organization[];
  isLoading: boolean;
  handleOrganizationClick: Function;
}

let treeItemTextClicked = false;

const getTreeItemsFromOrganizations = (
  handleOrganizationClick: Function,
  organizations: Organization[],
  classes: Record<
    "minimizeButton" | "treeView" | "treeParentText" | "treeChildText",
    string
  >
): React.ReactElement[] => {
  return organizations.map(organization => {
    let children;
    if (
      organization?.childrenOrganizations &&
      organization?.childrenOrganizations?.length > 0
    ) {
      children = getTreeItemsFromOrganizations(
        handleOrganizationClick,
        organization.childrenOrganizations,
        classes
      );
    }
    const treeItemStyle = children
      ? classes.treeParentText
      : classes.treeChildText;
    return (
      <TreeItem
        key={organization.id}
        nodeId={organization.id}
        label={
          <Typography
            className={treeItemStyle}
            onClick={(): void => {
              treeItemTextClicked = true;
              handleOrganizationClick(organization);
            }}
          >
            {organization.name}
          </Typography>
        }
      >
        {children}
      </TreeItem>
    );
  });
};

export const getIdsOfParents = (organizations: Organization[]): string[] => {
  const accumulatedIds = organizations.map(organization => {
    const ids = [];
    if (
      organization?.childrenOrganizations &&
      organization?.childrenOrganizations?.length > 0
    ) {
      ids.push(organization.id);
      ids.push(getIdsOfParents(organization.childrenOrganizations));
    }
    return ids;
  });
  return flattenDeep(accumulatedIds);
};

const filterData = (
  rootOrg: Organization[] = [],
  filterString: string
): Organization[] => {
  return rootOrg.filter((org: Organization) => {
    if (org?.childrenOrganizations)
      org.childrenOrganizations = filterData(
        org.childrenOrganizations,
        filterString
      );
    // check if they organization matches the filter string OR
    // if it contains children organizations that match the string
    return (
      org.name?.toLowerCase().includes(filterString.toLowerCase()) ||
      (org.childrenOrganizations && org.childrenOrganizations.length !== 0)
    );
  });
};

export const filterOrganizations = (
  rootOrgs: Organization[],
  filterString: string
): Organization[] => {
  if (filterString === "") {
    return rootOrgs;
  } else {
    const clonedRootOrgs = cloneDeep(rootOrgs);
    return filterData(clonedRootOrgs, filterString);
  }
};

const OrgTree: React.FC<OrganizationTree> = ({
  isLoading,
  rootOrganizations,
  handleOrganizationClick,
}) => {
  const classes = useStyles();
  const [searchOrg, setSearchOrg] = useState("");
  const [organizations, setOrganizations] = useState(rootOrganizations);
  const parentIds = getIdsOfParents(organizations);
  const [expanded, setExpanded] = useState(parentIds);
  const rootOrgIds = rootOrganizations.map(org => org.id);
  const areCollapsableChildrenPresent = !(
    rootOrgIds.length === parentIds.length
  );

  useEffect(() => {
    const filter = filterOrganizations(rootOrganizations, searchOrg);
    // have to setExpanded again with the filtered orgs
    // otherwise orgs could end up collapsed again after done searching
    setExpanded(getIdsOfParents(filter));
    setOrganizations(filter);
  }, [searchOrg, rootOrganizations]);

  const handleSearch = (filterString: string): void => {
    setSearchOrg(filterString);
  };

  const handleChange = (_event: object, nodeIds: string[]): void => {
    if (!treeItemTextClicked) {
      setExpanded(nodeIds);
    }
    treeItemTextClicked = false;
  };

  return (
    <div>
      {!isLoading ? (
        <div>
          <Grid container alignItems="flex-end">
            <Grid item>
              <Tooltip
                placement="top-start"
                title="Collapse all child organizations"
              >
                <IconButton
                  // disable the button when there are no "grandchildren"
                  // nodes to hide
                  disabled={!areCollapsableChildrenPresent}
                  className={classes.minimizeButton}
                  onClick={(e): void => {
                    handleChange(e, rootOrgIds);
                  }}
                >
                  <IndeterminateCheckBoxOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <TextField
                label="Search"
                type="search"
                margin="normal"
                value={searchOrg}
                key="orgSearch"
                onChange={(e): void => handleSearch(e.target.value)}
              />
            </Grid>
          </Grid>
          <TreeView
            id={`tree-view`}
            className={classes.treeView}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            onNodeToggle={handleChange}
          >
            {getTreeItemsFromOrganizations(
              handleOrganizationClick,
              organizations,
              classes
            )}
          </TreeView>
        </div>
      ) : (
        <OrgTreeSkeleton />
      )}
    </div>
  );
};
export default OrgTree;
