import { STOP_ROTATION, START_ROTATION } from "actiontypes/rotation";
import { setMapBearing, setMapPitch, setMapZoom } from "actions/map";
import { clearAllArea } from "actions/measureArea";
import { clearAllMeasure } from "actions/measureDistance";
import { DEFAULT_MAP_PITCH, DEFAULT_MAP_BEARING } from "constants/map";

export function stopRotation() {
  return {
    type: STOP_ROTATION,
  };
}

export function startRotation() {
  return {
    type: START_ROTATION,
  };
}

export function onPresentation() {
  return dispatch => {
    dispatch(clearAllArea());
    dispatch(clearAllMeasure());
    dispatch(setMapPitch(60));
    dispatch(setMapZoom([16]));
    dispatch(startRotation());
  };
}

export function offPresentation() {
  return dispatch => {
    dispatch(setMapPitch(DEFAULT_MAP_PITCH));
    dispatch(setMapBearing(DEFAULT_MAP_BEARING));
    dispatch(stopRotation());
  };
}
