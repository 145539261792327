import { makeStyles } from "@material-ui/core";
import { RB_LIGHT_GREY, RB_YELLOW } from "constants/colors";
import React, { Dispatch, FC } from "react";
import AnalysisSlider from "./AnalysisSlider";
import RadioButtonsGroup, { RadioValue } from "./form/RadioGroup";
import { useTracking } from "react-tracking";

const useStyles = makeStyles({
  slidersContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
  },
});

export enum PCI {
  pci = "HD-PCI",
}

export type PCIRanges = {
  title: PCI;
  initialRange: number[];
  value: number[];
  checked: boolean;
};

interface PCISlidersProps {
  setPCI: Dispatch<PCIRanges[]>;
  pci: PCIRanges[];
}

const PciSlider: FC<PCISlidersProps> = props => {
  const { setPCI, pci } = props;
  const styles = useStyles();
  const tracking = useTracking();

  const handleSliderChange = (sliderVal: PCIRanges) => {
    setPCI(
      pci.map(d =>
        d.title === sliderVal.title
          ? {
            ...d,
            initialRange: sliderVal.initialRange,
            value: sliderVal.value,
          }
          : d
      )
    );
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const value = event.target.value;
    setPCI(
      pci.map(d => (d.title === value ? { ...d, checked } : d))
    );

    tracking.trackEvent({
      event: "mouse-click",
      action: "filter-distresses-click",
    });
  };

  const renderAnalysisSliders = (
    slider: PCIRanges
  ): RadioValue<PCI> => {
    const sliderJSX = (
      <AnalysisSlider value={slider} handleSliderChange={handleSliderChange} />
    );
    return {
      value: slider.title,
      selectedStyle: { backgroundColor: RB_YELLOW },
      unselectedStyle: { backgroundColor: RB_LIGHT_GREY },
      uiElement: sliderJSX,
      checked: slider.checked,
    };
  };

  const radioValues: RadioValue<PCI>[] = pci.map(
    renderAnalysisSliders
  );

  return (
    <div className={styles.slidersContainer}>
      <RadioButtonsGroup onChange={handleChange} values={radioValues} />
    </div>
  );
};

export default PciSlider;
