import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { toggleLayerBySource } from "../actions/layers";
import PotholeCheckbox from "../components/PotholeCheckbox";
import AllPointCheckbox from "components/AllPointCheckbox";

const SliderGroupContainer = ({ toggleGroups, toggleLayerBySource }) => {
  const [potholeValue, setPotholeValue] = useState(false);
  const [allPointsValue, setAllPointsValue] = useState(false);

  const layerGroups = useSelector(state => state.layers.groups);

  useEffect(() => {
    // show the toggle on the checkbox correctly when allPoints is visible by default
    if (layerGroups.length > 0) {
      const allPointsLayerGroup = layerGroups.filter(
        layerGroup => layerGroup.style === "roadwayAllPoints"
      );
      if (allPointsLayerGroup.length > 0) {
        const allPointLayers = allPointsLayerGroup[0].layers;
        if (allPointLayers.length > 0) {
          setAllPointsValue(allPointLayers[0].visible);
        }
      }
    }
  }, [layerGroups]);

  useEffect(() => {
    // show the toggle on the checkbox correctly when potholes is visible by default
    if (layerGroups.length > 0) {
      const potholesLayerGroup = layerGroups.filter(
        layerGroup => layerGroup.style === "roadwayPotholes"
      );
      if (potholesLayerGroup.length > 0) {
        const potholeLayers = potholesLayerGroup[0].layers;
        if (potholeLayers.length > 0) {
          setPotholeValue(potholeLayers[0].visible);
        }
      }
    }
  }, [layerGroups]);

  const determineIfPotholeLayerExists = () => {
    // loop over the toggleGroups looking for pothole type
    const potholeGroup = toggleGroups.filter(
      toggleGroup => toggleGroup.name === "potholes"
    );
    return potholeGroup && potholeGroup.length >= 1;
  };

  const determineIfAllPointsLayerExists = () => {
    // loop over the toggleGroups looking for allPoint type
    const allPointsGroup = toggleGroups.filter(
      toggleGroup => toggleGroup.name === "allPoints"
    );
    return allPointsGroup && allPointsGroup.length >= 1;
  };

  const togglePotholeLayer = value => {
    // loop over the toggleGroups looking for pothole type
    const potholeToggleGroup = toggleGroups.filter(
      toggleGroup => toggleGroup.name === "potholes"
    );
    // go into toggles, get the id of the first one
    const potholelayerSource = potholeToggleGroup[0].toggles[0].layerId;

    // use layerId property to toggle by using toggleLayer in actions/layers
    toggleLayerBySource(potholelayerSource, value);
  };

  const toggleAllPointLayer = value => {
    // loop over the toggleGroups looking for pothole type
    const allPointToggleGroup = toggleGroups.filter(
      toggleGroup => toggleGroup.name === "allPoints"
    );
    // go into toggles, get the id of the first one
    const allpointlayerSource = allPointToggleGroup[0].toggles[0].layerId;

    // use layerId property to toggle by using toggleLayer in actions/layers
    toggleLayerBySource(allpointlayerSource, value);
  };

  const handlePotholeCheckboxChange = () => {
    setPotholeValue(!potholeValue);
    // have to toggle the ! due to the state value not updating in time
    togglePotholeLayer(!potholeValue);
  };

  const handleAllPointsCheckboxChange = () => {
    setAllPointsValue(!allPointsValue);
    // have to toggle the ! due to the state value not updating in time
    toggleAllPointLayer(!allPointsValue);
  };

  return (
    <div>
      {determineIfAllPointsLayerExists() && (
        <AllPointCheckbox
          handleShowAllPointsChange={handleAllPointsCheckboxChange}
          showAllPoints={allPointsValue}
        />
      )}
      {determineIfPotholeLayerExists() && (
        <PotholeCheckbox
          handleShowPotholeChange={handlePotholeCheckboxChange}
          showPotholes={potholeValue}
        />
      )}
    </div>
  );
};

SliderGroupContainer.propTypes = {
  toggleLayerBySource: PropTypes.func.isRequired,
  toggleGroups: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  toggleGroups: state.toggles.groups,
});

const mapDispatchToProps = dispatch => ({
  toggleLayerBySource: (layerSource, value) =>
    dispatch(toggleLayerBySource(layerSource, value)),
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SliderGroupContainer);
