export const RAIToRoadwayRating = (RAI: number): number => {
  if (RAI >= 0 && RAI <= 1.0) {
    return 1;
  } else if (RAI > 1.0 && RAI <= 2.5) {
    return 3;
  } else {
    return 5;
  }
};

type sidewalkMapType = {
  [key: number]: string;
};

export const sidewalkMap: sidewalkMapType = {
  1: "Good",
  3: "Fair",
  5: "Poor",
};
