import {
  TOGGLE_DEUTERANOPIA,
  TOGGLE_PROTANOPIA,
  TOGGLE_TRITANOPIA,
  TOGGLE_ACHROMATOPIA,
  TURN_OFF_COLORBLIND,
} from "actiontypes/colorPicker";
import {
  RB_GREEN,
  RB_LIGHT_GREEN,
  RB_YELLOW,
  RB_ORANGE,
  RB_RED,
} from "constants/colors";

const initialState = {
  colorBlindMode: "",
  RATING_1: RB_GREEN,
  RATING_2: RB_LIGHT_GREEN,
  RATING_3: RB_YELLOW,
  RATING_4: RB_ORANGE,
  RATING_5: RB_RED,
  RATING_NON_PAVED: "#808080",
  MEASURE_LINE: "#5495ff",
  MEASURE_AREA: "#3dffef",
};

const colorPicker = (state = initialState, action) => {
  switch (action.type) {
    case TURN_OFF_COLORBLIND:
      return {
        ...state,
        colorBlindMode: "",
        RATING_1: RB_GREEN,
        RATING_2: RB_LIGHT_GREEN,
        RATING_3: RB_YELLOW,
        RATING_4: RB_ORANGE,
        RATING_5: RB_RED,
        RATING_NON_PAVED: "#808080",
        MEASURE_LINE: "#5495ff",
        MEASURE_AREA: "#3dffef",
      };
    case TOGGLE_DEUTERANOPIA:
      return {
        ...state,
        colorBlindMode: "deuteranopia",
        RATING_1: "#051268",
        RATING_2: "#7c99cf",
        RATING_3: "#cfba7c",
        RATING_4: "#bf8f00",
        RATING_5: "#735600",
        RATING_NON_PAVED: "#000000",
        MEASURE_LINE: "#8392ff",
        MEASURE_AREA: "#3dffef",
      };
    case TOGGLE_PROTANOPIA:
      return {
        ...state,
        colorBlindMode: "protanopia",
        RATING_1: "#051268",
        RATING_2: "#7c99cf",
        RATING_3: "#cfba7c",
        RATING_4: "#bf8f00",
        RATING_5: "#735600",
        RATING_NON_PAVED: "#000000",
        MEASURE_LINE: "#8392ff",
        MEASURE_AREA: "#3dffef",
      };
    case TOGGLE_TRITANOPIA:
      return {
        ...state,
        colorBlindMode: "tritanopia",
        RATING_1: "#004f3e",
        RATING_2: "#2b806e",
        RATING_3: "#a0ded1",
        RATING_4: "#ed8282",
        RATING_5: RB_RED,
        RATING_NON_PAVED: "#000000",
        MEASURE_LINE: "#ca62fc",
        MEASURE_AREA: "#3dffef",
      };
    case TOGGLE_ACHROMATOPIA:
      return {
        ...state,
        colorBlindMode: "achromatopia",
        RATING_1: "#c9c9c9",
        RATING_2: "#a3a3a3",
        RATING_3: "#808080",
        RATING_4: "#595959",
        RATING_5: "#454545",
        RATING_NON_PAVED: "#000000",
        MEASURE_LINE: "#363636",
        MEASURE_AREA: "#3dffef",
      };
    default:
      return state;
  }
};

export default colorPicker;
