import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";
import PropTypes from "prop-types";

const CrackSealIcon = ({ viewBox = "0 0 24 24", ...props }) => (
  <SvgIcon viewBox={viewBox} {...props}>
    <g>
      <path
        // eslint-disable-next-line max-len
        d="M14.06,1.16c0.78,0.75,1.53,1.47,2.29,2.2c0.1-0.17,0.19-0.31,0.29-0.45c0.26-0.4,0.67-0.52,1.02-0.3 c0.36,0.23,0.44,0.66,0.18,1.08c-0.55,0.88-1.1,1.77-1.65,2.65c-0.26,0.41-0.68,0.53-1.04,0.29c-0.35-0.23-0.41-0.66-0.16-1.07 c0.19-0.31,0.39-0.63,0.59-0.94c-0.03-0.03-0.05-0.07-0.08-0.1c-1.13-1.09-2.26-2.17-3.39-3.25C12,1.19,14.06,1.16,14.06,1.16z M22.71,14.27c-0.1,0.06-0.18,0.11-0.26,0.15c-1.2,0.74-2.41,1.48-3.61,2.22c-0.09,0.06-0.18,0.11-0.27,0.16 c-0.35,0.18-0.76,0.07-0.95-0.26c-0.2-0.34-0.1-0.74,0.23-0.98c0.15-0.1,0.3-0.19,0.45-0.28c0.41-0.25,0.82-0.51,1.25-0.77 c-0.4-0.64-0.8-1.25-1.18-1.87c-0.25-0.4-0.18-0.83,0.17-1.06c0.35-0.23,0.76-0.12,1.02,0.28c0.36,0.56,0.72,1.13,1.08,1.7 c0.04,0.07,0.09,0.13,0.14,0.2c0.6-0.37,1.18-0.73,1.76-1.08c0.15-0.09,0.19-0.19,0.19-0.35L22.71,14.27z M11.26,22.96 c-0.84-1.12-1.65-2.2-2.47-3.3c-0.06,0.05-0.11,0.08-0.15,0.12c-0.32,0.26-0.63,0.54-0.96,0.79c-0.44,0.32-1,0.09-1.11-0.44 c-0.07-0.32,0.07-0.56,0.31-0.75c0.81-0.67,1.62-1.34,2.42-2.02c0.39-0.33,0.78-0.66,1.19-0.98c0.33-0.26,0.75-0.21,1,0.1 c0.24,0.3,0.2,0.72-0.09,1c-0.08,0.08-0.17,0.15-0.26,0.22c-0.41,0.34-0.82,0.69-1.24,1.04c1.04,1.39,2.06,2.76,3.1,4.12 c0.06,0.08,0.21,0.11,0.32,0.11L11.26,22.96z M1.33,10.43c0.08-0.06,0.13-0.09,0.19-0.13C2.54,9.55,3.56,8.8,4.58,8.04 C4.86,7.82,5.15,7.8,5.45,7.98c1.61,0.91,3.22,1.82,4.83,2.73c0.04,0.02,0.08,0.04,0.13,0.06c-0.01-0.02-0.03-0.04-0.04-0.06 c0.36-0.34,0.73-0.68,1.09-1.02c0.19-0.18,0.39-0.3,0.65-0.26c0.28,0.04,0.48,0.2,0.58,0.47c0.1,0.29,0.03,0.55-0.18,0.77 c-0.48,0.5-0.95,1.01-1.43,1.51c-0.27,0.28-0.58,0.31-0.93,0.11c-0.52-0.3-1.05-0.59-1.57-0.89c-0.13-0.08-0.21-0.06-0.31,0.04 c-0.46,0.47-0.92,0.93-1.39,1.38c-0.33,0.33-0.77,0.34-1.06,0.04c-0.29-0.31-0.27-0.73,0.07-1.07c0.35-0.35,0.71-0.7,1.06-1.05 c0.05-0.05,0.09-0.1,0.14-0.17c-0.68-0.38-1.33-0.75-1.97-1.11C5.1,9.46,5.08,9.47,5.07,9.48c-1.21,0.89-2.43,1.78-3.63,2.69 C1.36,12.22,1.33,10.43,1.33,10.43z"
      />
    </g>
    <path
      // eslint-disable-next-line max-len
      d="M22.73,24.01H1.35c-0.72,0-1.31-0.6-1.31-1.34V1.38c0-0.74,0.59-1.34,1.31-1.34h21.38c0.72,0,1.31,0.6,1.31,1.34v21.29 C24.04,23.41,23.46,24.01,22.73,24.01z M1.5,22.52h21.09v-21H1.5V22.52z"
    />
  </SvgIcon>
);

CrackSealIcon.propTypes = {
  viewBox: PropTypes.string,
};

export default CrackSealIcon;
