import React, { useMemo } from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";

import Grid from "@material-ui/core/Grid";
import ChildOrgList from "components/ChildOrg/ChildOrgList";

import RoadSenseGridItem from "../ChildOrg/RoadSenseGridItem";
import { useSelector } from "react-redux";
import OrganizationSelectorGridView from "./OrganizationSelectorGridView";

const useStyles = makeStyles({
  orgGrid: {
    marginBottom: "75px",
  },
  divContainer: {
    overflowX: "hidden",
    overflowY: "hidden",
  },
});

const OrganizationSelector = ({
  isDashboardList,
  childOrganizations,
  setActiveScan,
  organizationScans,
  isOrganizationsDataLoaded,
  measuringSystem,
  gridOrganizations,
  selectActiveScan,
}) => {
  const isFreeTrialUser = useSelector(
    state => state?.user?.isFreeTrialUser || false
  );
  const isRoadSenseExampleShown = useSelector(
    state => state?.userData.organizations[0].isExampleOrganization || false
  );

  const classes = useStyles();
  const displayGrid = useMemo(() => {
    return gridOrganizations.map(org => (
      <Grid className={classes.orgGrid} key={org.id} item sm={12}>
        <OrganizationSelectorGridView
          key={org.name}
          organization={org}
          setActiveScan={selectActiveScan}
        />
      </Grid>
    ));
  }, [selectActiveScan, gridOrganizations, classes.orgGrid]);

  return (
    <React.Fragment>
      <div className={classes.divContainer}>
        <Grid container spacing={5} style={{ paddingLeft: "15px" }}>
          <Grid item xs={11}>
            <Grid
              item
              sm={12}
              style={{
                display: isDashboardList ? "none" : "block",
              }}
            >
              {displayGrid}
              {(isFreeTrialUser || isRoadSenseExampleShown) && (
                <RoadSenseGridItem />
              )}
            </Grid>
            <Grid
              item
              sm={12}
              style={{
                display: !isDashboardList ? "none" : "block",
              }}
            >
              <ChildOrgList
                organizations={childOrganizations}
                setActiveScan={setActiveScan}
                organizationScans={organizationScans}
                isOrganizationsDataLoaded={isOrganizationsDataLoaded}
                measuringSystem={measuringSystem}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

OrganizationSelector.propTypes = {
  isDashboardList: PropTypes.bool.isRequired,
  childOrganizations: PropTypes.array.isRequired,
  setActiveScan: PropTypes.func.isRequired,
  isOrganizationsDataLoaded: PropTypes.bool.isRequired,
  organizationScans: PropTypes.object.isRequired,
  measuringSystem: PropTypes.string.isRequired,
};

export default OrganizationSelector;
