const HB_SCRIPT_ID = "hs-script-loader";
const HB_CHAT_WINDOW_ID = "hubspot-messages-iframe-container";
const HB_CHAT_URL = "//js.hs-scripts.com/3012760.js";

class HubSpotChatWindow {
  static _createOrShowChatWindow() {
    const element = document.getElementById(HB_CHAT_WINDOW_ID);
    if (element) {
      element.style.visibility = "";
    } else {
      const script = document.createElement("script");
      script.id = HB_SCRIPT_ID;
      script.async = true;
      script.defer = true;
      script.src = HB_CHAT_URL;
      document.body.appendChild(script);
    }
  }

  static _hideChatWindow() {
    const element = document.getElementById(HB_CHAT_WINDOW_ID);
    if (element) {
      element.style.visibility = "hidden";
    }
  }

  static create() {
    HubSpotChatWindow._createOrShowChatWindow();
  }

  static destroy() {
    HubSpotChatWindow._hideChatWindow();
  }
}

export default HubSpotChatWindow;
