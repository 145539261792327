import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import "chartjs-plugin-datalabels";
import MaterialTable, { Icons } from "material-table";
import KeyboardArrowDownRounded from "@material-ui/icons/KeyboardArrowDownRounded";
import {
  TABLE_HEADER_BACKGROUND,
  TABLE_HEADER_TEXT,
  RB_YELLOW,
} from "constants/colors";
import styleTableRow from "utils/styleTableRow";
import { getLengthInPreferredUnit } from "../utils/metrics";
import TableSkeleton from "../skeletons/table";
import PieChart from "./PieChart";
import Skeleton from "@material-ui/lab/Skeleton";
import { MeasuringSystem } from "../constants/measuringSystem";
import { AnalysisInterface, HighwayInterface } from "interfaces/analysis";
import { StreetData } from "./SummaryPage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    radialTitle: {
      fontFamily: "'Open Sans', sans-serif",
      fontSize: "19px",
      textAlign: "center",
      marginTop: "15px",
    },
    piechartTitle: {
      fontFamily: "'Open Sans', sans-serif",
      fontSize: "19px",
      textAlign: "center",
      marginTop: "15px",
    },
    box: {
      color: "white",
      margin: "2px 80px",
      padding: "5px",
      textAlign: "center",
    },
    number: {
      fontFamily: "'Open Sans', sans-serif",
      fontSize: "35px",
      fontWeight: "bold",
      padding: "10px",
    },
    title: {
      paddingLeft: "10px",
      fontSize: "34px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    subTitle: {
      paddingLeft: "10px",
      fontSize: "28px",
      fontFamily: "'Open Sans', sans-serif",
    },
    titleDiv: {
      borderLeft: `solid 10px ${RB_YELLOW}`,
      borderBottom: "solid 1px #d0d0d0",
    },
    tableTitle: {
      fontSize: "20px",
      fontFamily: "'Open Sans', sans-serif",
    },
  })
);

interface FullAnalysisChartProps {
  analysisData: AnalysisInterface;
  measuringSystem: MeasuringSystem;
  classificationAnalysisList: StreetData[];
  isDataLoaded: boolean;
}

const FullAnalysisChart = ({
  analysisData,
  measuringSystem,
  classificationAnalysisList,
  isDataLoaded,
}: FullAnalysisChartProps): React.ReactElement => {
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(5);

  return (
    <div>
      <Grid className={classes.root} container spacing={2}>
        <Grid item xs={12}>
          <div className={classes.titleDiv}>
            <Typography className={classes.title} gutterBottom>
              Road Report Analysis
            </Typography>
            {isDataLoaded ? (
              <Typography className={classes.subTitle} gutterBottom>
                <i>{analysisData.displayName}</i>
              </Typography>
            ) : (
                <Skeleton variant="text" width={350} height={70} />
              )}
          </div>
        </Grid>
        <Grid item xs={4}>
          <PieChart analysisData={analysisData} isDataLoaded={isDataLoaded} />
        </Grid>
        <Grid item xs={8}>
          <MaterialTable
            onChangeRowsPerPage={setPageSize}
            options={{
              actionsColumnIndex: -1,
              pageSizeOptions: [5, 10, 20],
              pageSize,
              headerStyle: {
                backgroundColor: TABLE_HEADER_BACKGROUND,
                color: TABLE_HEADER_TEXT,
                fontWeight: "bold",
                fontSize: "14px",
                fontFamily: "'Open Sans', sans-serif",
              },
              rowStyle: styleTableRow,
              search: false,
            }}
            title={
              <div className={classes.tableTitle}>
                Road Classification Statistics
              </div>
            }
            columns={[
              {
                title: "Classification",
                cellStyle: {
                  fontFamily: "'Open Sans', sans-serif",
                },
                customSort: (
                  a: HighwayInterface,
                  b: HighwayInterface
                ): number =>
                  a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
                render: function renderClassification(
                  rowData: HighwayInterface
                ): React.ReactElement {
                  return (
                    <div>
                      {rowData.name !== "null" ? (
                        <a href={`#${rowData.name}`}>{rowData.name}</a>
                      ) : (
                          <a href={`#${rowData.name}`}>
                            <i>Unknown Name</i>
                          </a>
                        )}
                    </div>
                  );
                },
              },
              {
                title:
                  measuringSystem === MeasuringSystem.Imperial
                    ? "Length (mi)"
                    : "Length (km)",
                cellStyle: {
                  fontFamily: "'Open Sans', sans-serif",
                },
                customSort: (
                  a: HighwayInterface,
                  b: HighwayInterface
                ): number => a.totalLength - b.totalLength,
                render: function renderLength(
                  rowData: HighwayInterface
                ): React.ReactElement {
                  return (
                    <div>
                      {rowData.totalLength
                        ? getLengthInPreferredUnit(
                          rowData.totalLength,
                          measuringSystem,
                          "large"
                        ).scalar.toFixed(3)
                        : ""}
                    </div>
                  );
                },
              },
              {
                title: "Score",
                cellStyle: {
                  fontFamily: "'Open Sans', sans-serif",
                },
                render: function renderScore(
                  rowData: HighwayInterface
                ): React.ReactElement {
                  return <div>{!rowData.avgRating || rowData.avgRating === 6 ? "Not Paved" : rowData.avgRating.toFixed(2)}</div>;
                },
                customSort: (a, b): number => a.avgRating - b.avgRating,
              },
            ]}
            data={classificationAnalysisList as []}
            icons={{ SortArrow: KeyboardArrowDownRounded } as Icons}
            localization={{
              header: { actions: "" },
              body: {
                emptyDataSourceMessage: ((isDataLoaded ? (
                  <Typography>
                    No records to display. Contact RoadBotics if this is
                    incorrect
                  </Typography>
                ) : (
                    <TableSkeleton nIcons={0} />
                  )) as unknown) as string,
              },
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default FullAnalysisChart;
