import {
  TOGGLE_AREA_MODE,
  UPDATE_AREA,
  CLEAR_ALL_AREA,
  CLEAR_SINGLE_AREA,
  SET_AREA,
} from "actiontypes/measureArea";
import MapboxDraw from "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw";

const initialState = {
  isAreaing: false,
  area: 0,
  unit: "feet",
  originalArea: 0,
  originalUnit: "feet",
  draw: new MapboxDraw({
    displayControlsDefault: false,
    styles: [
      // default themes provided by MB Draw
      // default themes provided by MB Draw
      // default themes provided by MB Draw
      // default themes provided by MB Draw
      {
        id: "gl-draw-polygon-fill-inactive",
        type: "fill",
        filter: [
          "all",
          ["==", "active", "false"],
          ["==", "$type", "Polygon"],
          ["!=", "mode", "static"],
        ],
        paint: {
          "fill-color": "#3bb2d0",
          "fill-outline-color": "#3bb2d0",
          "fill-opacity": 0.1,
        },
      },
      {
        id: "gl-draw-polygon-fill-active",
        type: "fill",
        filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
        paint: {
          "fill-color": "#3dffef",
          "fill-outline-color": "#3dffef",
          "fill-opacity": 0.1,
        },
      },
      {
        id: "gl-draw-polygon-midpoint",
        type: "circle",
        filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
        paint: {
          "circle-radius": 5,
          "circle-color": "#3dffef",
        },
      },
      {
        id: "gl-draw-polygon-stroke-inactive",
        type: "line",
        filter: [
          "all",
          ["==", "active", "false"],
          ["==", "$type", "Polygon"],
          ["!=", "mode", "static"],
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
        paint: {
          "line-color": "#3bb2d0",
          "line-width": 2,
        },
      },
      {
        id: "gl-draw-polygon-stroke-active",
        type: "line",
        filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
        paint: {
          "line-color": "#3dffef",
          "line-dasharray": [4, 4],
          "line-width": 2.5,
        },
      },
      {
        id: "gl-draw-line-inactive",
        type: "line",
        filter: [
          "all",
          ["==", "active", "false"],
          ["==", "$type", "LineString"],
          ["!=", "mode", "static"],
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
        paint: {
          "line-color": "#3bb2d0",
          "line-width": 2,
        },
      },
      {
        id: "gl-draw-line-active",
        type: "line",
        filter: [
          "all",
          ["==", "$type", "LineString"],
          ["==", "active", "true"],
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
        paint: {
          "line-color": "#3dffef",
          "line-dasharray": [4, 4],
          "line-width": 2.5,
        },
      },
      {
        id: "gl-draw-polygon-and-line-vertex-stroke-inactive",
        type: "circle",
        filter: [
          "all",
          ["==", "meta", "vertex"],
          ["==", "$type", "Point"],
          ["!=", "mode", "static"],
        ],
        paint: {
          "circle-radius": 5,
          "circle-color": "#fff",
        },
      },
      {
        id: "gl-draw-polygon-and-line-vertex-inactive",
        type: "circle",
        filter: [
          "all",
          ["==", "meta", "vertex"],
          ["==", "$type", "Point"],
          ["!=", "mode", "static"],
        ],
        paint: {
          "circle-radius": 5,
          "circle-color": "#3dffef",
        },
      },
      {
        id: "gl-draw-point-point-stroke-inactive",
        type: "circle",
        filter: [
          "all",
          ["==", "active", "false"],
          ["==", "$type", "Point"],
          ["==", "meta", "feature"],
          ["!=", "mode", "static"],
        ],
        paint: {
          "circle-radius": 5,
          "circle-opacity": 1,
          "circle-color": "#fff",
        },
      },
      {
        id: "gl-draw-point-inactive",
        type: "circle",
        filter: [
          "all",
          ["==", "active", "false"],
          ["==", "$type", "Point"],
          ["==", "meta", "feature"],
          ["!=", "mode", "static"],
        ],
        paint: {
          "circle-radius": 5,
          "circle-color": "#3bb2d0",
        },
      },
      {
        id: "gl-draw-point-stroke-active",
        type: "circle",
        filter: [
          "all",
          ["==", "$type", "Point"],
          ["==", "active", "true"],
          ["!=", "meta", "midpoint"],
        ],
        paint: {
          "circle-radius": 7,
          "circle-color": "#fff",
        },
      },
      {
        id: "gl-draw-point-active",
        type: "circle",
        filter: [
          "all",
          ["==", "$type", "Point"],
          ["!=", "meta", "midpoint"],
          ["==", "active", "true"],
        ],
        paint: {
          "circle-radius": 5,
          "circle-color": "#3dffef",
        },
      },
      {
        id: "gl-draw-polygon-fill-static",
        type: "fill",
        filter: ["all", ["==", "mode", "static"], ["==", "$type", "Polygon"]],
        paint: {
          "fill-color": "#404040",
          "fill-outline-color": "#404040",
          "fill-opacity": 0.1,
        },
      },
      {
        id: "gl-draw-polygon-stroke-static",
        type: "line",
        filter: ["all", ["==", "mode", "static"], ["==", "$type", "Polygon"]],
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
        paint: {
          "line-color": "#404040",
          "line-width": 2,
        },
      },
      {
        id: "gl-draw-line-static",
        type: "line",
        filter: [
          "all",
          ["==", "mode", "static"],
          ["==", "$type", "LineString"],
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
        paint: {
          "line-color": "#404040",
          "line-width": 2,
        },
      },
      {
        id: "gl-draw-point-static",
        type: "circle",
        filter: ["all", ["==", "mode", "static"], ["==", "$type", "Point"]],
        paint: {
          "circle-radius": 5,
          "circle-color": "#404040",
        },
      },

      // end default themes provided by MB Draw
      // end default themes provided by MB Draw
      // end default themes provided by MB Draw
      // end default themes provided by MB Draw
    ],
  }),
};

const measureArea = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_AREA_MODE:
      return {
        ...state,
        isAreaing: !state.isAreaing,
      };
    case UPDATE_AREA:
      return {
        ...state,
        area: action.area,
        unit: action.unit,
      };
    case SET_AREA:
      return {
        ...state,
        originalArea: action.area,
        originalUnit: action.unit,
      };
    case CLEAR_ALL_AREA:
      return {
        ...state,
        isAreaing: false,
        area: 0,
        unit: "feet",
        originalArea: 0,
        originalUnit: "feet",
      };
    case CLEAR_SINGLE_AREA:
      return {
        ...state,
        area: 0,
        originalArea: 0,
      };
    default:
      return state;
  }
};

export default measureArea;
