import React from "react";
import { connect } from "react-redux";
import StopDrawing from "components/icons/SquareFootIconClose";
import StartDrawing from "components/icons/SquareFootIcon";
import PropTypes from "prop-types";

const MeasureAreaContainer = ({ measureArea: { isAreaing } }) => (
  <React.Fragment>
    {isAreaing ? <StopDrawing /> : <StartDrawing />}
  </React.Fragment>
);

MeasureAreaContainer.propTypes = {
  measureArea: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  measureArea: state.measureArea,
});

export default connect(mapStateToProps)(MeasureAreaContainer);
