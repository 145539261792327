import {
  TURN_OFF_DYNAMIC_SEGMENTATION_DRAG,
  TOGGLE_DYNAMIC_SEGMENTATION_DRAG,
  TOGGLE_DYNAMIC_SEGMENTATION_SELECT,
  TOGGLE_DYNAMIC_SEGMENTATION_LASSO,
  TURN_OFF_DYNAMIC_SEGMENTATION_SELECT,
  TURN_OFF_DYNAMIC_SEGMENTATION_LASSO,
} from "actiontypes/dynamicSegmentation";

export function turnOffDynamicSegmentationDrag() {
  return {
    type: TURN_OFF_DYNAMIC_SEGMENTATION_DRAG,
  };
}

export function toggleDynamicSegmentationDrag() {
  return {
    type: TOGGLE_DYNAMIC_SEGMENTATION_DRAG,
  };
}

export function turnOffDynamicSegmentationSelect() {
  return {
    type: TURN_OFF_DYNAMIC_SEGMENTATION_SELECT,
  };
}

export function toggleDynamicSegmentationSelect() {
  return {
    type: TOGGLE_DYNAMIC_SEGMENTATION_SELECT,
  };
}

export function toggleDynamicSegmentationLasso() {
  return {
    type: TOGGLE_DYNAMIC_SEGMENTATION_LASSO,
  };
}

export function turnOffDynamicSegmentationLasso() {
  return {
    type: TURN_OFF_DYNAMIC_SEGMENTATION_LASSO,
  };
}
