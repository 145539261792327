import {
  TURN_ON_SCREEN_CAP,
  TURN_OFF_SCREEN_CAP,
} from "../actiontypes/screenCapture";

export function turnOnScreenCapture(screenshotType) {
  return {
    type: TURN_ON_SCREEN_CAP,
    screenshotType,
  };
}

export function turnOffScreenCapture() {
  return {
    type: TURN_OFF_SCREEN_CAP,
  };
}
