import React, { useState, useEffect } from "react";
import MaterialTable, { Icons, Action } from "material-table";
import withStyles from "@material-ui/core/styles/withStyles";
import { RedoRounded } from "@material-ui/icons";
import createStyles from "@material-ui/core/styles/createStyles";
import {
  TABLE_HEADER_BACKGROUND,
  TABLE_HEADER_TEXT,
  RB_YELLOW,
} from "constants/colors";
import styleTableRow from "utils/styleTableRow";
import KeyboardArrowDownRounded from "@material-ui/icons/KeyboardArrowDownRounded";
import omit from "lodash/omit";
import Organization from "interfaces/organization";
import TableSkeleton from "skeletons/table";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import State from "../../interfaces/state";

// Cannot use makestyles here since materialTable does not support className
const styles = (): void => {
  createStyles({
    dialog: {
      width: "100%",
    },
    button: { backgroundColor: RB_YELLOW, textTransform: "none" },
    textField: { width: "100%" },
    newOrgInput: {
      width: "40%",
    },
  });
};

interface OrganizationsAdminInterface {
  isDataLoading: boolean;
  organizations: Organization[];
  handleEdit: (org: Organization) => {};
  handleDelete: (org: Organization) => {};
  handleConvert: (data: Organization) => void;
  handleExtension: (data: Organization) => void;
}

const OrganizationsAdmin = ({
  isDataLoading,
  organizations,
  handleEdit,
  handleDelete,
  handleConvert,
  handleExtension,
}: OrganizationsAdminInterface): React.ReactElement => {
  const [rowOrganizations, setRowOrganizations] = useState<Organization[]>([]);
  const [pageSize, setPageSize] = useState(5);
  const isRoadBoticsAdmin = useSelector(
    (state: State) => state.userData.isRoadBoticsAdmin
  );

  useEffect(() => {
    const getOrganizationName = (
      organizationId: string | undefined
    ): string => {
      const organization = organizations.find(org => org.id === organizationId);
      return organization && organization.hasOwnProperty("name")
        ? organization.name
        : "";
    };

    setRowOrganizations(
      organizations.map(org => ({
        ...org,
        parentOrganizationName: getOrganizationName(org.parentOrganizationId),
      }))
    );
  }, [organizations]);

  return (
    <div>
      <MaterialTable
        isLoading={isDataLoading}
        style={{ boxShadow: "none" }}
        onChangeRowsPerPage={setPageSize}
        options={{
          pageSizeOptions: [5, 10, 20, 50],
          pageSize,
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: TABLE_HEADER_BACKGROUND,
            color: TABLE_HEADER_TEXT,
            fontWeight: "bold",
            fontSize: "14px",
          },
          rowStyle: styleTableRow,
        }}
        localization={{
          header: { actions: "" },
          body: {
            emptyDataSourceMessage: ((!isDataLoading ? (
              <Typography>
                No records to display. Contact RoadBotics if this is incorrect
              </Typography>
            ) : (
              <TableSkeleton nIcons={2} />
            )) as unknown) as string,
          },
        }}
        actions={[
          (rowData: Organization): Action<Organization> => ({
            // disabled the icon to hide that action button without moving columns
            disabled: !isRoadBoticsAdmin || !rowData.hasFreeTrialScans,
            // if a ft org and RB admin show the icon, otherwise hide it
            icon: isRoadBoticsAdmin && rowData.hasFreeTrialScans ? "add" : "",
            tooltip: isRoadBoticsAdmin && rowData.hasFreeTrialScans ? "Extend Free Trial" : undefined,
            onClick: (_, rowData): void => {
              handleExtension(omit(rowData, ["tableData"]) as Organization); // rm tableData from MatTable
            },
          }),
          (rowData: Organization): Action<Organization> => ({
            // disabled the icon to hide that action button without moving columns
            disabled: !isRoadBoticsAdmin || !rowData.hasFreeTrialScans,
            // if a ft org and RB admin show the icon, otherwise hide it
            icon: isRoadBoticsAdmin && rowData.hasFreeTrialScans ? () => <RedoRounded /> : "",
            tooltip: isRoadBoticsAdmin && rowData.hasFreeTrialScans ? "Convert Free Trial Organization" : undefined,
            onClick: (_, rowData): void => {
              handleConvert(omit(rowData, ["tableData"]) as Organization); // rm tableData from MatTable
            },
          }),
          {
            icon: "edit",
            tooltip: "Edit Organization",
            onClick: (_, rowData): void => {
              handleEdit(omit(rowData, ["tableData"]) as Organization); // rm data from MatTbl
            },
          },
          {
            icon: "delete",
            tooltip: "Delete Organization",
            onClick: (_, rowData): void => {
              handleDelete(omit(rowData, ["tableData"]) as Organization); // rm data from MatTbl
            },
          },
        ]}
        title="Organizations"
        columns={[
          { title: "Organization Name", field: "name" },
          {
            title: "Parent Organization Name",
            field: "parentOrganizationName",
          },
        ]}
        data={rowOrganizations}
        icons={{ SortArrow: KeyboardArrowDownRounded } as Icons}
      />
    </div>
  );
};

export default withStyles(styles)(OrganizationsAdmin);
