import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";

const styles = theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    borderRadius: "3px",
    boxShadow: "0 1px 4px 0 #00000080",
    fontSize: "12px",
    fontFamily: "'Open Sans', sans-serif",
    margin: "0",
    padding: "5px",
  },
});

const LightTooltip = ({ children, classes, title, ...rest }) => (
  <Tooltip classes={classes} title={title} {...rest}>
    {children}
  </Tooltip>
);

LightTooltip.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(LightTooltip);
