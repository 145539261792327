import {
  SET_USER,
  SET_FIRST_NAME,
  SET_LAST_NAME,
  SET_ORGANIZATION,
  SET_USER_UID,
  SET_LAST_LOGIN,
  SET_ROLE,
  SHOW_ROADWAY_WELCOME,
  SET_IS_FREE_TRIAL_USER,
  SET_USER_STATUS,
  SET_IS_BETA_USER,
} from "actiontypes/login";
import UserStatus from "../constants/userStatus";

const initialState = {
  email: null,
  firstName: null,
  lastName: null,
  organization: null,
  userUId: null,
  lastLogin: null,
  role: null,
  roadwayWelcome: false,
  isFreeTrialUser: false,
  isBetaUser: false,
  status: UserStatus.DISABLED,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        email: action.user,
      };
    case SET_FIRST_NAME:
      return {
        ...state,
        firstName: action.firstName,
      };
    case SET_LAST_NAME:
      return {
        ...state,
        lastName: action.lastName,
      };
    case SET_ORGANIZATION:
      return {
        ...state,
        organization: action.organization,
      };
    case SET_USER_UID:
      return {
        ...state,
        userUId: action.userUId,
      };
    case SET_LAST_LOGIN:
      return {
        ...state,
        lastLogin: action.lastLogin,
      };
    case SET_ROLE:
      return {
        ...state,
        role: action.role,
      };
    case SHOW_ROADWAY_WELCOME:
      return {
        ...state,
        roadwayWelcome: action.roadwayWelcome,
      };
    case SET_IS_FREE_TRIAL_USER:
      return {
        ...state,
        isFreeTrialUser: action.isFreeTrialUser,
      };
    case SET_USER_STATUS:
      return {
        ...state,
        status: action.status,
      };
    case SET_IS_BETA_USER:
      return {
        ...state,
        isBetaUser: action.isBetaUser,
      };
    default:
      return state;
  }
};

export default userReducer;
