import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import DynamicCheckbox from "./DynamicCheckbox";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    width: "50%",
    margin: "0 auto",
  },
};

const CrackSealSwitch = ({
  classes,
  label,
  color,
  onChange,
  checked,
  disabled,
}) => (
  <div className={classes.root}>
    <div className={classes.switch}>
      <DynamicCheckbox
        color={color}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
    </div>
    <div className={classes.label}>
      <Typography variant="h6">{label}</Typography>
    </div>
  </div>
);

CrackSealSwitch.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default withStyles(styles)(CrackSealSwitch);
