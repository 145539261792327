import mapValues from "lodash/mapValues";
import values from "lodash/values";
import {
  LEVEL_1,
  LEVEL_2,
  LEVEL_3,
  LEVEL_4,
  LEVEL_5,
} from "constants/damageRatings";

function chartData(arr, chartName, colorPicker) {
  // TODO: Find a better approach to line up the data to the colors (line 11)
  let occurrences = arr.reduce(
    (obj, item) => {
      const newObj = obj;
      newObj[item] = (newObj[item] || 0) + 1;
      return newObj;
    },
    { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 }
  );
  const count = arr.length;
  occurrences = mapValues(occurrences, value =>
    ((value / count) * 100).toFixed(5)
  );

  return {
    labels: [LEVEL_1, LEVEL_2, LEVEL_3, LEVEL_4, LEVEL_5],
    datasets: [
      {
        label: chartName,
        backgroundColor: [
          colorPicker.RATING_1,
          colorPicker.RATING_2,
          colorPicker.RATING_3,
          colorPicker.RATING_4,
          colorPicker.RATING_5,
        ],
        hoverBackgroundColor: [
          colorPicker.RATING_1,
          colorPicker.RATING_2,
          colorPicker.RATING_3,
          colorPicker.RATING_4,
          colorPicker.RATING_5,
        ],
        borderColor: "black",
        hoverBorderWidth: 4,
        data: values(occurrences),
      },
    ],
  };
}

export default chartData;
