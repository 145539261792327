import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import track from "react-tracking";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "components/form/Button";
import AddIcon from "@material-ui/icons/Add";
import { fetchAllOrganizations, fetchOrganizations } from "actions/userData";
import { setSnackbarMessageAndOpen, MessageTypes } from "actions/snackbar";
import { RB_YELLOW } from "constants/colors";
import OrganizationsAdmin from "components/admin/OrganizationsAdmin";
import OrganizationsAdminUpdateFormDialog from "./OrganizationsAdminUpdateFormDialog";
import * as Sentry from "@sentry/browser/dist/index";
import { excludeExampleOrganizations } from "../../utils/excludeExampleOrganizations";
import RBAPI from "api/RoadwayAPI";
import { getAssessmentType } from "../../utils/getAssessmentType";
import ConvertOrganizationPopup from "components/ConvertOrganizationPopup";
import ExtendFreeTrialPopup from "components/ExtendFreeTrialPopup";

const useStyles = makeStyles({
  button: { backgroundColor: RB_YELLOW, textTransform: "none" },
});

const OrganizationsAdminContainer = props => {
  const {
    allOrganizations,
    fetchAllOrganizations,
    fetchOrganizations,
    isAllOrganizationsDataLoaded,
    setSnackbarMessageAndOpen,
    tracking,
    userUId,
    openDeletePopup,
    isDeleteDialogOpen,
  } = props;
  const [isUpdateFormOpen, setIsUpdateFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isOrganizationUpdateActive, setIsOrganizationUpdateActive] = useState(
    false
  );
  const newOrganiztion = {
    name: "",
    parentOrganizationId: "",
  };
  const filteredOrganizations = excludeExampleOrganizations(allOrganizations);
  const [formValues, setFormValues] = useState(newOrganiztion);
  const [isConvertDialogOpen, setIsConvertDialogOpen] = useState(false);
  const [orgToConvert, setOrgToConvert] = useState({});
  const [isProcessingConvert, setIsProcessingConvert] = useState(false);
  const [isExtensionFormOpen, setIsExtensionFormOpen] = useState(false);
  const [orgToExtend, setOrgToExtend] = useState({});
  const [isExtensionActive, setIsExtensionActive] = useState(false);

  const handleCloseUpdateForm = () => setIsUpdateFormOpen(false);

  // function to reload all the orgs after making a change
  const loadAllOrganizations = useCallback(() => {
    // fetch all organizations to reload the organizations admin data
    fetchAllOrganizations(
      RBAPI.getOrganizations().catch(e => console.error(e))
    );
    // fetch organizations by user to reload the admin page data
    fetchOrganizations(
      RBAPI.fetchScansByUser(userUId, getAssessmentType()).catch(err => {
        if (err?.response?.status === 404) {
          setSnackbarMessageAndOpen(
            "Could not find assessments",
            MessageTypes.ERROR
          );
          return;
        }
        throw err;
      })
    );
  }, [
    fetchAllOrganizations,
    fetchOrganizations,
    setSnackbarMessageAndOpen,
    userUId,
  ]);

  const handleError = (e, message) => {
    setSnackbarMessageAndOpen(message, MessageTypes.ERROR);
  };

  const handleExtension = selectedOrg => {
    setOrgToExtend(selectedOrg);
    setIsExtensionFormOpen(true);
  };

  const extendOrgUsers = async () => {
    setIsExtensionActive(true);

    const orgUsers = await RBAPI.getOrganizationUsers(orgToExtend.id);

    for (const userIndex in orgUsers) {
      const user = orgUsers[userIndex];
      // extend the user's days in free trial
      const extendUserData = {
        id: user.id,
        daysInFreeTrial: user.daysInFreeTrial + 30,
      };
      await RBAPI.updateUser(extendUserData);
    }

    setIsExtensionActive(false);
    setIsExtensionFormOpen(false);
    setSnackbarMessageAndOpen(
      `Organization ${orgToExtend.name}'s free trial users have been extended by 30 days.`
    );
  };

  const handleUpdate = async selectedOrganization => {
    const statusMessage = isEditing ? "Edit saved" : "Created new organization";
    try {
      setIsOrganizationUpdateActive(true);
      if (isEditing) {
        await RBAPI.updateOrganization(selectedOrganization);
      } else {
        await RBAPI.createOrganization(selectedOrganization);
      }
      setSnackbarMessageAndOpen(statusMessage);
      loadAllOrganizations();
    } catch (e) {
      const statusMessage = isEditing
        ? "Failed to save the edits"
        : `Failed to create the ${selectedOrganization.name} organization. 
        Please ensure the ${selectedOrganization.name} organization does not exist`;
      handleError(e, statusMessage);
    } finally {
      setIsOrganizationUpdateActive(false);
      setIsUpdateFormOpen(false);
      setIsEditing(false);
    }
  };

  const handleClickAdd = () => {
    setIsEditing(false);
    setFormValues(newOrganiztion);
    setIsUpdateFormOpen(true);
    tracking.trackEvent({
      event: "mouse-click",
      action: "admin-add-organization",
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `admin add organization`,
      level: Sentry.Severity.Info,
    });
  };

  const handleEdit = selectedOrganization => {
    setIsEditing(true);
    setFormValues(selectedOrganization);
    setIsUpdateFormOpen(true);
    tracking.trackEvent({
      event: "mouse-click",
      action: `admin-edit-organization-${selectedOrganization.id}`,
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `admin-edit-organization-${selectedOrganization.id}`,
      level: Sentry.Severity.Info,
    });
  };

  const classes = useStyles();

  useEffect(() => {
    return () => {
      if (isDeleteDialogOpen) {
        loadAllOrganizations();
      }
    };
  }, [isDeleteDialogOpen, loadAllOrganizations]);

  const handleConvert = selectedConvertOrg => {
    setOrgToConvert(selectedConvertOrg);
    setIsConvertDialogOpen(true);
  };

  const convertOrganization = async () => {
    setIsProcessingConvert(true);

    const rbOrg = await RBAPI.getRoadBoticsOrganization();

    // move the org to under roadbotics
    const updatedOrg = {
      id: orgToConvert.id,
      parentOrganizationId: rbOrg.id,
    };
    await RBAPI.updateOrganization(updatedOrg);

    const orgUsers = await RBAPI.getOrganizationUsers(orgToConvert.id);

    for (const userIndex in orgUsers) {
      const user = orgUsers[userIndex];
      // set the isFreeTrialUser flag to false
      const convertedUserData = {
        id: user.id,
        isFreeTrialUser: false,
        freeTrialType: "none",
      };
      await RBAPI.updateUser(convertedUserData);

      // set the settings hideExampleOrganizations to false
      await RBAPI.updateUserSettings(user.id, {
        hideExampleOrganizations: true,
      });
    }

    // set the trialType of the scan
    const organizationScans = await RBAPI.getOrganizationScans(orgToConvert.id);

    for (const scanIndex in organizationScans) {
      const orgScan = organizationScans[scanIndex];
      const scan = {
        id: orgScan.id,
        trialType: "none",
      };
      await RBAPI.updateScan(scan);
    }

    setSnackbarMessageAndOpen(`${orgToConvert.name} has been converted.`);
    setIsProcessingConvert(false);
    setIsConvertDialogOpen(false);
    loadAllOrganizations();
  };

  return (
    <div>
      <Button
        className={classes.button}
        onClick={handleClickAdd}
        variant="contained"
        color="primary"
        capitalizeLabel={false}
      >
        <AddIcon />
        Add New Organization
      </Button>
      <OrganizationsAdmin
        isDataLoading={!isAllOrganizationsDataLoaded}
        organizations={filteredOrganizations}
        handleEdit={handleEdit}
        handleDelete={openDeletePopup}
        handleConvert={handleConvert}
        handleExtension={handleExtension}
      />
      {isUpdateFormOpen && (
        <OrganizationsAdminUpdateFormDialog
          classes={classes}
          handleUpdate={handleUpdate}
          organizations={filteredOrganizations}
          isOpen
          handleClose={handleCloseUpdateForm}
          isUpdateActive={isOrganizationUpdateActive}
          values={formValues}
          title={isEditing ? "Edit Organization" : "Add New Organization"}
          userUId={userUId}
        />
      )}
      {isConvertDialogOpen && (
        <ConvertOrganizationPopup
          convertName={orgToConvert.name}
          isOpen={isConvertDialogOpen}
          handleConvert={convertOrganization}
          handleClose={() => setIsConvertDialogOpen(false)}
          isProcessingConvert={isProcessingConvert}
        />
      )}
      {isExtensionFormOpen && (
        <ExtendFreeTrialPopup
          extendOrg={orgToExtend.name}
          isOpen={isExtensionFormOpen}
          handleExtend={extendOrgUsers}
          handleClose={() => {
            setIsExtensionFormOpen(false);
          }}
          isProcessingExtend={isExtensionActive}
        />
      )}
    </div>
  );
};

OrganizationsAdminContainer.defaultProps = {};

OrganizationsAdminContainer.propTypes = {
  allOrganizations: PropTypes.array.isRequired,
  fetchAllOrganizations: PropTypes.func.isRequired,
  isAllOrganizationsDataLoaded: PropTypes.bool.isRequired,
  setSnackbarMessageAndOpen: PropTypes.func.isRequired,
  tracking: PropTypes.object.isRequired,
  userUId: PropTypes.string.isRequired,
  fetchOrganizations: PropTypes.func.isRequired,
  openDeletePopup: PropTypes.func.isRequired,
  isDeleteDialogOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ userData, user }) => ({
  allOrganizations: userData.allOrganizations,
  isAllOrganizationsDataLoaded: userData.isAllOrganizationsDataLoaded,
  userUId: user.userUId,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSnackbarMessageAndOpen,
      fetchAllOrganizations,
      fetchOrganizations,
    },
    dispatch
  );

export default track()(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationsAdminContainer)
);
