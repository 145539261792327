import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { RB_LIGHT_GREY } from "../constants/colors";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
  },
  text: {
    background: RB_LIGHT_GREY,
    flexGrow: 4,
    margin: "5px 20px ",
  },
  button: {
    background: RB_LIGHT_GREY,
    width: "35px",
    margin: "5px",
  },
});

interface TableSkeletonInterface {
  rows?: number;
  nIcons?: number;
}

const TableSkeleton: React.FC<TableSkeletonInterface> = ({
  rows = 5,
  nIcons = 3,
}) => {
  const classes = useStyles();
  return (
    <div>
      {[...Array(rows).keys()].map(i => {
        return (
          <div className={classes.root} key={`${i}-skeleton-row`}>
            <Skeleton className={classes.text} variant="text" height={50} />
            {[...Array(nIcons).keys()].map(i => {
              return (
                <Skeleton
                  key={`${i}-skeleton-button`}
                  className={classes.button}
                  variant="text"
                  height={50}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default TableSkeleton;
