import {
  TOGGLE_MEASURE_MODE,
  UPDATE_DISTANCE,
  CLEAR_SINGLE_MEASURE,
  CLEAR_ALL_MEASURE,
  MEASURE_COLOR_CHANGE,
  RESET_CLEARING_TOGGLES,
} from "actiontypes/measureDistance";

const initialState = {
  isMeasuring: false,
  distance: 0,
  unit: "feet",
  isClearAll: false,
  isClearSingle: false,
  colorChange: false,
  geojson: {
    type: "FeatureCollection",
    features: [],
  },
  linestring: {
    type: "Feature",
    geometry: {
      type: "LineString",
      coordinates: [],
    },
  },
};

const measureDistance = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MEASURE_MODE:
      return {
        ...state,
        isMeasuring: !state.isMeasuring,
      };
    case UPDATE_DISTANCE:
      return {
        ...state,
        isClearAll: false,
        isClearSingle: false,
        colorChange: false,
        distance: action.distance,
        unit: action.unit,
      };
    case CLEAR_SINGLE_MEASURE:
      return {
        ...state,
        distance: 0,
        isClearAll: false,
        isClearSingle: true,
        colorChange: false,
        unit: "feet",
        geojson: {
          type: "FeatureCollection",
          features: [],
        },
      };
    case CLEAR_ALL_MEASURE:
      return {
        ...state,
        distance: 0,
        unit: "feet",
        isClearAll: true,
        isClearSingle: false,
        isMeasuring: false,
        colorChange: false,
        geojson: {
          type: "FeatureCollection",
          features: [],
        },
      };
    case RESET_CLEARING_TOGGLES:
      return {
        ...state,
        isClearAll: false,
        isClearSingle: false,
        isMeasuring: false,
        colorChange: false,
      };
    case MEASURE_COLOR_CHANGE:
      return {
        ...state,
        colorChange: true,
      };
    default:
      return state;
  }
};

export default measureDistance;
