import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { hideLayer, showLayer } from "actions/layers";
import RoundCheckbox from "components/RoundCheckbox";
import track from "react-tracking";

import { toggleToggleGroup, allTogglesToggled } from "utils/toggleGroups";

const RatingsLayerToggleAll = ({
  toggleGroupId,
  tracking,
  userUId,
  activeScan,
  toggleGroups,
  layerGroups,
  hideLayer,
  showLayer,
}) => {
  return (
    <RoundCheckbox
      key={`Toggle All ${toggleGroupId}`}
      label="All"
      onChange={() =>
        toggleToggleGroup({
          toggleGroupId,
          tracking,
          userUId,
          activeScan,
          hideLayer,
          showLayer,
          toggleGroups,
          layerGroups,
        })
      }
      checked={allTogglesToggled({
        toggleGroupId,
        toggleGroups,
        layerGroups,
      })}
    />
  );
};

const mapStateToProps = state => ({
  layerGroups: state.layers.groups,
  toggleGroups: state.toggles.groups,
  activeScan: state.userData.activeScan,
  userUId: state.user.userUId,
});

const mapDispatchToProps = dispatch => ({
  showLayer: layerId => dispatch(showLayer(layerId)),
  hideLayer: layerId => dispatch(hideLayer(layerId)),
});

RatingsLayerToggleAll.propTypes = {
  toggleGroups: PropTypes.array.isRequired,
  layerGroups: PropTypes.array.isRequired,
  showLayer: PropTypes.func.isRequired,
  hideLayer: PropTypes.func.isRequired,
  tracking: PropTypes.shape({
    trackEvent: PropTypes.func.isRequired,
  }).isRequired,
  activeScan: PropTypes.string.isRequired,
  userUId: PropTypes.string.isRequired,
  toggleGroupId: PropTypes.number.isRequired,
};

export default track()(
  connect(mapStateToProps, mapDispatchToProps)(RatingsLayerToggleAll)
);
