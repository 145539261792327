import {
  SET_MAP_CENTER,
  SET_MAP_PITCH,
  SET_MAP_STYLE,
  SET_MAP_ZOOM,
  SET_MAP_BOUNDS,
  SET_MAP_BEARING,
  SET_MAP_DEFAULT_ZOOM,
  SET_IS_MAP_DATA_LOADED,
} from "actiontypes/map";
import { combineReducers } from "redux";

import {
  DEFAULT_ZOOM_LEVEL,
  DEFAULT_MAX_ZOOM,
  DEFAULT_MIN_ZOOM,
} from "constants/map";
import { MAP_STYLE_DARK } from "../constants/mapStyles";

const initialState = {
  style: MAP_STYLE_DARK,
  center: [0, 0],
  pitch: 0,
  bearing: [0],
  zoom: DEFAULT_ZOOM_LEVEL,
  initialCenter: [0, 0],
  bounds: [0, 0, 0, 0],
  defaultZoom: { min: DEFAULT_MIN_ZOOM, max: DEFAULT_MAX_ZOOM },
  isMapDataLoaded: false,
};

const styleReducer = (state = initialState.style, action) => {
  switch (action.type) {
    case SET_MAP_STYLE:
      return action.style;
    default:
      return state;
  }
};

const isMapDataLoadedReducer = (
  state = initialState.isMapDataLoaded,
  action
) => {
  switch (action.type) {
    case SET_IS_MAP_DATA_LOADED:
      return action.isMapDataLoaded;
    default:
      return state;
  }
};

const centerReducer = (state = initialState.center, action) => {
  switch (action.type) {
    case SET_MAP_CENTER:
      return action.center;
    default:
      return state;
  }
};

const pitchReducer = (state = initialState.pitch, action) => {
  switch (action.type) {
    case SET_MAP_PITCH:
      return action.pitch;
    default:
      return state;
  }
};

const zoomReducer = (state = initialState.zoom, action) => {
  switch (action.type) {
    case SET_MAP_ZOOM:
      return action.zoom;
    default:
      return state;
  }
};

const boundsReducer = (state = initialState.bounds, action) => {
  switch (action.type) {
    case SET_MAP_BOUNDS:
      return action.bounds;
    default:
      return state;
  }
};

const bearingReducer = (state = initialState.bearing, action) => {
  switch (action.type) {
    case SET_MAP_BEARING:
      return action.bearing;
    default:
      return state;
  }
};

const defaultZoomReducer = (state = initialState.defaultZoom, action) => {
  switch (action.type) {
    case SET_MAP_DEFAULT_ZOOM:
      return {
        min: action.min,
        max: action.max,
      };
    default:
      return state;
  }
};

export default combineReducers({
  style: styleReducer,
  center: centerReducer,
  pitch: pitchReducer,
  zoom: zoomReducer,
  bounds: boundsReducer,
  bearing: bearingReducer,
  defaultZoom: defaultZoomReducer,
  isMapDataLoaded: isMapDataLoadedReducer,
});
