import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import useMount from "hooks/useMount";
import { configureAnchors } from "react-scrollable-anchor";
import { getAppBarHeight } from "../../utils/getAppBarHeight";
import { normalizeSegments } from "../../utils/scansSegmentNormalizer";
import { ROUTE_NOT_FOUND } from "../../constants/routes";
import SummaryPage from "../../components/SummaryPage";
import { setSnackbarMessageAndOpen, MessageTypes } from "actions/snackbar";
import RBAPI from "api/RoadwayAPI";
import { setAnalysisData } from "actions/userData";

const SummaryPageContainer = ({
  history,
  match,
  location,
  measuringSystem,
  setSnackbarMessageAndOpen,
  dispatchAnalysisData,
}) => {
  const [analysisData, setAnalysisData] = useState({});
  const [IDIData, setIDIData] = useState();
  const [isIDIDataLoaded, setIsIDIDataLoaded] = useState(false);
  const [isStreetDataLoaded, setIsStreetDataLoaded] = useState(false);
  const [isNetworkDataLoaded, setIsNetworkDataLoaded] = useState(false);
  const [classificationAnalysisList, setClassificationAnalysisList] = useState(
    []
  );
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [
    individualRoadsAnalysisList,
    setIndividualRoadsAnalysisList,
  ] = useState({});

  // used to round the street data
  const normalizeIndividualRoadsAnalysis = analysisType => {
    for (const streets in analysisType || []) {
      for (const street of analysisType[streets]) {
        const { pci, avgRating } = street;
        if (pci) street.pci = Math.round(pci);
        if (avgRating) street.avgRating = parseFloat(avgRating.toFixed(2));
        const statistics = street.segmentDistressStatistics;
        if (statistics) {
          for (const obj of Object.entries(statistics)) {
            const [key] = obj;
            let [, stats] = obj;
            if (typeof stats === "number") {
              stats = Math.round(stats);
              const keyTyped = key;
              statistics[keyTyped] = stats;
            }
          }
        }
      }
    }

    return analysisType;
  };

  const onMount = () => {
    // Use polyfill implementation if necessary for older browsers
    Promise.prototype.finally = // eslint-disable-line
      Promise.prototype.finally ||
      {
        finally(fn) {
          const onFinally = callback => Promise.resolve(fn()).then(callback);
          return this.then(
            result => onFinally(() => result),
            reason => onFinally(() => Promise.reject(reason))
          );
        },
      }.finally;

    setIsStreetDataLoaded(false);
    setIsNetworkDataLoaded(false);
    setIsIDIDataLoaded(false);
    const scanId = match.params.scanId;

    RBAPI.fetchScanStreetAnalysis(scanId)
      .catch(err => {
        if (err?.response?.status === 404) {
          //
          setSnackbarMessageAndOpen(
            "Could not find street analysis",
            MessageTypes.ERROR
          );
          return;
        }
        throw err;
      })
      .then(analysisType => {
        setIndividualRoadsAnalysisList(
          normalizeIndividualRoadsAnalysis(analysisType)
        );
        setIsStreetDataLoaded(true);
      });

    RBAPI.fetchScanNetworkAnalysis(scanId)
      .catch(err => {
        if (err?.response?.status === 404) {
          //
          setSnackbarMessageAndOpen(
            "Could not find network analysis",
            MessageTypes.ERROR
          );
          return;
        }
        throw err;
      })
      .then(async data => {
        const scan = await RBAPI.fetchScan(scanId).catch(err => {
          if (err?.response?.status === 404) {
            //
            setSnackbarMessageAndOpen(
              "Could not find assessment",
              MessageTypes.ERROR
            );
            return;
          }
          throw err;
        });
        data["displayName"] = scan.displayName || scan.name;
        setAnalysisData(data);

        dispatchAnalysisData({
          ...scan?.analysis,
          hasIDIData: scan?.analysis?.hasOwnProperty("potholeCount"),
        });

        // determine the data for the material table
        Object.keys(data.highway).forEach(key => {
          setClassificationAnalysisList(classificationList => {
            data.highway[key].key = key;
            return [...classificationList, data.highway[key]];
          });
        });
        setIsNetworkDataLoaded(true);
      })
      .catch(e => {
        if (e.response && e.response.status === 404) {
          history.push(ROUTE_NOT_FOUND);
        }
      });

    RBAPI.fetchScanSegments(scanId)
      .then(data => {
        setIDIData(normalizeSegments(data));
      })
      .catch(e => {
        if (e?.response?.status === 404) {
          setShowErrorMessage(true);
          return;
        }
        throw e;
      })
      .finally(() => {
        setIsIDIDataLoaded(true);
      });
  };

  // fetch the scan data on mount
  useMount(onMount);

  useEffect(() => {
    classificationAnalysisList.sort((a, b) => {
      return a.name > b.name;
    });
  }, [classificationAnalysisList]);

  // configure the anchors to account for the appbar height
  configureAnchors({ offset: -getAppBarHeight() });

  return (
    <SummaryPage
      location={location}
      analysisData={analysisData}
      measuringSystem={measuringSystem}
      classificationAnalysisList={classificationAnalysisList}
      isNetworkDataLoaded={isNetworkDataLoaded}
      individualRoadsAnalysisList={individualRoadsAnalysisList}
      isStreetDataLoaded={isStreetDataLoaded}
      isIDIDataLoaded={isIDIDataLoaded}
      IDIData={IDIData}
      showErrorMessage={showErrorMessage}
    />
  );
};

SummaryPageContainer.propTypes = {
  measuringSystem: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      scanId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const mapDispatchToProps = dispatch => ({
  setSnackbarMessageAndOpen: (message, type) =>
    dispatch(setSnackbarMessageAndOpen(message, type)),
  dispatchAnalysisData: analysisData => dispatch(setAnalysisData(analysisData)),
});

const mapStateToProps = state => ({
  user: state.user,
  measuringSystem: state.userData.measuringSystem,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SummaryPageContainer)
);
