import withStyles from "@material-ui/core/styles/withStyles";
import MUICheckbox from "@material-ui/core/Checkbox";

import { RB_YELLOW } from "constants/colors";

const Checkbox = withStyles({
  root: {
    color: RB_YELLOW,
    "&$checked": {
      color: RB_YELLOW,
    },
  },
  checked: {},
})(MUICheckbox);

export default Checkbox;
