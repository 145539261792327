import React from "react";
import Divider from "@material-ui/core/Divider";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import crossIcon from "images/icons/cross.svg";
import depressionsIcon from "images/icons/depressions.svg";
import roughIcon from "images/icons/rough.svg";
import runningIcon from "images/icons/running.svg";
import trippingIcon from "images/icons/tripping.svg";
import widthIcon from "images/icons/width.svg";
import { RAIToRoadwayRating, sidewalkMap } from "utils/sidewalkHelperFunctions";

const useStyles = makeStyles({
  rai: {
    textAlign: "center",
    fontWeight: "bolder",
    fontSize: "2em;",
  },
  raiNumber: props => ({
    padding: "0 1%",
    backgroundColor: `${
      props.colorPicker[`RATING_${RAIToRoadwayRating(props.segmentRAI)}`]
    }`,
    borderRadius: "5px",
  }),
  raiDivider: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  td: {
    fontFamily: "'Open Sans', sans-serif",
    textAlign: "left",
    fontSize: "1vw",
    padding: "5px",
    width: "50%",
  },
  bottomIcon: {
    width: "25px",
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: "2px",
    marginTop: "-15px",
  },
  tableHeaders: {
    fontWeight: "bold",
  },
});

const ModalSidewalkPoint = ({ colorPicker, modalData }) => {
  const {
    Segment_RA: segmentRAI,
    Num_Trips: trippingHazards,
    Max_Roughn: roughness,
    Max_Cross_: crossSlope,
    Max_Runnin: runningSlope,
    width,
    Num_Dep: depressions,
  } = modalData.pointData.content.feature.properties;
  const classes = useStyles({ segmentRAI, colorPicker });

  return (
    <div>
      <Divider className={classes.raiDivider} />
      <Typography className={classes.rai}>
        RAI:{" "}
        <span className={classes.raiNumber}>
          {sidewalkMap[RAIToRoadwayRating(segmentRAI)]}
        </span>
      </Typography>
      <Divider className={classes.raiDivider} />
      <table style={{ width: "100%" }}>
        <tbody style={{ width: "100%" }}>
          <tr>
            <td className={classes.td}>
              <img
                className={classes.bottomIcon}
                src={trippingIcon}
                alt="tripping"
              />{" "}
              <span className={classes.tableHeaders}>Tripping Hazards: </span>{" "}
              {trippingHazards}
            </td>
            <td className={classes.td}>
              <img
                className={classes.bottomIcon}
                src={roughIcon}
                alt="roughness"
              />{" "}
              <span className={classes.tableHeaders}>Roughness:</span>{" "}
              {roughness}
            </td>
          </tr>
          <tr>
            <td className={classes.td}>
              <img className={classes.bottomIcon} src={crossIcon} alt="cross" />{" "}
              <span className={classes.tableHeaders}>Cross Slope:</span>{" "}
              {crossSlope}
            </td>
            <td className={classes.td}>
              <img
                className={classes.bottomIcon}
                src={runningIcon}
                alt="running"
              />{" "}
              <span className={classes.tableHeaders}>Running Slope:</span>{" "}
              {runningSlope}
            </td>
          </tr>
          <tr>
            <td className={classes.td}>
              <img className={classes.bottomIcon} src={widthIcon} alt="width" />{" "}
              <span className={classes.tableHeaders}>Width: </span>{" "}
              {width || "N/A"}
            </td>
            <td className={classes.td}>
              <img
                className={classes.bottomIcon}
                src={depressionsIcon}
                alt="depressions"
              />{" "}
              <span className={classes.tableHeaders}>Depressions:</span>{" "}
              {depressions}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

ModalSidewalkPoint.propTypes = {
  colorPicker: PropTypes.object.isRequired,
  modalData: PropTypes.object.isRequired,
};

export default ModalSidewalkPoint;
