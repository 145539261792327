import { osDrawerWidth } from "roadbotics-os-sidebar";

interface UiConstants {
  osDrawerWidth: number;
}

export const uiConstants: UiConstants = { osDrawerWidth };

// allow callers to change the drawer width
export function updateOsDrawerWidth(val: number): void {
  uiConstants.osDrawerWidth = val;
}
