import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import RatingsLayer from "components/RatingsLayer";

import { getCurrentToggleGroups } from "utils/toggleGroups";

const RatingsLayerContainer = ({ toggleGroups }) => {
  const currentToggleGroups = getCurrentToggleGroups(toggleGroups);
  return <RatingsLayer toggleGroups={currentToggleGroups} />;
};

const mapStateToProps = state => ({
  toggleGroups: state.toggles.groups,
});

RatingsLayerContainer.propTypes = {
  toggleGroups: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(RatingsLayerContainer);
