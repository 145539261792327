import React, { Component } from "react";
import PropTypes from "prop-types";
import getStyle from "utils/getStyle";
import Layer from "components/Layer";
import { connect } from "react-redux";
import sortBy from "lodash/sortBy";
import {
  RB_GREEN,
  RB_LIGHT_GREEN,
  RB_YELLOW,
  RB_ORANGE,
  RB_RED,
} from "constants/colors";

class LayerContainer extends Component {
  // this method converts all of the original colors to the newly
  // chosen colors using a switch case with the original color scheme hex colors
  // done this way to prevent having to change all of the data on the backend for each project
  renderColors = color => {
    const {
      colorPicker: {
        RATING_1,
        RATING_2,
        RATING_3,
        RATING_4,
        RATING_5,
        RATING_NON_PAVED,
      },
    } = this.props;
    switch (color) {
      case RB_GREEN:
        return RATING_1;
      case RB_LIGHT_GREEN:
        return RATING_2;
      case RB_YELLOW:
        return RATING_3;
      case RB_ORANGE:
        return RATING_4;
      case RB_RED:
        return RATING_5;
      case "#808080":
        return RATING_NON_PAVED;
      default:
        return color;
    }
  };

  buildLayer = (layer, style, clickBehavior) => {
    const layerStyle = getStyle(
      style,
      this.renderColors(layer.color),
      layer.visible,
      layer.sourceLayer
    );
    return {
      type: layerStyle.type,
      clickBehavior,
      mapId: layer.url,
      id: layer.sourceLayer,
      layout: layerStyle.layout,
      paint: layerStyle.paint,
      sourceLayer: layer.sourceLayer,
      source: {
        type: "vector",
        url: `mapbox://${layer.url}`,
      },
      images: layerStyle.images,
    };
  };

  render() {
    const { layers, style, position, beforeLayer, clickBehavior } = this.props;

    return sortBy(layers, [position])
      .filter(l => !l.hidden)
      .map(layer => {
        const layerData = this.buildLayer(layer, style);
        return (
          <Layer
            key={layer.id}
            layer={layerData}
            beforeLayer={beforeLayer}
            meta={{ clickBehavior, style, mapId: layer.url }}
          />
        );
      });
  }
}

LayerContainer.propTypes = {
  layers: PropTypes.array.isRequired,
  colorPicker: PropTypes.object.isRequired,
  style: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  beforeLayer: PropTypes.string.isRequired,
  clickBehavior: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  colorPicker: state.colorPicker,
});

export default connect(mapStateToProps)(LayerContainer);
