import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/form/Button";
import ActivityIndicatorButton from "components/ActivityIndicatorButton";

interface DeletePopupProps {
  deleteName: string;
  isOpen: boolean;
  handleDelete: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  handleClose: () => void;
  isExampleOrganization?: boolean;
  isProcessingDelete: boolean;
}
const DeletePopup = ({
  deleteName,
  isOpen,
  handleDelete,
  handleClose,
  isExampleOrganization,
  isProcessingDelete,
}: DeletePopupProps): React.ReactElement => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        !isProcessingDelete && handleClose();
      }}
    >
      <DialogTitle>{`Are you sure you want to delete: ${deleteName}?`}</DialogTitle>
      <DialogContent>
        <DialogContentText>This action cannot be undone.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            !isProcessingDelete && handleClose();
          }}
          color="default"
          variant="text"
        >
          Cancel
        </Button>

        <ActivityIndicatorButton
          onClick={handleDelete}
          isActive={isProcessingDelete}
          progressSize={14}
          disabled={isExampleOrganization}
        >
          Yes, Delete
        </ActivityIndicatorButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePopup;
