import RBAPI from "roadbotics-api-wrapper";
import { MappyAPI, VeritasAPI } from "roadbotics-api-wrapper";
import firebase from "config/firebase";

const roadBoticsAPI = new RBAPI(
  process.env.REACT_APP_ROADWAY_API_BASE,
  `${process.env.REACT_APP_ACCOUNTS_DOMAIN}/login`,
  firebase
);

const mappyAPI = new MappyAPI();
const veritasAPI = new VeritasAPI(
  process.env.REACT_APP_VERITAS_API_BASE,
  process.env.REACT_APP_VERITAS_API_EMAIL,
  process.env.REACT_APP_VERITAS_API_PASSWORD
);

export default roadBoticsAPI;
export { mappyAPI, veritasAPI };
