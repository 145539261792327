import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { drawerWidth } from "components/Sidebar";
import { useSelector } from "react-redux";
import State from "../interfaces/state";
import { uiConstants } from "../constants/ui";

const leftShift = 20;
const rightShift = 20;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "fixed",
      display: "flex",
      flexDirection: "column",
    },
    topLeftCorner: {
      top: rightShift + (theme.mixins.toolbar.minHeight as number),
      left: leftShift + uiConstants.osDrawerWidth,
    },
    bottomLeftCorner: {
      bottom: 25,
      left: leftShift + uiConstants.osDrawerWidth,
    },
    topRightCorner: {
      top: rightShift + (theme.mixins.toolbar.minHeight as number),
      right: rightShift,
    },
    bottomRightCorner: {
      bottom: 25,
      right: rightShift,
    },
    child: {
      alignSelf: "flex-end",
      flexGrow: 1,
    },
    sidebarShiftDesktop: {
      left: leftShift + drawerWidth + uiConstants.osDrawerWidth,
    },
  })
);

export enum CornerType {
  TopLeft = "topLeft",
  TopRight = "topRight",
  BottomLeft = "bottomLeft",
  BottomRight = "bottomRight",
}

interface CornerProps {
  children: {};
  corner: CornerType;
}

const Corner = ({
  children = {},
  corner = CornerType.BottomLeft,
}: CornerProps): React.ReactElement => {
  const classes = useStyles();
  const isSidebarOpen = useSelector(
    (state: State) => state.visibility.sidebarVisibility
  );
  return (
    <div
      className={classNames(classes.container, {
        [classes.sidebarShiftDesktop]:
          isSidebarOpen && corner !== CornerType.TopRight,
        [classes.topLeftCorner]: corner === CornerType.TopLeft,
        [classes.bottomLeftCorner]: corner === CornerType.BottomLeft,
        [classes.topRightCorner]: corner === CornerType.TopRight,
        [classes.bottomRightCorner]: corner === CornerType.BottomRight,
      })}
    >
      {React.Children.map(children, child => (
        <div className={classes.child}>{child}</div>
      ))}
    </div>
  );
};

export default Corner;
