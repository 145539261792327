import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import CloseModalIcon from "components/CloseModalIcon";
import PropTypes from "prop-types";
import { RB_ORANGE } from "constants/colors";
import { MeasuringSystem } from "constants/measuringSystem";

const style = theme => ({
  background: {
    position: "absolute",
    border_radius: "11px",
    backgroundColor: "#dcdcdc",
    textAlign: "center",
    padding: "10px 10px 15px 10px",
    bottom: 410,
    left: 48,
    width: "auto",
    height: "auto",
    overflow: "hidden",
    whiteSpace: "nowrap",
    opacity: 0.85,
    minWidth: "145px",
    marginTop: "-100px",
    minHeight: "137px",
  },
  text: {
    padding: "0px 0px 0px 0px",
    fontSize: 15,
    color: theme.palette.common.black,
    fontWeight: "300",
  },
  subText: {
    padding: "0px 0px 0px 0px",
    fontSize: 18,
    color: theme.palette.common.black,
    marginTop: "4px",
  },
  units: {
    padding: "0px 15px 0px 15px",
  },
  link: {
    fontSize: 12,
    textDecoration: "underline",
    color: RB_ORANGE,
    fontWeight: "600",
    cursor: "pointer",
  },
  superscript: {
    verticalAlign: "super",
    fontSize: 10,
    display: "inline-block",
  },
});

const ShowAreaContainer = props => {
  const {
    measureArea: { area, unit, isAreaing },
    classes,
    open,
    onClearArea,
    onChangeUnits,
    measuringSystem,
    clearArea,
  } = props;
  return open && isAreaing ? (
    <Paper className={classes.background}>
      <CloseModalIcon onClick={clearArea} />
      <Typography className={classes.text}>Area:</Typography>
      <div className={classes.units}>
        <Typography className={classes.subText}>
          {Math.round(area * 100) / 100}
        </Typography>
        <FormControl className={classes.formControl}>
          {measuringSystem === MeasuringSystem.Imperial ? (
            <Select value={unit} onChange={e => onChangeUnits(e)}>
              <MenuItem value="feet">
                feet
                <div className={classes.superscript}>2</div>
              </MenuItem>
              <MenuItem value="yards">
                yards
                <div className={classes.superscript}>2</div>
              </MenuItem>
              <MenuItem value="acres">acres</MenuItem>
              <MenuItem value="miles">
                miles
                <div className={classes.superscript}>2</div>
              </MenuItem>
            </Select>
          ) : (
            <Select value={unit} onChange={e => onChangeUnits(e)}>
              <MenuItem value="meters">
                meters
                <div className={classes.superscript}>2</div>
              </MenuItem>
              <MenuItem value="kilometers">
                kilometers
                <div className={classes.superscript}>2</div>
              </MenuItem>
            </Select>
          )}
        </FormControl>
      </div>
      <div>
        <button type="button" onClick={onClearArea} className={classes.link}>
          Clear
        </button>
      </div>
    </Paper>
  ) : (
    <div />
  );
};
ShowAreaContainer.propTypes = {
  measureArea: PropTypes.shape({
    area: PropTypes.number,
    unit: PropTypes.string,
    isAreaing: PropTypes.bool,
  }).isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClearArea: PropTypes.func.isRequired,
  onChangeUnits: PropTypes.func.isRequired,
  measuringSystem: PropTypes.string.isRequired,
  clearArea: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  measureArea: state.measureArea,
  colorPicker: state.colorPicker,
  measuringSystem: state.userData.measuringSystem,
});

export default connect(mapStateToProps)(withStyles(style)(ShowAreaContainer));
