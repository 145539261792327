import React from "react";
import classNames from "classnames";
import Drawer from "@material-ui/core/Drawer";
import { getAppBarHeight } from "utils/getAppBarHeight";
import { uiConstants } from "../constants/ui";
import ModalTab, { OpenSide } from "./ModalTab";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { topBannerHeight } from "../views/Banner";

export const drawerWidth = 320;

const useStyles = makeStyles({
  sidebar: {
    flexShrink: 0,
  },
  sidebarPaper: {
    display: "flex",
    flexDirection: "column",
    height: `calc(100vh - ${getAppBarHeight()}px)`,
    left: uiConstants.osDrawerWidth,
    marginTop: `${getAppBarHeight()}px`,
    overflowX: "hidden",
  },
  sidebarPaperBanner: {
    display: "flex",
    flexDirection: "column",
    height: `calc(100vh - ${getAppBarHeight()}px - ${topBannerHeight})`,
    left: uiConstants.osDrawerWidth,
    marginTop: `${getAppBarHeight() + topBannerHeight}px`,
  },
  sidebarDesktop: {
    width: `${drawerWidth}px`,
  },
  sidebarHeader: {
    flex: "0 0 auto",
    padding: "0px 10px",
  },
  sidebarContent: {
    flex: "1 1 auto",
    position: "relative" /* need this to position inner content */,
    "&::-webkit-scrollbar": {
      /* Scrollbar */
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      /* Track */
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      /* Handle */
      background: "#888",
    },

    "&::-webkit-scrollbar-thumb:hover": {
      /* Handle on hover */
      background: "#555",
    },
  },
  sidebarFooter: {
    flex: "0 0 auto",
    bottom: 0,
    position: "relative",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    width: `${drawerWidth}px`,
  },
});

interface SidebarProps {
  isTopBannerOpen: boolean;
  header: React.ReactNode;
  footer: React.ReactNode;
  children: React.ReactNode;
  open: boolean;
  openSidebar: () => void;
  closeSidebar: () => void;
}

const Sidebar = ({
  isTopBannerOpen,
  open,
  header,
  footer,
  children,
  openSidebar,
  closeSidebar,
}: SidebarProps) => {
  const classes = useStyles();

  return (
    <div>
      <ModalTab
        isTopBannerOpen={isTopBannerOpen}
        open={open}
        drawerWidth={`${drawerWidth}px`}
        openModalClick={openSidebar}
        enlargedImg={false}
        isModalDataPresent={true}
        closeModalClick={closeSidebar}
        largeDrawerWidth={`${drawerWidth}px`}
        openSide={OpenSide.LEFT}
      />
      <Drawer
        id="sidebarId"
        variant="persistent"
        className={classNames(classes.sidebar, {
          [classes.sidebarDesktop]: true,
        })}
        classes={{
          paper: classNames(classes.sidebarPaper, {
            [classes.sidebarDesktop]: true,
            [classes.sidebarPaperBanner]: isTopBannerOpen,
          }),
        }}
        open={open}
      >
        <div className={classes.sidebarHeader}>{header}</div>
        <div className={classes.sidebarContent}>{children}</div>
        <div className={classes.sidebarFooter}>{footer}</div>
      </Drawer>
    </div>
  );
};

export default Sidebar;
