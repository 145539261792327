const style = {
  container: {
    fontFamily: "'Open Sans', sans-serif",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    margin: "auto",
    // marginTop: "10px",
  },
  centerAlign: {
    textAlign: "center",
  },
  leftButton: {
    fontSize: "1vw",
  },
  rightButton: {
    fontSize: "1vw",
  },
  playButton: {
    fontSize: "2vw",
  },
  leftEnd: {
    height: "42px",
    width: "50px",
  },
  rightEnd: {
    height: "42px",
    width: "50px",
  },
  segmentClick: {
    padding: "0px 0px 0px",
  },
  fakeImage: {
    marginTop: "75px",
  },
};

export default style;
