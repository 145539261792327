import { STOP_ROTATION, START_ROTATION } from "actiontypes/rotation";

const initialState = {
  isRotating: false,
};

const rotation = (state = initialState, action) => {
  switch (action.type) {
    case STOP_ROTATION:
      return {
        ...state,
        isRotating: false,
      };
    case START_ROTATION:
      return {
        ...state,
        isRotating: true,
      };
    default:
      return state;
  }
};

export default rotation;
