import { CLOSE_COG, OPEN_COG } from "actiontypes/settingsCog";

const initialState = {
  settingsCogOpen: true,
};

const settingsCogReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_COG:
      return {
        settingsCogOpen: true,
      };
    case CLOSE_COG:
      return {
        settingsCogOpen: false,
      };
    default:
      return state;
  }
};

export default settingsCogReducer;
