import React from "react";
import Map from "components/Map";
import { connect } from "react-redux";
import {
  setMapCenter,
  setMapPitch,
  setMapZoom,
  setMapBounds,
  setMapBearing,
  setMapDefaultZoom,
} from "actions/map";
import get from "lodash/get";
import PropTypes from "prop-types";
import clickBehavior from "utils/clickBehavior/clickBehavior";
import track from "react-tracking";
import selectCone from "images/icons/select_cone.png";
import { setAzimuth } from "actions/highlight";
import { openModal } from "actions/modal";
import MapSkeleton from "skeletons/map";

import "./style.css";

import signs from "images/signs";
import {
  DEFAULT_MIN_ZOOM,
  DEFAULT_MAX_ZOOM,
  DEFAULT_ZOOM_LEVEL,
} from "../../constants/map";
import RBAPI from "api/RoadwayAPI";
import { getAssessmentType } from "../../utils/getAssessmentType";
import { AssessmentTypes } from "../../constants/assessmentTypes";

const MAP_IMAGES = {
  ...signs,
  selectCone,
};

class MapContainer extends React.Component {
  setCenterAndBounds = async () => {
    const { activeScan, setMapCenter, setMapBounds } = this.props;
    const scan = await RBAPI.fetchScan(activeScan).catch(e =>
      console.error("Error getting scans", e)
    );

    if (scan && scan.defaultZoom) {
      setMapDefaultZoom(scan.defaultZoom.min, scan.defaultZoom.max);
    }

    if (scan && scan.defaults) {
      const bounds = scan.defaults.bounds;
      setMapBounds(bounds);
      setMapCenter([(bounds[0] + bounds[2]) / 2, (bounds[1] + bounds[3]) / 2]);
    }
  };

  addMissingImageToMap = (map, imageId) => {
    map.loadImage(MAP_IMAGES[imageId], (error, image) => {
      if (error) throw error;
      if (!map.hasImage(imageId)) {
        map.addImage(imageId, image);
      }
    });
    // Reload cache of all non raster sources in the map
    // Used when adding new images, since Mapbox doesn't play well with image sizes changing.
    Object.values(map.style.sourceCaches).forEach(cache => {
      // Skip raster sources, like satellite tiles.
      if (cache._source.type !== "raster") cache.reload();
    });
  };

  onStyleImageMissing = (map, event) => {
    const { id: imageId } = event;
    this.addMissingImageToMap(map, imageId);
  };

  checkBounds = (ne, sw) => {
    const { bounds } = this.props;
    // handle crossing international date line
    if (sw[1] - ne[1] > 180 || ne[1] - sw[1] > 180) {
      sw[1] = (sw[1] + 360) % 360;
      ne[1] = (ne[1] + 360) % 360;
    }
    // calculate the center of the stored bounds
    const centerLat = (sw[0] + ne[0]) / 2;
    const centerLng = (sw[1] + ne[1]) / 2;
    // check if center is within bounds of original map position
    let ret = false;
    if (bounds[0] > bounds[2]) {
      if (bounds[1] > bounds[3]) {
        ret =
          bounds[0] > centerLat &&
          centerLat > bounds[2] &&
          bounds[1] > centerLng &&
          centerLng > bounds[3];
      } else {
        ret =
          bounds[0] > centerLat &&
          centerLat > bounds[2] &&
          bounds[3] > centerLng &&
          centerLng > bounds[1];
      }
    } else if (bounds[0] < bounds[2]) {
      if (bounds[1] > bounds[3]) {
        ret =
          bounds[2] > centerLat &&
          centerLat > bounds[0] &&
          bounds[1] > centerLng &&
          centerLng > bounds[3];
      } else {
        ret =
          bounds[2] > centerLat &&
          centerLat > bounds[0] &&
          bounds[3] > centerLng &&
          centerLng > bounds[1];
      }
    }
    return ret;
  };

  /**
   *
   * @param {Mapbox.Map} map
   */
  onStyleLoad = map => {
    const { activeScan, userUId, updateMapState } = this.props;
    updateMapState(map);

    map.once("idle", () => {
      map.addLayer(
        {
          id: "3d-buildings",
          source: "composite",
          "source-layer": "building",
          filter: ["==", "extrude", "true"],
          type: "fill-extrusion",
          minzoom: 15,
          paint: {
            "fill-extrusion-color": "#aaa",
            "fill-extrusion-height": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "height"],
            ],
            "fill-extrusion-base": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "min_height"],
            ],
            "fill-extrusion-opacity": 0.6,
          },
        },
        "road-label-small"
      );
    });

    // TODO: Use selected scan to determine center of map, bounding box and set searchbar
    this.setCenterAndBounds();

    if (process.env.REACT_APP_ISDEMO !== "1") {
      RBAPI.getUserBoundsState(userUId, activeScan)
        .then(bounds => {
          if (
            "NE" in bounds &&
            "SW" in bounds &&
            this.checkBounds(bounds.NE, bounds.SW)
          ) {
            // reset the zoom to the values from firestore
            // reset the center to the values from firestore
            map.fitBounds([bounds.NE, bounds.SW]);
          }

          map.setZoom(get(bounds, "level", DEFAULT_ZOOM_LEVEL));
        })
        .catch(err => {
          if (err?.response?.status === 404) {
            return;
          }
          throw err;
        });
    }
  };

  mapClick = (map, event) => {
    const {
      dispatch,
      colorPicker,
      isMeasuring,
      isAreaing,
      tracking: { trackEvent },
      isDynamicSegmentationActive,
      sidebarVisibility,
      modalVisibility,
      enlargedImg,
      potholeChecked,
      fatigueCrackingChecked,
      patchSealChecked,
      transLongChecked,
      surfaceDeteriorationChecked,
      pavementDistortionsChecked,
    } = this.props;
    if (!isMeasuring && !isAreaing && !isDynamicSegmentationActive) {
      clickBehavior.mapClick({
        map,
        event,
        dispatch,
        colorPicker,
        trackEvent,
        modalVisibility,
        sidebarVisibility,
        enlargedImg,
        potholeChecked,
        fatigueCrackingChecked,
        patchSealChecked,
        transLongChecked,
        surfaceDeteriorationChecked,
        pavementDistortionsChecked,
      });
    }
  };

  onRotate = map => {
    const { setAzimuth, originalAzimuth, setMapBearing } = this.props;

    const trueBearing = map.getBearing();
    setAzimuth(originalAzimuth - trueBearing);
    setMapBearing(trueBearing);
  };

  mapMouseMove = (map, event) => {
    const features = map.queryRenderedFeatures(event.point);
    const layer = features.find(feat =>
      get(feat.layer, "metadata.clickBehavior")
    );

    const cursor = map.getCanvas().style.cursor;
    if (layer && cursor === "") {
      map.getCanvas().style.cursor = "pointer";
    } else if (!layer && cursor === "pointer") {
      map.getCanvas().style.cursor = "";
    }
  };

  onMoveEnd = map => {
    const { userUId, isRotating, zoom, activeScan } = this.props;
    if (!isRotating) {
      const assessmentType = getAssessmentType();
      // If the assessment is road sense we do not want to keep track of the
      // users last state on the map
      if (assessmentType === AssessmentTypes.ROADSENSE) return;
      const scanState = {
        NE: [map.getBounds()._ne.lng, map.getBounds()._ne.lat],
        SW: [map.getBounds()._sw.lng, map.getBounds()._sw.lat],
        level: zoom[0],
      };
      RBAPI.updateUserBoundsState(userUId, activeScan, scanState).catch(err => {
        if (err?.response?.status === 404) {
          return;
        }
        throw err;
      });
    }
  };

  zoomStylesOpenSidebar = sidebarOpen => {
    if (
      document.getElementById("sidebarId") &&
      document.getElementById("appBarId")
    ) {
      let left = 24;
      const top = document.getElementById("appBarId").clientHeight + 20;

      if (sidebarOpen) {
        left = document.getElementById("sidebarId").clientWidth + left;
      }
      return {
        marginTop: top,
        marginLeft: left,
      };
    }
    return null;
  };

  render() {
    const {
      isMapDataLoaded,
      style,
      center,
      bounds,
      pitch,
      bearing,
      zoom,
      onMove,
      onZoom,
      onPitch,
      measuringSystem,
      sidebarVisibility,
      modalVisibility,
      defaultZoom,
      isScreenCaptureActive,
      map,
      isTopBannerOpen,
    } = this.props;

    if (map) {
      if (modalVisibility) {
        map.keyboard.disable();
      } else {
        map.keyboard.enable();
      }
    }

    if (map) {
      map.setMinZoom(parseInt(defaultZoom.min, 10) || DEFAULT_MIN_ZOOM);
      map.setMaxZoom(parseInt(defaultZoom.max, 10) || DEFAULT_MAX_ZOOM);
    }
    return (
      <div>
        {!(center && bounds) || !isMapDataLoaded ? (
          <MapSkeleton isTopBannerOpen={isTopBannerOpen} />
        ) : (
          <Map
            style={style}
            center={center}
            pitch={pitch}
            bearing={bearing}
            zoom={zoom}
            onMove={onMove}
            onZoom={onZoom}
            onRotate={this.onRotate}
            onPitch={onPitch}
            measuringSystem={measuringSystem}
            onStyleLoad={this.onStyleLoad}
            onClick={this.mapClick}
            onMouseMove={this.mapMouseMove}
            onMoveEnd={this.onMoveEnd}
            zoomStyles={this.zoomStylesOpenSidebar(sidebarVisibility)}
            map={map}
            onStyleImageMissing={this.onStyleImageMissing}
            isScreenCaptureActive={isScreenCaptureActive}
          />
        )}
      </div>
    );
  }
}

MapContainer.defaultProps = {
  isMapDataLoaded: false,
  onMove: undefined,
  bearing: undefined,
  isRotating: undefined,
  userUId: undefined,
  modalVisibility: false,
  enlargedImg: false,
  originalAzimuth: undefined,
  map: null,
  center: undefined,
  bounds: undefined,
};

MapContainer.propTypes = {
  isMapDataLoaded: PropTypes.bool,
  bounds: PropTypes.array,
  onZoom: PropTypes.func.isRequired,
  setMapBounds: PropTypes.func.isRequired,
  setMapBearing: PropTypes.func.isRequired,
  setMapCenter: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  measuringSystem: PropTypes.string.isRequired,
  colorPicker: PropTypes.object.isRequired,
  isMeasuring: PropTypes.bool.isRequired,
  isAreaing: PropTypes.bool.isRequired,
  tracking: PropTypes.shape({
    trackEvent: PropTypes.func.isRequired,
  }).isRequired,
  isDynamicSegmentationActive: PropTypes.bool.isRequired,
  zoom: PropTypes.array.isRequired,
  userUId: PropTypes.string,
  isRotating: PropTypes.bool,
  style: PropTypes.string.isRequired,
  center: PropTypes.array,
  pitch: PropTypes.number.isRequired,
  bearing: PropTypes.array,
  onMove: PropTypes.func,
  onPitch: PropTypes.func.isRequired,
  sidebarVisibility: PropTypes.bool.isRequired,
  modalVisibility: PropTypes.bool,
  enlargedImg: PropTypes.bool,
  updateMapState: PropTypes.func.isRequired,
  setAzimuth: PropTypes.func.isRequired,
  originalAzimuth: PropTypes.number,
  activeScan: PropTypes.string.isRequired,
  setMapDefaultZoom: PropTypes.func.isRequired,
  defaultZoom: PropTypes.object.isRequired,
  potholeChecked: PropTypes.bool.isRequired,
  fatigueCrackingChecked: PropTypes.bool.isRequired,
  patchSealChecked: PropTypes.bool.isRequired,
  transLongChecked: PropTypes.bool.isRequired,
  surfaceDeteriorationChecked: PropTypes.bool.isRequired,
  pavementDistortionsChecked: PropTypes.bool.isRequired,
  isScreenCaptureActive: PropTypes.bool.isRequired,
  map: PropTypes.object,
  isTopBannerOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  style: state.baseMap.style,
  pitch: state.baseMap.pitch,
  zoom: state.baseMap.zoom,
  bearing: state.baseMap.bearing,
  defaultZoom: state.baseMap.defaultZoom,
  user: state.user.email,
  measuringSystem: state.userData.measuringSystem,
  isMeasuring: state.measureDistance.isMeasuring,
  colorPicker: state.colorPicker,
  isAreaing: state.measureArea.isAreaing,
  userUId: state.user.userUId,
  sidebarVisibility: state.visibility.sidebarVisibility,
  modalVisibility: state.modal.isModalOpen,
  enlargedImg: state.modal.enlargedImg,
  azimuth: state.highlight.azimuth,
  originalAzimuth: state.highlight.originalAzimuth,
  isRotating: state.rotation.isRotating,
  isDynamicSegmentationActive:
    state.dynamicSegmentation.isDragActive ||
    state.dynamicSegmentation.isSelectActive ||
    state.dynamicSegmentation.isLassoActive,
  activeScan: state.userData.activeScan,
  fatigueCrackingChecked: state.isolateDistresses.fatigueCrackingChecked,
  patchSealChecked: state.isolateDistresses.patchSealChecked,
  pavementDistortionsChecked:
    state.isolateDistresses.pavementDistortionsChecked,
  surfaceDeteriorationChecked:
    state.isolateDistresses.surfaceDeteriorationChecked,
  potholeChecked: state.isolateDistresses.potholeChecked,
  transLongChecked: state.isolateDistresses.transLongChecked,
  isScreenCaptureActive: state.screenCapture.isActive,
  isTopBannerOpen: state.visibility.isTopBannerOpen,
});

const mapDispatchToProps = dispatch => ({
  onZoom: map => dispatch(setMapZoom([map.getZoom()])),
  onPitch: map => dispatch(setMapPitch(map.getPitch())),
  onRotate: map => dispatch(setMapBearing(map.getBearing())),
  setMapCenter: center => dispatch(setMapCenter(center)),
  setMapBounds: bounds => dispatch(setMapBounds(bounds)),
  setMapDefaultZoom: (min, max) => dispatch(setMapDefaultZoom(min, max)),
  setAzimuth: azimuth => dispatch(setAzimuth(azimuth)),
  setMapBearing: bearing => dispatch(setMapBearing(bearing)),
  openModal: modalData => dispatch(openModal(modalData)),
  dispatch,
});

export default track({ component: "map" })(
  connect(mapStateToProps, mapDispatchToProps)(MapContainer)
);
