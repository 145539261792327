import { hideModal, openModal, stopModal } from "actions/modal";
import {
  setAzimuth,
  setPointCoords,
  setOriginalAzimuth,
  showHighlight,
  hideHighlight,
} from "actions/highlight";
import { POINT } from "constants/highlightTypes";
import { bearingToAzimuth } from "@turf/helpers";
import { closeSidebar } from "actions/visibility";
import { isIPad } from "utils/deviceCheck";
import { centerMap } from "../pointsUtils";
import returnAddress from "../returnAddress";

const sidewalkPoint = async ({
  feature,
  dispatch,
  dispatchHighlight = true,
  showRating = true,
  leftAndRightPoints,
  modalVisibility,
  sidebarVisibility,
  enlargedImg,
  map,
  switchPoint = true,
  modalType,
}) => {
  const lng = feature.geometry.coordinates[0];
  const lat = feature.geometry.coordinates[1];

  const address = await returnAddress({ lng: lng, lat: lat });

  centerMap({
    map,
    enlargedImg,
    sidebarVisibility,
    modalVisibility,
    lng,
    lat,
  });

  const geometry = feature.geometry;
  dispatch(setOriginalAzimuth(bearingToAzimuth(feature.properties.bearing)));
  dispatch(
    setAzimuth(bearingToAzimuth(feature.properties.bearing - map.getBearing()))
  );
  dispatch(hideHighlight());
  dispatch(
    setPointCoords([
      parseFloat(geometry.coordinates[0].toFixed(8)),
      parseFloat(geometry.coordinates[1].toFixed(8)),
    ])
  );

  // close the sidebar if on iPad
  if (isIPad()) {
    dispatch(closeSidebar());
  }

  if (switchPoint) {
    dispatch(stopModal());
  }

  if (dispatchHighlight) {
    dispatch(showHighlight(POINT));
  }

  const style = {
    width: "40%",
    padding: "-5%",
    position: "fixed",
    zIndex: "5",
    backgroundColor: "white",
    marginTop: "-5%",
    marginLeft: "0%",
    borderRadius: "10px",
  };
  const expandedStyle = {
    width: "80%",
    position: "fixed",
    zIndex: "5",
    backgroundColor: "white",
    marginTop: "-45%",
    marginLeft: "-45%",
    borderRadius: "10px",
  };
  const content = {
    feature,
    closeButtonClick: hideModal,
    showRating,
    // currently is unused because virtual driver isn't implemented with sidewalks
    leftAndRightPoints,
    address: address,
  };
  const config = {
    hideCloseIcon: true,
    disableOverlay: true,
    style,
    expanded: expandedStyle,
  };

  dispatch(
    openModal({
      type: modalType,
      segmentData: {},
      pointData: {
        title: "Circle",
        ...config,
        content,
      },
    })
  );
};

export default sidewalkPoint;
