import React from "react";

type RoadSenseVideoProps = {
  size?: string;
};

const RoadSenseVideo = ({ size }: RoadSenseVideoProps): React.ReactElement => {
  const width = size === "large" ? "840" : "100%";
  const height = size === "large" ? "470" : "221";
  return (
    <iframe
      title="RoadSense"
      width={width}
      height={height}
      style={{ display: "block" }}
      src="https://www.youtube.com/embed/yeNiOU6plNQ"
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
};

export default RoadSenseVideo;
