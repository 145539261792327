import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import logo from "images/logos/RoadboticsLogoNoText.png";
import words from "images/logos/RoadBoticsLogo-wordonly.svg";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import background from "images/maps/background.jpg";
import { RB_YELLOW } from "constants/colors";

const useStyles = makeStyles({
  progress: {
    color: RB_YELLOW,
    zIndex: -1,
    opacity: ".7",
  },
  image: {
    width: "150px",
    zIndex: 1,
    position: "absolute",
    marginTop: "10px",
    marginLeft: "10px",
    opacity: ".7",
  },
  words: {
    width: "400px",
    position: "fixed",
    opacity: ".7",
    left: "50%",
    top: "60%",
    transform: "translate(-50%, -50%)",
  },
  imageContainer: {
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
  },
  divWhole: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    height: "100vh",
    zIndex: 100,
  },
  gloss: {
    height: "100vh",
    backgroundColor: "rgba(200,200,200,.8)",
  },
  loadingTxt: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "2em",
    fontWeight: 900,
    color: "#000",
    bottom: 10,
    right: 20,
    position: "fixed",
    opacity: ".8",
  },
});

const LoadingSpinnerContainer = () => {
  const classes = useStyles();
  return (
    <div className={classes.divWhole}>
      <div className={classes.gloss}>
        <Grid container>
          <Grid item xs={5} />
          <Grid item xs={1}>
            <div className={classes.container}>
              <div className={classes.imageContainer}>
                <img src={logo} alt="landinglogo" className={classes.image} />

                <CircularProgress size={170} className={classes.progress} />
              </div>
              <img src={words} alt="words" className={classes.words} />
            </div>
          </Grid>
          <Grid item xs={5} />
        </Grid>
        <Typography className={classes.loadingTxt}>loading...</Typography>
      </div>
    </div>
  );
};

export default LoadingSpinnerContainer;
