import React from "react";
import packageJson from "../../package.json";

const semanticVersionGreaterThan = (
  versionA: string,
  versionB: string
): boolean => {
  const versionsA = versionA.split(/\./g);
  const versionsB = versionB.split(/\./g);

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
    const b = Number(versionsB.shift());

    if (a === b) continue;
    return a > b || isNaN(b);
  }
  return false;
};

interface State {
  isLatestVersion: boolean;
  loading: boolean;
}
interface ChildrenProps extends State {
  refreshCacheAndReload: Function;
}
interface Props {
  children: (state: ChildrenProps) => React.ReactNode;
}
class CacheBuster extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
    };
  }

  refreshCacheAndReload = (): void => {
    console.log("Clearing cache and hard reloading...");
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(names => {
        names.forEach(name => caches.delete(name));
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  };

  componentDidMount(): void {
    fetch("/meta.json")
      .then(response => response.json())
      .then(meta => {
        const latestVersion = meta.version;
        const currentVersion = packageJson.version;

        const shouldForceRefresh = semanticVersionGreaterThan(
          latestVersion,
          currentVersion
        );
        if (shouldForceRefresh) {
          console.log(
            `We have a new version - ${latestVersion}. Should force refresh`
          );
          this.setState({ loading: false, isLatestVersion: false });
        } else {
          console.log(
            `You already have the latest version - ${latestVersion}. No cache refresh needed.`
          );
          this.setState({ loading: false, isLatestVersion: true });
        }
      });
  }

  render(): React.ReactNode {
    const { loading, isLatestVersion } = this.state;
    return this.props.children({
      loading,
      isLatestVersion,
      refreshCacheAndReload: this.refreshCacheAndReload,
    });
  }
}

export default CacheBuster;
