import produce from "immer";
import {
  ADD_LAYERS,
  HIDE_LAYER,
  SHOW_LAYER,
  TOGGLE_LAYER,
  CLEAR_LAYER_GROUPS,
  ADD_LAYER_GROUP,
  CHANGE_LAYER_NAME,
  CHANGE_LAYER_COLOR,
} from "actiontypes/layers";

let layerGroupId = 0;

const defaultState = {
  groups: [],
};

function modifyLayerVisibility(state, layerId, type) {
  return produce(state, draft => {
    const layerGroupWithLayerId = draft.groups.find(layerGroup =>
      layerGroup.layers.find(layer => layer.id === layerId)
    );
    const layer = layerGroupWithLayerId.layers.find(
      layer => layer.id === layerId
    );
    layer.visible =
      type === "toggle" ? !layer.visible : type === "show" ? true : false;
  });
}

function changeLayerName(state, layerId, name) {
  return produce(state, draft => {
    const layerGroupWithLayerId = draft.groups.find(layerGroup =>
      layerGroup.layers.find(layer => layer.id === layerId)
    );
    const layer = layerGroupWithLayerId.layers.find(
      layer => layer.id === layerId
    );
    layer.name = name;
  });
}

function changeLayerColor(state, layerId, color) {
  return produce(state, draft => {
    const layerGroupWithLayerId = draft.groups.find(layerGroup =>
      layerGroup.layers.find(layer => layer.id === layerId)
    );
    const layer = layerGroupWithLayerId.layers.find(
      layer => layer.id === layerId
    );
    layer.color = color;
  });
}

const layers = (state = defaultState, action) => {
  switch (action.type) {
    case ADD_LAYER_GROUP:
      return produce(state, draft => {
        draft.groups.push({
          projectName: action.projectName,
          name: action.name,
          id: layerGroupId++,
          style: action.style,
          clickBehavior: action.clickBehavior,
          position: action.position,
          layers: [],
        });
      });
    case ADD_LAYERS:
      return produce(state, draft => {
        const layerGroup = draft.groups.find(
          layerGroup => layerGroup.id === action.layerGroupId
        );
        layerGroup.layers.push(
          ...action.layers.map(layer => {
            return {
              id: layer.layerId,
              url: layer.url,
              color: layer.color,
              position: layer.position,
              hidden: layer.hidden,
              visible: layer.visible,
              sourceLayer: layer.sourceLayer,
              dateCreated: layer.dateCreated,
              dateDriven: layer.dateDriven,
              name: layer.name,
              layerId: layer.layerId,
            };
          })
        );
      });
    case TOGGLE_LAYER:
      return modifyLayerVisibility(state, action.id, "toggle");
    case SHOW_LAYER:
      return modifyLayerVisibility(state, action.id, "show");
    case HIDE_LAYER:
      return modifyLayerVisibility(state, action.id, "hide");
    case CHANGE_LAYER_NAME:
      return changeLayerName(state, action.id, action.name);
    case CHANGE_LAYER_COLOR:
      return changeLayerColor(state, action.id, action.color);
    case CLEAR_LAYER_GROUPS:
      return defaultState;
    default:
      return state;
  }
};

export default layers;
