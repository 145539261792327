import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

const styles = {
  divider: {
    margin: "auto",
    marginTop: "28px",
    width: "100%",
    padding: "8px",
    display: "inline-block",
  },
  ratingBar: {
    width: "100%",
    margin: "auto",
    textAlign: "center",
    marginBottom: "10px",
  },
  rating: {
    color: "#525252",
    fontSize: "26px",
    textAlign: "center",
    padding: "5px",
    display: "inline-block",
    paddingTop: "2px",
    paddingBottom: "0px",
  },
};

const SignageContent = ({
  classes,
  imgURL,
  ratingSign,
  colorPicker,
  rating,
}) => (
  <div className="clickBox">
    <div className="introdiv">
      <div
        style={{
          padding: "1%",
        }}
      >
        <div>
          <div className={classes.ratingBar}>
            <Grid container>
              <Grid item xs={5}>
                <Divider
                  className={classes.divider}
                  style={{
                    backgroundColor: colorPicker[`RATING_${rating}`],
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <div className={classes.rating}>
                  <img src={ratingSign} alt="rating" />
                </div>
              </Grid>
              <Grid item xs={5}>
                <Divider
                  className={classes.divider}
                  style={{
                    backgroundColor: colorPicker[`RATING_${rating}`],
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <img src={imgURL} alt="" />{" "}
        </div>
      </div>
    </div>
  </div>
);

SignageContent.defaultProps = {
  rating: 0,
};

SignageContent.propTypes = {
  classes: PropTypes.object.isRequired,
  imgURL: PropTypes.string.isRequired,
  ratingSign: PropTypes.string.isRequired,
  colorPicker: PropTypes.object.isRequired,
  rating: PropTypes.number,
};

export default withStyles(styles)(SignageContent);
