import React, { FormEvent } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "components/Dialog/Dialog";
import TextField from "components/form/TextField";
import Select from "components/form/Select";
import ActivityIndicatorButton from "components/ActivityIndicatorButton";
import { useForm, Controller } from "react-hook-form";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  formControl: {
    width: "100%",
  },
});

interface Organization {
  id: string;
  name: string;
  childrenOrganizations?: Organization[];
}

interface CopyDialog {
  title: string;
  isOpen: boolean;
  organizations: Organization[];
  handleClose: () => void;
  handleCopy: (formState: object) => void;
  values?: {
    organizationId?: string;
  };
  isCopyActive: boolean;
}

const ScansAdminCopyFormDialog = ({
  title,
  isOpen,
  organizations,
  handleClose,
  handleCopy,
  values: initialFormState,
  isCopyActive,
}: CopyDialog): JSX.Element => {
  const { register, errors, control, handleSubmit } = useForm();
  const classes = useStyles();

  const handleFormSubmit = (e: FormEvent): void => {
    e.preventDefault();
    handleSubmit(data => {
      // have to expand the initialFormState here so the other initial values can be set correctly
      handleCopy({ ...initialFormState, ...data });
    })(e);
  };

  const renderForm = (): JSX.Element => (
    <form onSubmit={handleFormSubmit}>
      <div>
        <TextField
          label="New Scan Display Name"
          name="displayName"
          inputRef={register({
            required: "This field is required.",
          })}
          error={errors?.displayName !== undefined}
          helperText={errors?.displayName?.message}
        />
        <FormControl
          error={errors?.organizationId !== undefined}
          className={classes.formControl}
        >
          <Controller
            name={"organizationId"}
            control={control}
            rules={{
              required: "This field is required",
            }}
            defaultValue={initialFormState?.organizationId}
            as={
              <Select displayEmpty name="organizationId">
                <MenuItem value="" disabled>
                  Select Organization
                </MenuItem>
                {organizations &&
                  organizations.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
              </Select>
            }
          />
          <FormHelperText>{errors?.organizationId?.message}</FormHelperText>
        </FormControl>
      </div>
    </form>
  );

  const renderFormActions = (): JSX.Element => (
    <ActivityIndicatorButton
      onClick={handleFormSubmit}
      isActive={isCopyActive}
      progressSize={14}
      disableButtonWhileActive={false}
      disabled={false}
    >
      Submit
    </ActivityIndicatorButton>
  );

  return (
    <Dialog
      title={title}
      isOpen={isOpen}
      handleClose={handleClose}
      actions={renderFormActions()}
    >
      {renderForm()}
    </Dialog>
  );
};

export default ScansAdminCopyFormDialog;
