import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";

const useStyles = makeStyles({
  ribbon: {
    width: "150px",
    height: "150px",
    overflow: "hidden",
    position: "absolute",
    top: "16px",
    right: "16px",
    zIndex: 100,
  },
  content: {
    position: "absolute",
    display: "block",
    width: "225px",
    padding: "15px 0",
    backgroundColor: "#3498db",
    boxShadow: "0 5px 10px rgba(0,0,0,.1)",
    color: "#fff",
    textShadow: "0 1px 1px rgba(0,0,0,.2)",
    textTransform: "uppercase",
    textAlign: "center",
    left: "-25px",
    top: "30px",
    transform: "rotate(45deg)",
    font: "https://fonts.googleapis.com/css?family=Lato:700",
  },
});

interface RibbonProps {
  children: React.ReactNode;
}

const Ribbon = ({ children }: RibbonProps): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.ribbon}>
      <span className={classes.content}>{children}</span>
    </div>
  );
};

export default Ribbon;
