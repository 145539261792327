import React from "react";
import classNames from "classnames";
import withStyles from "@material-ui/styles/withStyles";

import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

import { DarkMap, LightMap, SatelliteMap, TopographicMap } from "images/maps";
import PropTypes from "prop-types";

const style = {
  action: {
    border: "1px solid #FFF",
    backgroundSize: "contain",
    "&$DarkMap": {
      backgroundImage: `url(${DarkMap})`,
    },
    "&$LightMap": {
      backgroundImage: `url(${LightMap})`,
    },
    "&$SatelliteMap": {
      backgroundImage: `url(${SatelliteMap})`,
    },
    "&$TopographicMap": {
      backgroundImage: `url(${TopographicMap})`,
    },
  },
  DarkMap: {},
  LightMap: {},
  SatelliteMap: {},
  TopographicMap: {},
};

const SpeedDialMapAction = ({
  classes,
  className,
  backgroundImage,
  ...rest
}) => (
  <SpeedDialAction
    icon="" // Use empty sting since with a map button we are styling the background
    className={classNames(className, classes.action, classes[backgroundImage])}
    {...rest}
  />
);

SpeedDialMapAction.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string.isRequired,
};

export default withStyles(style)(SpeedDialMapAction);
