import {
  TOGGLE_AREA_MODE,
  UPDATE_AREA,
  CLEAR_ALL_AREA,
  CLEAR_SINGLE_AREA,
  SET_AREA,
} from "actiontypes/measureArea";
import { clearAllMeasure } from "./measureDistance";

export function toggleAreaMode() {
  return {
    type: TOGGLE_AREA_MODE,
  };
}

export function onArea() {
  return dispatch => {
    dispatch(clearAllMeasure());
    dispatch(toggleAreaMode());
  };
}

export function updateArea(area, unit) {
  return {
    type: UPDATE_AREA,
    area,
    unit,
  };
}

export function offArea() {
  return dispatch => {
    dispatch(toggleAreaMode());
    dispatch(updateArea(null, null));
  };
}

export function setArea(area, unit) {
  return {
    type: SET_AREA,
    area,
    unit,
  };
}

export function clearAllArea() {
  return {
    type: CLEAR_ALL_AREA,
  };
}

export function clearSingleArea() {
  return {
    type: CLEAR_SINGLE_AREA,
  };
}
