import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";

import ToolbarToggleCheckbox from "containers/ToolbarToggleCheckbox";
import sortBy from "lodash/sortBy";
import track from "react-tracking";
import * as Sentry from "@sentry/browser/dist/index";
import PropTypes from "prop-types";

const style = theme => ({
  popoverButton: {
    alignSelf: "stretch",
  },
  paper: {
    marginTop: `${theme.spacing()}px`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
});

class PopoverButton extends React.Component {
  state = {
    anchorEl: null,
  };

  handlePopoverOpen = event => {
    this.props.tracking.trackEvent({
      event: "mouse-click",
      action: "popover-open",
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `popover open`,
      level: Sentry.Severity.Info,
    });

    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.props.tracking.trackEvent({
      event: "mouse-click",
      action: "popover-close",
    });
    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `popover close`,
      level: Sentry.Severity.Info,
    });
    this.setState({ anchorEl: null });
  };

  componentDidUpdate() {
    // This is needed for the popover container to update its position after content has loaded
    if (this.popoverActions) this.popoverActions.updatePosition();
  }

  render() {
    const { buttonIcon, buttonIconText, toggles, classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <React.Fragment>
        <Button
          aria-owns={open ? "popover-container" : undefined}
          aria-haspopup="true"
          onClick={this.handlePopoverOpen}
          disableRipple
          className={classes.popoverButton}
          data-testid={
            open
              ? `open-popover-${buttonIconText}`
              : `closed-popover-${buttonIconText}`
          }
        >
          <img src={buttonIcon} alt={buttonIconText} />
        </Button>
        <Popover
          PaperProps={{ "data-testid": "sign-checkboxes" }}
          id="popover-container"
          open={open}
          anchorEl={anchorEl}
          onClose={this.handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          classes={{
            paper: classes.paper,
          }}
          action={actions => (this.popoverActions = actions)}
        >
          {sortBy(toggles, "position").map(toggle => (
            <ToolbarToggleCheckbox
              key={`toolbarToggleCheckbox-${toggle.id}`}
              toggle={toggle}
            />
          ))}
        </Popover>
      </React.Fragment>
    );
  }
}

PopoverButton.propTypes = {
  tracking: PropTypes.object.isRequired,
  buttonIcon: PropTypes.string.isRequired,
  buttonIconText: PropTypes.string.isRequired,
  toggles: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default track()(withStyles(style)(PopoverButton));
