import React, { useState } from "react";
import MaterialTable, { Icons } from "material-table";
import Typography from "@material-ui/core/Typography";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import Button from "components/form/Button";
import { TABLE_HEADER_BACKGROUND, TABLE_HEADER_TEXT } from "constants/colors";
import styleTableRow from "utils/styleTableRow";
import KeyboardArrowDownRounded from "@material-ui/icons/KeyboardArrowDownRounded";
import moment from "moment-timezone";
import get from "lodash/get";
import omit from "lodash/omit";
import pick from "lodash/pick";
import AddIcon from "@material-ui/icons/Add";
import Scans from "interfaces/scan";
import TableSkeleton from "skeletons/table";

interface OrganizationScansInterface {
  isScansLoaded: boolean;
  scans: Scans[];
  handleEdit: (data: Scans[]) => void;
  handleDelete: (data: Scans[]) => void;
  handleCopy: (data: Scans[]) => void;
  defaultZoom: { min: number; max: number };
  organizationName: string;
  handleClickCreate: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

function FileCopyOutlinedIconComponent(): React.ReactElement {
  return <FileCopyOutlinedIcon />;
}

const OrganizationScans: React.FC<OrganizationScansInterface> = ({
  isScansLoaded,
  scans,
  handleEdit,
  handleDelete,
  handleCopy,
  organizationName = "",
  defaultZoom,
  handleClickCreate,
}) => {
  const [pageSize, setPageSize] = useState(5);
  return (
    <div>
      <Button
        onClick={handleClickCreate}
        disabled={!isScansLoaded}
        variant="contained"
        color="primary"
        capitalizeLabel={false}
      >
        <AddIcon />
        {`Create a new assessment for ${organizationName}`}
      </Button>
      <MaterialTable
        isLoading={!isScansLoaded}
        style={{ boxShadow: "none" }}
        onChangeRowsPerPage={setPageSize}
        options={{
          pageSizeOptions: [5, 10, 20, 50],
          pageSize,
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: TABLE_HEADER_BACKGROUND,
            color: TABLE_HEADER_TEXT,
            fontWeight: "bold",
            fontSize: "14px",
          },
          rowStyle: styleTableRow,
        }}
        actions={[
          {
            icon: FileCopyOutlinedIconComponent,
            tooltip: "Copy Assessment",
            onClick: (_event, rowData): void => {
              handleCopy(omit(rowData, ["tableData"]) as Scans[]); // rm tableData from MatTable
            },
          },
          {
            icon: "edit",
            tooltip: "Edit Assessment",
            onClick: (_event, rowData): void => {
              handleEdit(
                // Currently only min, max, organization and the display name can be edited.
                // Also keep the id.
                pick(rowData, [
                  "defaultZoom.min",
                  "defaultZoom.max",
                  "organizationId",
                  "displayName",
                  "id",
                ]) as Scans[]
              );
            },
          },
          {
            icon: "delete",
            tooltip: "Delete Assessment",
            onClick: (_event, rowData): void => {
              handleDelete(omit(rowData, ["tableData"]) as Scans[]); // rm tableData from MatTable
            },
          },
        ]}
        title={`${organizationName || ""} Assessments`}
        columns={[
          { title: "Assessment Display Name", field: "displayName" },
          { title: "Assessment Name", field: "name" },
          {
            title: "Date Created",
            customSort: (row1, row2): number => {
              return moment(row1.dateCreated).diff(
                moment(row2.dateCreated),
                "seconds"
              );
            },
            render: function renderDateCreated(rowData): React.ReactElement {
              return (
                <div>
                  {moment(rowData.dateCreated).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}
                </div>
              );
            },
          },
          {
            title: "Min Zoom Level",
            render: function RenderMinZoomLevel(rowData): React.ReactElement {
              return (
                <div>{get(rowData, "defaultZoom.min", defaultZoom.min)}</div>
              );
            },
          },
          {
            title: "Max Zoom Level",
            render: function renderMaxZoomLevel(rowData): React.ReactElement {
              return (
                <div>{get(rowData, "defaultZoom.max", defaultZoom.max)}</div>
              );
            },
          },
        ]}
        data={scans}
        icons={{ SortArrow: KeyboardArrowDownRounded } as Icons}
        localization={{
          header: { actions: "" },
          body: {
            emptyDataSourceMessage: ((isScansLoaded ? (
              <Typography>
                No records to display. Contact RoadBotics if this is incorrect
              </Typography>
            ) : (
              <TableSkeleton />
            )) as unknown) as string,
          },
        }}
      />
    </div>
  );
};

export default OrganizationScans;
