import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { RB_LIGHT_GREY } from "../constants/colors";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "11px",
  },
  topBlock: {
    display: "flex",
    flexDirection: "row",
  },
  bottomBlock: {
    display: "flex",
    flexDirection: "row",
  },
  block: {
    background: RB_LIGHT_GREY,
    flexGrow: 1,
    marginTop: "8px",
    marginBottom: "0px",
    marginLeft: "4px",
    marginRight: "4px",
    paddingTop: "10px",
    paddingBottom: "6px",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
});

interface AnalysisViewProps {
  rows: number;
}

const DistressSlidersSkeleton: React.FC<AnalysisViewProps> = ({ rows }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {[...Array(rows).keys()].map(i => {
        return (
          <div key={`${i}-skeleton-block`} className={classes.topBlock}>
          {[...Array(2).keys()].map(j => {
              return (
                <Skeleton
                  key={`${i}${j}`}
                  className={classes.block}
                  variant="rect"
                  height={74}
                  width={450}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default DistressSlidersSkeleton;
