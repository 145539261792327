import {
  TOGGLE_MEASURE_MODE,
  UPDATE_DISTANCE,
  CLEAR_SINGLE_MEASURE,
  CLEAR_ALL_MEASURE,
  MEASURE_COLOR_CHANGE,
  RESET_CLEARING_TOGGLES,
} from "actiontypes/measureDistance";
import { clearAllArea } from "./measureArea";

export function toggleMeasureMode() {
  return {
    type: TOGGLE_MEASURE_MODE,
  };
}

export function onMeasure() {
  return dispatch => {
    dispatch(clearAllArea());
    dispatch(toggleMeasureMode());
  };
}

export function offMeasure() {
  return dispatch => {
    dispatch(toggleMeasureMode());
  };
}

export function updateDistance(distance, unit) {
  return {
    type: UPDATE_DISTANCE,
    distance,
    unit,
  };
}

export function clearSingleMeasure() {
  return {
    type: CLEAR_SINGLE_MEASURE,
  };
}

export function clearAllMeasure() {
  return {
    type: CLEAR_ALL_MEASURE,
  };
}

export function measureColorChange() {
  return {
    type: MEASURE_COLOR_CHANGE,
  };
}

export function resetClearMeasureToggles() {
  return {
    type: RESET_CLEARING_TOGGLES,
  };
}
