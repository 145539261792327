import React from "react";
import IsolateDistressGroupContainer from "containers/IsolateDistressGroupContainer";
import IndividualDistressContainer from "containers/IndividualDistressContainer";
import CircleModalContent from "components/CircleModalContent";
import get from "lodash/get";
import { getDirection } from "utils/bearing";
import { determineRatingSign, determineCrackSealSign } from "utils/ratings";
import LineModalContent from "components/LineModalContent";
import PropTypes from "prop-types";
import { centerMap } from "utils/pointsUtils";
import track from "react-tracking";
import * as Sentry from "@sentry/browser/dist/index";
import BottomBarContainer from "containers/BottomBarContainer/BottomBarContainer";
import { modalTabDefinitions } from "constants/modal";
import PCIScoreBar from "../../../components/PCIScoreBar";

const ModalContent = ({
  hideModal,
  enlargedImg,
  modalData,
  colorPicker,
  map,
  pointCoords,
  userUId,
  expandImg,
  tracking,
  shrinkImg,
  closeSidebar,
  modalTab,
}) => {
  const pointContent = get(modalData, "pointData.content", {});
  const segmentContent = get(modalData, "segmentData.content", {});

  const enlargeImageClick = () => {
    const [lng, lat] = pointCoords;
    tracking.trackEvent({
      event: "mouse-click",
      action: "enlarge-image",
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `enlarge image`,
      level: Sentry.Severity.Info,
    });
    centerMap({
      map,
      enlargedImg: true,
      sidebarVisibility: false,
      modalVisibility: true,
      lng,
      lat,
    });
    closeSidebar();
    expandImg();
  };

  const shrinkImageClick = () => {
    tracking.trackEvent({
      event: "mouse-click",
      action: "shrink-image",
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `shrink image`,
      level: Sentry.Severity.Info,
    });
    const [lng, lat] = pointCoords;
    centerMap({
      map,
      enlargedImg: false,
      sidebarVisibility: false,
      modalVisibility: true,
      lng,
      lat,
    });
    shrinkImg();
  };

  return (
    <React.Fragment>
      {modalData.type === "point/segment" && (
        <LineModalContent
          chartData={segmentContent.chartData}
          averageRating={segmentContent.averageRating}
          length={segmentContent.length}
          colorPicker={colorPicker}
          chartOptions={segmentContent.chartOptions}
        />
      )}
      {segmentContent.segmentDistressStatistics && (
        <IndividualDistressContainer
          segmentDistressStatistics={segmentContent.segmentDistressStatistics}
        />
      )}
      {segmentContent.pciScore && (
        <PCIScoreBar pciScore={segmentContent.pciScore} />
      )}
      {pointContent.primaryDistresses &&
        modalTab === modalTabDefinitions.IDI && (
          <IsolateDistressGroupContainer map={map} />
        )}
      <CircleModalContent
        imgURL={pointContent.feature.properties.href}
        color={pointContent.feature.properties.color}
        rating={pointContent.feature.properties.rating}
        timestamp={pointContent.feature.properties.timestamp}
        longitude={pointContent.feature.geometry.coordinates[0]}
        latitude={pointContent.feature.geometry.coordinates[1]}
        showRating={pointContent.showRating}
        compassDirection={getDirection(pointContent.feature.properties.bearing)}
        bearing={pointContent.feature.properties.bearing}
        closeButtonClick={() => hideModal()}
        isExpanded={enlargedImg}
        imgClick={() =>
          enlargedImg ? shrinkImageClick() : enlargeImageClick()
        }
        colorPicker={colorPicker}
        streetName={pointContent.address}
        ratingSign={determineRatingSign(pointContent.feature.properties.rating)}
        primaryDistresses={pointContent.primaryDistresses}
        map={map}
        csDistresses={pointContent.csDistresses}
        crackSealColor={pointContent.crackSealColor}
        csSign={
          pointContent.csDistresses &&
          determineCrackSealSign(pointContent.csDistresses)
        }
        modalDataType={modalData.type}
        modalTab={modalTab}
      />

      <BottomBarContainer />
    </React.Fragment>
  );
};

export default track()(ModalContent);

ModalContent.propTypes = {
  hideModal: PropTypes.func.isRequired,
  enlargedImg: PropTypes.bool.isRequired,
  modalData: PropTypes.object.isRequired,
  colorPicker: PropTypes.object.isRequired,
  map: PropTypes.object.isRequired,
  pointCoords: PropTypes.array,
  userUId: PropTypes.string.isRequired,
  expandImg: PropTypes.func.isRequired,
  tracking: PropTypes.object.isRequired,
  shrinkImg: PropTypes.func.isRequired,
  closeSidebar: PropTypes.func.isRequired,
};
