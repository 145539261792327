import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import track from "react-tracking";
import { withRouter } from "react-router-dom";
import MapContainer from "containers/MapContainer/MapContainer";
import { setUser } from "actions/login";
import SidebarContainer from "containers/SidebarContainer";
import { setMapPitch, setMapZoom, setMapBearing } from "actions/map";
import { setSnackbarMessageAndOpen, MessageTypes } from "actions/snackbar";
import "./style.css";
import { processAssessment } from "actions/assessment";
import HubSpotChatWindow from "utils/HubSpotChatWindow";
import { hideModal } from "actions/modal";
import { closeSidebar, openSidebar } from "../../actions/visibility";
import GeocoderContainer from "containers/GeocoderContainer";
import Corner, { CornerType } from "components/Corner";
import Compass from "containers/Compass/Compass";
import SettingsCog from "containers/SettingsCog";
import { toggleLayerBySource } from "actions/layers";
import Hidden from "@material-ui/core/Hidden";
import ModalSidebarContainer from "containers/ModalSidebarContainer";
import AppBarContainer from "containers/AppBarContainer";
import { setActiveOrganization, setActiveScan } from "actions/userData";
import packageJson from "../../../package.json";
import { resetModal } from "../../actions/modal";
import mungey2 from "../../utils/mungey2";
import { setIsMapDataLoaded } from "../../actions/map";
import SliderGroupContainer from "../SliderGroupContainer";
import { ROUTE_SUMMARY, ROUTE_NOT_FOUND } from "../../constants/routes";
import { clearLayerGroups } from "../../actions/layers";
import AnalysisView from "./AnalysisView";
import RoadSenseControls from "../RoadSenseControls";
import TitleSkeleton from "../../skeletons/title";
import TabsContainer from "containers/TabsContainer";
import RBAPI from "api/RoadwayAPI";

const styles = {
  root: {
    display: "flex",
  },
  header: {
    margin: "5px",
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "22px",
    fontWeight: "bold",
  },
  footer: {
    color: "rgb(155,155,155)",
    marginBottom: "3px",
    width: "100%",
  },
  scanName: {
    fontSize: "20px",
    fontFamily: "'Open Sans', sans-serif",
    textAlign: "center",
    margin: "20px 5px 5px 5px",
    wordWrap: "break-word",
    fontWeight: "700",
  },
};

const MapView = ({
  setActiveScan,
  processAssessment,
  resetModal,
  openSidebar,
  toggleLayerBySource,
  setIsMapDataLoaded,
  match,
  layerGroups,
  history,
  activeScan: activeScanId,
  userUId,
  measuringSystem,
  location,
  isMapDataLoaded,
  setSnackbarMessageAndOpen,
  isTopBannerOpen,
}) => {
  const [map, setMap] = useState(null);
  const [activeScanData, setActiveScanData] = useState({});
  const [isActiveScanDataLoaded, setIsActiveScanDataLoaded] = useState(false);

  const loadLayerStateFromFireStore = useCallback(() => {
    RBAPI.getUserLayerState(userUId, activeScanId)
      .then(layers => {
        Object.keys(layers).forEach(key => {
          const value = layers[key];
          toggleLayerBySource(key, value);
        });
      })
      .catch(err => {
        console.error(err);
      });
  }, [activeScanId, toggleLayerBySource, userUId]);

  const updateMapState = map => {
    setMap(map);
  };

  const fetchActiveScanData = useCallback(async () => {
    setIsActiveScanDataLoaded(false);
    if (activeScanId) {
      try {
        const scan = await RBAPI.fetchScan(activeScanId).catch(err => {
          if (err?.response?.status === 404) {
            //
            setSnackbarMessageAndOpen(
              "Could not find assessment",
              MessageTypes.ERROR
            );
            return;
          }
          throw err;
        });
        const { name, displayName } = scan;
        const networkLength = scan?.analysis?.networkLength;
        const networkScore = scan?.analysis?.networkScore;
        const networkPCI = scan?.analysis?.networkPCI;
        const sidewalkLength = scan?.sidewalkAnalysis?.sidewalkLength;
        const sidewalkScore = scan?.sidewalkAnalysis?.sidewalkScore;
        const potholeCount = scan?.analysis?.potholeCount;
        const defaultMapBounds = scan?.defaults?.bounds || [0, 0, 0, 0];
        const defaultMapCenter = [
          (defaultMapBounds[0] + defaultMapBounds[2]) / 2,
          (defaultMapBounds[1] + defaultMapBounds[3]) / 2,
        ];
        setActiveScanData({
          networkLength,
          networkScore,
          networkPCI,
          sidewalkLength,
          sidewalkScore,
          potholeCount,
          name,
          displayName,
          defaultMapBounds,
          defaultMapCenter,
        });
        setIsActiveScanDataLoaded(true);
      } catch (error) {
        console.error(error);
      }
    }
  }, [activeScanId, setSnackbarMessageAndOpen]);

  const handleClickAnalysisButton = () => {
    history.push(`${ROUTE_SUMMARY}/${activeScanId}`);
  };

  const setNewScanCallback = useCallback(async () => {
    HubSpotChatWindow.destroy();
    resetModal();

    const loadActiveScan = async () => {
      setIsMapDataLoaded(false);
      openSidebar();

      // HubSpot Stuff
      HubSpotChatWindow.create();

      clearLayerGroups();
      const mapId = match.params.mapId;
      setActiveScan(mapId);

      if (layerGroups.length === 0 && userUId && activeScanId) {
        try {
          const layers = await RBAPI.fetchLayersByScan(mapId).catch(err => {
            if (err?.response?.status === 404) {
              setSnackbarMessageAndOpen(
                "Could not find assessments",
                MessageTypes.ERROR
              );
              return;
            }
            throw err;
          });
          const munged = mungey2.startMunge(layers);
          processAssessment(munged);
          // set the layers only after mungey is done getting data
          if (process.env.REACT_APP_ISDEMO !== "1") {
            loadLayerStateFromFireStore();
          }
        } catch (e) {
          if (e.response && e.response.status === 404) {
            history.push(ROUTE_NOT_FOUND);
          }
        }
      }
    };

    await loadActiveScan();
    await fetchActiveScanData();

    setIsMapDataLoaded(true);
  }, [
    resetModal,
    activeScanId,
    fetchActiveScanData,
    history,
    layerGroups.length,
    loadLayerStateFromFireStore,
    match.params.mapId,
    openSidebar,
    processAssessment,
    setActiveScan,
    setIsMapDataLoaded,
    userUId,
    setSnackbarMessageAndOpen,
  ]);

  useEffect(() => {
    setNewScanCallback();
    setIsActiveScanDataLoaded(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeScanId]);

  return (
    <div>
      <AppBarContainer urlLocation={location.pathname} />
      <div>
        <MapContainer
          isMapDataLoaded={isMapDataLoaded}
          updateMapState={updateMapState}
          map={map}
          center={activeScanData.defaultMapCenter}
          bounds={activeScanData.defaultMapBounds}
        />
        <Corner corner={CornerType.TopRight}>
          <Hidden mdDown>
            <Compass
              isMapDataLoaded={isMapDataLoaded && map !== null}
              isTopBannerOpen={isTopBannerOpen}
            />
          </Hidden>
        </Corner>
        <Corner corner={CornerType.BottomLeft}>
          <SettingsCog isMapDataLoaded={isMapDataLoaded} map={map} />
        </Corner>
        <SidebarContainer
          footer={
            packageJson && (
              <Typography align="center" style={styles.footer} variant="body2">
                {`v${packageJson.version}`}
              </Typography>
            )
          }
          header={
            <React.Fragment>
              {!isActiveScanDataLoaded ? (
                <TitleSkeleton />
              ) : (
                (activeScanData.displayName || activeScanData.name) && (
                  <Typography style={styles.scanName}>
                    {activeScanData.displayName || activeScanData.name}
                  </Typography>
                )
              )}
              {(
                <AnalysisView
                  type="Centerline"
                  isLoaded={isActiveScanDataLoaded}
                  score={activeScanData.networkScore}
                  length={activeScanData.networkLength}
                  pci={activeScanData.networkPCI}
                  potholeCount={activeScanData.potholeCount}
                  handleClickAnalysisButton={handleClickAnalysisButton}
                  measuringSystem={measuringSystem}
                />
              ) || (
                <AnalysisView
                  type="Sidewalk"
                  isLoaded={isActiveScanDataLoaded}
                  score={activeScanData.sidewalkScore}
                  length={activeScanData.sidewalkLength}
                  handleClickAnalysisButton={handleClickAnalysisButton}
                  measuringSystem={measuringSystem}
                />
              )}
            </React.Fragment>
          }
        >
          <GeocoderContainer isMapDataLoaded={isMapDataLoaded} map={map} />
          <TabsContainer />
          <SliderGroupContainer />
          <RoadSenseControls map={map} />
        </SidebarContainer>
        {map && <ModalSidebarContainer map={map} />}
      </div>
    </div>
  );
};

MapView.propTypes = {
  processAssessment: PropTypes.func.isRequired,
  toggleLayerBySource: PropTypes.func.isRequired,
  layerGroups: PropTypes.array.isRequired,
  openSidebar: PropTypes.func.isRequired,
  activeScan: PropTypes.string.isRequired,
  resetModal: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  tracking: PropTypes.object.isRequired,
  // eslint-disable-next-line react/require-default-props
  userUId: PropTypes.string,
  match: PropTypes.object.isRequired,
  setActiveScan: PropTypes.func.isRequired,
  setIsMapDataLoaded: PropTypes.func.isRequired,
  measuringSystem: PropTypes.string.isRequired,
  isMapDataLoaded: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  userEmail: state.user.email,
  responsive: state.responsive,
  layerGroups: state.layers.groups,
  activeScan: state.userData.activeScan,
  userUId: state.user.userUId,
  measuringSystem: state.userData.measuringSystem,
  isMapDataLoaded: state.baseMap.isMapDataLoaded,
  isTopBannerOpen: state.visibility.isTopBannerOpen,
});

const mapDispatchToProps = dispatch => ({
  setActiveOrganization: org => dispatch(setActiveOrganization(org)),
  setActiveScan: scan => dispatch(setActiveScan(scan)),
  setUser: email => dispatch(setUser(email)),
  processAssessment: dataStructure =>
    dispatch(processAssessment(dataStructure)),
  resetModal: () => dispatch(resetModal()),
  closeSidebar: () => dispatch(closeSidebar()),
  openSidebar: () => dispatch(openSidebar()),
  hideModal: () => dispatch(hideModal()),
  setMapPitch: pitch => dispatch(setMapPitch(pitch)),
  setMapZoom: zoom => dispatch(setMapZoom(zoom)),
  setMapBearing: bearing => dispatch(setMapBearing(bearing)),
  toggleLayerBySource: (layerSource, value) =>
    dispatch(toggleLayerBySource(layerSource, value)),
  setIsMapDataLoaded: value => dispatch(setIsMapDataLoaded(value)),
  setSnackbarMessageAndOpen: (message, type) =>
    dispatch(setSnackbarMessageAndOpen(message, type)),
});

const trackedMapView = track()(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MapView))
);

export default withRouter(trackedMapView);
