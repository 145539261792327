import {
  CLOSE_SIDEBAR,
  OPEN_SIDEBAR,
  OPEN_TOP_BANNER,
  CLOSE_TOP_BANNER,
  VIEW_DASHBOARD_GRID,
  VIEW_DASHBOARD_LIST,
} from "../actiontypes/visibility";

const initialState = {
  sidebarVisibility: false,
  isTopBannerOpen: false,
  isDashboardList: false,
};

interface Action {
  type: string;
}

const visibility = (state = initialState, action: Action) => {
  switch (action.type) {
    case OPEN_SIDEBAR:
      return {
        ...state,
        sidebarVisibility: true,
      };
    case CLOSE_SIDEBAR:
      return {
        ...state,
        sidebarVisibility: false,
      };
    case OPEN_TOP_BANNER:
      return {
        ...state,
        isTopBannerOpen: true,
      };
    case CLOSE_TOP_BANNER:
      return {
        ...state,
        isTopBannerOpen: false,
      };
    case VIEW_DASHBOARD_GRID:
      return {
        ...state,
        isDashboardList: false,
      };
    case VIEW_DASHBOARD_LIST:
      return {
        ...state,
        isDashboardList: true,
      };

    default:
      return state;
  }
};

export default visibility;
