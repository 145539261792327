import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { RB_LIGHT_GREY } from "../constants/colors";
import Corner, { CornerType } from "components/Corner";
import { topBannerHeight } from "../views/Banner";
import classNames from "classnames";

const useStyles = makeStyles({
  zoom: {
    background: RB_LIGHT_GREY,
    margin: "5px 20px ",
  },
  zoomBanner: {
    marginTop: topBannerHeight,
  },
});

interface MapSkeletonProps {
  isTopBannerOpen: boolean;
}

const MapSkeleton: React.FC<MapSkeletonProps> = ({ isTopBannerOpen }) => {
  const classes = useStyles();
  return (
    <Corner corner={CornerType.TopLeft}>
      <Skeleton
        className={classNames(classes.zoom, {
          [classes.zoomBanner]: isTopBannerOpen,
        })}
        variant="rect"
        width={26}
        height={52}
      />
    </Corner>
  );
};

export default MapSkeleton;
