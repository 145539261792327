import {
  SHOW_MODAL,
  HIDE_MODAL,
  ENLARGE_IMG,
  SHRINK_IMG,
  UPDATE_MODAL,
  SET_PLAY,
  RESET_PLAY,
  NO_POINTS_MODAL,
  RESET_MODAL,
  SET_MODAL_TAB,
} from "actiontypes/modal";
import {
  RB_GREEN,
  RB_LIGHT_GREEN,
  RB_YELLOW,
  RB_ORANGE,
  RB_RED,
} from "constants/colors";
import {} from "../actiontypes/modal";

const initialState = {
  isModalOpen: false,
  dataPresent: false,
  modalData: {
    pointData: {},
    segmentData: {},
    segmentsData: {},
  },
  enlargedImg: false,
  backgroundColor: [RB_GREEN, RB_LIGHT_GREEN, RB_YELLOW, RB_ORANGE, RB_RED],
  hoverBackgroundColor: [
    RB_GREEN,
    RB_LIGHT_GREEN,
    RB_YELLOW,
    RB_ORANGE,
    RB_RED,
  ],
  isModalPlaying: false,
  modalPlayingID: 0,
  noPoints: false,
  modalTab: "Annotations",
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        noPoints: false,
        isModalOpen: true,
        dataPresent: true,
        modalData: action.modalData,
      };
    case UPDATE_MODAL:
      return {
        ...state,
        modalData: action.modalData,
      };
    case NO_POINTS_MODAL:
      return {
        ...state,
        noPoints: true,
      };
    case HIDE_MODAL:
      return {
        ...state,
        isModalOpen: false,
        enlargedImg: false,
      };
    case ENLARGE_IMG:
      return {
        ...state,
        enlargedImg: true,
      };
    case SHRINK_IMG:
      return {
        ...state,
        enlargedImg: false,
      };
    case RESET_MODAL:
      return { ...initialState };
    case SET_PLAY:
      return {
        ...state,
        isModalPlaying: true,
        modalPlayingID: action.modalPlayingID,
      };
    case RESET_PLAY:
      // this is a hack right now to prevent a runaway point on play button
      clearInterval(state.modalPlayingID);
      return {
        ...state,
        isModalPlaying: false,
        modalPlayingID: 0,
      };
    case SET_MODAL_TAB:
      return { ...state, modalTab: action.modalTab };
    default:
      return state;
  }
};

export default modalReducer;
