import React, { memo } from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import get from "lodash/get";

const useStyles = makeStyles(theme => ({
  list: {
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflowX: "auto",
    maxHeight: "200px",
  },
  listSection: {
    backgroundColor: "inherit",
    padding: 0,
    margin: 0,
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
    margin: 0,
  },
}));

const OrganizationsList = ({ currentOrganization }) => {
  const classes = useStyles();
  return (
    <div>
      <List
        dense={true}
        disablePadding={true}
        className={classes.list}
        subheader={
          <ListSubheader disableGutters>
            {currentOrganization.name}
          </ListSubheader>
        }
      >
        {get(currentOrganization, "childrenOrganizations", []).map(childOrg => (
          <ListItem className={classes.nested} key={childOrg.id} disableGutters>
            {childOrg.name}
          </ListItem>
        ))}
      </List>
    </div>
  );
};

OrganizationsList.propTypes = {
  currentOrganization: PropTypes.object.isRequired,
};

export default memo(OrganizationsList);
