import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import ModalSidebar from "components/ModalSidebar";
import { hideHighlight, showHighlight } from "actions/highlight";
import {
  hideModal,
  showModal,
  shrinkImg,
  enlargeImg,
  stopModal,
  noPointsModal,
} from "actions/modal";
import { closeSidebar } from "../../actions/visibility";
import SignageContent from "components/SignageContent";
import isEmpty from "lodash/isEmpty";
import { isIPad } from "utils/deviceCheck";
import { POINT, SEGMENT, SEGMENTS } from "constants/highlightTypes";
import { determineRatingSign } from "utils/ratings";
import { getAppBarHeight } from "utils/appBar";
import track from "react-tracking";
import { showLayer } from "actions/layers";
import RawSegmentContent from "./ModalVariations/RawSegmentContent";
import ModalContent from "./ModalVariations/ModalContent";
import style from "./style";
import * as Sentry from "@sentry/browser/dist/index";
import ModalDynamicSegmentation from "./ModalVariations/ModalDynamicSegmentation";

const ModalSidebarContainer = ({
  hideModal,
  hideHighlight,
  tracking,
  userUId,
  stopModal,
  showModal,
  showHighlight,
  modalData,
  closeSidebar,
  colorPicker,
  segments,
  measuringSystem,
  enlargedImg,
  map,
  noPoints,
  pointCoords,
  expandImg,
  shrinkImg,
  layerGroups,
  dispatch,
  modalVisibility,
  sidebarVisibility,
  toggleGroups,
  showLayer,
  noPointsModal,
  segmentCoords,
  open,
  dataPresent,
  modalTab,
  isTopBannerOpen,
}) => {
  const closeModalClick = () => {
    tracking.trackEvent({
      event: "mouse-click",
      action: "close-modal-tab",
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `close modal tab`,
      level: Sentry.Severity.Info,
    });
    hideModal();
    hideHighlight();
    stopModal();
  };

  const openModalClick = () => {
    tracking.trackEvent({
      event: "mouse-click",
      action: "open-modal-tab",
      userUId,
    });
    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `open modal tab`,
      level: Sentry.Severity.Info,
    });
    showModal(modalData);
    if (modalData.type === "dynamicSegmentation") {
      showHighlight(SEGMENTS);
    } else if (
      modalData.type === "point/segment" ||
      modalData.type === "point"
    ) {
      showHighlight(POINT);
    } else if (
      modalData.type === "segment" ||
      modalData.type === "sidewalkSegment"
    ) {
      showHighlight(SEGMENT);
    }

    // close the sidebar if on iPad
    if (isIPad()) {
      closeSidebar();
    }
  };

  const renderModal = () => {
    const { pointData, segmentsData } = modalData;
    if (modalData.type === "dynamicSegmentation" && !isEmpty(segments)) {
      return (
        <ModalDynamicSegmentation
          pointData={pointData}
          segmentsData={segmentsData}
          measuringSystem={measuringSystem}
          segments={segments}
          colorPicker={colorPicker}
          closeSidebar={closeSidebar}
          expandImg={expandImg}
          shrinkImg={shrinkImg}
          enlargedImg={enlargedImg}
        />
      );
    } else if (
      modalData.type === "point" ||
      modalData.type === "point/segment" ||
      modalData.type === "cracksealPoint" ||
      modalData.type === "sidewalkPoint"
    ) {
      return (
        <ModalContent
          hideModal={hideModal}
          enlargedImg={enlargedImg}
          modalData={modalData}
          colorPicker={colorPicker}
          map={map}
          pointCoords={pointCoords}
          userUId={userUId}
          expandImg={expandImg}
          shrinkImg={shrinkImg}
          closeSidebar={closeSidebar}
          modalTab={modalTab}
        />
      );
    } else if (
      (modalData.type === "segment" || modalData.type === "sidewalkSegment") &&
      isEmpty(pointData)
    ) {
      return (
        <RawSegmentContent
          colorPicker={colorPicker}
          noPoints={noPoints}
          modalData={modalData}
          map={map}
          layerGroups={layerGroups}
          dispatch={dispatch}
          modalVisibility={modalVisibility}
          sidebarVisibility={sidebarVisibility}
          toggleGroups={toggleGroups}
          showLayer={showLayer}
          noPointsModal={noPointsModal}
          segmentCoords={segmentCoords}
        />
      );
    } else if (modalData.type === "signage") {
      return (
        <SignageContent
          imgURL={modalData.content.imgURL}
          ratingSign={determineRatingSign(modalData.content.rating)}
          colorPicker={colorPicker}
          rating={modalData.content.rating}
        />
      );
    }
    return null;
  };

  return (
    <ModalSidebar
      isTopBannerOpen={isTopBannerOpen}
      open={open}
      dataPresent={dataPresent}
      closeModalClick={closeModalClick}
      openModalClick={openModalClick}
      appbarHeight={getAppBarHeight()}
      enlargedImg={enlargedImg}
    >
      {renderModal()}
    </ModalSidebar>
  );
};

ModalSidebarContainer.propTypes = {
  modalData: PropTypes.object.isRequired,
  modalTab: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  dataPresent: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  hideHighlight: PropTypes.func.isRequired,
  enlargedImg: PropTypes.bool.isRequired,
  shrinkImg: PropTypes.func.isRequired,
  colorPicker: PropTypes.object.isRequired,
  closeSidebar: PropTypes.func.isRequired,
  expandImg: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  showHighlight: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  map: PropTypes.object.isRequired,
  toggleGroups: PropTypes.array.isRequired,
  layerGroups: PropTypes.array.isRequired,
  sidebarVisibility: PropTypes.bool.isRequired,
  modalVisibility: PropTypes.bool.isRequired,
  segmentCoords: PropTypes.array.isRequired,
  pointCoords: PropTypes.array.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  stopModal: PropTypes.func.isRequired,
  tracking: PropTypes.shape({
    trackEvent: PropTypes.func.isRequired,
  }).isRequired,
  userUId: PropTypes.string.isRequired,
  showLayer: PropTypes.func.isRequired,
  segments: PropTypes.arrayOf(PropTypes.object).isRequired,
  noPointsModal: PropTypes.func.isRequired,
  noPoints: PropTypes.bool.isRequired,
  measuringSystem: PropTypes.string.isRequired,
  potholeChecked: PropTypes.bool.isRequired,
  fatigueCrackingChecked: PropTypes.bool.isRequired,
  patchSealChecked: PropTypes.bool.isRequired,
  transLongChecked: PropTypes.bool.isRequired,
  surfaceDeteriorationChecked: PropTypes.bool.isRequired,
  pavementDistortionsChecked: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  modalData: state.modal.modalData,
  modalTab: state.modal.modalTab,
  enlargedImg: state.modal.enlargedImg,
  colorPicker: state.colorPicker,
  open: state.modal.isModalOpen,
  dataPresent: state.modal.dataPresent,
  layerGroups: state.layers.groups,
  toggleGroups: state.toggles.groups,
  sidebarVisibility: state.visibility.sidebarVisibility,
  modalVisibility: state.modal.isModalOpen,
  pointCoords: state.highlight.pointCoords,
  segmentCoords: state.highlight.segmentCoords,
  segments: state.highlight.segments,
  isPlaying: state.modal.isModalPlaying,
  userUId: state.user.userUId,
  noPoints: state.modal.noPoints,
  measuringSystem: state.userData.measuringSystem,
  fatigueCrackingChecked: state.isolateDistresses.fatigueCrackingChecked,
  patchSealChecked: state.isolateDistresses.patchSealChecked,
  pavementDistortionsChecked:
    state.isolateDistresses.pavementDistortionsChecked,
  surfaceDeteriorationChecked:
    state.isolateDistresses.surfaceDeteriorationChecked,
  potholeChecked: state.isolateDistresses.potholeChecked,
  transLongChecked: state.isolateDistresses.transLongChecked,
  isTopBannerOpen: state.visibility.isTopBannerOpen,
});

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  showModal: data => dispatch(showModal(data)),
  expandImg: () => dispatch(enlargeImg()),
  shrinkImg: () => dispatch(shrinkImg()),
  closeSidebar: () => dispatch(closeSidebar()),
  hideHighlight: () => dispatch(hideHighlight()),
  showHighlight: highlightType => dispatch(showHighlight(highlightType)),
  stopModal: () => dispatch(stopModal()),
  showLayer: id => dispatch(showLayer(id)),
  noPointsModal: () => dispatch(noPointsModal()),
  dispatch,
});

export default track({ component: "modal-sidebar-container" })(
  withStyles(style)(
    connect(mapStateToProps, mapDispatchToProps)(ModalSidebarContainer)
  )
);
