import React from "react";
import ReactDOM from "react-dom";
import Roadway from "views/Roadway";
import { Provider } from "react-redux";
import store from "store";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { BrowserRouter } from "react-router-dom";
import { init } from "@sentry/browser";
import { unregister as unregisterServiceWorker } from "registerServiceWorker";
import "react-image-gallery/styles/css/image-gallery.css";
import packageJson from "../package.json";
import ErrorBoundary from "containers/ErrorBoundaryContainer";
import CacheBuster from "containers/CacheBuster";
import enableSlotElement from "utils/htmlSlotElementPoly";
import { watchCSRFTokenCookie, watchSessionCookie } from "./cookieWatcher";
import { StylesProvider, createGenerateClassName } from "@material-ui/styles";
import { PUBLIC_ROUTES } from "./constants/routes";
import theme from "./theme";

enableSlotElement();

global.appVersion = packageJson.version;
if (process.env.NODE_ENV === "development") window.store = store;

init({
  dsn: process.env.REACT_APP_SENTRY_KEY,
  release: `roadway@${packageJson.version}`,
});

const generateClassName = createGenerateClassName({
  productionPrefix: "roadbotics-roadway",
});

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <CacheBuster>
              {({ loading, isLatestVersion, refreshCacheAndReload }) => (
                <Roadway
                  loading={loading}
                  isLatestVersion={isLatestVersion}
                  refreshCacheAndReload={refreshCacheAndReload}
                />
              )}
            </CacheBuster>
          </BrowserRouter>
        </ThemeProvider>
      </StylesProvider>
    </ErrorBoundary>
  </Provider>,
  document.getElementById("root")
);

unregisterServiceWorker();

// Disable cookie watching for demo
if (
  process.env.REACT_APP_ISDEMO !== "1" &&
  process.env.NODE_ENV !== "development" &&
  !PUBLIC_ROUTES.includes(window.location.pathname)
) {
  watchCSRFTokenCookie();
  watchSessionCookie();
}
