import React from "react";
import PropTypes from "prop-types";
import { Layer as MBLayer, Source as MBSource } from "react-mapbox-gl";

const Layer = ({ layer, beforeLayer, meta }) => {
  return (
    <React.Fragment>
      <MBSource id={layer.sourceLayer} tileJsonSource={layer.source} />
      <MBLayer
        type={layer.type}
        id={layer.id}
        layout={layer.layout}
        paint={layer.paint}
        sourceLayer={layer.sourceLayer}
        before={beforeLayer}
        images={layer.images}
        metadata={meta}
      />
    </React.Fragment>
  );
};

Layer.propTypes = {
  layer: PropTypes.object.isRequired,
  beforeLayer: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
};

export default Layer;
