import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateModal } from "actions/modal";
import IsolateDistressGroup from "../components/IsolateDistressGroup";
import { toggleIsolateDistress } from "../actions/isolateDistresses";
import { getLeftAndRightPoints } from "../utils/pointsUtils";

const IsolateDistressGroupContainer = ({
  potholeChecked,
  fatigueCrackingChecked,
  patchSealChecked,
  transLongChecked,
  surfaceDeteriorationChecked,
  pavementDistortionsChecked,
  toggleIsolateDistress,
  map,
  modalData,
  updateModal,
}) => {
  useEffect(() => {
    const feature = modalData.pointData.content.feature;
    const leftAndRightPoints = getLeftAndRightPoints({
      feature,
      map,
      potholeChecked,
      fatigueCrackingChecked,
      patchSealChecked,
      transLongChecked,
      surfaceDeteriorationChecked,
      pavementDistortionsChecked,
    });

    const content = {
      ...modalData.pointData.content,
      leftAndRightPoints,
    };

    updateModal({
      ...modalData,
      pointData: {
        content,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    potholeChecked,
    fatigueCrackingChecked,
    patchSealChecked,
    transLongChecked,
    surfaceDeteriorationChecked,
    pavementDistortionsChecked,
  ]);

  const handleDistressCheckChange = event => {
    const name = event.target.name;
    toggleIsolateDistress(name);
  };

  return (
    <IsolateDistressGroup
      potholeChecked={potholeChecked}
      fatigueCrackingChecked={fatigueCrackingChecked}
      patchSealChecked={patchSealChecked}
      transLongChecked={transLongChecked}
      surfaceDeteriorationChecked={surfaceDeteriorationChecked}
      pavementDistortionsChecked={pavementDistortionsChecked}
      handleDistressCheckChange={handleDistressCheckChange}
    />
  );
};

IsolateDistressGroupContainer.propTypes = {
  potholeChecked: PropTypes.bool.isRequired,
  fatigueCrackingChecked: PropTypes.bool.isRequired,
  patchSealChecked: PropTypes.bool.isRequired,
  transLongChecked: PropTypes.bool.isRequired,
  surfaceDeteriorationChecked: PropTypes.bool.isRequired,
  pavementDistortionsChecked: PropTypes.bool.isRequired,
  toggleIsolateDistress: PropTypes.func.isRequired,
  modalData: PropTypes.object.isRequired,
  updateModal: PropTypes.func.isRequired,
  map: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  fatigueCrackingChecked: state.isolateDistresses.fatigueCrackingChecked,
  patchSealChecked: state.isolateDistresses.patchSealChecked,
  pavementDistortionsChecked:
    state.isolateDistresses.pavementDistortionsChecked,
  surfaceDeteriorationChecked:
    state.isolateDistresses.surfaceDeteriorationChecked,
  potholeChecked: state.isolateDistresses.potholeChecked,
  transLongChecked: state.isolateDistresses.transLongChecked,
  modalData: state.modal.modalData,
});

const mapDispatchToProps = dispatch => ({
  toggleIsolateDistress: toggleType =>
    dispatch(toggleIsolateDistress(toggleType)),
  updateModal: modalData => dispatch(updateModal(modalData)),
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IsolateDistressGroupContainer);
