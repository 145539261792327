import React from "react";
import UserStatus from "../constants/userStatus";
import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autorenew from "@material-ui/icons/Autorenew";
import makeStyles from "@material-ui/styles/makeStyles/makeStyles";

const useStyles = makeStyles({
  button: {
    marginLeft: "10px",
    padding: "0px 1px",
  },
});

interface InviteStatusProps {
  handleInvite: Function;
  rowData: {
    email?: string;
  };
  isSendInviteActive: boolean;
  invitingEmail: string;
}

const InviteStatus = ({
  handleInvite,
  rowData,
  isSendInviteActive,
  invitingEmail,
}: InviteStatusProps): React.ReactElement => {
  const classes = useStyles();
  return (
    <span>
      {UserStatus.PENDING}
      <Tooltip title={"Resend Invite"} placement="bottom">
        <IconButton
          className={classes.button}
          onClick={() => handleInvite(rowData)}
          disabled={isSendInviteActive}
        >
          {invitingEmail === rowData.email ? (
            <CircularProgress size={20} />
          ) : (
            <Autorenew />
          )}
        </IconButton>
      </Tooltip>
    </span>
  );
};

export default InviteStatus;
