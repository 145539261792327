import withStyles from "@material-ui/core/styles/withStyles";
import MUITextField from "@material-ui/core/TextField";

const TextField = withStyles({
  root: {
    marginBottom: "15px",
    width: "100%",
  },
})(MUITextField);

export default TextField;
