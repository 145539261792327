import map from "lodash/map";
import every from "lodash/every";
import find from "lodash/find";
import filter from "lodash/filter";
import flatten from "lodash/flatten";
import indexOf from "lodash/indexOf";
import * as Sentry from "@sentry/browser/dist/index";
import RBAPI from "api/RoadwayAPI";

export function getCurrentToggleGroups(toggleGroups) {
  return toggleGroups.filter(
    toggleGroup =>
      toggleGroup.name === "segments" ||
      toggleGroup.name === "points" ||
      toggleGroup.name === "sidewalkSegments" ||
      toggleGroup.name === "sidewalkPoints" ||
      toggleGroup.name === "roadSenseSegments" ||
      toggleGroup.name === "roadSensePoints"
  );
}

function toggleGroupName(toggleGroups, toggleGroupId) {
  return toggleGroups.find(toggleGroup => toggleGroup.id === toggleGroupId)
    .name;
}

function getLayersOfToggleGroup({ toggleGroupId, toggleGroups, layerGroups }) {
  const toggleGroup = find(toggleGroups, { id: toggleGroupId });
  const layerSources = map(toggleGroup.toggles, "layerId");
  return filter(
    flatten(map(layerGroups, "layers")),
    o => indexOf(layerSources, o.sourceLayer) >= 0
  );
}

export function allTogglesToggled({
  toggleGroupId,
  toggleGroups,
  layerGroups,
}) {
  const layers = getLayersOfToggleGroup({
    toggleGroupId,
    toggleGroups,
    layerGroups,
  });
  return every(layers, "visible");
}

export function toggleToggleGroup({
  toggleGroupId,
  tracking,
  userUId,
  activeScan,
  hideLayer,
  showLayer,
  toggleGroups,
  layerGroups,
}) {
  tracking.trackEvent({
    event: "mouse-click",
    action: `ratings-layer-toggle-all-${toggleGroupName(
      toggleGroups,
      toggleGroupId
    )}`,
  });

  Sentry.addBreadcrumb({
    category: "mouse-click",
    message: `ratings layer toggle all`,
    level: Sentry.Severity.Info,
  });
  const layers = getLayersOfToggleGroup({
    toggleGroupId,
    toggleGroups,
    layerGroups,
  });
  const allLayersVisible = allTogglesToggled({
    toggleGroupId,
    toggleGroups,
    layerGroups,
  });

  layers.forEach(layer => {
    if (allLayersVisible) {
      hideLayer(layer.id);
    } else {
      showLayer(layer.id);
    }
  });

  layers.forEach(layer => {
    // firestore setup
    // handle if the user has already toggled some segments and points
    const scanState = {
      id: activeScan,
      [layer.name]: !allLayersVisible,
    };
    RBAPI.updateUserLayerState(userUId, activeScan, scanState).catch(err => {
      console.error(err);
    });
  });
}

export function anyTogglesToggled({
  toggleGroupId,
  toggleGroups,
  layerGroups,
}) {
  const layers = getLayersOfToggleGroup({
    toggleGroupId,
    toggleGroups,
    layerGroups,
  });
  return layers.some(layer => layer.visible);
}
