import React, { useState, useEffect, useCallback } from "react";
import PointAnnotations from "components/PointAnnotations/PointAnnotations";
import { useSelector, useDispatch } from "react-redux";
import { getHash } from "../../utils/hash";
import State from "../../interfaces/state";
import {
  setSnackbarMessageAndOpen,
  MessageTypes,
  AnchorOrigin,
} from "actions/snackbar";
import { camelCase } from "lodash";
import { usePrevious } from "hooks/usePrevious";
import { useTracking } from "react-tracking";

import { optionsObject, AnnotationOptionType } from "constants/pointAnnotation";
import RBAPI from "api/RoadwayAPI";

interface HeaderInterface {
  [key: string]: { value: string };
}

const initHeader: HeaderInterface = {};

const PointAnnotationsContainer = (): React.ReactElement => {
  const dispatch = useDispatch();
  const tracking = useTracking();

  const [dropdownOptions, setDropdownOptions] = useState(optionsObject);
  const [userAnnotations, setUserAnnotations] = useState(initHeader);

  const [isAnnotationsLoaded, setIsAnnotationsLoaded] = useState(false);
  const [isAnnotationBeingSaved, setIsAnnotationBeingSaved] = useState(false);
  const [annotationExists, setAnnotationExists] = useState(false);
  const [isFormClean, setIsFormClean] = useState(true);
  const [textFieldError, setTextFieldError] = useState("");

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [fieldName, setFieldName] = useState("");

  const closeDialog = () => {
    setIsDialogOpen(false);
    setTextFieldError("");
    setFieldName("");
  };
  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const featureUID = useSelector(
    (state: State) =>
      state.modal.modalData.pointData.content.feature.properties.f_uid
  );
  const scanId = useSelector((state: State) => state.userData.activeScan);

  const getAnnotation = useCallback(async () => {
    setIsAnnotationsLoaded(false);
    try {
      const annotatedFeature = await RBAPI.getAnnotatedFeature(
        getHash({ scanId: scanId, featureId: featureUID })
      );

      if (annotatedFeature.annotations) {
        setUserAnnotations(annotatedFeature.annotations);
      } else {
        setUserAnnotations({});
      }

      setAnnotationExists(true);
    } catch (e) {
      setAnnotationExists(false);
      setUserAnnotations({});
    } finally {
      setIsAnnotationsLoaded(true);
    }
  }, [featureUID, scanId]);

  const isDefaultField = (fieldName: string): boolean => {
    return fieldName in optionsObject;
  };

  const getCustomAnnotationFields = useCallback(async () => {
    const customAnnotationFields = await RBAPI.getAnnotationFields(
      scanId
    ).catch((err: any) => {
      if (err?.response?.status === 404) {
        return;
      }
      throw err;
    });

    // if it is a customAnnotationField, then those options are allowed
    // to be deleted, so lets set enableDelete on those options

    const allFields = dropdownOptions;
    if (customAnnotationFields) {
      Object.keys(customAnnotationFields).forEach(field => {
        customAnnotationFields[field].forEach(
          (option: AnnotationOptionType) => {
            option["enableDelete"] = true;
          }
        );
        if (allFields[field] && allFields[field].options) {
          allFields[field].options = allFields[field].options.concat(
            customAnnotationFields[field]
          );
        } else {
          allFields[field] = {
            options: customAnnotationFields[field],
            display: true,
            enableDelete: !isDefaultField(field),
          };
        }
      });
      setDropdownOptions(allFields);
    }
    // We don't want this to call the API again to concat our dropdownOptions and add second and ghost options
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanId]);

  useEffect(() => {
    getCustomAnnotationFields();
  }, [scanId, getCustomAnnotationFields]);

  useEffect(() => {
    getAnnotation();
  }, [featureUID, getAnnotation]);

  const submitAnnotation = useCallback(
    async (featureId: string = featureUID) => {
      setIsAnnotationBeingSaved(true);

      const hashId = getHash({ scanId: scanId, featureId: featureId });
      const annotatedPoint = {
        id: hashId,
        annotations: userAnnotations,
      };

      try {
        if (annotationExists) {
          await RBAPI.updateAnnotatedFeature(annotatedPoint);
        } else {
          await RBAPI.createAnnotatedFeature({
            ...annotatedPoint,
            featureId: featureId,
            scanId: scanId,
            type: "point",
          });
          setAnnotationExists(true);
        }
        dispatch(
          setSnackbarMessageAndOpen(
            "Annotation Saved",
            MessageTypes.INFO,
            AnchorOrigin.BOTTOM_RIGHT
          )
        );
      } catch (e) {
        dispatch(
          setSnackbarMessageAndOpen(
            "Annotation Not Saved. Please Try Again.",
            MessageTypes.ERROR,
            AnchorOrigin.BOTTOM_RIGHT
          )
        );
        console.error("Error", e.message);
      } finally {
        setIsFormClean(true);
        setIsAnnotationBeingSaved(false);
        if (Object.keys(userAnnotations).length === 0) {
          setAnnotationExists(false);
        }
      }
    },
    [annotationExists, dispatch, featureUID, scanId, userAnnotations]
  );

  const previousFeatureUID = usePrevious(featureUID);

  useEffect(() => {
    if (!isFormClean && featureUID !== previousFeatureUID) {
      if (window.confirm("Do you want to save your changes?")) {
        submitAnnotation(previousFeatureUID);
      } else {
        setIsFormClean(true);
      }
    }
  }, [featureUID, isFormClean, previousFeatureUID, submitAnnotation]);

  const addField = (fieldName: string): void => {
    const fieldNameKey = camelCase(fieldName.toLowerCase());

    if (fieldName === "") {
      setTextFieldError("Please provide a field name");
    } else if (fieldNameKey in dropdownOptions) {
      setTextFieldError("This field name is already in use");
    } else if (fieldName && !dropdownOptions[fieldNameKey]) {
      const updatedScan = {
        scanId: scanId,
        fieldName: fieldNameKey,
      };
      tracking.trackEvent({
        event: "mouse-click",
        action: `add-field-${scanId}-${fieldName}`,
      });
      RBAPI.addAnnotatedField(updatedScan).catch((e: any) => {
        dispatch(
          setSnackbarMessageAndOpen(
            "Could not add annotated field",
            MessageTypes.ERROR
          )
        );
        throw e;
      });
      setDropdownOptions({
        ...dropdownOptions,
        [fieldNameKey]: {
          options: [],
          display: true,
          enableDelete: !isDefaultField(fieldName),
        },
      });
      setIsDialogOpen(false);
      setFieldName("");
      setTextFieldError("");
    }
  };

  const deleteField = (fieldName: string): void => {
    if (fieldName) {
      const updatedScan = {
        scanId: scanId,
        annotatedFieldName: fieldName,
      };
      tracking.trackEvent({
        event: "mouse-click",
        action: `delete-field-${scanId}-${fieldName}`,
      });
      RBAPI.deleteAnnotatedField(updatedScan).catch((e: any) => {
        dispatch(
          setSnackbarMessageAndOpen(
            "Could not delete field",
            MessageTypes.ERROR
          )
        );
        throw e;
      });
    } else {
      alert("Please use a valid name");
    }
  };

  const addOption = (option: string, optionKey: string): void => {
    dropdownOptions[optionKey].options.push({
      name: option,
      id: option,
      enableDelete: true,
    });
    tracking.trackEvent({
      event: "mouse-click",
      action: `add-option-${scanId}-${optionKey}-${option}`,
    });

    setUserAnnotations({
      ...userAnnotations,
      [optionKey]: { value: option },
    });
    setDropdownOptions({
      ...dropdownOptions,
      [optionKey]: {
        options: [...dropdownOptions[optionKey].options],
        display: true,
        enableDelete: !isDefaultField(optionKey),
      },
    });

    const updatedScanOptions = {
      scanId: scanId,
      fieldName: optionKey,
      option: {
        name: camelCase(option),
        id: camelCase(option),
      },
    };
    RBAPI.addAnnotatedFieldOption(updatedScanOptions).catch((e: any) => {
      dispatch(
        setSnackbarMessageAndOpen("Could not add option", MessageTypes.ERROR)
      );
      throw e;
    });
  };

  const deleteOption = (option: string, optionKey: string): void => {
    const newOptions = dropdownOptions[optionKey].options.filter(
      o => o.id !== option
    );

    tracking.trackEvent({
      event: "mouse-click",
      action: `delete-option-${scanId}-${optionKey}-${option}`,
    });

    const newUserAnnotations = { ...userAnnotations };
    delete newUserAnnotations[optionKey];
    setUserAnnotations(newUserAnnotations);

    setDropdownOptions({
      ...dropdownOptions,
      [optionKey]: {
        options: newOptions,
        display: true,
        enableDelete: !isDefaultField(optionKey),
      },
    });

    const deletedScanOptions = {
      scanId: scanId,
      annotatedFieldName: optionKey,
      optionId: camelCase(option),
    };
    RBAPI.deleteAnnotatedFieldOption(deletedScanOptions).catch((e: any) => {
      dispatch(
        setSnackbarMessageAndOpen("Could not delete option", MessageTypes.ERROR)
      );
      throw e;
    });
  };

  return (
    <PointAnnotations
      textFieldError={textFieldError}
      userAnnotations={userAnnotations}
      setUserAnnotations={setUserAnnotations}
      setDropdownOptions={setDropdownOptions}
      dropdownOptions={dropdownOptions}
      isAnnotationsLoaded={isAnnotationsLoaded}
      submitAnnotation={submitAnnotation}
      addField={addField}
      deleteField={deleteField}
      addOption={addOption}
      deleteOption={deleteOption}
      isAnnotationBeingSaved={isAnnotationBeingSaved}
      setIsFormClean={setIsFormClean}
      isFormClean={isFormClean}
      fieldName={fieldName}
      setFieldName={setFieldName}
      isDialogOpen={isDialogOpen}
      closeDialog={closeDialog}
      openDialog={openDialog}
    />
  );
};

export default PointAnnotationsContainer;
