import {
  Button,
  makeStyles,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { RB_GREY } from "constants/colors";
import React, { FC } from "react";

const useStyles = makeStyles(theme => ({
  container: {
    padding: "20px 0px 10px 0px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
  },
  title: {
    fontSize: theme.typography.pxToRem(26),
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: "24px",
    fontFamily: "'Open Sans', sans-serif",
    color: RB_GREY,
  },
  infoButton: {
    marginBottom: 6,
  },
}));

interface ParagraphProps {
  title: string;
  body: string;
}

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 420,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const TooltipInfo: FC<ParagraphProps> = props => {
  const { title, body } = props;
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <Typography className={styles.title} gutterBottom>
        {title}
      </Typography>
      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit">{body}</Typography>
          </React.Fragment>
        }
      >
        <Button className={styles.infoButton}>
          <InfoIcon />
        </Button>
      </HtmlTooltip>
    </div>
  );
};

export default TooltipInfo;
