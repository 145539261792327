import {
  RB_GREEN,
  RB_RED,
  RB_ROAD_SENSE,
  RB_YELLOW,
} from "../constants/colors";
import {
  ROADWAY_SIGN_NRT,
  ROADWAY_SIGN_ONWY,
  ROADWAY_SIGN_WRWY,
  ROADWAY_SIGN_DNE,
  ROADWAY_SIGN_FIRE,
  ROADWAY_SIGN_STOP,
  ROADWAY_SIGN_NLT,
  ROADWAY_SIGN_YLD,
  ROADWAY_SIGN_TRFL,
  ROADWAY_SIGN_SPDL,
} from "../constants/signs";

const ROADWAY_SEGMENTS_NAME = "roadwaySegments";
const ROADWAY_SIDEWALK_SEGMENTS_NAME = "roadwaySidewalkSegments";
const ROADWAY_POINTS_NAME = "roadwayPoints";
const ROADWAY_SIDEWALK_POINTS_NAME = "roadwaySidewalkPoints";
const ROADWAY_ROAD_SENSE_POINTS_NAME = "roadwayRoadSensePoints";
const ROADWAY_ALL_POINTS_NAME = "roadwayAllPoints";
const ROADWAY_DIFFS_NAME = "roadwayDiffs";
const ROADWAY_CRACKSEAL_NAME = "roadwayCrackseals";
const ROADWAY_POTHOLE_NAME = "roadwayPotholes";

function getType(styleName) {
  switch (styleName) {
    case ROADWAY_SEGMENTS_NAME:
    case ROADWAY_SIDEWALK_SEGMENTS_NAME:
    case ROADWAY_DIFFS_NAME:
      return "line";
    case ROADWAY_CRACKSEAL_NAME:
    case ROADWAY_POINTS_NAME:
    case ROADWAY_SIDEWALK_POINTS_NAME:
    case ROADWAY_ROAD_SENSE_POINTS_NAME:
    case ROADWAY_ALL_POINTS_NAME:
      return "circle";
    case ROADWAY_POTHOLE_NAME:
      return "symbol";
    case ROADWAY_SIGN_NRT:
    case ROADWAY_SIGN_ONWY:
    case ROADWAY_SIGN_WRWY:
    case ROADWAY_SIGN_DNE:
    case ROADWAY_SIGN_FIRE:
    case ROADWAY_SIGN_STOP:
    case ROADWAY_SIGN_NLT:
    case ROADWAY_SIGN_YLD:
    case ROADWAY_SIGN_TRFL:
    case ROADWAY_SIGN_SPDL:
      return "symbol";
    default:
      return null;
  }
}

function getPaint(styleName, color) {
  switch (styleName) {
    case ROADWAY_SEGMENTS_NAME:
    case ROADWAY_SIDEWALK_SEGMENTS_NAME:
      return {
        "line-color": color,
        "line-opacity": 1.0,
        "line-width": {
          base: 1,
          stops: [
            [10, 2],
            [15, 4],
          ],
        },
      };
    case ROADWAY_CRACKSEAL_NAME:
    case ROADWAY_POINTS_NAME:
    case ROADWAY_SIDEWALK_POINTS_NAME:
      return {
        "circle-color": color,
        "circle-opacity": {
          base: 1,
          stops: [
            [0, 1],
            [22, 1],
          ],
        },
        "circle-radius": {
          base: 1,
          stops: [
            [12, 3],
            [18, 5],
          ],
        },
      };
    case ROADWAY_ROAD_SENSE_POINTS_NAME:
    case ROADWAY_ALL_POINTS_NAME:
      return {
        "circle-color": color ? color : RB_ROAD_SENSE,
        "circle-opacity": {
          base: 1,
          stops: [
            [0, 1],
            [22, 1],
          ],
        },
        "circle-radius": {
          base: 1,
          stops: [
            [12, 3],
            [18, 5],
          ],
        },
      };
    case ROADWAY_DIFFS_NAME:
      return {
        "line-color": color,
        "line-width": {
          base: 3,
          stops: [
            [10, 7],
            [15, 18],
          ],
        },
      };
    default:
      return {};
  }
}

function getPartnerLayerImage(layerSource, color) {
  function getColor(color) {
    switch (color) {
      case RB_GREEN:
        return "Green";
      case RB_YELLOW:
        return "Yellow";
      case RB_RED:
        return "Red";
      default:
        return "";
    }
  }
  let imageName;
  if (layerSource.indexOf("ONWY") !== -1) {
    imageName = "OneWaySign";
  } else if (layerSource.indexOf("YLD") !== -1) {
    imageName = "YieldSign";
  } else if (layerSource.indexOf("STOP") !== -1) {
    imageName = "StopSign";
  } else if (layerSource.indexOf("NLT") !== -1) {
    imageName = "NoLeftTurnSign";
  } else if (layerSource.indexOf("NRT") !== -1) {
    imageName = "NoRightTurnSign";
  } else if (layerSource.indexOf("WRWY") !== -1) {
    imageName = "WrongWaySign";
  } else if (layerSource.indexOf("SPDL") !== -1) {
    imageName = "SpeedLimitSign";
  } else if (layerSource.indexOf("DNE") !== -1) {
    imageName = "DoNotEnterSign";
  } else if (layerSource.indexOf("FIRE") !== -1) {
    imageName = "FireHydrant";
  } else if (layerSource.indexOf("TRFL") !== -1) {
    imageName = "TrafficLight";
  } else {
    imageName = "OneWaySign";
  }

  const colorName = getColor(color);
  imageName += `${colorName}Active`;
  return imageName;
}

function getLayout(styleName, visible, layerSource, color) {
  switch (styleName) {
    case ROADWAY_DIFFS_NAME:
      return {
        visibility: visible ? "visible" : "none",
        "line-join": "round",
        "line-cap": "round",
      };
    case ROADWAY_CRACKSEAL_NAME:
    case ROADWAY_POINTS_NAME:
    case ROADWAY_SIDEWALK_POINTS_NAME:
    case ROADWAY_ROAD_SENSE_POINTS_NAME:
    case ROADWAY_ALL_POINTS_NAME:
      return {
        visibility: visible ? "visible" : "none",
      };
    case ROADWAY_POTHOLE_NAME:
      // eslint-disable-next-line no-case-declarations
      const potholeImage = "Roadlolli";
      return {
        visibility: visible ? "visible" : "none",
        "icon-image": potholeImage,
        "icon-allow-overlap": true,
        "icon-size": {
          base: 0.5,
          stops: [
            [13.5, 0.5],
            [23, 1],
          ],
        },
        "icon-anchor": "bottom",
        "icon-rotation-alignment": "viewport",
        "icon-pitch-alignment": "viewport",
      };
    case ROADWAY_SEGMENTS_NAME:
    case ROADWAY_SIDEWALK_SEGMENTS_NAME:
      return {
        visibility: visible ? "visible" : "none",
        "line-join": "round",
        "line-cap": "round",
      };
    case ROADWAY_SIGN_NRT:
    case ROADWAY_SIGN_ONWY:
    case ROADWAY_SIGN_WRWY:
    case ROADWAY_SIGN_DNE:
    case ROADWAY_SIGN_FIRE:
    case ROADWAY_SIGN_STOP:
    case ROADWAY_SIGN_NLT:
    case ROADWAY_SIGN_YLD:
    case ROADWAY_SIGN_TRFL:
    case ROADWAY_SIGN_SPDL:
      // eslint-disable-next-line no-case-declarations
      const image = getPartnerLayerImage(layerSource, color);
      return {
        visibility: visible ? "visible" : "none",
        "icon-image": image,
        "icon-allow-overlap": true,
      };
    default:
      return null;
  }
}

/**
 * Generates a layer style object consisting of `type`, 'paint', and 'layout' properties
 * @param {string} styleName
 * @param {string} color
 * @param {boolean} visible
 * @param {string} layerSource
 * @returns {Object}
 */
function getStyle(styleName, color, visible, layerSource) {
  const type = getType(styleName);
  const paint = getPaint(styleName, color);
  const layout = getLayout(styleName, visible, layerSource, color);
  return {
    layout,
    type,
    paint,
  };
}

export default getStyle;
