import find from "lodash/find";
import get from "lodash/get";

export function getLayer(layerSource: string, layerGroups: []): object {
  const results = find(layerGroups, { layers: [{ sourceLayer: layerSource }] });
  return find(get(results, "layers"), { sourceLayer: layerSource });
}

// get the data of the layer to be used for visible property
export function getLayerStatus(
  layerSource: string,
  layerId: number,
  layerGroups: []
): object {
  const results = find(layerGroups, { layers: [{ sourceLayer: layerSource }] });
  return find(get(results, "layers"), { id: layerId });
}
