import { IDI, SegmentDistressStatistics } from "interfaces/IDIAnalysis";

export const normalizeSegments = (data: IDI): IDI => {
  const segments = data.segments;
  for (const segment of segments || []) {
    const { pci } = segment;
    segment.pci = Math.round(pci);
    const statistics = segment.segmentDistressStatistics;
    for (const obj of Object.entries(statistics)) {
      let [, stats] = obj;
      const [key] = obj;
      if (typeof stats === "number") {
        stats = Math.round(stats);
        const keyTyped = key as keyof SegmentDistressStatistics;
        statistics[keyTyped] = stats;
      }
    }
  }

  return data;
};
