import grey from "@material-ui/core/colors/grey";

const styles = theme => ({
  root: {
    position: "relative",
  },
  speedDialPull: {
    position: "absolute",
    bottom: 0,
    left: 0,
    zIndex: 500,
  },
  speedDialFab: {
    color: theme.palette.common.white,
    backgroundColor: grey[500],
    "&:hover": {
      backgroundColor: grey[600],
    },
    zIndex: 1000,
  },
  speedDialFabHidden: {
    visibility: "hidden",
  },
  speedDialIconRoot: {
    height: 30,
  },
  speedDialIconIcon: {
    fontSize: 30,
  },
  speedDialAction: {
    width: "30px",
    height: "30px",
    minHeight: "30px",
  },
  speedDialActions: {
    alignItems: "center",
  },
  hidden: {
    display: "none",
  },
});

export default styles;
