import React, { FC, useEffect, useState } from "react";
import MaterialTable from "material-table";
import { IDITable, Segment } from "interfaces/IDIAnalysis";
import { TABLE_HEADER_BACKGROUND, TABLE_HEADER_TEXT } from "constants/colors";
import styleTableRow from "utils/styleTableRow";
import { Filters } from "./IDIAnalysis";
import { Distresses, DistressesRanges } from "./DistressesSliders";
import { Ratings } from "./RatingRadios";
import _ from "lodash";
import { Typography } from "@material-ui/core";
import TableSkeleton from "skeletons/table";
import { getLengthInPreferredUnit } from "utils/metrics";
import { useSelector } from "react-redux";
import State from "interfaces/state";
import { MeasuringSystem } from "constants/measuringSystem";
import { StreetData } from "./SummaryPage";
import { PCI, PCIRanges } from "./PciSlider";

enum IDIFields {
  "Road Name" = "name",
  "Segments Geo Coordinates" = "geocoordinates",
  "Length" = "length",
  Rating = "rating",
  "HD-PCI" = "pci",
  Potholes = "numPothole",
  "% Fatigue Cracking" = "pctFatigueCracking",
  "% Distortion" = "pctPavementDistortions",
  "% Deterioration" = "pctSurfaceDeterioration",
  "Patch/Seal" = "numPatchSeal",
  "% Trans/Long" = "pctTransLongCracking",
}

interface IDIAnalysisTableProps {
  segments: Segment[];
  streets: StreetData[];
  isLoaded: boolean;
  filters: Filters[];
  showSegmentData: boolean;
  showErrorMessage: boolean;
}

const IDIAnalysisTable: FC<IDIAnalysisTableProps> = props => {
  const { segments, filters, isLoaded, streets, showSegmentData, showErrorMessage } = props;
  const [data, setData] = useState<IDITable[]>([]);
  const [filterData, setFilterData] = useState<IDITable[]>([]);
  const measuringSystem = useSelector(
    (state: State) => state.userData.measuringSystem
  );
  const columns = Object.entries(IDIFields).filter(([key]) => !showSegmentData ? key !== "Segments Geo Coordinates" : true).map(([key, value]) => {
    if (value === "length") {
      return {
        title: `${key} (${measuringSystem === MeasuringSystem.Imperial ? "ft" : "m"
          })`,
        field: value,
      };
    } else {
      return { title: key, field: value };
    }
  });
  const UNKNOWN = "Unknown";

  useEffect(() => {
    let initialData: IDITable[] = [];
    if (showSegmentData) {
      initialData = segments.reduce((accum: IDITable[], segment) => {
        const geocoordinates = `${JSON.stringify(
          segment.startGeocoord
        )} ${JSON.stringify(segment.endGeocoord)}`;
        const obj: IDITable = {
          ...segment.segmentDistressStatistics,
          name: segment.name && segment.name !== "null" ? segment.name : UNKNOWN,
          geocoordinates,
          rating: segment.avgRating ? segment.avgRating : "Not Paved",
          pci: segment.pci,
          length: getLengthInPreferredUnit(
            segment.length,
            measuringSystem,
            "small",
            2
          ).scalar,
        };
        accum.push(obj);
        return accum;
      }, []);
    } else {
      initialData = streets.reduce((accum: IDITable[], street) => {
        const geocoordinates = "N/A";
        const obj: IDITable = {
          ...street.segmentDistressStatistics,
          name: street.name && street.name !== "null" ? street.name : UNKNOWN,
          geocoordinates,
          rating: street.avgRating ? street.avgRating : "Not Paved",
          pci: street.pci,
          length: getLengthInPreferredUnit(
            street.totalLength,
            measuringSystem,
            "small",
            2
          ).scalar,
        };
        accum.push(obj);
        return accum;
      }, []);
    }
    setFilterData(initialData);
    setData(initialData);
  }, [segments, streets, isLoaded, measuringSystem, showSegmentData]);

  useEffect(() => {
    let newFilters: IDITable[] = [];
    if (filters.length) {
      for (const filter of filters) {
        const dataForFilter = newFilters;
        if (isDistressRanges(filter)) {
          const dataFiltered = dataForFilter.filter(el => {
            const start = filter.value[0];
            // Plus 1 because end is not inclusive
            const end = filter.value[1] + 1;
            switch (filter.title) {
              case Distresses.potholes:
                return _.inRange(el.numPothole, start, end);
              case Distresses.fatigueCracking:
                return _.inRange(el.pctFatigueCracking, start, end);
              case Distresses.deterioration:
                return _.inRange(el.pctSurfaceDeterioration, start, end);
              case Distresses.distortion:
                return _.inRange(el.pctPavementDistortions, start, end);
              case Distresses.sealant:
                return _.inRange(el.numPatchSeal, start, end);
              case Distresses.longCracking:
                return _.inRange(el.pctTransLongCracking, start, end);
              default:
                return true;
            }
          });
          newFilters = [...dataFiltered];
        } else if (isPCIRanges(filter)) {
          const dataFiltered = dataForFilter.filter(el => {
            const start = filter.value[0];
            // Plus 1 because end is not inclusive
            const end = filter.value[1] + 1;
            switch (filter.title) {
              case PCI.pci:
                return _.inRange(el.pci, start, end);
              default:
                return true;
            }
          });
          newFilters = [...dataFiltered];
        } else {
          const ratingFilter = filter as Ratings;
          const dataFiltered = data.filter(el => {
            switch (ratingFilter) {
              case 1:
                return el.rating >= 1 && el.rating < 1.5;
              case 2:
                return el.rating >= 1.5 && el.rating < 2.5;
              case 3:
                return el.rating >= 2.5 && el.rating < 3.5;
              case 4:
                return el.rating >= 3.5 && el.rating < 4.5;
              case 5:
                return el.rating >= 4.5 && el.rating <= 5;
              default:
                return el.rating === ratingFilter;
            }
          });
          newFilters.push(...dataFiltered);
        }
      }
    }
    setFilterData(newFilters);
  }, [filters, data]);

  const isDistressRanges = (
    toBeDetermined: Filters
  ): toBeDetermined is DistressesRanges => {
    if (Object.values(Distresses).includes((toBeDetermined as DistressesRanges).title)) {
      return true;
    }
    return false;
  };

  const isPCIRanges = (
    toBeDetermined: Filters
  ): toBeDetermined is PCIRanges => {
    if (Object.values(PCI).includes((toBeDetermined as PCIRanges).title)) {
      return true;
    }
    return false;
  };

  return (
    <MaterialTable
      title="Individual_Distress_Analysis"
      isLoading={!isLoaded}
      style={{ boxShadow: "none" }}
      options={{
        showTitle: false,
        pageSize: 10,
        search: false,
        loadingType: undefined,
        exportButton: true,
        exportAllData: true,
        headerStyle: {
          backgroundColor: TABLE_HEADER_BACKGROUND,
          color: TABLE_HEADER_TEXT,
          fontWeight: "bold",
          fontSize: "14px",
        },
        rowStyle: styleTableRow,
      }}
      localization={{
        header: { actions: "" },
        body: {
          emptyDataSourceMessage: ((isLoaded ? (
            <Typography>
              {showErrorMessage ? "IDI Segment Analysis is unavailable for this assessment. Please contact Roadbotics support." : "No records to display. Contact RoadBotics if this is incorrect"}
            </Typography>
          ) : (
              <TableSkeleton nIcons={0} />
            )) as unknown) as string,
        },
      }}
      columns={columns}
      data={filterData}
    />
  );
};

export default IDIAnalysisTable;
