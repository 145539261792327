import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CloseModalIcon from "components/CloseModalIcon";
import PropTypes from "prop-types";
import { RB_ORANGE } from "constants/colors";

const style = theme => ({
  background: {
    position: "absolute",
    border_radius: "11px",
    backgroundColor: "#dcdcdc",
    textAlign: "center",
    padding: "10px 10px 15px 10px",
    bottom: 410,
    left: 48,
    width: "auto",
    height: "auto",
    overflow: "hidden",
    whiteSpace: "nowrap",
    opacity: 0.85,
    minWidth: "145px",
  },
  text: {
    padding: "0px 0px 0px 0px",
    fontSize: 15,
    color: theme.palette.common.black,
    fontWeight: "300",
  },
  subText: {
    padding: "0px 0px 0px 0px",
    fontSize: 18,
    color: theme.palette.common.black,
    marginTop: "4px",
  },
  link: {
    fontSize: 12,
    textDecoration: "underline",
    color: RB_ORANGE,
    fontWeight: "600",
    cursor: "pointer",
  },
});

const ShowDistanceContainer = props => {
  const {
    measureDistance: { distance, unit, isMeasuring },
    classes,
    onClearMeasure,
    open,
    clearDistance,
  } = props;

  return distance !== null && open && isMeasuring ? (
    <Paper className={classes.background}>
      <CloseModalIcon onClick={clearDistance} />
      <Typography className={classes.text}>Distance:</Typography>
      <Typography className={classes.subText}>{distance}</Typography>
      <Typography className={classes.text}>{unit}</Typography>
      <button type="button" onClick={onClearMeasure} className={classes.link}>
        Clear
      </button>
    </Paper>
  ) : (
    <div />
  );
};

ShowDistanceContainer.propTypes = {
  measureDistance: PropTypes.shape({
    distance: PropTypes.number,
    unit: PropTypes.string,
    isMeasuring: PropTypes.bool,
  }).isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClearMeasure: PropTypes.func.isRequired,
  clearDistance: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  measureDistance: state.measureDistance,
  colorPicker: state.colorPicker,
});

export default connect(mapStateToProps)(
  withStyles(style)(ShowDistanceContainer)
);
