import RBAPI from "api/RoadwayAPI";
import { logoutOfFirebaseAndRedirect } from "config/firebase";

export const getCookie = function(cookieName: string): string {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const watchCSRFTokenCookie = function(): void {
  const domain = process.env.REACT_APP_ROADWAY_API_BASE?.split(".")
    .slice(1)
    .join(".");
  window.setInterval(() => {
    const csrfToken = getCookie(`csrfToken_.${domain}`);
    if (!csrfToken) {
      RBAPI.logoutSession().then((resp: any) => {
        const redirectURL = resp?.data?.accountsRedirectURL;
        logoutOfFirebaseAndRedirect(redirectURL);
      });
    }
  }, 1000 * 5); // Check every 5 seconds
};

export const watchSessionCookie = function(): void {
  window.setInterval(() => {
    RBAPI.validateSessionToken().catch((e: any) => {
      // session is invalid, log out user and redirect.
      const redirectURL = e?.response?.data?.accountsRedirectURL;
      logoutOfFirebaseAndRedirect(redirectURL);
    });
  }, 1000 * 60 * 60); // Check every hour
};
