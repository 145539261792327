import React from "react";
import FreeTrialSuccess from "../components/FreeTrialSuccess";
import { useLocation, withRouter } from "react-router-dom";
import RoadSenseSignUpSuccess from "../components/RoadSenseSignUpSuccess";

const SuccessPageContainer = (): React.ReactElement => {
  const location = useLocation();

  const goToLogin = (): void => {
    window.location.replace(`${process.env.REACT_APP_ACCOUNTS_DOMAIN}`);
  };
  return (
    <>
      {location.pathname.slice(1) === "free-trial-success" ? (
        <FreeTrialSuccess location={location} goToLogin={goToLogin} />
      ) : (
        <RoadSenseSignUpSuccess location={location} goToLogin={goToLogin} />
      )}
    </>
  );
};

export default withRouter(SuccessPageContainer);
