import {
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR,
  SET_SNACKBAR_MESSAGE,
} from "actiontypes/snackbar";

const defaultState = {
  open: false,
  message: "",
  messageType: null,
};

const snackbarReducer = (state = defaultState, action) => {
  switch (action.type) {
    case OPEN_SNACKBAR:
      return {
        ...state,
        open: true,
      };
    case CLOSE_SNACKBAR:
      return defaultState;
    case SET_SNACKBAR_MESSAGE:
      return {
        ...state,
        message: action.message,
        messageType: action.messageType,
        anchorOrigin: action.anchorOrigin,
      };
    default:
      return state;
  }
};

export default snackbarReducer;
