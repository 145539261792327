import React from "react";
import PropTypes from "prop-types";
import LayerContainer from "containers/LayerContainer";

const LayerGroup = ({
  layers,
  position,
  clickBehavior,
  style,
  beforeLayer,
}) => (
  <LayerContainer
    position={position}
    layers={layers}
    style={style}
    clickBehavior={clickBehavior}
    beforeLayer={beforeLayer}
  />
);

LayerGroup.propTypes = {
  layers: PropTypes.array.isRequired,
  position: PropTypes.number.isRequired,
  clickBehavior: PropTypes.string.isRequired,
  style: PropTypes.string.isRequired,
  beforeLayer: PropTypes.string.isRequired,
};

export default LayerGroup;
