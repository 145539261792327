import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import RoomRounded from "@material-ui/icons/RoomRounded";
import { largestRemainderRound } from "utils/largestRemainderRound";
import { displayLength } from "utils/metrics";
import { displayAverageRating } from "utils/ratings";
import { RAIToRoadwayRating, sidewalkMap } from "utils/sidewalkHelperFunctions";
import times from "lodash/times";
import { clickBehaviorConstants } from "constants/clickBehavior";

const styles = {
  dataGroup: {
    width: "100%",
    display: "flex",
    textAlign: "center",
    marginTop: "7px",
  },
  datatext: {
    textAlign: "center",
    fontSize: "18px",
    fontFamily: "'Open Sans', sans-serif",
    display: "inline-block",
  },
  rating: {
    alignItems: "center",
    width: "50%",
    display: "inline-block",
  },
  length: {
    alignItems: "center",
    width: "50%",
    display: "inline",
  },
  bar: {
    width: "100%",
    height: "50px",
    alignItems: "center",
    margin: "auto",
    padding: "0 7px",
  },
  portion: {
    height: "100%",
    float: "left",
  },
  roadname: {
    fontSize: "20px",
    textAlign: "center",
    paddingBottom: "10px",
  },
  circle: {
    height: "15px",
    width: "15px",
    borderRadius: "50%",
    display: "inline-block",
    marginLeft: "3px",
    verticalAlign: "middle",
  },
  tooltipPlacementBottom: {
    transformOrigin: "center",
    margin: "0px 0px",
  },
  icon: {
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: "2px",
  },
};

const LineModalContent = ({
  chartData,
  averageRating,
  length,
  classes,
  colorPicker,
  measuringSystem,
  clickType,
}) => {
  const roundedRatingDistributions = chartData.datasets[0].data
    ? largestRemainderRound(
        chartData.datasets[0].data.map(s => parseFloat(s)), // this is the distribution of ratings
        100
      )
    : null;
  return (
    <div className="lineModalContent">
      <Typography className={classes.roadname}>
        <RoomRounded className={classes.icon} />
        {chartData.datasets[0].label !== "Damage Report"
          ? chartData.datasets[0].label
          : "Unknown Road"}
      </Typography>
      {roundedRatingDistributions && (
        <div className={classes.bar}>
          {times(5).map(idx => {
            return (
              <Tooltip
                title={`Rating ${idx + 1}: ${roundedRatingDistributions[idx]}%`}
                placement="bottom"
                className={classes.tooltipPlacementBottom}
                key={`rating_bar_${idx}`}
              >
                <div
                  className={classes.portion}
                  style={{
                    width:
                      chartData.datasets[0].data[idx] !== "NaN"
                        ? `${chartData.datasets[0].data[idx]}%`
                        : 0,
                    backgroundColor: colorPicker[`RATING_${idx + 1}`],
                  }}
                />
              </Tooltip>
            );
          })}
          <Tooltip
            title={`Not Paved: ${
              chartData.datasets[0].data[5] === "NaN"
                ? "100"
                : Math.round(chartData.datasets[0].data[6])
            }%`}
            placement="bottom"
          >
            <div
              className={classes.portion}
              style={{
                // eslint-disable-next-line no-nested-ternary
                width: chartData.datasets[0].data[6]
                  ? `${chartData.datasets[0].data[6]}%`
                  : chartData.datasets[0].data[5] === "NaN"
                  ? "100%"
                  : 0,
                backgroundColor: colorPicker.RATING_NON_PAVED,
              }}
            />
          </Tooltip>
        </div>
      )}
      <div className={classes.dataGroup}>
        {averageRating && (
          <div className={classes.rating}>
            <div
              className={classes.circle}
              style={{
                backgroundColor:
                  colorPicker[
                    `RATING_${
                      clickType === "sidewalkSegment"
                        ? RAIToRoadwayRating(averageRating)
                        : Math.round(averageRating)
                    }`
                  ],
                marginRight: "5px",
              }}
            />
            <Typography className={classes.datatext}>
              {`Avg Rating: ${
                clickType === "sidewalkSegment"
                  ? sidewalkMap[RAIToRoadwayRating(averageRating)]
                  : displayAverageRating(averageRating)
              }`}
            </Typography>
          </div>
        )}
        <div className={classes.length}>
          <Typography className={classes.datatext}>
            {`Length: ${displayLength(length, measuringSystem, "small")}`}
          </Typography>
        </div>
      </div>
    </div>
  );
};

LineModalContent.defaultProps = {
  clickType: clickBehaviorConstants.roadwaySegment,
};

LineModalContent.propTypes = {
  chartData: PropTypes.object.isRequired,
  // eslint-disable-next-line react/require-default-props
  averageRating: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  length: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  colorPicker: PropTypes.object.isRequired,
  measuringSystem: PropTypes.string.isRequired,
  clickType: PropTypes.string,
};

const mapStateToProps = state => ({
  measuringSystem: state.userData.measuringSystem,
});

export default connect(mapStateToProps)(withStyles(styles)(LineModalContent));
