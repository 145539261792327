import LanguageRounded from "@material-ui/icons/LanguageRounded";
import AccessTimeRounded from "@material-ui/icons/AccessTimeRounded";
import RoomOutlined from "@material-ui/icons/RoomOutlined";
import {
  OpenInNewRounded,
  NavigationOutlined,
  ZoomInRounded,
  ZoomOutRounded,
} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/styles/withStyles";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import CrossfadeImage from "react-crossfade-image";
import moment from "moment-timezone";
import { parseTimestamp } from "utils/timestamp";
import IndividualDistressPointStatistics from "./IndividualDistressPointStatistics";
// eslint-disable-next-line max-len
import VirtualDriverControls from "containers/ModalSidebarContainer/ModalVariations/VirtualDriverControls";
import { modalTabDefinitions } from "constants/modal";

const styles = () => ({
  table: {
    width: "100%",
    alignItems: "center",
    margin: "10px auto 0 auto",
  },
  coords: {
    fontSize: "14px",
    margin: "4px",
  },
  td: {
    fontFamily: "'Open Sans', sans-serif",
    textAlign: "left",
    fontSize: "1vw",
    padding: "5px",
    width: "50%",
  },
  timestamp: {
    fontFamily: "'Open Sans', sans-serif",
    textAlign: "center",
    fontSize: "20px",
    marginBottom: "5px",
  },
  circle: {
    height: "15px",
    width: "15px",
    borderRadius: "50%",
    display: "inline-block",
    marginLeft: "3px",
    verticalAlign: "middle",
  },
  rating: {
    color: "#525252",
    fontSize: "26px",
    textAlign: "center",
    padding: "5px",
    display: "inline-block",
    paddingTop: "2px",
    paddingBottom: "0px",
  },
  crackSealRating: {
    color: "#525252",
    fontSize: "26px",
    textAlign: "center",
    padding: "8px",
    display: "inline-block",
    paddingTop: "2px",
    paddingBottom: "0px",
  },
  leftButton: {
    float: "left",
    position: "absolute",
    borderBottomRightRadius: "5px",
    backgroundColor: "#9e9e9e",
    opacity: 0.85,
    zIndex: 1,
  },
  rightButton: {
    float: "right",
    position: "absolute",
    right: "0px",
    borderBottomLeftRadius: "5px",
    backgroundColor: "#9e9e9e",
    opacity: 0.85,
    zIndex: 1,
  },
  leftButtonLarge: {
    float: "left",
    marginBottom: "8px",
    marginTop: "4px",
    marginLeft: "4px",
  },
  rightButtonLarge: {
    float: "right",
    marginBottom: "8px",
    marginTop: "4px",
    marginRight: "4px",
  },
  divider: {
    margin: "auto",
    marginTop: "28px",
    width: "100%",
    padding: "8px",
    display: "inline-block",
  },
  crackSealDivider: {
    margin: "auto",
    marginTop: "8px",
    width: "100%",
    padding: "9px",
    display: "inline-block",
  },
  ratingBar: {
    width: "100%",
    margin: "auto",
    textAlign: "center",
    marginBottom: "10px",
  },
  ratingLabel: {
    fontSize: "11px",
    fontFamily: "'Open Sans', sans-serif",
  },
  icon: {
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: "2px",
  },
  number: {
    fontSize: "26px",
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: "bold",
  },
  picture: {
    width: "100%",
    margin: "auto",
    position: "relative",
  },
  magnify: {
    width: "36px",
    padding: "4px",
  },
  crackSealBar: {
    width: "100%",
    margin: "auto",
    textAlign: "center",
    marginBottom: "10px",
  },
  cracksealType: {
    color: "#525252",
    fontSize: "20px",
    textAlign: "center",
    padding: "5px",
    display: "inline-block",
    paddingTop: "2px",
    paddingBottom: "0px",
  },
  divContainer: {
    padding: "1%",
    marginBottom: "20px",
  },
});

const CircleModalContent = ({
  imgURL = "http://placehold.it/625x350",
  rating,
  timestamp,
  imgClick,
  classes,
  colorPicker,
  streetName,
  latitude,
  longitude,
  bearing,
  isExpanded,
  compassDirection,
  ratingSign,
  primaryDistresses,
  crackSealColor,
  csSign,
  map,
  modalDataType,
  modalTab,
  showRating,
}) => {
  const formattedDate = timestamp
    ? moment
        .tz(parseTimestamp(timestamp), moment.tz.guess())
        .format("MMM DD, YYYY, h:mm a")
    : "N/A";
  const formattedLatitude = parseFloat(latitude.toFixed(5));
  const formattedLongitude = parseFloat(longitude.toFixed(5));
  return (
    <div className="clickBox">
      <div className="introdiv">
        <div className={classes.divContainer}>
          <div>
            {crackSealColor && (
              <div className={classes.crackSealBar}>
                <Grid container>
                  <Grid item xs={4}>
                    <Divider
                      className={classes.crackSealDivider}
                      style={{
                        backgroundColor: crackSealColor,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <div className={classes.crackSealRating}>
                      <img src={csSign} alt="cs" />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <Divider
                      className={classes.crackSealDivider}
                      style={{
                        backgroundColor: crackSealColor,
                      }}
                    />
                  </Grid>
                </Grid>
                <Divider
                  style={{ width: "100%", height: "2px", marginTop: "10px" }}
                />
              </div>
            )}
            {showRating && (
              <div className={classes.ratingBar}>
                <Grid container>
                  <Grid item xs={5}>
                    <Divider
                      className={classes.divider}
                      style={{
                        backgroundColor: colorPicker[`RATING_${rating}`],
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <div className={classes.rating}>
                      <img src={ratingSign} alt="rating" />
                    </div>
                  </Grid>
                  <Grid item xs={5}>
                    <Divider
                      className={classes.divider}
                      style={{
                        backgroundColor: colorPicker[`RATING_${rating}`],
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
            <div className={classes.picture}>
              <div className={classes.leftButton}>
                <Tooltip
                  title={isExpanded ? "Shrink Photo" : "Enlarge Photo"}
                  placement="right"
                >
                  <Button
                    className="tabButton"
                    onClick={() => imgClick()}
                    variant="text"
                  >
                    {isExpanded ? (
                      <ZoomOutRounded fontSize="large" />
                    ) : (
                      <ZoomInRounded fontSize="large" />
                    )}
                  </Button>
                </Tooltip>
              </div>
              <div className={classes.rightButton}>
                <Tooltip title="Open in New Tab" placement="left">
                  <Button
                    className="tabButton"
                    onClick={() => window.open(imgURL, "_blank")}
                    variant="text"
                  >
                    <OpenInNewRounded fontSize="large" />
                  </Button>
                </Tooltip>
              </div>
              <CrossfadeImage
                src={imgURL}
                duration={400}
                timingFunction="ease-out"
              />
            </div>
            {primaryDistresses &&
              modalDataType !== "sidewalkPoint" &&
              modalTab === modalTabDefinitions.IDI && (
                <IndividualDistressPointStatistics
                  hasPothole={primaryDistresses.hasPothole}
                  hasFatigueCracking={primaryDistresses.hasFatigueCracking}
                  hasPatchSeal={primaryDistresses.hasPatchSeal}
                  hasTransLongCracking={primaryDistresses.hasTransLongCracking}
                  hasSurfaceDeterioration={
                    primaryDistresses.hasSurfaceDeterioration
                  }
                  hasPavementDistortions={
                    primaryDistresses.hasPavementDistortions
                  }
                />
              )}
            {modalDataType !== "sidewalkPoint" && (
              <VirtualDriverControls map={map} />
            )}
            <table className={classes.table}>
              <tbody>
                <tr>
                  <td className={classes.td}>
                    <Tooltip title="Address" placement="top-start">
                      <RoomOutlined className={classes.icon} />
                    </Tooltip>
                    {` ${streetName}`}
                  </td>
                  <td className={classes.td}>
                    <Tooltip title="Datetime" placement="top-start">
                      <AccessTimeRounded className={classes.icon} />
                    </Tooltip>
                    {formattedDate}
                  </td>
                </tr>
                <tr>
                  <td className={classes.td}>
                    <Tooltip
                      title="Latitude, Longitude"
                      placement="bottom-start"
                    >
                      <LanguageRounded className={classes.icon} />
                    </Tooltip>
                    {` ${formattedLatitude}, ${formattedLongitude}`}
                  </td>
                  <td className={classes.td}>
                    <Tooltip title="Direction" placement="bottom-start">
                      <NavigationOutlined
                        className={classes.icon}
                        style={{ transform: `rotate(${bearing}deg)` }}
                      />
                    </Tooltip>
                    {` ${compassDirection || "N/A"}`}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

CircleModalContent.defaultProps = {
  rating: null,
  bearing: null,
  compassDirection: null,
  crackSealColor: null,
  primaryDistresses: {},
  csSign: null,
  timestamp: "",
};

CircleModalContent.propTypes = {
  imgURL: PropTypes.string.isRequired,
  rating: PropTypes.number,
  timestamp: PropTypes.string,
  imgClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  colorPicker: PropTypes.object.isRequired,
  streetName: PropTypes.string.isRequired,
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  compassDirection: PropTypes.string,
  bearing: PropTypes.number,
  ratingSign: PropTypes.string.isRequired,
  crackSealColor: PropTypes.string,
  primaryDistresses: PropTypes.object,
  csSign: PropTypes.string,
  map: PropTypes.object.isRequired,
  modalDataType: PropTypes.string.isRequired,
};

export default withStyles(styles)(CircleModalContent);
