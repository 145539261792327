import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import Divider from "@material-ui/core/Divider";
import {
  changeLayerColor,
  changeLayerName,
  toggleLayer,
} from "../actions/layers";
import State from "../interfaces/state";
import { clickBehaviorConstants } from "constants/clickBehavior";
import * as MapboxGl from "mapbox-gl";
import Layer from "../interfaces/state/layers/layer";
import { MoreVertRounded } from "@material-ui/icons";
import LayerGroup from "../interfaces/state/layers/layerGroup";
import LayerUpdateDialog from "./LayerUpdateDialog";
import RBAPI from "api/RoadwayAPI";
import Tooltip from "@material-ui/core/Tooltip";
import RoadSenseCheckboxes from "components/RoadSenseCheckboxes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "80%",
      textAlign: "center",
      margin: "auto",
    },
    margin: {
      height: theme.spacing(3),
    },
    divider: {
      width: "100%",
    },
    collectionTitle: {
      fontFamily: "'Open Sans', sans-serif",
      fontSize: "18px",
    },
    collectionEdit: {
      top: "5px",
      position: "relative",
      "&:hover": {
        cursor: "pointer",
      },
    },
    editIcon: {
      top: "6px",
      position: "relative",
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);

const RoadSenseControls = ({ map }: { map: MapboxGl.Map }): React.ReactElement => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const layerGroups = useSelector((state: State) => state.layers.groups);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [layer, setLayer] = useState({} as Layer);
  const [isEditing, setIsEditing] = useState(false);

  const flyToLayer = (layerId: number, allLayers: boolean, onlyId?: boolean): void => {
    if (map?.loaded()) {
      // get all layers
      const layers = layerGroups.reduce((a, b) => {
        if (b.layers.length > 0) {
          return a.concat(b.layers);
        }
        return a;
      }, [] as Layer[]);
      const west: number[] = []
      const south: number[] = []
      const east: number[] = []
      const north: number[] = []

      if(!onlyId){
        // Get layers that are visible and the new one that was just toggled, or all of them
        layers.filter(layer => allLayers ? true : layer.visible === true || layer.id === layerId).forEach(layer => {
          const { bounds } = map.getSource(
            layer.sourceLayer
          ) as MapboxGl.VectorSource;
          if (bounds) {
            west.push(bounds[0])
            south.push(bounds[1])
            east.push(bounds[2])
            north.push(bounds[3])
          }
        })
      } else {
        //Since we only want the ID passed in, fly to just that layer.
        const { bounds } = map.getSource(layers[layerId].sourceLayer) as MapboxGl.VectorSource;
        if (bounds) {
          west.push(bounds[0])
          south.push(bounds[1])
          east.push(bounds[2])
          north.push(bounds[3])
        }
      }
      const totalBounds = [Math.min(...west), Math.min(...south), Math.max(...east), Math.max(...north)]
      if (totalBounds) {
        map.fitBounds(totalBounds as MapboxGl.LngLatBoundsLike);
      }
    }
  };

  const editLayer = (
    layer: Layer
  ) => {
    setIsDialogOpen(true);
    setLayer(layer);
  };

  const hasRoadSenseData: LayerGroup[] = filter(
    layerGroups,
    (obj: LayerGroup) =>
      obj.clickBehavior === clickBehaviorConstants.roadwayRoadSensePoint
  );

  const handleLayerClick = (layerId: number) => {
    dispatch(toggleLayer(layerId));
  }

  const updateLayer = (newName: string, newColor: string) => {
    dispatch(changeLayerName(layer.id, newName));
    dispatch(changeLayerColor(layer.id, newColor));

    RBAPI.updateLayer({ id: layer.layerId, name: newName, color: newColor });
    setIsDialogOpen(false);
  };
  
  return (
    <div className={classes.root}>
      {!isEmpty(hasRoadSenseData) && (
        <div>
          <Typography
            id="discrete-slider-custom"
            gutterBottom
            className={classes.collectionTitle}
          >
            Image Collections
            <Tooltip
              title="Edit Collection Names"
              placement="bottom"
              className={classes.collectionEdit}
            >
              <MoreVertRounded onClick={() => setIsEditing(!isEditing)} />
            </Tooltip>
          </Typography>
          <Divider className={classes.divider} />
          <RoadSenseCheckboxes
            layers={hasRoadSenseData.reduce(
              (acc, curVal) => [...acc, ...curVal.layers],
              [] as Layer[]
            )}
            toggleLayer={handleLayerClick}
            isEditing={isEditing}
            editLayer={editLayer}
            flyToLayer={flyToLayer}
          />
          <LayerUpdateDialog
            updateLayer={updateLayer}
            currentName={layer.name}
            currentColor={layer.color}
            isOpen={isDialogOpen}
            closeDialog={() => setIsDialogOpen(false)}
          />
        </div>
      )}
    </div>
  );
};

export default RoadSenseControls;
