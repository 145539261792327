import {
  SET_ORGANIZATIONS,
  SET_ACTIVE_ORGANIZATION,
  SET_ACTIVE_SCAN,
  SET_MEMBERSHIPS,
  FETCH_ORGANIZATIONS,
  FETCH_ADMIN_LEVEL_ORGANIZATIONS,
  FETCH_ORGANIZATION_USERS,
  FETCH_ORGANIZATION_SCANS,
  FETCH_ALL_ORGANIZATIONS,
  SET_MEASURING_SYSTEM,
  FETCH_ORGANIZATION_EMBEDDED_MAPS,
  SET_HIDE_EXAMPLE_ORGANIZATIONS,
  SET_ANALYSIS_DATA,
} from "actiontypes/userData";
import { MeasuringSystem } from "constants/measuringSystem";

const initialState = {
  organizations: [],
  adminOrganizations: [],
  activeOrganization: "",
  activeScan: "",
  memberships: [],
  isAdmin: false,
  isRoadBoticsAdmin: false,
  organizationUsers: {},
  organizationScans: {},
  organizationEmbeddedMaps: {},
  allOrganizations: [],
  isAllOrganizationsDataLoaded: false,
  isOrganizationsDataLoaded: false,
  isOrganizationUsersDataLoaded: false,
  isOrganizationScansDataLoaded: false,
  isOrganizationEmbeddedMapsDataLoaded: false,
  analysisData: {
    potholeCount: 0,
    hasIDIData: false,
  },
  measuringSystem: MeasuringSystem.Imperial,
  hideExampleOrganizations: false,
};

const ORG_ROADBOTICS = "RoadBotics";
const ROLE_ADMIN = "Admin";

const updateOrganizationData = (
  organizationsData,
  organizationData,
  organizationId
) => {
  const { ...restOrganizationData } = organizationsData;
  return {
    ...restOrganizationData,
    [organizationId]: organizationData,
  };
};

const groupOrganizationScans = (organizations, organizationScans = {}) => {
  if (organizations && organizations.length > 0) {
    organizations.forEach(({ id, scans, childrenOrganizations }) => {
      if (scans) {
        organizationScans[id] = scans;
      }
      if (childrenOrganizations) {
        groupOrganizationScans(childrenOrganizations, organizationScans);
      }
    });
  }
  return organizationScans;
};

const userData = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.organizations,
      };
    case SET_ACTIVE_ORGANIZATION:
      return {
        ...state,
        activeOrganization: action.activeOrganization,
      };
    case SET_ACTIVE_SCAN:
      return {
        ...state,
        activeScan: action.activeScan,
      };
    case SET_MEMBERSHIPS:
      return {
        ...state,
        memberships: action.memberships,
        isAdmin: action.memberships.some(({ role }) => role === ROLE_ADMIN),
        isRoadBoticsAdmin: action.memberships.some(
          ({ organization, role }) =>
            organization === ORG_ROADBOTICS && role === ROLE_ADMIN
        ),
      };
    case `${FETCH_ALL_ORGANIZATIONS}_PENDING`:
      return {
        ...state,
        isAllOrganizationsDataLoaded: false,
      };
    case `${FETCH_ALL_ORGANIZATIONS}_FULFILLED`:
      return {
        ...state,
        allOrganizations: action.payload,
        isAllOrganizationsDataLoaded: true,
      };
    case `${FETCH_ORGANIZATIONS}_PENDING`:
      return {
        ...state,
        isOrganizationsDataLoaded: false,
        isOrganizationScansDataLoaded: false,
      };
    case `${FETCH_ORGANIZATIONS}_FULFILLED`:
      return {
        ...state,
        organizations: action.payload,
        organizationScans: groupOrganizationScans(action.payload),
        isOrganizationsDataLoaded: true,
        isOrganizationScansDataLoaded: true,
      };
    case `${FETCH_ORGANIZATIONS}_REJECTED`:
      return {
        ...state,
        isOrganizationsDataLoaded: true,
        isOrganizationScansDataLoaded: true,
        isOrganizationUsersDataLoaded: true,
      };
    case `${FETCH_ADMIN_LEVEL_ORGANIZATIONS}_PENDING`:
      return {
        ...state,
      };
    case `${FETCH_ADMIN_LEVEL_ORGANIZATIONS}_FULFILLED`:
      return {
        ...state,
        adminOrganizations: action.payload,
      };
    case `${FETCH_ADMIN_LEVEL_ORGANIZATIONS}_REJECTED`:
      return {
        ...state,
      };
    case `${FETCH_ORGANIZATION_USERS}_PENDING`:
      return {
        ...state,
        isOrganizationUsersDataLoaded: false,
      };
    case `${FETCH_ORGANIZATION_USERS}_FULFILLED`:
      return {
        ...state,
        organizationUsers: updateOrganizationData(
          state.organizationUsers,
          action.payload,
          action.meta.organizationId
        ),
        isOrganizationUsersDataLoaded: true,
      };
    case `${FETCH_ORGANIZATION_SCANS}_PENDING`:
      return {
        ...state,
        isOrganizationScansDataLoaded: false,
      };
    case `${FETCH_ORGANIZATION_SCANS}_FULFILLED`:
      return {
        ...state,
        organizationScans: updateOrganizationData(
          state.organizationScans,
          action.payload,
          action.meta.organizationId
        ),
        isOrganizationScansDataLoaded: true,
      };
    case `${FETCH_ORGANIZATION_EMBEDDED_MAPS}_PENDING`:
      return {
        ...state,
        isOrganizationEmbeddedMapsDataLoaded: false,
      };
    case `${FETCH_ORGANIZATION_EMBEDDED_MAPS}_FULFILLED`:
      return {
        ...state,
        organizationEmbeddedMaps: updateOrganizationData(
          state.organizationEmbeddedMaps,
          action.payload,
          action.meta.organizationId
        ),
        isOrganizationEmbeddedMapsDataLoaded: true,
      };
    case SET_MEASURING_SYSTEM:
      return {
        ...state,
        measuringSystem: action.measuringSystem,
      };
    case SET_HIDE_EXAMPLE_ORGANIZATIONS:
      return {
        ...state,
        hideExampleOrganizations: action.hideExampleOrganizations,
      };
    case SET_ANALYSIS_DATA:
      return {
        ...state,
        analysisData: action.analysisData,
      };
    default:
      return state;
  }
};

export default userData;
