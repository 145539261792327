import React, { Dispatch, FC } from "react";
import RadioButtonsGroup, { RadioValue } from "./form/RadioGroup";
import { useTracking } from "react-tracking";

export enum Ratings {
  "Rating 1" = 1,
  "Rating 2" = 2,
  "Rating 3" = 3,
  "Rating 4" = 4,
  "Rating 5" = 5,
}

export type RatingsType = keyof typeof Ratings;

interface RatingRadiosProps {
  setRatings: Dispatch<RadioValue<RatingsType>[]>;
  ratings: RadioValue<RatingsType>[];
}

const RatingRadios: FC<RatingRadiosProps> = props => {
  const { setRatings, ratings } = props;
  const tracking = useTracking();

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const value = event.target.value;
    setRatings(ratings.map(r => (r.value === value ? { ...r, checked } : r)));

    tracking.trackEvent({
      event: "mouse-click",
      action: "filter-ratings-click",
    });
  };

  return <RadioButtonsGroup onChange={onChange} values={ratings} />;
};

export default RatingRadios;
