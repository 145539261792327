import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import grey from "@material-ui/core/colors/grey";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grow from "@material-ui/core/Grow";
import GroupedOption from "./GroupedOption";

const ScanSwitcherSelect = ({
  options,
  selectedOption,
  handleChange,
  hasNoData,
  isDisabled,
}) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: `1px solid ${grey[300]}`,
      color: state.isDisabled ? grey[600] : grey[900],
      backgroundColor: state.isFocused ? "#00000014" : "transparent",
      padding: "5px",
      cursor: state.isDisabled ? "default" : "pointer",
    }),
  };

  const renderNoOptionsMessage = () =>
    hasNoData ? (
      <div>
        <Grow in={true}>
          <CircularProgress
            style={{
              marginRight: "8px",
            }}
            size={14}
          />
        </Grow>
        Assessments Loading...
      </div>
    ) : (
      <p>No Assessments found</p>
    );

  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      options={options}
      components={{ Option: GroupedOption }}
      isOptionDisabled={option => !option.isOption}
      styles={customStyles}
      placeholder="Select an assessment..."
      noOptionsMessage={renderNoOptionsMessage}
      isDisabled={isDisabled}
    />
  );
};

ScanSwitcherSelect.defaultProps = {
  selectedOption: null,
  isDisabled: false,
};

ScanSwitcherSelect.propTypes = {
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
  hasNoData: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
};

export default ScanSwitcherSelect;
