import React from "react";
import PropTypes from "prop-types";

import MenuItem from "@material-ui/core/MenuItem";

import Dialog from "components/Dialog/Dialog";
import TextField from "components/form/TextField";
import Select from "components/form/Select";
import ActivityIndicatorButton from "components/ActivityIndicatorButton";

import { useForm, Controller } from "react-hook-form";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  formControl: {
    width: "100%",
  },
});

const UsersAdminUpdateFormDialog = ({
  title,
  isOpen,
  roles,
  handleClose,
  handleUpdate,
  values: initialFormState,
  isUpdateActive,
}) => {
  const { register, errors, control, handleSubmit } = useForm({
    defaultValues: initialFormState,
  });
  const classes = useStyles();

  const handleFormSubmit = e => {
    e.preventDefault();
    handleSubmit(data => {
      handleUpdate({ ...initialFormState, ...data });
    })(e);
  };

  const renderForm = () => (
    <form onSubmit={handleFormSubmit}>
      <div>
        <TextField
          label="First Name"
          name="firstName"
          inputRef={register({
            required: "This field is required.",
          })}
          error={errors?.firstName !== undefined}
          helperText={errors?.firstName?.message}
        />
        <TextField
          label="Last Name"
          name="lastName"
          inputRef={register({
            required: "This field is required.",
          })}
          error={errors?.lastName !== undefined}
          helperText={errors?.lastName?.message}
        />
        <FormControl
          error={errors?.role !== undefined}
          className={classes.formControl}
        >
          <Controller
            name={"role"}
            control={control}
            rules={{
              required: "This field is required",
            }}
            defaultValue={initialFormState?.role}
            as={
              <Select displayEmpty name="role">
                <MenuItem value="" disabled>
                  Select Role
                </MenuItem>
                {roles &&
                  roles.map(role => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
              </Select>
            }
          />
          <FormHelperText>{errors?.role?.message}</FormHelperText>
        </FormControl>
      </div>
    </form>
  );

  const renderFormActions = () => (
    <ActivityIndicatorButton
      onClick={handleFormSubmit}
      isActive={isUpdateActive}
    >
      Submit
    </ActivityIndicatorButton>
  );

  return (
    <Dialog
      title={title}
      isOpen={isOpen}
      handleClose={handleClose}
      actions={renderFormActions()}
    >
      {renderForm()}
    </Dialog>
  );
};

UsersAdminUpdateFormDialog.defaultProps = {
  title: "",
  isOpen: false,
  handleClose: () => {},
};

UsersAdminUpdateFormDialog.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  roles: PropTypes.array.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  isUpdateActive: PropTypes.bool.isRequired,
};

export default UsersAdminUpdateFormDialog;
