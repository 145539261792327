import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import welcomeImage from "../images/welcomeImage2.jpg";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "./form/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { AssessmentTypes } from "constants/assessmentTypes";
import { getAssessmentType } from "utils/getAssessmentType";

const useStyles = makeStyles({
  dialogTitle: {
    paddingTop: "30px",
    paddingBottom: "10px",
  },
});

type WelcomeDialogContentProps = {
  checked: boolean;
  handleCheckboxChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  dismissDialog: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
};

const WelcomeDialogContent = ({
  checked,
  handleCheckboxChange,
  dismissDialog,
}: WelcomeDialogContentProps): React.ReactElement => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <DialogTitle className={classes.dialogTitle} disableTypography={true}>
      <Typography variant="h3"> Welcome to {getAssessmentType() === AssessmentTypes.NORMAL ? "RoadWay" : "RoadSense"}!</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`If this is your first time visting ${getAssessmentType() === AssessmentTypes.NORMAL ? "RoadWay" : "RoadSense"} 
          click the '?' icon in the bottom left to learn how to use our platform and read about some of the most 
          frequently asked questions.`}
        </DialogContentText>
        <img src={welcomeImage} alt="welcome screen" />
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={handleCheckboxChange}
              color="primary"
            />
          }
          label="Do Not Show Again"
        />
        <Button onClick={dismissDialog} variant="contained" color="primary">
          Close
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

export default WelcomeDialogContent;
