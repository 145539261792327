import { makeStyles } from "@material-ui/core";
import { RB_LIGHT_GREY, RB_YELLOW } from "constants/colors";
import React, { Dispatch, FC } from "react";
import AnalysisSlider from "./AnalysisSlider";
import RadioButtonsGroup, { RadioValue } from "./form/RadioGroup";
import { useTracking } from "react-tracking";

const useStyles = makeStyles({
  slidersContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
  },
});

export enum Distresses {
  potholes = "Number of Potholes",
  fatigueCracking = "% of Fatigue Cracking",
  deterioration = "% of Surface Deterioration",
  distortion = "% of Pavement Distortion",
  sealant = "Number of Patches/Sealant",
  longCracking = "% of Trans/Long Cracking",
}

export type DistressesRanges = {
  title: Distresses;
  initialRange: number[];
  value: number[];
  checked: boolean;
};

interface DistressesSlidersProps {
  setDistresses: Dispatch<DistressesRanges[]>;
  distresses: DistressesRanges[];
}

const DistressesSliders: FC<DistressesSlidersProps> = props => {
  const { setDistresses, distresses } = props;
  const styles = useStyles();
  const tracking = useTracking();

  const handleSliderChange = (sliderVal: DistressesRanges) => {
    setDistresses(
      distresses.map(d =>
        d.title === sliderVal.title
          ? {
              ...d,
              initialRange: sliderVal.initialRange,
              value: sliderVal.value,
            }
          : d
      )
    );
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const value = event.target.value;
    setDistresses(
      distresses.map(d => (d.title === value ? { ...d, checked } : d))
    );

    tracking.trackEvent({
      event: "mouse-click",
      action: "filter-distresses-click",
    });
  };

  const renderAnalysisSliders = (
    slider: DistressesRanges
  ): RadioValue<Distresses> => {
    const sliderJSX = (
      <AnalysisSlider value={slider} handleSliderChange={handleSliderChange} />
    );
    return {
      value: slider.title,
      selectedStyle: { backgroundColor: RB_YELLOW },
      unselectedStyle: { backgroundColor: RB_LIGHT_GREY },
      uiElement: sliderJSX,
      checked: slider.checked,
    };
  };

  const radioValues: RadioValue<Distresses>[] = distresses.map(
    renderAnalysisSliders
  );

  return (
    <div className={styles.slidersContainer}>
      <RadioButtonsGroup onChange={handleChange} values={radioValues} />
    </div>
  );
};

export default DistressesSliders;
