import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import CreateProfileForm from "../components/CreateProfileForm";
import Snackbar from "./Snackbar";
import { ROUTE_ROAD_SENSE_SIGN_UP_SUCCESS } from "../constants/routes";
import RBAPI from "api/RoadwayAPI";
import { submitHubSpotForm } from "../utils/hubspot";
import { ROADSENSE_FORM_ID } from "../constants/hubspot";
import { setSnackbarMessageAndOpen, MessageTypes } from "actions/snackbar";
import { useDispatch } from "react-redux";

export type RoadSenseSignUpContainerFormData = {
  email: string;
  organizationName: string;
  firstName: string;
  lastName: string;
  password: string;
  verifyPassword: string;
  numberOfMiles: number;
};

const RoadSenseSignUpContainer: React.FC = () => {
  const { register, handleSubmit, watch, errors } = useForm<
    RoadSenseSignUpContainerFormData
  >();

  const location = useLocation();
  const history = useHistory();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    handleSubmit(
      async ({ email, organizationName, firstName, lastName, password }) => {
        setFormSubmitted(true);

        try {
          if (process.env.NODE_ENV === "production") {
            await submitHubSpotForm(
              firstName,
              lastName,
              email,
              process.env.REACT_APP_ROADWAY_URL + "/" + location.pathname,
              ROADSENSE_FORM_ID
            );
          }
        } catch (e) {
          console.error(e);
        }

        try {
          await RBAPI.createRoadSense({
            email,
            organizationName,
            firstName,
            lastName,
            password,
          });
          history.push(ROUTE_ROAD_SENSE_SIGN_UP_SUCCESS);
        } catch (e) {
          console.error(e);
          dispatch(setSnackbarMessageAndOpen(`Failed to create RoadSense account with organization ${organizationName}. Contact your administrator to see if they have already created an account for your organization.`, MessageTypes.ERROR));
          setFormSubmitted(false);
        }
      }
    )(event);
  };

  return (
    <React.Fragment>
      <CreateProfileForm
        title={"RoadWay RoadSense Sign Up"}
        register={register}
        watch={watch}
        errors={errors}
        onSubmit={onSubmit}
        location={location}
        formSubmitted={formSubmitted}
      />
      <Snackbar />
    </React.Fragment>
  );
};

export default RoadSenseSignUpContainer;
