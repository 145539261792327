import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Pie } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import {
  RB_GREEN,
  RB_LIGHT_GREEN,
  RB_YELLOW,
  RB_ORANGE,
  RB_RED,
} from "constants/colors";
import { determineBackgroundColor } from "utils/determineColor";
import Paper from "@material-ui/core/Paper";
import PieChartSkeleton from "../skeletons/pieChart";
import { AnalysisInterface } from "interfaces/analysis";

const useStyles = makeStyles({
  radialTitle: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "19px",
    textAlign: "center",
    marginTop: "15px",
  },
  piechartTitle: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "19px",
    textAlign: "center",
    marginTop: "15px",
  },
  box: {
    color: "white",
    margin: "2px 80px",
    padding: "5px",
    textAlign: "center",
  },
  number: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "35px",
    fontWeight: "bold",
    padding: "10px",
  },
});

interface PieChartProps {
  analysisData: AnalysisInterface;
  isDataLoaded: boolean;
}

const PieChart = ({
  analysisData,
  isDataLoaded,
}: PieChartProps): React.ReactElement => {
  const classes = useStyles();

  const pieChartSeriesData = [];

  if (analysisData) {
    for (let i = 1; i <= 5; i++) {
      if (analysisData.networkRatingDistribution !== undefined) {
        const networkRatingDistribution = parseFloat(
          analysisData.networkRatingDistribution[i]
        );
        if (!Number.isNaN(networkRatingDistribution)) {
          pieChartSeriesData.push(
            parseFloat(networkRatingDistribution.toFixed(2))
          );
        }
      }
    }
  }

  const pieChart = {
    data: {
      datasets: [
        {
          data: pieChartSeriesData,
          backgroundColor: [
            RB_GREEN,
            RB_LIGHT_GREEN,
            RB_YELLOW,
            RB_ORANGE,
            RB_RED,
          ],
        },
      ],
      labels: ["Rating 1", "Rating 2", "Rating 3", "Rating 4", "Rating 5"],
    },
    options: {
      maintainAspectRatio: true,
      legend: { position: "bottom" },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 20,
          bottom: 20,
        },
      },
      plugins: {
        datalabels: {
          display: "auto",
          align: "end",
          anchor: "center",
          color: "white",
          font: {
            size: 16,
          },
          textShadowBlur: 2,
          textShadowColor: "black",
          formatter: function(value: string | number): string {
            return `${value} %`;
          },
        },
      },
    },
  };

  return (
    <div>
      {!isDataLoaded ? (
        <PieChartSkeleton />
      ) : (
        <div>
          <Typography className={classes.radialTitle}>
            Road Network Score
          </Typography>
          <Paper
            className={classes.box}
            style={{
              backgroundColor: determineBackgroundColor(
                analysisData.networkScore.toFixed(2)
              ),
            }}
          >
            <Typography className={classes.number}>
              {analysisData.networkScore.toFixed(2)}
            </Typography>
          </Paper>
          <Typography className={classes.piechartTitle}>
            Network Ratings Breakdown
          </Typography>
          <div style={{ maxWidth: "480px", margin: "auto" }}>
            <Pie
              data={pieChart.data}
              width={100}
              height={100}
              options={pieChart.options}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PieChart;
