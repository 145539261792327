import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

const style = {
  root: {
    width: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "15px",
    fontFamily: "'Open Sans', sans-serif",
  },
};

const Geocoder = ({ classes, id }) => <div id={id} className={classes.root} />;

Geocoder.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};

export default withStyles(style)(Geocoder);
