import React from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles from "@material-ui/core/styles/withStyles";

import DynamicCheckbox from "components/DynamicCheckbox";
import PropTypes from "prop-types";

const styles = {
  root: {
    marginLeft: 0,
    marginRight: 0,
  },
  sidewalk: {
    marginRight: "5px",
    marginLeft: "5px",
  },
  label: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: "bold",
  },
};

const RoundCheckbox = ({
  classes,
  label,
  onChange,
  disabled,
  checked,
  color,
  layerType,
}) => (
  <FormControlLabel
    labelPlacement="top"
    label={label}
    data-testid={checked ? label : `${label}-off`}
    disabled={disabled}
    classes={{
      root: layerType === "sidewalk" ? classes.sidewalk : classes.root,
      label: classes.label,
    }}
    control={
      <DynamicCheckbox
        color={color}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
    }
  />
);

RoundCheckbox.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  color: PropTypes.string,
};

export default withStyles(styles)(RoundCheckbox);
