import React from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { startCase, camelCase } from "lodash";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  deleteIcon: {
    padding: "0px",
  },
});

interface PointAnnotationOptionsProps {
  option: {
    name: string;
    id: string;
    enableDelete: boolean;
  };
  optionKey: string;
  prefix: string;
  stringIndex: number;
  isDeletingOptionsToTrue: () => void;
  deleteOption: (id: string, optionKey: string) => void;
}

const PointAnnotationOptions = ({
  option,
  optionKey,
  prefix,
  stringIndex,
  isDeletingOptionsToTrue,
  deleteOption,
}: PointAnnotationOptionsProps): React.ReactElement => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" justify="space-between">
      <Grid>
        {option.name?.slice(0, 3) === prefix ? (
          <span>
            <i>{prefix}&nbsp;</i>

            {startCase(camelCase(option.name.slice(stringIndex)))}
          </span>
        ) : (
          startCase(camelCase(option.name))
        )}
      </Grid>
      <Grid>
        {option.enableDelete && (
          <IconButton
            id={optionKey}
            classes={{ root: classes.deleteIcon }}
            onClick={(): void => {
              isDeletingOptionsToTrue();
              deleteOption(option.id, optionKey);
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default PointAnnotationOptions;
