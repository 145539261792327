import React, { useState, ReactNode } from "react";

import MaterialTable, { Icons } from "material-table";
import AddIcon from "@material-ui/icons/Add";
import CodeIcon from "@material-ui/icons/Code";
import KeyboardArrowDownRounded from "@material-ui/icons/KeyboardArrowDownRounded";

import Button from "components/form/Button";

import Map from "interfaces/map";

import { TABLE_HEADER_BACKGROUND, TABLE_HEADER_TEXT } from "constants/colors";
import styleTableRow from "utils/styleTableRow";
import Typography from "@material-ui/core/Typography";
import TableSkeleton from "skeletons/table";

interface OrganizationEmbeddedMapsProps {
  isEmbeddedMapsLoaded: boolean;
  embeddedMaps: Map[];
  handleClickCreate: () => void;
  handleEdit: (rowData: Map | Map[]) => void;
  handleDelete: (rowData: Map | Map[]) => void;
  handlePreview: (rowData: Map | Map[]) => void;
  organizationName: string;
}

function CodeIconComponent(): React.ReactElement {
  return <CodeIcon />;
}

const OrganizationEmbeddedMaps = ({
  isEmbeddedMapsLoaded,
  embeddedMaps,
  handleClickCreate,
  handleEdit,
  handleDelete,
  handlePreview,
  organizationName,
}: OrganizationEmbeddedMapsProps): ReactNode => {
  const [pageSize, setPageSize] = useState(5);

  return (
    <div>
      <Button
        onClick={(): void => handleClickCreate()}
        disabled={!isEmbeddedMapsLoaded}
        variant="contained"
        color="primary"
        capitalizeLabel={false}
      >
        <AddIcon />
        {`Create Embedded Map`}
      </Button>
      <MaterialTable
        isLoading={!isEmbeddedMapsLoaded}
        style={{ boxShadow: "none" }}
        onChangeRowsPerPage={setPageSize}
        options={{
          pageSizeOptions: [5, 10, 20, 50],
          pageSize,
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: TABLE_HEADER_BACKGROUND,
            color: TABLE_HEADER_TEXT,
            fontWeight: "bold",
            fontSize: "14px",
          },
          rowStyle: styleTableRow,
        }}
        localization={{
          header: { actions: "" },
          body: {
            emptyDataSourceMessage: ((isEmbeddedMapsLoaded ? (
              <Typography>
                No records to display. Contact RoadBotics if this is incorrect
              </Typography>
            ) : (
              <TableSkeleton />
            )) as unknown) as string,
          },
        }}
        actions={[
          {
            icon: CodeIconComponent,
            tooltip: "Preview",
            onClick: (_event, rowData): void => {
              handlePreview(rowData);
            },
          },
          {
            icon: "edit",
            tooltip: "Edit",
            onClick: (_event, rowData): void => {
              handleEdit(rowData);
            },
          },
          {
            icon: "delete",
            tooltip: "Delete",
            onClick: (_event, rowData): void => {
              handleDelete(rowData);
            },
          },
        ]}
        title={`Embedded Maps - ${organizationName}`}
        columns={[
          { title: "Name", field: "name" },
          { title: "Assessment", field: "scanName" },
          {
            title: "Public",
            field: "enabled",
          },
        ]}
        data={embeddedMaps}
        icons={{ SortArrow: KeyboardArrowDownRounded } as Icons}
      />
    </div>
  );
};

OrganizationEmbeddedMaps.defaultProps = {
  embeddedMaps: [],
};

export default OrganizationEmbeddedMaps;
