import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import classNames from "classnames";
import Drawer from "@material-ui/core/Drawer";
import ModalTab, { OpenSide } from "./ModalTab";
import { topBannerHeight } from "../views/Banner";

export const drawerWidth = "36vw";
export const largeDrawerWidth = "60vw";

const style = makeStyles({
  sidebar: {
    flexShrink: 0,
  },
  sidebarPaper: (props: StyleProps) => ({
    display: "flex",
    flexDirection: "column",
    height: `calc(100% - ${props.appbarHeight}px)`,
    top: props.appbarHeight,
    zIndex: 11,
  }),
  sidebarPaperBanner: (props: StyleProps) => ({
    display: "flex",
    flexDirection: "column",
    height: `calc(100% - ${props.appbarHeight}px - ${topBannerHeight}px)`,
    top: props.appbarHeight + topBannerHeight,
    zIndex: 11,
  }),
  sidebarDesktop: {
    width: `${drawerWidth}`,
  },
  sidebarExpandDesktop: {
    width: `${largeDrawerWidth}`,
  },
  sidebarContent: {
    flex: "1 1 auto",
    position: "relative" /* need this to position inner content */,
    overflowY: "auto",
    padding: "8px",
    "&::-webkit-scrollbar": {
      /* Scrollbar */
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      /* Track */
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      /* Handle */
      background: "#888",
    },

    "&::-webkit-scrollbar-thumb:hover": {
      /* Handle on hover */
      background: "#555",
    },
  },
  closeModal: {
    right: `calc(${drawerWidth} - 4px)`,
    position: "fixed",
    width: "24px",
    top: "58px",
    height: "40px",
    boxShadow: "none",
    cursor: "pointer",
  },
  closeExpandModal: {
    right: `calc(${largeDrawerWidth} - 4px)`,
    position: "fixed",
    width: "24px",
    top: "58px",
    height: "40px",
    boxShadow: "none",
    cursor: "pointer",
  },
  chevron: {
    position: "absolute",
    top: "7px",
  },
  openModal: {
    right: "-1px",
    position: "fixed",
    width: "24px",
    top: "58px",
    height: "40px",
    boxShadow: "none",
    cursor: "pointer",
  },
});

interface StyleProps {
  appbarHeight: string;
}

interface ModalSidebarProps {
  isTopBannerOpen: boolean;
  open: boolean;
  children: React.ReactNode;
  closeModalClick: () => void;
  openModalClick: () => void;
  appbarHeight: string;
  dataPresent: boolean;
  enlargedImg: boolean;
}

const ModalSidebar = ({
  isTopBannerOpen,
  open,
  children,
  closeModalClick,
  openModalClick,
  appbarHeight,
  dataPresent,
  enlargedImg,
}: ModalSidebarProps) => {
  const props = { appbarHeight };
  const classes = style(props);
  return (
    <div>
      <ModalTab
        isTopBannerOpen={isTopBannerOpen}
        open={open}
        openModalClick={openModalClick}
        enlargedImg={enlargedImg}
        isModalDataPresent={dataPresent}
        closeModalClick={closeModalClick}
        drawerWidth={drawerWidth}
        largeDrawerWidth={largeDrawerWidth}
        openSide={OpenSide.RIGHT}
      />
      <Drawer
        id="modalSidebarId"
        variant="persistent"
        anchor="right"
        className={classNames(classes.sidebar, {
          [classes.sidebarDesktop]: !enlargedImg,
          [classes.sidebarExpandDesktop]: enlargedImg,
        })}
        classes={{
          paper: classNames(classes.sidebarPaper, {
            [classes.sidebarDesktop]: !enlargedImg,
            [classes.sidebarExpandDesktop]: enlargedImg,
            [classes.sidebarPaperBanner]: isTopBannerOpen,
          }),
        }}
        open={open}
      >
        <div className={classes.sidebarContent}>{children}</div>
      </Drawer>
    </div>
  );
};

export default ModalSidebar;
