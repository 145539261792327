import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/styles/withStyles";
import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { RB_POTHOLE_ORANGE } from "constants/colors";
import {
  POTHOLES,
  FATIGUE_CRACKING,
  PAVEMENT_DISTORTIONS,
  PATCH_SEAL,
  TRANS_LONG_CRACKING,
  SURFACE_DETERIORATION,
} from "constants/distressTypes";

const styles = {
  label: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: "bold",
    fontSize: "10px",
    display: "inline-block",
    textAlign: "center",
  },
  group: {
    textAlign: "center",
  },
  emptyBox: {
    border: "2px solid #000",
    color: "#b4b4b4",
    background: "#dbdbdb",
  },
  filledBox: {
    border: "2px solid #000",
    background: RB_POTHOLE_ORANGE,
  },
};

const IndividualDistressPointStatistics = ({
  classes,
  hasPothole,
  hasFatigueCracking,
  hasPatchSeal,
  hasTransLongCracking,
  hasSurfaceDeterioration,
  hasPavementDistortions,
}) => {
  return (
    <div>
      <Grid
        container
        className={classes.group}
        justify="center"
        alignItems="stretch"
      >
        <Grid container>
          <Grid
            item
            xs
            className={hasPothole ? classes.filledBox : classes.emptyBox}
          >
            <Typography className={classes.label}>{POTHOLES}</Typography>
          </Grid>
          <Grid
            item
            xs
            className={
              hasFatigueCracking ? classes.filledBox : classes.emptyBox
            }
          >
            <Typography className={classes.label}>
              {FATIGUE_CRACKING}
            </Typography>
          </Grid>
          <Grid
            item
            xs
            className={
              hasPavementDistortions ? classes.filledBox : classes.emptyBox
            }
          >
            <Typography className={classes.label}>
              {PAVEMENT_DISTORTIONS}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xs
            className={hasPatchSeal ? classes.filledBox : classes.emptyBox}
          >
            <Typography className={classes.label}>{PATCH_SEAL}</Typography>
          </Grid>
          <Grid
            item
            xs
            className={
              hasTransLongCracking ? classes.filledBox : classes.emptyBox
            }
          >
            <Typography className={classes.label}>
              {TRANS_LONG_CRACKING}
            </Typography>
          </Grid>
          <Grid
            item
            xs
            className={
              hasSurfaceDeterioration ? classes.filledBox : classes.emptyBox
            }
          >
            <Typography className={classes.label}>
              {SURFACE_DETERIORATION}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

IndividualDistressPointStatistics.propTypes = {
  classes: PropTypes.object.isRequired,
  hasPothole: PropTypes.bool.isRequired,
  hasFatigueCracking: PropTypes.bool.isRequired,
  hasTransLongCracking: PropTypes.bool.isRequired,
  hasSurfaceDeterioration: PropTypes.bool.isRequired,
  hasPavementDistortions: PropTypes.bool.isRequired,
  hasPatchSeal: PropTypes.bool.isRequired,
};

export default withStyles(styles)(IndividualDistressPointStatistics);
