export const mungeyLayerSignTypes = [
  "NRT",
  "ONWY",
  "DNE",
  "STOP",
  "WRWY",
  "NLT",
  "FIRE",
  "YLD",
  "TRFL",
  "SPDL",
];

export const PLURAL_NO_RIGHT_TURN = "NRTS";
export const PLURAL_ONE_WAY = "ONWIES";
export const PLURAL_YIELD = "YLDS";
export const PLURAL_FIRE_HYRDRANT = "FIRES";
export const PLURAL_NO_LEFT_TURN = "NLTS";
export const PLURAL_WRONG_WAY = "WRWIES";
export const PLURAL_STOP_SIGN = "STOPS";
export const PLURAL_DO_NOT_ENTER = "DNES";
export const PLURAL_TRAFFIC_LIGHT = "TRFLS";
export const PLURAL_SPEED_LIMIT = "SPDLS";

export const pluralizedSignTypes = [
  PLURAL_NO_RIGHT_TURN,
  PLURAL_ONE_WAY,
  PLURAL_YIELD,
  PLURAL_FIRE_HYRDRANT,
  PLURAL_NO_LEFT_TURN,
  PLURAL_WRONG_WAY,
  PLURAL_STOP_SIGN,
  PLURAL_DO_NOT_ENTER,
  PLURAL_TRAFFIC_LIGHT,
  PLURAL_SPEED_LIMIT,
];

export const ROADWAY_SIGN_NRT = "roadwayNRTs";
export const ROADWAY_SIGN_ONWY = "roadwayONWYs";
export const ROADWAY_SIGN_WRWY = "roadwayWRWYs";
export const ROADWAY_SIGN_DNE = "roadwayDNEs";
export const ROADWAY_SIGN_FIRE = "roadwayFIREs";
export const ROADWAY_SIGN_STOP = "roadwaySTOPs";
export const ROADWAY_SIGN_NLT = "roadwayNLTs";
export const ROADWAY_SIGN_YLD = "roadwayYLDs";
export const ROADWAY_SIGN_TRFL = "roadwayTRFLs";
export const ROADWAY_SIGN_SPDL = "roadwaySPDLs";

export const styleSignTypes = [
  "roadwayNRT",
  "roadwayONWY",
  "roadwayWRWYs",
  "roadwayDNE",
  "roadwayFIRE",
  "roadwaySTOP",
  "roadwayNLT",
  "roadwayYLD",
  "roadwayTRFL",
  "roadwaySPDL",
];
