import React from "react";
import Logo from "images/boxlogo.png";

const MobileWarning = () => (
  <div>
    <img
      style={{
        display: "block",
        marginTop: "3%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
      src={Logo}
      alt="notThere"
    />
    <h3
      style={{
        textAlign: "center",
        border: "5px dashed #fad201",
        width: "70%",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "20px",
      }}
    >
      To get the most out of RoadWay, view this on a larger screen.
    </h3>
  </div>
);

export default MobileWarning;
