import React from "react";
import { Layer, Source } from "react-mapbox-gl";
import PropTypes from "prop-types";
import { SEGMENT, SEGMENTS, POINT } from "constants/highlightTypes";
import makeSegment from "utils/makeSegment";

const HighlightSourcesAndLayers = ({
  pointCoords,
  segmentCoords,
  segments,
  highlightType,
  azimuth,
  color = "blue",
}) => {
  if (highlightType === SEGMENTS) {
    return segments.map(segmentFeature => {
      const segment = makeSegment(segmentFeature);
      return (
        <React.Fragment key={segment.id}>
          <Source
            id={segment.id}
            geoJsonSource={{
              type: "geojson",
              data: {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "LineString",
                  coordinates: segment.coordinates,
                },
              },
            }}
          />
          <Layer
            id={`${segment.id}-highlight-layer`}
            type="line"
            sourceId={segment.id}
            layout={{
              "line-join": "round",
              "line-cap": "round",
              visibility: "visible",
            }}
            paint={{
              "line-color": "#000",
              "line-width": 15,
              "line-opacity": 0.7,
            }}
          />
        </React.Fragment>
      );
    });
  } else if (highlightType === SEGMENT) {
    return (
      <React.Fragment>
        <Source
          id="lineHighlightSource"
          geoJsonSource={{
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: segmentCoords,
              },
            },
          }}
        />
        <Layer
          id="lineHighlightLayer"
          type="line"
          sourceId="lineHighlightSource"
          layout={{
            "line-join": "round",
            "line-cap": "round",
            visibility: "visible",
          }}
          paint={{
            "line-color": "#000",
            "line-width": 15,
            "line-opacity": 0.7,
          }}
        />
      </React.Fragment>
    );
  } else if (highlightType === POINT) {
    return (
      <React.Fragment>
        <Source
          id="pointHighlightSource"
          geoJsonSource={{
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  geometry: {
                    type: "Point",
                    coordinates: pointCoords,
                  },
                },
              ],
            },
          }}
        />
        {!isNaN(azimuth) ? (
          <Layer
            id="pointHighlightLayer"
            type="symbol"
            sourceId="pointHighlightSource"
            layout={{
              visibility: "visible",
              "icon-image": "selectCone",
              "icon-size": 0.5,
              "icon-rotate": azimuth,
              "icon-anchor": "center",
            }}
          />
        ) : (
          <Layer
            id="pointHighlightLayer"
            type="circle"
            sourceId="pointHighlightSource"
            paint={{
              "circle-radius": 9,
              "circle-color": color,
              "circle-stroke-color": color,
              "circle-stroke-width": 4,
              "circle-opacity": 0.5,
            }}
            layout={{
              visibility: "visible",
            }}
          />
        )}
      </React.Fragment>
    );
  }
};

HighlightSourcesAndLayers.defaultProps = {
  azimuth: 0,
};

HighlightSourcesAndLayers.propTypes = {
  azimuth: PropTypes.number,
  segmentCoords: PropTypes.array,
  segments: PropTypes.arrayOf(PropTypes.object),
  pointCoords: PropTypes.array,
  highlightType: PropTypes.string.isRequired,
};

export default HighlightSourcesAndLayers;
